import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Panel, Row, Col, FormGroup } from 'react-bootstrap';
import DropDownListEditable from '../../components/common/DropDownListEditable';
import MeldingDetailHeader from '../../components/Meldinger/MeldingDetailHeader';
import MeldingComments from '../../components/Meldinger/MeldingComments';
import Tilbakemeldinger from '../../components/Meldinger/MeldingTilbakemeldinger';
import LeafletMapWithSearch from '../../components/Kart/LeafletMapWithSearch';
import MeldingsstatusVelger from '../MeldingsstatusVelger';
import '../../css/Meldinger/meldingDetail.css';
import Gallery from '../../components/common/Gallery';
import { addFlashMessage } from '../../actions/FlashMessagesAction';
import {
    loadEntrepreneurs,
    loadMeldingstyper,
    loadMeldingsstatuser,
    loadAvtaler,
    PutServiceavdelingerMeldingById,
    loadServiceavdelingerMeldingById,
    sendMeldingToRoute,
} from '../../actions/MeldingerActions';
import {
    loadFeilrettingskoder,
    putFeilrettingskodeForMelding,
    deleteFeilrettingskodeForMelding,
} from '../../actions/feilrettingActions';
import { loadAvtalerForEntrepreneurAdmin } from '../../actions/avtalerAction';
import { getMeldingKommentarer, addMeldingKommentarer } from '../../actions/KommentarerAction';
import { getMeldingTilbakeMeldinger, addMeldingTilbakeMelding } from '../../actions/TilbakemeldingerAction';
import { getMeldingHistorisk } from '../../actions/HistoricAction';
import { genererPdf } from '../../actions/pdfgeneratorAction';
import dateFormat from 'dateformat';
import _ from 'lodash';
import {
    formatDateByCommaSepratedDayMonthYear,
    formatDateByCommaSepratedYearMonthDay,
    formatDateTimeHavingKL,
} from '../../utils/utils';
import validateInput from '../../validations/updateSaksbehandlingMeldingValidator';
import { imageUrl, imageThumbUrl, getkontraktByAvtaleIdUrl } from '../../constants/api';
import * as responseMessages from '../../constants/ResponseMessages';
import validator from 'validator';
import RessursbrukContainter from '../Entrepreneur/ressursbrukContainer';
import { getResources } from '../../actions/RessursActions';
import AddCommentModal from '../../components/common/AddCommentModal';
import AlertModal from '../../components/common/AlertModal';
import Dokumentasjon from '../../components/common/Dokumentasjon';
import { groupMeldingstatuser } from '../../utils/meldingUtils';
import { getAvtaleId, avtaler } from '../../constants/avtaler';
import { statuskoderSomIkkeSkalTriggeTilbakemeldingsdialog } from '../../constants/Globals';

class SaksbehandlerMeldingDetails extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            meldingId: '',
            meldingstypeId: '',
            meldingsStatusId: 0,
            meldingsStatusNavn: '',
            meldingsStatusTekstmal: '',
            adresse: '-',
            latitude: 0,
            longitude: 0,
            GPSCoordinate: '',
            selskapId: '',
            lopeNummer: '',
            selskapNavn: '-',
            feilrettingskodeId: '',
            innsenderNavn: '',
            opprettetTid: '',
            innsenderEpost: '',
            avtaleNavn: '',
            data: '',
            tidsfrist: '',
            avtaleId: '0',
            opprettetAv: '',
            kontraktId: '',
            kontraktNavn: '',
            errors: {},
            popupNotAvailableModal: false,
            expandedPanel: '',
            isGeneratingPdf: false,
            touched: false,
            collapsibleKart: false,
            zoom: 18,
        };
        this.onChange = this.onChange.bind(this);
        this.onSelectEntrepreneur = this.onSelectEntrepreneur.bind(this);
        this.resetChanges = this.resetChanges.bind(this);
        this.nearByMeldinger = this.nearByMeldinger.bind(this);
        this.collapseKart = this.collapseKart.bind(this);
        this.updateGeoPosition = this.updateGeoPosition.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.changeZoom = this.changeZoom.bind(this);
        this.sendTilRouting = this.sendTilRouting.bind(this);
        this.updateMelding = this.updateMelding.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.isExpanded = this.isExpanded.bind(this);
        this.Printout = this.Printout.bind(this);
        this.showPopUpModal = this.showPopUpModal.bind(this);
        this.showAddCommentModal = this.showAddCommentModal.bind(this);
        this.showNotAvailableModal = this.showNotAvailableModal.bind(this);
        this.updateMeldingStatusRejected = this.updateMeldingStatusRejected.bind(this);
    }

    updateState(melding) {
        if (melding) {
            this.setState({
                meldingId: melding.meldingId,
                meldingstypeId: melding.meldingstypeId ? melding.meldingstypeId : '',
                meldingsStatusId: melding.meldingsStatus ? melding.meldingsStatus.id : 0,
                meldingsStatusNavn: melding.meldingsStatus ? melding.meldingsStatus.navn : '',
                meldingsStatusTekstmal: melding.meldingsStatus ? melding.meldingsStatus.tekstmal : '',
                adresse: melding.adresse,
                latitude: melding.latitude,
                longitude: melding.longitude,
                GPSCoordinate: `${melding.latitude.toFixed(6).toString()} ${melding.longitude.toFixed(6).toString()}`,
                selskapId: melding.selskap ? melding.selskap.id : '0',
                selskapNavn: melding.selskap ? melding.selskap.navn : '',
                feilrettingskodeId: melding.feilrettingskodeId,
                innsenderNavn: melding.innsenderNavn,
                opprettetTid: melding.opprettetTid,
                innsenderEpost: melding.innsenderEpost,
                opprettetAv: melding.opprettetAvEpost ? melding.opprettetAvEpost : '',
                avtaleNavn: melding.avtale ? melding.avtale.navn : '',
                data: melding.data || '',
                tidsfrist: melding.tidsfrist || '',
                avtaleId: melding.avtale ? melding.avtale.avtaleId : '0',
                beskrivelse: melding.beskrivelse,
                kontraktId: melding.kontrakt ? melding.kontrakt.kontraktId : '0',
                kontraktNavn: melding.kontrakt ? melding.kontrakt.navn : '',
                lopeNummer: melding.lopeNummer,
            });
            if (melding.selskap) {
                this.getAvtalerForEntrepreneur(melding.selskap.id);
            }
        }
    }

    toggleExpanded(panelToExpand) {
        const { expandedPanel } = this.state;
        this.setState({
            expandedPanel: expandedPanel !== panelToExpand ? panelToExpand : '',
        });
    }

    isExpanded(panelToCheck) {
        const { expandedPanel } = this.state;
        return panelToCheck === expandedPanel;
    }

    componentWillMount() {
        const meldingId = this.context.router.params.id;
        this.props.loadServiceavdelingerMeldingById(meldingId).then(res => {
            this.updateState(res);
        });
        this.props.loadEntrepreneurs();
        this.props.loadMeldingstyper();
        this.props.loadMeldingsstatuser();
        this.props.loadFeilrettingskoder();
        this.props.getResources(meldingId);
        this.props.getMeldingKommentarer(meldingId);
        this.props.getMeldingTilbakeMeldinger(meldingId);
    }

    getAvtalerForEntrepreneur(selskapkId) {
        this.props.loadAvtalerForEntrepreneurAdmin(selskapkId);
    }

    updateGeoPosition(lat, lng) {
        // console.log(lat + ", " + lng + ", " + address)
        const geoposition = `${lat.toString()} , ${lng.toString()}`;
        this.setState({
            latitude: lat,
            longitude: lng,
            GPSCoordinate: geoposition,
            touched: true,
        });
    }

    updateAddress(address) {
        this.setState({ adresse: address, touched: true });
    }

    collapseKart() {
        this.setState({ collapsibleKart: !this.state.collapsibleKart });
    }

    isValid(data) {
        const { isValid, errors } = validateInput(data);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    updateMelding() {
        this.updateMeldingApiCall();
    }

    updateViewModel(m, meldingstypeId, meldingsStatusId, avtaleId) {
        return Object.assign(
            {},
            {
                meldingstypeId: meldingstypeId || m.meldingstypeId,
                meldingsStatusId: meldingsStatusId || m.meldingsStatusId,
                adresse: m.adresse,
                latitude: m.latitude,
                longitude: m.longitude,
                selskapId: m.selskapId,
                avtaleId: avtaleId || (m.avtaleId === '0' ? null : m.avtaleId),
                GPSCoordinate: m.GPSCoordinate,
            }
        );
    }

    async updateMeldingApiCall(meldingstypeId, meldingsStatusId, avtaleId) {
        const melding = this.updateViewModel(this.state, meldingstypeId, meldingsStatusId, avtaleId);
        const { meldingId } = this.state;
        if (this.isValid(melding)) {
            try {
                await this.props.PutServiceavdelingerMeldingById(meldingId, melding);
                this.props.addFlashMessage({
                    type: 'success',
                    text: responseMessages.meldingIsupdated,
                });
                this.props.loadServiceavdelingerMeldingById(meldingId).then(res => {
                    this.updateState(res);
                });
                this.props.getMeldingHistorisk(meldingId);
                this.setState({ touched: false, zoom: 14, errors: {} });
                return false;
            } catch (err) {
                this.props.addFlashMessage({
                    type: 'error',
                    text: err.response.data.errorMessage,
                });
                return true;
            }
        }
    }

    changeZoom() {
        this.setState({ zoom: 18 });
    }

    resetChanges() {
        this.props.loadServiceavdelingerMeldingById(this.state.meldingId).then(res => {
            this.updateState(res);
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, touched: true });
    }

    nearByMeldinger(e) {
        e.preventDefault();
        alert('Dette er ikke implementert på backend');
    }

    updateMeldingstypeId = val => {
        this.updateMeldingApiCall(val, undefined, undefined);
    };

    showPopUpModal() {
        this.setState({ popupTilbakeMelding: !this.state.popupTilbakeMelding });
    }

    showAddCommentModal() {
        this.setState({ popupAddComment: !this.state.popupAddComment });
    }

    showNotAvailableModal() {
        this.setState({
            popupNotAvailableModal: !this.state.popupNotAvailableModal,
        });
    }

    updateMeldingsStatusId = async val => {
        const { meldingsstatuser } = this.props;
        const { selskapId } = this.state;

        if (val === '3') {
            this.showAddCommentModal();
        } else if (val === '2' && selskapId === '0') {
            this.showNotAvailableModal();
        } else {
            const apiErrorred = await this.updateMeldingApiCall(undefined, val, undefined);
            if (!apiErrorred) {
                const status = _.find(meldingsstatuser, m => m.id.toString() === val);
                this.setState({
                    meldingsStatusId: Number(val),
                    meldingsStatusTekstmal: status ? status.tekstmal : '',
                });

                if (Number(val) >= 4 && statuskoderSomIkkeSkalTriggeTilbakemeldingsdialog.indexOf(Number(val)) === -1) {
                    this.showPopUpModal();
                }
            } else {
                this.onCancelUpdateStatus();
                // remove the focus from the status chooser
                this.statusvelger.cancel();
            }
        }
    };

    updateMeldingStatusRejected() {
        this.updateMeldingApiCall(undefined, 3, undefined);
    }

    onCancelUpdateStatus = () => {
        const { melding } = this.props;
        this.setState({
            meldingsStatusId: melding.meldingsStatus ? melding.meldingsStatus.id : 0,
        });
        this.statusvelger.onChange(melding.meldingsStatus ? melding.meldingsStatus.id : 0);
    };

    onSelectEntrepreneur(e) {
        const val = e.target.value;

        if (val !== '0') {
            this.getAvtalerForEntrepreneur(val);
            this.setState({ selskapId: val, avtaleId: '0', touched: true });
            this.avtaleInput.focus();
        }
    }

    resetFeilrettingskode = async () => {
        const { meldingId } = this.state;
        this.setState({ feilrettingskodeId: undefined, touched: true });
        await this.props.deleteFeilrettingskodeForMelding(meldingId);
    };

    onSaveFeilrettingskode = async val => {
        const { meldingId } = this.state;
        try {
            if (val == '0') {
                await this.resetFeilrettingskode();
            } else {
                await this.props.putFeilrettingskodeForMelding(meldingId, val);
            }

            this.props.addFlashMessage({
                type: 'success',
                text: responseMessages.meldingIsupdated,
            });

            this.setState({ feilrettingskodeId: val, touched: true });
            return false;
        } catch (err) {
            this.props.addFlashMessage({
                type: 'error',
                text: err ? err.response.data.errorMessage : 'Ukjent feil oppstått!',
            });
            return true;
        }
    };

    updateAvtaler = async val => {
        const currentAvtaleId = this.props.melding.avtale ? this.props.melding.avtale.avtaleId : '0';
        const currentSelskapId = this.props.melding.selskap ? this.props.melding.selskap.id : '0';

        if (currentAvtaleId === val) {
            this.setState({
                avtaleId: currentAvtaleId,
                selskapId: currentSelskapId,
            });
        } else {
            this.updateMeldingApiCall(undefined, undefined, val);
            this.resetFeilrettingskode();
        }
    };

    updateAvtalerBlur = () => {
        const currentAvtaleId = this.props.melding.avtale ? this.props.melding.avtale.avtaleId : '0';
        const currentSelskapId = this.props.melding.selskap ? this.props.melding.selskap.id : '0';
        this.setState({
            avtaleId: currentAvtaleId,
            selskapId: currentSelskapId,
        });
        if (currentSelskapId !== '0') {
            this.getAvtalerForEntrepreneur(currentSelskapId);
        }
    };

    Printout(lopenummer) {
        this.setState({ isGeneratingPdf: true });
        const meldingId = this.context.router.params.id;
        const join = `MeldingId=${meldingId}`;
        this.props
            .genererPdf(join, 'Bymelding_' + dateFormat(Date.now(), 'yyyymmdd_HHMMss') + '_' + lopenummer + '.pdf')
            .finally(() => {
                this.setState({ isGeneratingPdf: false });
            })
            .catch(err => {
                throw err;
            });
    }

    sendTilRouting(e) {
        e.preventDefault();
        const { meldingId } = this.state;
        this.props
            .sendMeldingToRoute(meldingId)
            .then(res => {
                const prosessert = res.data.prosessert;
                if (prosessert) {
                    this.props.addFlashMessage({
                        type: 'success',
                        text: responseMessages.meldingIsRouted,
                    });
                    this.props.loadServiceavdelingerMeldingById(meldingId).then(res => {
                        this.updateState(res);
                    });
                } else {
                    this.props.addFlashMessage({
                        type: 'error',
                        text: responseMessages.meldingIsNotRouted,
                    });
                }
            })
            .catch(err => {
                this.props.addFlashMessage({
                    type: 'error',
                    text: `${responseMessages.meldingIsNotRouted}`,
                });
            });
    }

    getSizeOfImages(melding) {
        return melding.bildeIds ? _.size(melding.bildeIds) : 0;
    }

    getSizeOfDokumentasjon(melding) {
        return melding.dokumentasjonIds ? _.size(melding.dokumentasjonIds) : 0;
    }

    getRessursCount() {
        const ressursforbruk = this.props.resources ? this.props.resources.ressursforbruk : {};
        return ressursforbruk ? _.size(ressursforbruk) : 0;
    }

    getTilbakeMeldingerCount() {
        const counttilbakemeldinger = this.props.tilbakemeldinger;
        return counttilbakemeldinger ? _.size(counttilbakemeldinger) : 0;
    }

    renderImages(images) {
        const imagesData = [];
        _.map(images, i => {
            const imgUrl = imageUrl(i);
            const thumbUrl = imageThumbUrl(i);
            imagesData.push(Object.assign({}, { src: imgUrl, thumbSrc: thumbUrl }));
        });
        return <Gallery images={imagesData} id="meldingDetailsGallery" />;
    }

    meldingKategori(meldingsTypeId) {
        const meldingsKategori = _.first(
            _.filter(this.props.meldingstyper, {
                meldingstypeId: meldingsTypeId,
            })
        );
        return meldingsKategori ? meldingsKategori.beskrivelse : '';
    }
    render() {
        const {
            meldingId,
            meldingstypeId,
            meldingsStatusId,
            meldingsStatusNavn,
            meldingsStatusTekstmal,
            innsenderNavn,
            innsenderEpost,
            adresse,
            opprettetTid,
            latitude,
            expandedPanelOne,
            expandedPanelTwo,
            expandedPanelThree,
            expandedPanelFour,
            avtaleNavn,
            data,
            tidsfrist,
            avtaleId,
            beskrivelse,
            longitude,
            selskapId,
            selskapNavn,
            feilrettingskodeId,
            opprettetAv,
            GPSCoordinate,
            kontraktNavn,
            kontraktId,
            lopeNummer,
            popupAddComment,
            popupNotAvailableModal,
            popupTilbakeMelding,
            isGeneratingPdf,
            errors,
        } = this.state;
        const {
            melding,
            meldingstyper,
            meldingsavtaler,
            meldingsstatuser,
            entrepreneurs,
            feilrettingskoder,
        } = this.props;
        if (meldingId.length === 0) return null;
        const mottattDato = melding ? formatDateTimeHavingKL(opprettetTid) : '-';
        const meldingstyperItems = _.sortBy(
            _.map(meldingstyper, m => {
                return { id: m.meldingstypeId, navn: m.beskrivelse };
            }),
            'navn'
        );

        const deaktiverteAvtaler = [
            {
                id: '3782fa75-ceb6-4154-9dbe-45a6cfdec950', // arbeidsordre
                tooltip: 'Deaktivert - Meldinger til Arbeidsordre må meldes via bymelding.no',
            },
        ];
        const avtalerItems = _.sortBy(
            _.map(_.filter(meldingsavtaler, { selskapId }), m => {
                return { id: m.avtaleId, navn: m.navn, deaktivert: _.find(deaktiverteAvtaler, { id: m.avtaleId }) };
            }),
            'navn'
        );

        const entrepreneurItems = _.sortBy(entrepreneurs, 'navn');
        const bilder = `Bilder (${this.getSizeOfImages(melding)})`;
        const dokumentasjon = `Dokumentasjon (${this.getSizeOfDokumentasjon(melding)})`;

        const pildown = 'glyphicon-chevron-down';
        const pilright = 'glyphicon-chevron-right';
        const ressurs = `Ressursbruk (${this.getRessursCount()})`;
        const tilbakeMeldinger = `Meldinger til innmelder (${this.getTilbakeMeldingerCount()})`;

        const filteredFeilrettingskoder = feilrettingskoder?.filter(f => f.avtaleId === this.state.avtaleId) ?? [];
        const isArbeidUtfortVisible = filteredFeilrettingskoder.length > 0;

        return (
            <div>
                <div className="mainContainer">
                    <button
                        className="btn btn-default pull-right tilbakeMeldingBackButton active"
                        id="tilbakeMeldingBackButton"
                        onClick={() => {
                            this.context.router.goBack();
                        }}
                    >
                        <span className="glyphicon glyphicon-remove color-orange" />
                        <b>LUKK</b>
                    </button>
                    <div className="submainContainer" id="submainContainer">
                        <button
                            className="btn btn-default pull-left"
                            id="printOutButton"
                            disabled={isGeneratingPdf ? 'disabled' : ''}
                            onClick={() => this.Printout(lopeNummer)}
                        >
                            {isGeneratingPdf && <span className="glyphicon glyphicon-refresh bym-glyphicon-spin" />}
                            {!isGeneratingPdf && <span className="glyphicon glyphicon-print" />} <span>Skriv ut</span>
                        </button>

                        <button
                            className="btn btn-default pull-right"
                            id="sendTiRutingButton"
                            onClick={this.sendTilRouting}
                        >
                            Tildel på nytt
                        </button>
                        <div className="tilbakemeldingerContainer">
                            <div className="topBannerWrapper" id="topBannerWrapper">
                                <MeldingDetailHeader
                                    innsenderNavn={innsenderNavn}
                                    opprettetAv={opprettetAv}
                                    innsenderEpost={innsenderEpost}
                                    lopeNummer={lopeNummer}
                                    mottattDato={mottattDato}
                                    enterpreneurId={selskapId}
                                    selskapNavn={selskapNavn}
                                    meldingsStatusNavn={meldingsStatusNavn}
                                    kontraktNavn={kontraktNavn}
                                    avtaleNavn={avtaleNavn}
                                    data={data}
                                    tidsfrist={tidsfrist}
                                    kontraktId={kontraktId}
                                    address={adresse}
                                    GPSCoordinate={GPSCoordinate}
                                    meldingsStatusId={meldingsStatusId}
                                    isEntreprenor={false}
                                />
                            </div>

                            <div>
                                <div className="meldingDescription">
                                    <span>
                                        <label>Beskrivelse</label>
                                    </span>
                                    <br />
                                    <span id="sendmeldingBeskrivelse">{beskrivelse}</span>
                                </div>
                                <Row className="sendMeldingDropdownBoxes">
                                    <Col md={3}>
                                        <DropDownListEditable
                                            Items={meldingstyperItems}
                                            selected={meldingstypeId}
                                            id="melding-meldingstype"
                                            label="Meldingstype"
                                            onSubmit={this.updateMeldingstypeId}
                                        />
                                    </Col>
                                    <Col md={isArbeidUtfortVisible ? 2 : 3}>
                                        {meldingsstatuser && meldingsstatuser.length > 0 && (
                                            <MeldingsstatusVelger
                                                statuser={meldingsstatuser}
                                                popupAddComment={popupAddComment}
                                                valgtStatus={meldingsStatusId}
                                                meldingsStatusId={meldingsStatusId}
                                                error={errors.meldingsStatusId}
                                                onSubmit={this.updateMeldingsStatusId}
                                                ref={node => (this.statusvelger = node)}
                                            />
                                        )}
                                    </Col>
                                    {isArbeidUtfortVisible && (
                                        <Col md={2}>
                                            <DropDownListEditable
                                                Items={filteredFeilrettingskoder}
                                                id="melding-arbeid-utfort"
                                                label="Arbeid utført"
                                                title="-----------"
                                                selected={feilrettingskodeId}
                                                onSubmit={this.onSaveFeilrettingskode}
                                                error={errors.feilrettingskodeId}
                                            />
                                        </Col>
                                    )}
                                    <Col md={isArbeidUtfortVisible ? 2 : 3}>
                                        <DropDownListEditable
                                            Items={entrepreneurItems}
                                            id="melding-entreprenør"
                                            label="Entreprenør"
                                            title="-----------"
                                            selected={selskapId}
                                            onChange={this.onSelectEntrepreneur}
                                            error={errors.selskapId}
                                            withoutButtons
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <DropDownListEditable
                                            Items={avtalerItems}
                                            id="melding-avtale"
                                            error={errors.avtaleId}
                                            label="Avtale"
                                            title="-----------"
                                            selected={avtaleId}
                                            onSubmit={this.updateAvtaler}
                                            ref={r => (this.avtaleInput = r)}
                                            onBlur={this.updateAvtalerBlur}
                                        />
                                    </Col>
                                </Row>
                                <AddCommentModal
                                    confirmText=""
                                    modalTitle="Endre Status til avvist"
                                    showAddComment={this.showAddCommentModal}
                                    popup={popupAddComment}
                                    addKommentar={this.props.addMeldingKommentarer}
                                    meldingId={meldingId}
                                    updateStatus={this.updateMeldingStatusRejected}
                                    onCancelUpdateStatus={this.onCancelUpdateStatus}
                                />

                                <AlertModal
                                    open={popupNotAvailableModal}
                                    onClose={this.showNotAvailableModal}
                                    headerText="Denne handlingen er ikke tillat"
                                    bodyText={
                                        'Saken må være tildelt en entreprenør for å kunne få status "Ikke behandlet".'
                                    }
                                    confirmText="Ok"
                                />
                            </div>
                            <div className="clearfix" />

                            <div className="tilbakeMeldingerMap">
                                <div className="clearfix" />
                                <Accordion id="tilbakepanelheading">
                                    <Panel
                                        onSelect={() => this.toggleExpanded('ressurs')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('ressurs') ? pildown : pilright
                                                    }`}
                                                />
                                                {ressurs}
                                            </strong>
                                        }
                                        eventKey="1"
                                    >
                                        {kontraktId !== '0' ? (
                                            <RessursbrukContainter
                                                editable={false}
                                                meldingId={meldingId}
                                                avtaleId={avtaleId}
                                                kontraktId={kontraktId}
                                            />
                                        ) : (
                                            <div> Meldingen har ingen kontrakt.</div>
                                        )}
                                    </Panel>

                                    <Panel
                                        onSelect={() => this.toggleExpanded('bilder')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('bilder') ? pildown : pilright
                                                    }`}
                                                />
                                                {bilder}
                                            </strong>
                                        }
                                        eventKey="2"
                                    >
                                        <div className="photoGallery">
                                            <div className="clearfix" />
                                            {this.renderImages(melding.bildeIds)}
                                        </div>

                                        {/* <ImageUploader 
                                            bilder={bilder} addImage={this.addImage} removeImage={this.removeImage}
                                        /> */}
                                    </Panel>
                                    <Panel
                                        onSelect={() => this.toggleExpanded('dokumentrasjon')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('dokumentrasjon') ? pildown : pilright
                                                    }`}
                                                />
                                                {dokumentasjon}
                                            </strong>
                                        }
                                        eventKey="20"
                                    >
                                        <Dokumentasjon
                                            existingDocuments={melding.dokumentasjonIds}
                                            meldingId={meldingId}
                                        />
                                    </Panel>
                                    <Panel
                                        onSelect={() => this.toggleExpanded('tilbakeMeldinger')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('tilbakeMeldinger') ? pildown : pilright
                                                    }`}
                                                />
                                                {tilbakeMeldinger}
                                            </strong>
                                        }
                                        eventKey="3"
                                    >
                                        <Tilbakemeldinger
                                            meldingId={meldingId}
                                            lopenummer={lopeNummer}
                                            meldingStatusId={meldingsStatusId}
                                            meldingsstatuser={meldingsstatuser}
                                            tekstmal={meldingsStatusTekstmal}
                                            tilbakemeldinger={this.props.tilbakemeldinger}
                                            innsenderNavn={opprettetAv}
                                            innsenderEpost={innsenderEpost}
                                            addTilbakemelding={this.props.addMeldingTilbakeMelding}
                                            popup={popupTilbakeMelding}
                                            showPopUpModal={this.showPopUpModal}
                                            mottattDato={mottattDato}
                                            meldingsType={this.meldingKategori(meldingstypeId)}
                                            adresse={adresse}
                                            beskrivelse={beskrivelse}
                                        />
                                    </Panel>

                                    <Panel
                                        onSelect={() => this.toggleExpanded('kart')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('kart') ? pildown : pilright
                                                    }`}
                                                />
                                                Kart
                                            </strong>
                                        }
                                        eventKey="4"
                                        onEntering={() => this.map.invalidateMap()}
                                    >
                                        {/* <button className="btn btn-link form-group" id="meldingerButton" onClick={this.showSearchField}>Vis meldinger i nærheten</button> */}
                                        <LeafletMapWithSearch
                                            addressInput="outside"
                                            ref={r => (this.map = r)}
                                            address={adresse}
                                            latitude={latitude}
                                            longitude={longitude}
                                            updateMelding={this.updateMelding}
                                            updateAddress={this.updateAddress}
                                            updateGeoPosition={this.updateGeoPosition}
                                            addFlashMessage={this.props.addFlashMessage}
                                        />
                                    </Panel>
                                </Accordion>

                                <div className="row">
                                    <div className="col-md-12">
                                        <MeldingComments
                                            meldingId={meldingId}
                                            kommentarer={this.props.kommentarer}
                                            getMeldingHistorisk={this.props.getMeldingHistorisk}
                                            historicList={this.props.historic}
                                            addKommentar={this.props.addMeldingKommentarer}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SaksbehandlerMeldingDetails.propTypes = {
    melding: PropTypes.object,
    loadServiceavdelingerMeldingById: PropTypes.func.isRequired,
    PutServiceavdelingerMeldingById: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func,
    meldingstyper: PropTypes.array,
    meldingsavtaler: PropTypes.array,
    meldingsstatuser: PropTypes.array,
    entrepreneurs: PropTypes.array,
    feilrettingskoder: PropTypes.array,
    putFeilrettingskodeForMelding: PropTypes.func.isRequired,
    deleteFeilrettingskodeForMelding: PropTypes.func.isRequired,
    loadEntrepreneurs: PropTypes.func.isRequired,
    loadMeldingstyper: PropTypes.func.isRequired,
    loadMeldingsstatuser: PropTypes.func.isRequired,
    sendMeldingToRoute: PropTypes.func.isRequired,
    // loadAvtaler: PropTypes.func.isRequired,
    // loadAvtalerForEntrepreneur: PropTypes.func.isRequired,
    loadAvtalerForEntrepreneurAdmin: PropTypes.func.isRequired,
    genererPdf: PropTypes.func.isRequired,
    kommentarer: PropTypes.array,
    getMeldingKommentarer: PropTypes.func.isRequired,
    addMeldingKommentarer: PropTypes.func.isRequired,
    tilbakemeldinger: PropTypes.array,
    getMeldingTilbakeMeldinger: PropTypes.func.isRequired,
    addMeldingTilbakeMelding: PropTypes.func,
    getMeldingHistorisk: PropTypes.func.isRequired,
    resources: PropTypes.object,
    getResources: PropTypes.func.isRequired,
    historic: PropTypes.array,
};

const mapStateToProps = state => {
    return {
        melding: state.meldinger.melding,
        meldinger: state.meldinger.meldingerList,
        meldingstyper: state.meldinger.meldingstyper,
        // meldingsavtaler: state.meldinger.meldingssoner,
        resources: state.ressursBruk.resources,
        meldingsavtaler: state.contractZones,
        meldingsstatuser: state.meldinger.meldingsstatuser,
        entrepreneurs: state.meldinger.entrepreneurs,
        feilrettingskoder: state.feilrettingskoder,
        kommentarer: state.kommentarer,
        tilbakemeldinger: state.tilbakemeldinger,
        historic: state.historisk,
    };
};
SaksbehandlerMeldingDetails.contextTypes = {
    router: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            loadServiceavdelingerMeldingById,
            PutServiceavdelingerMeldingById,
            addFlashMessage,
            genererPdf,
            loadAvtalerForEntrepreneurAdmin,
            loadEntrepreneurs,
            loadMeldingstyper,
            loadMeldingsstatuser,
            loadAvtaler,
            loadFeilrettingskoder,
            putFeilrettingskodeForMelding,
            deleteFeilrettingskodeForMelding,
            sendMeldingToRoute,
            getMeldingKommentarer,
            addMeldingKommentarer,
            getMeldingTilbakeMeldinger,
            addMeldingTilbakeMelding,
            getMeldingHistorisk,
            getResources,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SaksbehandlerMeldingDetails);
