import delay from './delay';

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
export let users = [{
    id: '3a473add-2e7a-4a6d-9ac8-ac3e6bb4d6b6',
    navn: 'Anne Kari Mikkelsen',
    epost: 'anne.mikkelsen@bym.oslo.kommune.no',
    selskapsRolle: { rolleId: '3cfbfb8c-7f32-4c47-a4a5-7e55dc0690f3', navn: 'Entreprenor' },
}, {
    id: '4cd15c9f-5e9d-4a59-a041-e8987f8d5345',
    navn: 'Beate Volden',
    epost: 'beate.volden@bym.oslo.kommune.no',
    selskapsRolle: { rolleId: '5bd99df0-55f2-4888-946c-e7a826eee3b4', navn: 'EntreprenorAdmin' }
}, {
    id: '3a473add-2e7a-4a6d-9ac8-ac3e6bb4d6b7',
    navn: 'Diako Kezri',
    epost: 'diako.kezri@maakeselskap.no',
    selskapsRolle: { rolleId: '3cfbfb8c-7f32-4c47-a4a5-7e55dc0690f3', navn: 'Entreprenor' }
}, {
    id: '4cd15c9f-5e9d-4a59-a041-e8987f8d5348',
    navn: 'Erik Karlsen',
    epost: 'amund.odegaard@erik.karlsen.betongselskap.no',
    selskapsRolle: { rolleId: '5bd99df0-55f2-4888-946c-e7a826eee3b4', navn: 'EntreprenorAdmin' }
}];

function replaceAll(str, find, replace)
{
    return str.replace(new RegExp(find, 'g'), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (user) =>
{
    return replaceAll(user.navn, ' ', '-');
};

class UserApi
{
    static getAllUsers()
    {
        return new Promise((resolve, reject) =>
        {
            setTimeout(() =>
            {
                resolve(Object.assign([], users));
            }, delay);
        });
    }

    static saveUser(user)
    {
        return new Promise((resolve, reject) =>
        {
            setTimeout(() =>
            {
                // Simulate server-side validation
                const minUserNameLength = 1;
                if (user.name.length < minUserNameLength)
                {
                    reject(`Name must be at least ${minUserNameLength} characters.`);
                }

                if (user.id)
                {
                    const existingUserIndex = users.findIndex(a => a.id === user.id);
                    users.splice(existingUserIndex, 1, user);
                } else
                {
                    //Just simulating creation here.
                    //The server would generate ids for new users in a real app.
                    //Cloning so copy returned is passed by value rather than by reference.
                    user.id = generateId(user);

                    users.push(user);
                }

                resolve(Object.assign({}, user));
            }, delay);
        });
    }

    static deleteUser(user)
    {
        const userId = user ? user.id : '';
        return new Promise((resolve) =>
        {
            setTimeout(() =>
            {
                const indexOfUserToDelete = users.findIndex(user =>
                {
                    return user.id === userId;
                });
                users.splice(indexOfUserToDelete, 1);
                resolve();
            }, delay);
        });
    }
}

export default UserApi;
