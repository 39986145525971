import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import AvtaleSelectDropdownList from '../AvtaleSelectDropdownList';
import { getRollerForSelskapsType } from '../../../utils/globalsHelper';
import { selskapsTypeEntreprenorerId } from '../../../constants/Globals';

import '../../../css/user/NewUserForm.css';

class NewUserForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.nameIsValid = this.nameIsValid.bind(this);
        this.emailIsValid = this.emailIsValid.bind(this);
        this.roleIsValid = this.roleIsValid.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.rolleOptions = this.rolleOptions.bind(this);
        this.userExistsError = this.userExistsError.bind(this);
        this.userExistsMessage = this.userExistsMessage.bind(this);
        this.onChangeAvtale = this.onChangeAvtale.bind(this);

        this.state = this.initialState();
    }

    initialState() {
        return {
            fornavn: '',
            fornavnTouched: false,

            etternavn: '',
            etternavnTouched: false,

            email: '',
            emailTouched: false,

            role: this.rolleOptions()[0],
            roleTouched: false,
            avtaler: [],
        };
    }

    saveAndClose() {
        if (this.nameIsValid() && this.emailIsValid() && this.roleIsValid()) {
            this.props.onSubmit({
                id: '',
                fornavn: this.state.fornavn,
                etternavn: this.state.etternavn,
                navn: this.state.fornavn + ' ' + this.state.etternavn,
                epost: this.state.email,
                selskapsRolle: this.state.role,
                avtaler: this.state.avtaler,
            });
        } else {
            this.setState({
                fornavnTouched: true,
                roleTouched: true,
                emailTouched: true,
            });
        }
    }

    changeHandler(event) {
        const { value } = event.target;
        switch (event.target.id) {
            case 'formControlsFornavn':
                this.setState({ fornavn: value, fornavnTouched: true });
                break;
            case 'formControlsEtternavn':
                this.setState({ etternavn: value, etternavnTouched: true });
                break;
            case 'formControlsEmail':
                this.setState({ email: value, emailTouched: true });
                break;
            case 'formControlsSelect':
                this.setState({
                    role: this.rolleOptions().find(x => x.rolleId === value),
                    roleTouched: true,
                });
                break;
            default:
        }
    }

    nameIsValid() {
        return this.state.fornavn.length > 0;
    }

    userExistsError() {
        return this.props.error && this.props.error.startsWith('Duplisert objekt');
    }

    userExistsMessage() {
        if (this.props.error && this.props.error.startsWith('Duplisert objekt')) {
            return this.props.error.split('Duplisert objekt. Selskaps')[1];
        }
        return '';
    }

    emailIsValid() {
        if (this.userExistsError()) return false;

        const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return regex.test(this.state.email);
    }

    roleIsValid() {
        return this.state.role.rolleId !== '0';
    }

    rolleOptions() {
        const filterRolles = getRollerForSelskapsType(this.props.selskapsType);
        return [{ rolleId: '0', navn: 'Velg Rolle' }, ...filterRolles];
    }

    onChangeAvtale(values) {
        this.setState({ avtaler: values });
    }

    render() {
        const { submitButtonText, onSubmit, error, avtaler, selskapsType } = this.props;
        const { email, emailTouched, fornavn, etternavn, fornavnTouched, role, roleTouched } = this.state;
        const roller = this.rolleOptions();
        const showInvalidEmail = !this.emailIsValid() && emailTouched && !error;
        const hasavtaler = selskapsType === selskapsTypeEntreprenorerId;
        const formInstance = (
            <form className="newUserForm">
                <FormGroup
                    controlId="formControlsFornavn"
                    validationState={this.nameIsValid() || !fornavnTouched ? null : 'error'}
                >
                    <ControlLabel>Fornavn</ControlLabel>
                    <FormControl
                        type="text"
                        value={fornavn}
                        onChange={this.changeHandler}
                        onBlur={this.changeHandler}
                    />
                    {!this.nameIsValid() && fornavnTouched && <HelpBlock>Fornavn kan ikke være tom</HelpBlock>}
                </FormGroup>
                <FormGroup controlId="formControlsEtternavn">
                    <ControlLabel>Etternavn</ControlLabel>
                    <FormControl
                        type="text"
                        value={etternavn}
                        onChange={this.changeHandler}
                        onBlur={this.changeHandler}
                    />
                </FormGroup>
                <FormGroup
                    controlId="formControlsEmail"
                    validationState={this.emailIsValid() || !emailTouched ? null : 'error'}
                >
                    <ControlLabel>E-post / brukernavn</ControlLabel>
                    <FormControl type="email" value={email} onChange={this.changeHandler} onBlur={this.changeHandler} />
                    {showInvalidEmail && <HelpBlock>Må være en gyldig e-post</HelpBlock>}
                    {this.userExistsError() && <HelpBlock>{this.userExistsMessage()}</HelpBlock>}
                </FormGroup>
                <FormGroup
                    controlId="formControlsSelect"
                    validationState={this.roleIsValid() || !roleTouched ? null : 'error'}
                >
                    <ControlLabel>Rolle</ControlLabel>
                    <FormControl
                        componentClass="select"
                        placeholder="select"
                        value={role.rolleId}
                        onChange={this.changeHandler}
                    >
                        {roller.map(role => {
                            return (
                                <option key={role.rolleId} value={role.rolleId}>
                                    {role.navn}
                                </option>
                            );
                        })}
                    </FormControl>
                    {!this.roleIsValid() && roleTouched && <HelpBlock>Rolle må være valgt</HelpBlock>}
                    {hasavtaler && (
                        <AvtaleSelectDropdownList
                            selectedValues={this.state.avtaler}
                            onChange={this.onChangeAvtale}
                            avtaler={avtaler}
                        />
                    )}
                </FormGroup>
            </form>
        );

        return (
            <div className="newUserButtons">
                <div className="modal-body">{formInstance}</div>
                <div className="modal-footer">
                    {!this.userExistsError() && error && (
                        <div className="error">
                            <p>{error}</p>
                        </div>
                    )}
                    <div>
                        <Button
                            className="pull-left"
                            bsStyle="link"
                            onClick={() => {
                                onSubmit(null);
                            }}
                        >
                            Avbryt
                        </Button>
                        <Button
                            className="pull-left"
                            bsStyle="primary"
                            bsSize="large"
                            type="submit"
                            id="submitButton"
                            onClick={this.saveAndClose}
                        >
                            {submitButtonText}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

NewUserForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    avtaler: PropTypes.array,
    error: PropTypes.string,
    selskapsType: PropTypes.number,
};

export default NewUserForm;
