import initialState from "./initialState"
import {LOAD_MELDING_TILBAKEMELDINGER,ADD_MELDING_TILBAKEMELDINGER} from "../actions/ActionTypes"

export default function tilbakeMeldingerReducer (state =initialState.tilbakemeldinger , action)  {
    switch (action.type) {
        case LOAD_MELDING_TILBAKEMELDINGER:
            return action.feedbacks

        case ADD_MELDING_TILBAKEMELDINGER:{
            return [...state, action.feedback]
        }
        default:
            return state
    }
}