import validator from "validator";
import isEmpty from "lodash/isEmpty";
import * as validationErrors from "../constants/ValidationErrors"

export default function validateInput(data) {
    let errors = {};
    /* if (validator.isEmpty(data.innsenderEpost)) {
         errors.innsenderEpost = validationErrors.EmailIsRequired;
     }*/
     if (!validator.isEmpty(data.innsenderEpost) && !validator.isEmail(data.innsenderEpost)) {
         errors.innsenderEpost = validationErrors.EmailIsNotValid;
     }
    if (data.beskrivelse && data.beskrivelse.length > 300) {
        errors.beskrivelse = validationErrors.meldingBeskrivelseTextLength;
    }


    // else if (!validator.isNumeric(data.beskrivelse.toString()))
    // {
    //     errors.beskrivelse = validationErrors.PhoneNumberIsNotValid;
    // }
    // else if (validator.isNumeric(data.beskrivelse.toString()))
    // {
    //     if (data.beskrivelse.length < 8)
    //     {
    //         errors.beskrivelse = validationErrors.PhoneNumberIsNotValid;
    //     }
    // }

    if (data.meldingstypeId === "0") {
        errors.meldingstypeId = validationErrors.meldingsTypeIsRequired;
    }
    if (validator.isEmpty(data.adresse)) {
        errors.adresse = validationErrors.AddressIsRequired;
    }
    return {
        errors,
        isValid: isEmpty(errors)
    }
}