import validator from "validator";
import isEmpty from "lodash/isEmpty";

export default function validateInput(data){
    const errors = {};
    if(validator.isEmpty(data.nyttPassord)){
        errors.nyttPassord="Nytt passord er påkrevd";
    }
    if(validator.isEmpty(data.gjentanyttPassord)){
        errors.gjentanyttPassord="Gjenta nytt passord er påkrevd!";
    }
    else if(!validator.equals(data.nyttPassord, data.gjentanyttPassord)){
        errors.gjentanyttPassord="Begge passord må være like!";
    }
    else if(!validator.isLength(data.nyttPassord,6))
    {
        errors.nyttPassord="Nytt passord må være minst 6 karakterer!";
    }
    return {
        errors,
        isValid: isEmpty(errors)
    }
} 