import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { Glyphicon } from 'react-bootstrap';
import './AdresseSok.css';

let cancelAdressesokRequest;
const { CancelToken } = axios;

class AdresseSok extends Component {
    constructor(props) {
        super(props);
        this.state = {
            soketekst: '',
            sokResultat: [],
            apiInstance: undefined,
            activeItem: 0,
            showSuggestions: false,
            loading: false,
        };
        this.adresseSok = this.adresseSok.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateLocation = this.updateLocation.bind(this);
        this.handleKeybordEvent = this.handleKeybordEvent.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        const { geodata } = this.props;
        const instance = axios.create();
        delete instance.defaults.headers['X-Bymelding-Client'];
        delete instance.defaults.headers.common.Authorization;
        this.setState({
            apiInstance: instance,
            soketekst: geodata.display_name || '',
            clearButton: !!geodata.display_name,
        });
    }

    componentDidUpdate(prevProps) {
        const { geodata } = this.props;
        if (geodata.display_name !== prevProps.geodata.display_name) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                soketekst: geodata.display_name || '',
                clearButton: !!geodata.display_name,
            });
        }
    }

    onChange(value) {
        const { locationSeleted } = this.props;
        if (value === '') {
            locationSeleted(null);
        }
        this.adresseSok(value);
    }

    adresseSok(soketekst) {
        const { apiInstance } = this.state;
        this.setState({ soketekst });
        if (cancelAdressesokRequest !== undefined) {
            cancelAdressesokRequest();
        }
        if (soketekst.length > 3 && apiInstance) {
            this.setState({ loading: true });
            const url = 'https://ws.geonorge.no/adresser/v1/sok';
            const star = /\d/.test(soketekst) ? '' : '*';
            const sokeParams = `${soketekst}${star}`;
            apiInstance
                .get(`${url}?sok=${sokeParams}&sokemodus=AND&kommunenummer=0301&treffPerSide=10&asciiKompatibel=true`, {
                    cancelToken: new CancelToken(c => {
                        cancelAdressesokRequest = c;
                    }),
                })
                .then(res => {
                    if (res.data.adresser) {
                        this.setState({
                            showSuggestions: true,
                            sokResultat: sortBy(res.data.adresser, ['adressenavn', 'nummer', 'bokstav']),
                            activeItem: 0,
                        });
                        this.setState({ loading: false });
                    }
                })
                .catch(e => {})
                .finally(() => {});
        } else {
            this.setState({ sokResultat: [] });
        }
    }

    handleKeybordEvent(e) {
        const { activeItem, sokResultat } = this.state;
        let i = 0;
        if (e.key === 'Enter' && sokResultat.length > 0) {
            this.updateLocation(sokResultat[activeItem]);
        }
        if (e.key === 'ArrowUp') {
            i = activeItem - 1;
        }
        if (e.key === 'ArrowDown') {
            i = activeItem + 1;
        }
        if (e.key === 'Home') {
            i = 0;
        }
        if (e.key === 'End') {
            i = sokResultat.length - 1;
        }
        if (e.key === 'Escape') {
            this.setState({ sokResultat: [], soketekst: '' });
        }
        if (i >= 0 && i < sokResultat.length) {
            this.setState({ activeItem: i });
        }
    }

    handleBlur() {
        this.setState({ showSuggestions: false });
        // const { activeItem, sokResultat } = this.state;
        // if (
        //   sokResultat.length > 0 &&
        //   activeItem >= 0 &&
        //   activeItem < sokResultat.length
        // ) {
        //   this.updateLocation(sokResultat[activeItem]);
        // }
    }

    updateLocation(e) {
        const { locationSeleted } = this.props;
        this.setState({ soketekst: e.adressetekst, sokResultat: [] });
        const geoLocation = {
            display_name: e.adressetekst,
            lat: e.representasjonspunkt.lat,
            lon: e.representasjonspunkt.lon,
        };
        locationSeleted(geoLocation);
    }

    render() {
        const { label, placeholder } = this.props;
        const { soketekst, sokResultat, activeItem, showSuggestions, clearButton, loading } = this.state;
        return (
            <div className="combobox-wrapper">
                <label htmlFor="adressesok_input" id="adressesok-label" className="adressesok-label">
                    {label}
                </label>
                <div
                    role="combobox"
                    aria-controls="adressesok-listbox"
                    aria-expanded={sokResultat.length > 0 && showSuggestions}
                    aria-owns="adressesok-listbox"
                    aria-haspopup="listbox"
                    id="adressesok_combobox"
                    className="form-group input-group"
                >
                    <input
                        type="text"
                        className="form-control"
                        onChange={e => {
                            this.onChange(e.target.value);
                        }}
                        value={soketekst}
                        onKeyUp={this.handleKeybordEvent}
                        aria-autocomplete="list"
                        aria-controls="adressesok-listbox"
                        id="adressesok_input"
                        aria-activedescendant={activeItem}
                        placeholder={placeholder}
                        aria-roledescription="Bruk opp og ned piler for å navigere i listen"
                        onFocus={() => this.setState({ showSuggestions: true })}
                        onBlur={() => this.handleBlur()}
                    />
                    <button
                        type="button"
                        onClick={() => {
                            this.setState({ sokResultat: [], soketekst: '' });
                        }}
                    >
                        {loading ? (
                            <span className="glyphicon glyphicon-refresh bym-glyphicon-spin" />
                        ) : (
                            <Glyphicon glyph="remove-sign" />
                        )}
                    </button>
                </div>
                <ul
                    aria-labelledby="adressesok-label"
                    role="listbox"
                    id="adressesok-listbox"
                    className={`geonorge-sokeresultat-container ${
                        sokResultat.length > 0 && showSuggestions ? 'visible' : 'hidden'
                    }`}
                >
                    {sokResultat.map((e, index) => {
                        return (
                            <li
                                role="option"
                                aria-selected={activeItem === index}
                                key={e.adressetekst}
                                id={index}
                                className={`geonorge-sokeresultat-element ${
                                    activeItem === index ? 'active' : 'inactive'
                                }`}
                                onMouseDown={() => {
                                    this.updateLocation(e);
                                }}
                                // onMouseEnter={() => {
                                //   this.setState({ activeItem: index });
                                // }}
                                onKeyUp={() => {}}
                            >
                                {e.adressetekst}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

AdresseSok.propTypes = {
    showSearch: PropTypes.bool,
    showMap: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    geodata: PropTypes.shape({
        display_name: PropTypes.string,
        lat: PropTypes.number,
        lon: PropTypes.number,
    }),
    locationSeleted: PropTypes.func.isRequired,
};

AdresseSok.defaultProps = {
    showSearch: false,
    showMap: false,
    label: '',
    placeholder: '',
    error: null,
    geodata: { display_name: '' },
};

export default AdresseSok;
