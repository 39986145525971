import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Table, Button } from 'react-bootstrap';
import ContractListRow from '../../components/contract/ContractListRow';

class ContractList extends Component {
    constructor(props) {
        super(props);
        this.allekontrakter = this.allekontrakter.bind(this);
        this.gyldigekontrakter = this.gyldigekontrakter.bind(this);

        this.state = {
            contractFilter: 'gyldige',
        };
    }

    allekontrakter(event) {
        event.preventDefault();
        this.setState({ contractFilter: 'alle' });
    }

    gyldigekontrakter(event) {
        event.preventDefault();
        this.setState({ contractFilter: 'gyldige' });
    }

    isTodayInsideDateRange(gyldigFraDato, gyldiTilDato) {
        const currentDate = new Date();
        if (gyldigFraDato && gyldiTilDato) {
            return new Date(gyldigFraDato) < currentDate && new Date(gyldiTilDato) > currentDate;
        }
    }

    render() {
        // console.log(this.props.contracts)
        return (
            <div>
                <Row>
                    <div className="filters col-xs-6">
                        <Button
                            onClick={this.allekontrakter}
                            bsStyle="link"
                            className={this.state.contractFilter === 'alle' ? 'active' : ''}
                        >
                            Alle kontrakter
                        </Button>
                        <Button
                            onClick={this.gyldigekontrakter}
                            bsStyle="link"
                            className={this.state.contractFilter === 'gyldige' ? 'active' : ''}
                        >
                            Gyldige kontrakter
                        </Button>
                    </div>
                </Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Kontraktsnavn</th>
                            <th>Avtale</th>
                            <th>Gyldighet</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.contractFilter === 'gyldige' &&
                            this.props.contracts
                                .filter(contract =>
                                    this.isTodayInsideDateRange(contract.gyldigFraDato, contract.gyldigTilDato)
                                )
                                .map(contract => (
                                    <ContractListRow
                                        key={contract.id}
                                        contract={contract}
                                        company={this.props.company}
                                        isEntrepreneurAdmin={this.props.isEntrepreneurAdmin}
                                    />
                                ))}

                        {this.state.contractFilter === 'alle' &&
                            this.props.contracts.map(contract => (
                                <ContractListRow
                                    key={contract.id}
                                    contract={contract}
                                    company={this.props.company}
                                    isEntrepreneurAdmin={this.props.isEntrepreneurAdmin}
                                />
                            ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}

ContractList.propTypes = {
    contracts: PropTypes.array.isRequired,
    company: PropTypes.object.isRequired,
    isEntrepreneurAdmin: PropTypes.bool,
};

export default ContractList;
