import React, { Component, PropTypes } from 'react';
import { bymeldingServiceBaseUrl } from '../../constants/api';
import { imageUrl, imageThumbUrl, getkontraktByAvtaleIdUrl } from '../../constants/api';
import * as api from '../../constants/api';
import _ from 'lodash';

class Meldingeksport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meldingsIder: '',
            meldinger: [],
            visSok: true,
        };

        this.handleMeldingsIdChange = this.handleMeldingsIdChange.bind(this);
        this.getMeldinger = this.getMeldinger.bind(this);
    }

    handleMeldingsIdChange(e) {
        this.setState({ meldingsIder: e.target.value });
    }

    getMeldinger(e) {
        let meldingsIder = this.state.meldingsIder;

        const url = bymeldingServiceBaseUrl + `/api/eksport?meldingsIder=${meldingsIder}`;

        return api.getClient().get(url).then(res => {
            this.setState({
                meldinger: res.data,
                visSok: false,
            });
        });
    }

    renderImages(images) {
        const imagesData = [];
        _.map(images, i => {
            const imgUrl = imageUrl(i);
            const thumbUrl = imageThumbUrl(i);
            imagesData.push(Object.assign({}, { src: imgUrl, thumbSrc: thumbUrl }));
        });
        return imagesData;
    }

    render() {
        return (
            <div>
                {this.state.visSok && (
                    <div>
                        Meldinger: <input type="text" onChange={this.handleMeldingsIdChange} />
                        <button onClick={this.getMeldinger}>Generer</button>
                    </div>
                )}
                <div>
                    {this.state.meldinger &&
                        this.state.meldinger.map(m => {
                            return (
                                <div>
                                    Meldingsnummer: {m.lopeNummer}
                                    <br />
                                    {this.renderImages(m.bildeIds).length === 0 && <div>Ingen bilder</div>}
                                    {this.renderImages(m.bildeIds).length > 0 &&
                                        this.renderImages(m.bildeIds).map(image => {
                                            return (
                                                <div>
                                                    {' '}
                                                    <img
                                                        src={image.src}
                                                        style={{ maxHeight: '500px', paddingBottom: '5px' }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    <br />
                                    <hr />
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default Meldingeksport;
