import validator from "validator";
import isEmpty from "lodash/isEmpty";
import * as validationErrors from "../constants/ValidationErrors"

export default function validateInput(data)
{
    let errors = {};
    if (validator.isEmpty(data.email))
    {
        errors.email = validationErrors.EmailIsRequired;
    }
    else if (!validator.isEmail(data.email))
    {
        errors.email = validationErrors.EmailIsNotValid;
    }
    if (validator.isEmpty(data.name))
    {
        errors.name = validationErrors.NameIsRequired;
    }
    // if (validator.isEmpty(data.phonenumber.toString()))
    // {
    //     errors.phonenumber = validationErrors.PhoneNumberIsRequired;
    // }
    // else if (!validator.isNumeric(data.phonenumber.toString()))
    // {
    //         errors.phonenumber = validationErrors.PhoneNumberIsNotValid;        
    // }
    // else if (validator.isNumeric(data.phonenumber.toString()))
    // {
    //     if (data.phonenumber.length < 8)
    //     {
    //         errors.phonenumber = validationErrors.PhoneNumberIsNotValid;
    //     }
    // }
    
    if (validator.isEmpty(data.orgnumber.toString()))
    {
        errors.orgnumber = validationErrors.OrgNumberIsRequired;
    }
    else if (!validator.isNumeric(data.orgnumber.toString()))
    {
            errors.orgnumber = validationErrors.OrgNumberIsNotValid;        
    }
    else if (validator.isNumeric(data.orgnumber.toString()))
    {
        if (data.orgnumber.length < 9)
        {
            errors.orgnumber = validationErrors.OrgNumberIsNotValid;
        }
    }
    return {
        errors,
        isValid: isEmpty(errors)
    }
}