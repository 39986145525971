import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadEntrepreneurById } from '../../actions/selskaperActions';
import { loadEntrepreneurContracts } from '../../actions/contractActions';
import PageHeader from '../../components/Layout/PageHeader';
import * as routes from '../../constants/clientRoutes';
import _ from 'lodash';

class Breadcrumbs extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            context: {},
            pageTitle: '',
            selskap: {},
            subTitle: '',
            pageTitleLink: '',
            hasSubTitle: false,
        };
    }
    // loadData()
    // {
    //     const context = this.context.router
    //     const pathname = context.location.pathname
    //     const params = context.params
    //     if (_.includes(pathname, routes.selskaper))
    //     {
    //         if (params.id || params.selskapsId)
    //         {
    //             const selskapId = params.id ? params.id : params.selskapsId
    //             this.props.loadEntrepreneurById(selskapId).then(res => this.setState({ selskap: res }))
    //             this.props.loadEntrepreneurContracts(selskapId)
    //         }
    //     }

    // }
    componentWillMount() {
        //this.loadData()
        this.setState({ context: this.context.router });
    }
    // componentWillReceiveProps(prevProps, nextProps)
    // {
    //     if (nextProps.router.params)
    //         this.loadData()
    // }

    getLocationStateSubTitle(location) {
        return location.state ? location.state.subTitle : '';
    }
    getLocationStatePageTitle(location) {
        return location.state ? location.state.pageTitle : '';
    }
    getLocationStatePageLink(location) {
        return location.state ? location.state.pageLink : '';
    }
    xIndexOf(Val, Str, x) {
        if (x <= Str.split(Val).length - 1) {
            let Ot = Str.indexOf(Val);
            if (x > 1) {
                for (var i = 1; i < x; i++) {
                    Ot = Str.indexOf(Val, Ot + 1);
                }
            }
            return Ot;
        } else {
            alert(Val + ' Occurs less than ' + x + ' times');
            return 0;
        }
    }

    setPageHeaderInfo() {
        //if (!context.location.pathname) return;
        const context = this.state.context;
        let params = context.params;
        let location = context.location;
        let title = '';
        let pageTitleLink = '';
        let subTitle = '';
        let hasSubTitle = false;
        let url = location.pathname;
        if (params.selskapsId && params.kontraktsId) {
            title = this.getLocationStatePageTitle(location);
            pageTitleLink = this.getLocationStatePageLink(location);
            subTitle = this.getLocationStateSubTitle(location);
            hasSubTitle = true;
        } else if (_.includes(url, routes.selskaper) && params.id) {
            title = routes.getDisplayName(routes.selskaper);
            pageTitleLink = routes.selskaper;
            subTitle = this.getLocationStateSubTitle(location);
            hasSubTitle = true;
        } else if (_.includes(url, routes.prosessKodeSkjema)) {
            title = routes.getDisplayName(routes.prosessKodeSkjema);
            pageTitleLink = routes.prosessKodeSkjema;
            subTitle = this.getLocationStateSubTitle(location);
            hasSubTitle = false;
        } else {
            title = routes.getDisplayName(location.pathname);
            pageTitleLink = url.substring(1, url.lastIndexOf('/') + 1);
            hasSubTitle = false;
        }
        return (
            <PageHeader pageTitle={title} pageTitleLink={pageTitleLink} subTitle={subTitle} hasSubTitle={hasSubTitle} />
        );
    }

    render() {
        return this.setPageHeaderInfo();
    }
}
Breadcrumbs.propTypes = {
    location: PropTypes.object,
    entrepreneur: PropTypes.object,
    contracts: PropTypes.array,
    loadEntrepreneurById: PropTypes.func,
    loadEntrepreneurContracts: PropTypes.func,
};

Breadcrumbs.contextTypes = {
    router: PropTypes.object,
};
const mapStateToProps = state => {
    return {
        entrepreneur: state.entreprenor,
        contracts: state.contracts,
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ loadEntrepreneurById, loadEntrepreneurContracts }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);

// mapAvtalToKontraktName(kontraktId)
// {
//     const contracts = this.props.contracts
//     const contract = _.first(_.filter(contracts, { id: kontraktId }))
//     return contract ? contract.navn : "-"
// }
// mapAvtalToSelskapName(selskapId)
// {
//     const selskaps = this.props.entrepreneurs
//     const selskap = _.first(_.filter(selskaps, { id: selskapId }))
//     return selskap ? selskap.name : "-"
// }

// const mapStateToProps = (state) =>
// {
//     return {
//         entrepreneurs: state.entreprenorer,
//         contracts: state.contracts
//     }
// }
// const mapDispatchToProps = (dispatch) =>
// {
//     return bindActionCreators({ loadEntrepreneurs, loadContracts }, dispatch)
// }
// export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsHeader)
