import * as types from './ActionTypes';
import userApi from '../Api_MockData/mockUsersApi';
import * as api from '../constants/api';
import { useMock } from '../constants/settings';
import * as messages from '../constants/ResponseMessages';
import * as globals from '../constants/Globals';
import { addFlashMessage } from './FlashMessagesAction';
import { UserIsRemoved } from '../constants/ResponseMessages';

export function loadUsersStart() {
    return { type: types.LOAD_USERS };
}
export function loadUsersSuccess(users) {
    return { type: types.LOAD_USERS_SUCCESS, users };
}
export function loadUsersFailure(error) {
    return { type: types.LOAD_USERS_FAILURE, error };
}

export function createUserSuccess(user) {
    return { type: types.CREATE_USER_SUCCESS, user };
}

export function createUser(user) {
    return { type: types.CREATE_USER, user };
}

export function createUserFailure(error) {
    return { type: types.CREATE_USER_FAILURE, error };
}

export function createUserReset() {
    return { type: types.CREATE_USER_RESET };
}

export function updateUserSuccess(user) {
    return { type: types.UPDATE_USER_SUCCESS, user };
}

export function deleteUserSuccess(user) {
    return { type: types.DELETE_USER_SUCCESS, user };
}

export function deleteUserFailure(user) {
    return { type: types.DELETE_USER_FAILURE, user };
}

function handleLoadUsersError(error) {
    if (error.response) {
        if (error.response.status === 404) return messages.UsersNotAvailable;

        if (error.response.status === 500) return messages.BymeldingServerError;
    }

    return error.message;
}

export function loadUsers(selskapsType, selskapsId) {
    //const url = api.selskapsbrukere;
    const url =
        selskapsType === globals.selskapsTypeEntreprenorerId ? api.entreprenorBrukere(selskapsId) : api.selskapsbrukere;

    return function(dispatch) {
        dispatch(loadUsersStart());
        if (useMock === false) {
            // const url = api.selskapsBrukere;
            api.getClient()
                .get(url)
                .then(response => {
                    dispatch(loadUsersSuccess(response.data.result));
                })
                .catch(error => {
                    dispatch(loadUsersFailure(handleLoadUsersError(error)));
                });
        } else {
            return userApi
                .getAllUsers()
                .then(users => {
                    dispatch(loadUsersSuccess(users));
                })
                .catch(error => {
                    throw error;
                });
        }
    };
}

export function createServiceavdelingBruker(selskapsId, user) {
    // const newuser = { selskapsRolleId: user.selskapsRolle.rolleId, epost: user.epost, navn: user.navn };
    // return axios.post(api.selskapsBrukere, newuser);
    const nyBruker = {
        fornavn: user.fornavn,
        etternavn: user.etternavn,
        navn: user.fornavn + ' ' + user.etternavn, // bakoverkompatibilitet
        epost: user.epost,
        passord: user.passord,
        rolle: user.selskapsRolle.rolleId,
    };
    return api.getClient().post(api.selskapsbrukere, nyBruker);
}

export function createEntreprenorBruker(selskapsId, user) {
    const contractZone = user.avtaler.map(sone => {
        return { avtaleId: sone.id };
    });
    const newuser = {
        selskapsId,
        fornavn: user.fornavn,
        etternavn: user.etternavn,
        navn: user.fornavn + ' ' + user.etternavn, // bakoverkompatibilitet
        epost: user.epost,
        passord: user.passord,
        rolle: user.selskapsRolle.rolleId,
        avtaler: contractZone,
    };
    // return axios.post(api.entreprenorBrukere(selskapsId), newuser);
    return api.getClient().post(api.selskapsbrukere, newuser);
}

// export function updateUserRequest( user) {
//     return axios.put(api.selskapsBrukere + '/' + user.id, {selskapsRolleId: user.selskapsRolle.rolleId})
// }

export function updateUserRequest(selskapsType, selskapsId, user) {
    if (selskapsType === globals.selskapsTypeEntreprenorerId) {
        const contractZone = user.avtaler.map(sone => {
            return { avtaleId: sone.id };
        });
        return api.getClient().put(api.selskapsbrukere + '/' + user.id, {
            rolle: user.selskapsRolle.rolleId,
            avtaler: contractZone,
        });
    }
    // let url = api.selskapsBrukere + '/' + user.id;
    // return axios.put(url, { selskapsRolleId: user.selskapsRolle.rolleId });
    return api.getClient().put(api.selskapsbrukere + '/' + user.id + '/rolle', { rolle: user.selskapsRolle.rolleId });
}

export function saveUser(selskapsType, selskapsId, user) {
    return function(dispatch) {
        if (user && user.id && user.id !== '') {
            updateUserRequest(selskapsType, selskapsId, user).then(() => dispatch(updateUserSuccess(user)));
        } else {
            createServiceavdelingBruker(selskapsId, user, dispatch).then(() => dispatch(createUser(user)));
        }
    };
}

export function removeUser(selskapsType, selskapsId, user, onSuccess, onError) {
    const url = api.selskapsbrukere;
    //selskapsType === globals.selskapsTypeEntreprenorerId ? api.entreprenorBrukere(selskapsId) : api.selskapsbrukere;

    return function(dispatch) {
        if (!useMock) {
            api.getClient()
                .delete(url + '/' + user.id)
                .then(response => {
                    if (response.data) {
                        dispatch(deleteUserSuccess(user));
                        dispatch(addFlashMessage({ type: 'success', text: UserIsRemoved }));
                    }
                })
                .catch(error => {
                    dispatch(deleteUserFailure(error));
                });
        } else {
            return userApi.deleteUser(user).then(() => {
                dispatch(deleteUserSuccess(user));
                dispatch(addFlashMessage({ type: 'success', text: UserIsRemoved }));
            });
        }
    };
}

/*  return function (dispatch) {
        if (!useMock) {
            axios.delete(url + "/" + user.id)
                .then(() => {
                    dispatch(deleteUserSuccess(user))
                })
                .catch((error) => {
                    dispatch(deleteUserFailure(error))
                })
        } else {
            return userApi.deleteUser(user).then(() => {
                dispatch(deleteUserSuccess(user))
            });
        }
    }
}
*/
