import * as types from './ActionTypes';
import ProcesscodeschemeApi from '../Api_MockData/mockProcesscodeschemeApi';
import { beginAjaxCall, ajaxCallError } from '../Api_MockData/ajaxStatusActions';
import * as api from '../constants/api';
import { useMock } from "../constants/settings";
import {prosessKodeSkjemaerUrl} from "../constants/api";

export function loadProcessCodeSchemesSuccess(processcodeschemes) {
  return { type: types.LOAD_PROCESSCODESCHEMES_SUCCESS, processcodeschemes };
}

export function createProcessCodeSchemeSuccess(processcodescheme) {
  return { type: types.CREATE_PROCESSCODESCHEME_SUCCESS, processcodescheme };
}

export function updateProcessCodeSchemeSuccess(processcodescheme) {
  return { type: types.UPDATE_PROCESSCODESCHEME_SUCCESS, processcodescheme };
}

const url = prosessKodeSkjemaerUrl;


export function updateProcesscodescheme(processcodescheme) {
  return function (dispatch) {
    dispatch(beginAjaxCall());

    if (useMock === false) {
      //Use the web service
    var e = { id: processcodescheme.id, navn: processcodescheme.name, orgnr: processcodescheme.orgnumber, epost: processcodescheme.email, telefon: processcodescheme.phonenumber };
    //axios.defaults.withCredentials = false;
    api.getClient().put(url, e)
      .then(function(response) {
        if(response.data)
        {
          dispatch(updateProcessCodeSchemeSuccess(processcodescheme));
        }
      })
      .catch(function (error) {
      });
    }
    else {
      // // Use the mock
      console.log("updateProcesscodescheme not implemented in mock");
    }


  };
}


export function loadProcesscodeschemes() {
  return function (dispatch) {
    dispatch(beginAjaxCall());

    if (useMock === false) {
      //Use the web service
      //axios.defaults.withCredentials = false;
      api.getClient().get(url)
        .then(function (response) {
          var e = response.data.result.map(function (x) { return { id: x.id, name: x.navn } });
          dispatch(loadProcessCodeSchemesSuccess(e));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      // Use the mock
      return ProcesscodeschemeApi.getAllProcesscodeschemes().then(processcodeschemes => {
        dispatch(loadProcessCodeSchemesSuccess(processcodeschemes));
      }).catch(error => {
        throw (error);
      });
    }

  };
}


export function saveProcesscodescheme(processcodescheme) {
  return function (dispatch, getState) {
    dispatch(beginAjaxCall());

    if (useMock === false) {
      //Use the web service
      var e = { navn: processcodescheme.name }
      //axios.defaults.withCredentials = false;
      api.getClient().post(url, e)
        .then(function (response) {
          if (response.data) {
            processcodescheme.id = response.data.result.id;
            dispatch(createProcessCodeSchemeSuccess(processcodescheme));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      // Use the mock
      return ProcesscodeschemeApi.saveProcesscodescheme(processcodescheme).then(processcodescheme => {
        processcodescheme.id ? dispatch(updateProcessCodeSchemeSuccess(processcodescheme)) :
          dispatch(createProcessCodeSchemeSuccess(processcodescheme));
      }).catch(error => {
        dispatch(ajaxCallError(error));
        throw (error);
      });
    }

  };
}

