import {
  LOAD_SAVED_MELDING_SEARCH,
  ADD_SAVED_MELDING_SEARCH,
  DELETE_SAVED_MELDING_SEARCH,
} from '../actions/ActionTypes';
import InitialState from './initialState';

export default function SavedMeldingSearchReducer(
  state = InitialState.lagredSok,
  action
) {
  switch (action.type) {
    case LOAD_SAVED_MELDING_SEARCH:
      return action.payload;

    case ADD_SAVED_MELDING_SEARCH:
      return [...state, action.payload];

    case DELETE_SAVED_MELDING_SEARCH: {
      return [...state.filter(item => item.id !== action.payload.id)];
    }
    default:
      return state;
  }
}
