import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import '../../css/Ressursbruk/RessursbrukFilters.css';
import moment from 'moment';
import DropDownList from '../common/DropDownList';
import Datepicker from '../common/Datepicker';
import TextFieldGroup from '../common/TextFieldGroup';
// import Timepicker from '../common/Timepicker';
import Datetime from 'react-datetime';
import RessursbrukFormProsesskodeVelger from './RessursbrukFormProsesskodeVelger';
import 'react-datetime/css/react-datetime.css';
import './RessursbrukForm.css';

const RessursbrukForm = ({
    selectedTjeneste,
    Dato,
    Tid,
    antall,
    Kommentar,
    priceListItems,
    onChangeTimepicker,
    handleChangeTjeneste,
    enhetNavn,
    onChangeDateFrom,
    onChange,
    onClickLeggtil,
    errors,
    addFormisLoading,
    kontraktId,
}) => {
    moment.locale('nb');
    const date = moment.isMoment(Dato) ? Dato.format('DD.MM.YYYY') : Dato;
    let time = Tid;
    if (moment.isMoment(Tid)) {
        time = Tid.format('HH:mm');
    } else if (
        Tid.length === 4 &&
        Tid.indexOf(':') === -1 &&
        Tid.indexOf('.') === -1 &&
        moment(Tid, 'HHmm').isValid()
    ) {
        time = moment(Tid, 'HHmm').format('HH:mm');
        onChangeTimepicker(moment(Tid, 'HHmm'));
    }

    return (
        <form name="ressursbruk-form" id="ressursbruk-form" className="ressursbruk-innmelding-form-container">
            <h2>Registrer ressursbruk</h2>

            <div className="ressursbruk-innmelding-form">
                <div className="ressursbruk-innmelding-form-section" id="ressursbruk-tjeneste">
                    <RessursbrukFormProsesskodeVelger
                        selected={selectedTjeneste}
                        error={errors.selectedTjeneste}
                        Items={priceListItems}
                        handleChangeTjeneste={handleChangeTjeneste}
                        kontraktId={kontraktId}
                    />
                </div>
                <div
                    className={`form-group ${
                        errors.Dato ? ' has-error ' : ''
                    } ressursbruk-innmelding-form-section ressursbruk-innmelding-form-section-dato`}
                    id="ressursbruk-dato"
                >
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="control-label" htmlFor="filtertimepickerFrom">
                        Dato *
                        <Datetime
                            onChange={onChangeDateFrom}
                            value={date}
                            dateFormat="DD.MM.YYYY"
                            timeFormat={false}
                            id="filtertimepickerFrom"
                            name="filtertimepickerFrom"
                        />
                    </label>
                    <span className="help-block">{errors.Dato}</span>
                </div>

                <div
                    className={`form-group ${
                        errors.Tid ? ' has-error ' : ''
                    } ressursbruk-innmelding-form-section ressursbruk-innmelding-form-section-tid`}
                    id="ressursbruk-time"
                >
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="control-label" htmlFor="filtertimepickerFrom">
                        Tid *
                        <Datetime
                            onChange={onChangeTimepicker}
                            value={time}
                            dateFormat={false}
                            timeFormat="HH:mm"
                            id="filtertimepickerFrom"
                            name="filtertimepickerFrom"
                        />
                    </label>
                    <span className="help-block">{errors.Tid}</span>
                </div>
                <div className="ressursbruk-innmelding-form-section ressursbruk-innmelding-form-section-mengde">
                    <div id="ressursbruk-mengde">
                        <TextFieldGroup
                            name="antall"
                            label="Mengde *"
                            value={antall.toString()}
                            onChange={e => onChange('antall', e.target.value)}
                            error={errors.antall}
                        />
                    </div>
                    <div className="ressursbruk-innmelding-form-section" id="ressursbruk-enhet">
                        {enhetNavn || ' '}
                    </div>
                </div>
            </div>
            <div className="ressursbruk-innmelding-form-kommentar">
                <div className="ressursbruk-innmelding-form-section" id="ressursbruk-kommentar">
                    <div className="form-group">
                        <label className="control-label" htmlFor="Kommentar">
                            Kommentar
                            <textarea
                                name="Kommentar"
                                className="ressursbruk-innmelding-form-kommentar-textarea"
                                value={Kommentar}
                                onChange={e => onChange('Kommentar', e.target.value)}
                                rows={5}
                            />
                        </label>
                    </div>
                </div>
                <div className="ressursbruk-innmelding-form-submit">
                    <div className="ressursbruk-innmelding-form-section" id="ressursbruk-handling">
                        <Button type="submit" bsStyle="success" onClick={onClickLeggtil} disabled={addFormisLoading}>
                            Legg til
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

RessursbrukForm.propTypes = {
    // handleChangeRessursBruk: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeDateFrom: PropTypes.func.isRequired,
    handleChangeTjeneste: PropTypes.func.isRequired,
    onClickLeggtil: PropTypes.func.isRequired,
    handleChangeSkift: PropTypes.func.isRequired,
    onChangeTimepicker: PropTypes.func.isRequired,
    Dato: PropTypes.string,
    Tid: PropTypes.string,
    // tjenesteItems: PropTypes.arrayOf(PropTypes.shape({})),
    priceListItems: PropTypes.arrayOf(PropTypes.shape({})),
    // skiftItems: PropTypes.arrayOf(PropTypes.shape({})),
    // time: PropTypes.string,
    antall: PropTypes.string,
    datoError: PropTypes.string,
    tidError: PropTypes.string,
    Kommentar: PropTypes.string,
    selectedTjeneste: PropTypes.string,
    selectedSkift: PropTypes.string,
    enhetNavn: PropTypes.string,
    errors: PropTypes.shape({
        selectedTjeneste: PropTypes.string,
        Dato: PropTypes.string,
        Tid: PropTypes.string,
        antall: PropTypes.string,
    }),
    Disabled: PropTypes.bool,
    addFormisLoading: PropTypes.bool,
    kontraktId: PropTypes.string,
};

RessursbrukForm.defaultProps = {
    Dato: '',
    Tid: '',
    kontraktId: null,
    // tjenesteItems: [],
    priceListItems: [],
    // skiftItems: [],
    // time: '',
    antall: null,
    Kommentar: '',
    selectedTjeneste: '',
    selectedSkift: '',
    enhetNavn: '',
    errors: null,
    Disabled: false,
    addFormisLoading: false,
};

export default RessursbrukForm;
