import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

export default function processcodeReducer(state = InitialState.processCodes, action) {
  switch (action.type) {
    case types.LOAD_PROCESSCODES_SUCCESS:
      return action.processcodes;
      
    case types.CREATE_PROCESSCODE_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.processcode)
      ];
    case types.UPDATE_PROCESSCODE_SUCCESS: {
      const indexToRemove = state.findIndex(processcode => processcode.id === action.processcode.id)
      return [
        ...state.slice(0, indexToRemove), Object.assign({}, action.processcode),
        ...state.slice(indexToRemove + 1)
      ];
    }
    case types.DELETE_PROCESSCODE_SUCCESS:
      return [
        ...state.filter(processcode => processcode.id !== action.processcode.id)
      ];


    default:
      return state;
  }
}
