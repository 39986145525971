import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { statusKoder } from '../../constants/Globals';
import './StatusIcon.css';

const StatusIcon = ({ statusId }) => {
  const status = find(statusKoder, kode => kode.id === statusId);

  if (status) {
    return (
      <div name="statusIcon" className={`status-icon status-icon-${status.class}`} alt={status.alt}>
        {status.navn}
      </div>
    );
  }

  return (
    <div name="statusIcon" className="status-icon status-icon-ukjent">
      Ukjent statuskode
    </div>
  );
};

StatusIcon.propTypes = {
  statusId: PropTypes.number.isRequired,
};

export default StatusIcon;
