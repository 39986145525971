export const brukerinfo_serviceId = 'dfc17371-8163-4870-8ffa-cf5fb4ff4305';
export const brukerinfo_admin_roleId = '1b117479-1ff4-4788-ae8e-88313239a405';

export const autentisering_serviceId = '1aeffc5d-9d3e-4db9-aead-525a33660b9c';
export const autentisering_admin_roleId = 'd3ca222c-103c-44ce-9a96-d0745c6c545a';
export const autentisering_brukeradmin_roleId = '009ba9a2-f9e6-4320-b7de-34672edaa33b';

export const bymelding_serviceId = '59fface5-c76c-446c-bb58-15b110578a87';
export const bymelding_saksbehandling_clientId = 'de342c3e-5692-4386-94e3-e13a03dd70c0';

export const bymeldingAdmin = 'bymelding-admin'; // '2acd6c6e-1f75-4a39-b225-56f6169e2bfa';
export const bymeldingAdminNavn = 'Bymelding Admin';

export const bymeldingKlientNavn = 'bymelding-klient';
export const serviceAvdelingAdmin = 'bymelding-serviceavdeling-admin'; // 'd03ab2e2-c978-46d4-b20a-e3a107bab1a9';
export const serviceAvdelingAdminNavn = 'Serviceavdeling Admin';
export const serviceavdeling = 'bymelding-serviceavdeling'; // '614ea746-965b-428d-861b-81d8d5de561f';
export const serviceavdelingNavn = 'Serviceavdeling';
export const entrepreneurAdmin = 'bymelding-entreprenor-admin'; // '5bd99df0-55f2-4888-946c-e7a826eee3b4';
export const entrepreneurAdminNavn = 'Entreprenør Admin';
export const entrepreneurs = 'bymelding-entreprenor'; // '3cfbfb8c-7f32-4c47-a4a5-7e55dc0690f3';
export const entrepreneursNavn = 'Entreprenør';

// muligens bare fase ut disse
export const samarbeidspartner = 'bcd07735-801d-4a5c-8a57-1a8b0076dbbd';
export const samarbeidspartnerNavn = 'Samarbeidspartner';
export const samarbeidspartnerAdmin = 'e73274f9-fe7b-42e3-8734-917f4eeec416';
export const samarbeidspartnerAdminNavn = 'SamarbeidspartnerAdmin';

export const publikum = 'bymelding-publikum'; // '26477db0-d06f-4e24-8335-996baa8abb47';
export const publikumNavn = 'Publikum';
export const bymiljoetatenSelskapId = '2a5cb46a-71a7-4b05-9e5d-7dcc93166deb';
export const selskapsTypeSaksbehandlereId = 1;
export const selskapsTypeSaksbehandlereNavn = 'Saksbehandlere';
export const selskapsTypeForvaltereId = 2;
export const selskapsTypeForvaltereNavn = 'Forvaltere';
export const selskapsTypeEntreprenorerId = 3;
export const selskapsTypeEntreprenorerNavn = 'Entreprenører';
export const selskapsTypeSamarbeidspartnereId = 4;
export const selskapsTypeSamarbeidspartnereNavn = 'Samarbeidspartnere';

export const GlobalRessursforbruk = 'GlobalRessursforbruk';
export const MeldingRessursforbruk = 'MeldingRessursforbruk';

export const EntreprenorkontrollAdmin = 'entreprenorkontroll-admin'; // '63690d61-452b-4dda-9b5e-6bfd69f116cf';
export const EnkEntreprenor = 'entreprenorkontroll-entreprenor'; // '2c5539cc-26de-4419-bea8-80e8447150aa';
export const EnkForvalter = 'entreprenorkontroll-forvalter'; // '637b3c47-567f-41f3-9ee1-d50d82305835';
export const EnkKontrollor = 'entreprenorkontroll-kontrollor'; // 'c2125e33-7da0-4f92-b196-070529b0acfe';

export const rollerForSelskapsType = {
    [selskapsTypeSaksbehandlereId]: [
        { rolleId: serviceAvdelingAdmin, navn: serviceAvdelingAdminNavn },
        { rolleId: serviceavdeling, navn: serviceavdelingNavn },
    ],
    [selskapsTypeForvaltereId]: [],
    [selskapsTypeEntreprenorerId]: [
        { rolleId: entrepreneurAdmin, navn: entrepreneurAdminNavn },
        { rolleId: entrepreneurs, navn: entrepreneursNavn },
    ],
    [selskapsTypeSamarbeidspartnereId]: [
        { rolleId: samarbeidspartnerAdmin, navn: samarbeidspartnerAdminNavn },
        { rolleId: samarbeidspartner, navn: samarbeidspartnerNavn },
    ],
};
export const bymeldingRoller = [
    { rolleId: serviceAvdelingAdmin, navn: serviceAvdelingAdminNavn },
    { rolleId: serviceavdeling, navn: serviceavdelingNavn },
    { rolleId: entrepreneurAdmin, navn: entrepreneurAdminNavn },
    { rolleId: entrepreneurs, navn: entrepreneursNavn },
    { rolleId: samarbeidspartnerAdmin, navn: samarbeidspartnerAdminNavn },
    { rolleId: samarbeidspartner, navn: samarbeidspartnerNavn },
    { rolleId: bymeldingAdmin, navn: bymeldingAdminNavn },
];

export const rolleSelskapsTypeMapping = {
    [bymeldingAdmin]: selskapsTypeSaksbehandlereId,
    [serviceAvdelingAdmin]: selskapsTypeSaksbehandlereId,
    [serviceavdeling]: selskapsTypeSaksbehandlereId,
    [entrepreneurAdmin]: selskapsTypeEntreprenorerId,
    [entrepreneurs]: selskapsTypeEntreprenorerId,
    [samarbeidspartner]: selskapsTypeSamarbeidspartnereId,
    [samarbeidspartnerAdmin]: selskapsTypeSamarbeidspartnereId,
};

// Statuskoder som ikke skal vise popup:
//   310: Borkjøring av snø - sendes automatisk av NotificationService
export const statuskoderSomIkkeSkalTriggeTilbakemeldingsdialog = [310];

export const getStausKodenavn = meldingstatus => {
    if (meldingstatus) {
        switch (meldingstatus.foreldreId) {
            case 8:
                return {
                    label: 'Avslått',
                    class: '',
                };
            case 100:
                return {
                    label: 'Henvises ekstern',
                    class: '',
                };
            case 200:
                return {
                    label: 'Videresendt',
                    class: '',
                };
            default:
                switch (meldingstatus.id) {
                    case 1:
                        return {
                            label: 'Ikke tildelt',
                            class: 'ikke-tildelt',
                        };
                    case 2:
                        return {
                            label: 'Ikke behandlet',
                            class: 'ikke-behandlet',
                        };

                    case 3:
                        return {
                            label: 'Avvist',
                            class: 'avvist',
                        };

                    case 4:
                        return {
                            label: 'Under behandling',
                            class: 'under-behandling',
                        };

                    case 5:
                        return {
                            label: 'Ferdig behandlet',
                            class: 'ferdig-behandlet',
                        };

                    case 6:
                        return {
                            label: 'Arbeid utført',
                            class: 'ferdig-behandlet',
                        };

                    case 7:
                        return {
                            label: 'Duplikat',
                            class: 'ferdig-behandlet',
                        };

                    case 8:
                        return {
                            label: 'Avslått',
                            class: 'ferdig-behandlet',
                        };

                    case 9:
                    case 14:
                    case 25:
                    case 200:
                    case 300:
                    case 310:
                        return {
                            label: 'Videresendt',
                            class: 'ferdig-behandlet',
                        };

                    case 13:
                    case 100:
                        return {
                            label: 'Henvises ekstern',
                            class: 'ferdig-behandlet',
                        };

                    default:
                        return '';
                }
        }
    }
};

export const statusKoder = [
    {
        id: 1,
        alt: 'Ikke tildelt',
        navn: 'Ikke tildelt',
        foreldreId: 0,
        class: 'ikke-tildelt',
    },
    {
        id: 2,
        alt: 'Ikke behandlet',
        navn: 'Ikke behandlet',
        foreldreId: 0,
        class: 'ikke-behandlet',
    },
    {
        id: 3,
        alt: 'Avvist',
        navn: 'Avvist',
        foreldreId: 0,
        class: 'avvist',
    },
    {
        id: 4,
        alt: 'Under behandling',
        navn: 'Under behandling',
        foreldreId: 0,
        class: 'under-behandling',
    },
    {
        id: 5,
        alt: 'Ferdig behandlet',
        navn: 'Ferdig behandlet',
        foreldreId: 0,
        class: 'ferdig-behandlet',
    },
    {
        id: 6,
        alt: 'Arbeid utført',
        navn: 'Arbeid utført',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 7,
        alt: 'Duplikat',
        navn: 'Duplikat',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 8,
        alt: 'Avslått',
        navn: 'Avslått',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 71,
        alt: 'Utbedring bestilt',
        navn: 'Utbedring bestilt',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 10,
        alt: 'Fri tekst',
        navn: 'Avslått',
        foreldreId: 8,
        class: 'ferdig-behandlet',
    },
    {
        id: 11,
        alt: 'Privat vei',
        navn: 'Avslått',
        foreldreId: 8,
        class: 'ferdig-behandlet',
    },
    {
        id: 15,
        alt: 'Statsbygg',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 16,
        alt: 'Statens vegvesen',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 17,
        alt: 'BaneNor',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 18,
        alt: 'Sporveien AS',
        navn: 'Videresendt',
        foreldreId: 150,
        class: 'ferdig-behandlet',
    },
    {
        id: 19,
        alt: 'Forsvarsbygg',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 20,
        alt: 'Slottsforvalter',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 21,
        alt: 'Elvia/Hafslund',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 22,
        alt: 'Leverandør avfallsekker (isekk)',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 23,
        alt: 'Kirkelig fellesråd',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 50,
        alt: 'Fri tekst',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 52,
        alt: 'Aktør for sparkesykkel',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 24,
        alt: 'Gårdeiers ansvar',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 26,
        alt: 'Bydel Frogner',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 27,
        alt: 'Bydel St.Hanshaugen',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 28,
        alt: 'Bydel Grünerløkka',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 29,
        alt: 'Bydel Sagene',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 30,
        alt: 'Eiendoms og byfornyelsesetaten',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 31,
        alt: 'Kulturetaten (KUL)',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 32,
        alt: 'Fri tekst',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 33,
        alt: 'Oslo Havn KF',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 34,
        alt: 'Plan- og bygningsetaten',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 35,
        alt: 'Gravferdsetaten',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 51,
        alt: 'Bydel Gamle Oslo',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 55,
        alt: 'Treforvaltning - Del av årshjul',
        navn: 'Avslått',
        foreldreId: 8,
        class: 'ferdig-behandlet',
    },
    {
        id: 12,
        alt: 'Privat eiendom',
        navn: 'Avslått',
        foreldreId: 8,
        class: 'ferdig-behandlet',
    },
    {
        id: 53,
        alt: 'Saksbehandles',
        navn: 'Avslått',
        foreldreId: 8,
        class: 'ferdig-behandlet',
    },
    {
        id: 54,
        alt: 'Tagging privat eiendom',
        navn: 'Avslått',
        foreldreId: 8,
        class: 'ferdig-behandlet',
    },
    {
        id: 59,
        alt: 'Boligbygg Oslo KF',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 61,
        alt: 'Bydel Alna',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 62,
        alt: 'Bydel Bjerke',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 63,
        alt: 'Bydel Grorud',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 64,
        alt: 'Bydel Nordre Aker',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 65,
        alt: 'Bydel Norstrand',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 66,
        alt: 'Bydel Stovner',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 67,
        alt: 'Bydel Søndre Norstrand',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 68,
        alt: 'Bydel Ullern',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 69,
        alt: 'Bydel Vestre Aker',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 70,
        alt: 'Bydel Østensjø',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 72,
        alt: 'Telenor',
        navn: 'Henvises ekstern',
        foreldreId: 100,
        class: 'ferdig-behandlet',
    },
    {
        id: 73,
        alt: 'Feilparkert kjøretøy/elsparkesykkel',
        navn: 'Avslått',
        foreldreId: 8,
        class: 'ferdig-behandlet',
    },
    {
        id: 60,
        alt: 'Omsorgsbygg Oslo KF',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },

    {
        id: 58,
        alt: 'Undervisningsbygg Oslo KF',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 57,
        alt: 'Vann- og avløpsetaten',
        navn: 'Videresendt',
        foreldreId: 200,
        class: 'ferdig-behandlet',
    },
    {
        id: 100,
        alt: 'Henvises områdeansvarlig',
        navn: 'Henvises ekstern',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 150,
        navn: 'Videresendt',
        alt: 'Videresendt områddeansvarlig',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 200,
        navn: 'Videresendt',
        alt: 'Videresendt områdeansvarlig Oslo kommune',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 300,
        alt: 'Videresendt BYM områdeansvarlig',
        navn: 'Videresendt',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 301,
        alt: 'Videresendt veidrift',
        navn: 'Videresendt',
        foreldreId: 300,
        class: 'ferdig-behandlet',
    },
    {
        id: 302,
        alt: 'Videresendt parkforvaltning',
        navn: 'Videresendt',
        foreldreId: 300,
        class: 'ferdig-behandlet',
    },
    {
        id: 303,
        alt: 'Videresendt vaktsentralen',
        navn: 'Videresendt',
        foreldreId: 300,
        class: 'ferdig-behandlet',
    },
    {
        id: 304,
        alt: 'Videresendt arbeidsordre',
        navn: 'Videresendt',
        foreldreId: 300,
        class: 'ferdig-behandlet',
    },
    {
        id: 305,
        alt: 'Videresendt entreprenør for toalett',
        navn: 'Videresendt',
        foreldreId: 150,
        class: 'ferdig-behandlet',
    },
    {
        id: 310,
        alt: 'Bortkjøring av snø',
        navn: 'Videresendt',
        foreldreId: 300,
        class: 'ferdig-behandlet',
    },
    {
        id: 315,
        alt: 'Takk for innspill',
        navn: 'Ferdig behandlet',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
    {
        id: 316,
        alt: 'Takk for innspill',
        navn: 'Ferdig behandlet',
        foreldreId: 5,
        class: 'ferdig-behandlet',
    },
];
