import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from '../common/AnchorLink';
import * as routes from '../../constants/clientRoutes';

const EntrepreneurListRow = ({ selskap }) => {
    return (
        <tr>
            <td>
                <AnchorLink
                    to={routes.selskaper + '/' + selskap.id}
                    pageTitle={routes.selskaper}
                    pageLink={routes.selskaper}
                    children={selskap.name}
                    linkLabel={selskap.name}
                />
            </td>
            <td>{selskap.orgnumber}</td>
            <td>
                <a href={`mailto:${selskap.email}`}> {selskap.email}</a>
            </td>
        </tr>
    );
};

EntrepreneurListRow.propTypes = {
    selskap: PropTypes.object.isRequired,
};

export default EntrepreneurListRow;
