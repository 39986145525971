import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RootCloseWrapper } from 'react-overlays';
import './EditableRessursbruk.css';

class EditableRessursbrukNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newValue: null,
            editActive: false,
        };
    }

    cancel = () => {
        this.setState({ editActive: false, newValue: null });
    };

    save = () => {
        const { onSubmitEditableEnhent, ressursbruk } = this.props;
        const { newValue } = this.state;
        onSubmitEditableEnhent(newValue, ressursbruk);
        this.setState({ editActive: false, newValue: null });
    };

    activate = () => {
        const { value } = this.props;
        this.setState({ editActive: true, newValue: value });
    };

    change = e => {
        this.setState({ newValue: e.target.value });
    };

    render() {
        const { value, isEditable } = this.props;
        const { editActive, newValue } = this.state;

        return !editActive ? (
            <Button
                className="editable-ressursbruk-activate-button editable-ressursbruk-activate-button-number"
                disabled={!isEditable}
                onClick={this.activate}
            >
                {value.toString().replace('.', ',')}
            </Button>
        ) : (
            <RootCloseWrapper onRootClose={this.cancel}>
                    <form className="editable-ressursbruk editable-ressursbruk-number">
                        <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            id="editable-ressursbruk-number"
                            name="editable-ressursbruk-number"
                            onChange={this.change}
                            autoFocus
                            value={newValue}
                        />
                        <Button
                            type="submit"
                            id="save"
                            bsSize="small"
                            onClick={this.save}
                        >
                            <Glyphicon glyph="ok" className="button-save" />
                        </Button>
                        <Button id="save" bsSize="small" onClick={this.cancel}>
                            <Glyphicon
                                glyph="remove"
                                className="button-cancel"
                            />
                        </Button>
                    </form>
            </RootCloseWrapper>
        );
    }
}

EditableRessursbrukNumber.propTypes = {
    value: PropTypes.number,
    isEditable: PropTypes.bool,
    onSubmitEditableEnhent: PropTypes.func.isRequired,
    ressursbruk: PropTypes.shape({}),
};

EditableRessursbrukNumber.defaultProps = {
    value: null,
    isEditable: false,
    ressursbruk: {},
};

export default EditableRessursbrukNumber;
