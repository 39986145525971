import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCompanySpecificContracts, loadContractSistEndret, updateKontrakt } from '../../actions/contractActions';
import { loadUsers } from '../../actions/userActions';
import { loadProcesscodes } from '../../actions/processcodeActions';
import { getProcesscodeUnits } from '../../actions/RessursActions';
import { loadPrices, createPrice, deaktiverPrice, updatePrice } from '../../actions/pricesAction';
import ContractDetailHeader from '../../components/contract/ContractDetailHeader';
import {
    loadPriceChanges,
    createPriceChange,
    deaktiverPriceChange,
    updatePriceChange,
} from '../../actions/pricesChangesAction';
import { GetFakturagrunnlagRapport, GetRessursforbrukRapport } from '../../actions/RapporterAction';
import RapportModal from '../../components/Rapporter/RapportModal';
import '../../css/Contract.css';
import _ from 'lodash';
import Spinner from 'react-spinkit';

class EntrepreneurAdminContractPriser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinnerLoad: false,
        };

        this.handleKontraktSaveName = this.handleKontraktSaveName.bind(this);
    }

    componentDidMount() {
        const params = this.props.params;
        this.props.getProcesscodeUnits();
        this.props
            .loadCompanySpecificContracts(params.selskapsId)
            .then(response => {
                const contract = response.find(c => c.id === params.kontraktsId);
                if (contract) this.props.loadProcesscodes(contract.prosesskodeSkjema.id);
                this.setState({ spinnerLoad: true });
                this.props.loadPrices(params.kontraktsId);
                this.setState({ spinnerLoad: false });
            })
            .catch(() => {
                this.setState({ spinnerLoad: false });
            });
    }
    renderRows() {
        return _.map(_.orderBy(this.props.prices, ['prosesskode.navn']), (rad, index) => {
            return (
                <tr key={index}>
                    <td>{rad.prosesskode.navn}</td>
                    <td>{rad.prosesskode.beskrivelse}</td>
                    <td>{rad.prosesskode.prosesskodeEnhet.navn}</td>
                    <td>
                        <center>kr</center>
                    </td>
                    <td>
                        <div>{rad.pris.toString()}</div>
                    </td>
                </tr>
            );
        });
    }
    handleKontraktSaveName(input) {
        const { selskapsId, kontraktsId } = this.props.params;
        const contract = this.props.contracts.find(c => c.id === kontraktsId);
        const payload = Object.assign({
            SelskapId: selskapsId,
            KontraktId: kontraktsId,
            navn: input,
            avtaleId: contract.avtale.avtaleId,
            gyldigFraDato: contract.gyldigFraDato,
            gyldigTilDato: contract.gyldigTilDato,
        });
        this.props.updateKontrakt(payload);
        //this.updateUrlState(input)
    }

    render() {
        const { contracts, params, GetFakturagrunnlagRapport, GetRessursforbrukRapport } = this.props;
        const kontraktId = params.kontraktsId;
        const { spinnerLoad } = this.state;
        const currentContract = contracts.find(c => c.id === this.props.params.kontraktsId);
        if (!currentContract) return null;
        return (
            <div className="mainContainer">
                <div className="col-md-12">
                    <ContractDetailHeader contract={currentContract} onSubmitName={this.handleKontraktSaveName} />
                    <br />
                    <br />
                    <h2 className="prisskjema-header">Prisskjema</h2>
                    <br />
                    <div>
                        <center>
                            {spinnerLoad && (
                                <Spinner
                                    spinnerName="circle"
                                    className="spinner-circle-div"
                                    style={{ width: '75px', height: '75px' }}
                                />
                            )}
                        </center>
                    </div>
                    <table className="table table-fixed" id="processcode-table">
                        <thead>
                            <tr>
                                <th>Prosesskode</th>
                                <th>Beskrivelse</th>
                                <th>Enhet</th>
                                <th>Pris</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderRows()}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}
EntrepreneurAdminContractPriser.contextTypes = {
    router: PropTypes.object,
};

EntrepreneurAdminContractPriser.defaultProps = {
    prosesscodes: [],
    contracts: [],
    prices: [],
    pricechanges: [],
    lastchanged: '',
    users: [],
    prosesskodeenhent: [],
};

EntrepreneurAdminContractPriser.propTypes = {
    params: PropTypes.shape({
        selskapsId: PropTypes.string.isRequired,
        kontraktsId: PropTypes.string.isRequired,
    }).isRequired,
    rapporter: PropTypes.array,
    lastChangedDate: PropTypes.string,
    contracts: PropTypes.array.isRequired,
    updateKontrakt: PropTypes.func.isRequired,
    prosesscodes: PropTypes.array.isRequired,
    loadProcesscodes: PropTypes.func.isRequired,
    contractId: PropTypes.string.isRequired,
    loadingContracts: PropTypes.bool.isRequired,
    loadCompanySpecificContracts: PropTypes.func.isRequired,
    loadPrices: PropTypes.func.isRequired,
    prices: PropTypes.array.isRequired,
    prosesskodeenhent: PropTypes.array.isRequired,
    getProcesscodeUnits: PropTypes.func.isRequired,
    GetFakturagrunnlagRapport: PropTypes.func,
    GetRessursforbrukRapport: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        contractId: ownProps.params.kontraktsId,
        contracts: state.contracts,
        prosesscodes: state.processCodes,
        loadingContracts: state.contracts === null || state.contracts.length === 0,
        prices: state.prices,
        loadingBulkupdates: state.bulkupdates === null || state.bulkupdates.length === 0,
        bulkupdates: state.bulkupdates,
        pricechanges: state.pricechanges,
        lastchanged: state.lastChanged,
        users: state.users.userList.users,
        prosesskodeenhent: state.ressursBruk.processcodeunit,
        rapporter: state.rapporter,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            loadContractSistEndret,
            loadProcesscodes,
            loadCompanySpecificContracts,
            loadPrices,
            createPrice,
            createPriceChange,
            deaktiverPrice,
            deaktiverPriceChange,
            updatePrice,
            updatePriceChange,
            loadPriceChanges,
            loadUsers,
            getProcesscodeUnits,
            updateKontrakt,
            GetFakturagrunnlagRapport,
            GetRessursforbrukRapport,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneurAdminContractPriser);
