import cookie from 'react-cookie';
import jwtDecode from 'jwt-decode';
import map from 'lodash/map';
import union from 'lodash/union';
import includes from 'lodash/includes';
import * as globals from '../constants/Globals';
import { oidcConfig } from '../oidcConfig';
import { User } from 'oidc-client-ts';

const bymcookie = 'bymtoken';
const prosessrolleId = 'ProsessrolleId';
const prosessrolleRolleId = 'ProsessrolleRolleId';

export function loadBymCookie() {
    return getAccessToken();
    //return cookie.load(bymcookie);
}

export function saveBymCookie(tk) {
    const expiration = new Date(tk.exp * 1000);
    cookie.save(bymcookie, tk.access_token, { path: '/', expires: expiration });
}

export function removeBymCookie() {
    if (bymCookieExists()) {
        localStorage.removeItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
        //cookie.remove(bymcookie, { path: '/' });
    }
}

function getToken() {
    const oidcStorage = localStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
    const token = oidcStorage ? User.fromStorageString(oidcStorage) : undefined;
    if (token && token.expired) {
        localStorage.removeItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
        return undefined;
    }

    return token;
}

export function getAccessToken() {
    const token = getToken();
    if (token) {
        return jwtDecode(token?.access_token);
    }
}

export function Username() {
    const token = getToken();
    if (token) {
        return token.profile.name;
        //return token['sub'];
    }
    return '';
}
export function UserId() {
    const token = getToken();
    if (token) {
        return token.profile?.params?.['bym-id'];
        //return token['brukerId'];
    }
    return '';
}
export function RoleId() {
    const token = getAccessToken();
    if (token) {
        // returnerer "primærrollen" basert på et hierarki
        if (UserIsBymAdmin()) return globals.bymeldingAdmin;
        if (UserIsServicedeskAdmin()) return globals.serviceAvdelingAdmin;
        if (UserIsServicedesk()) return globals.serviceavdeling;
        if (UserIsEntrepreneurAdmin()) return globals.entrepreneurAdmin;
        if (UserIsEntrepreneur()) return globals.entrepreneurs;

        console.log('Bruker har ingen relevante primærroller');
        return undefined;
    }
    return '';
}
export function UsersRoleAndProcessroleRoles() {
    const token = getAccessToken();
    if (token) {
        return token.roles; 
    }
    return [];
}

export function CheckValueExistsInToken(value) {
    const token = getAccessToken();
    //return token && token.roles && token.roles.indexOf(value) > -1;
    const rolleFinnes = token && token.roles && token.roles.indexOf(value) > -1;
    //console.log('Rolle ' + value + ' ble ' + (rolleFinnes ? '' : 'IKKE ') + 'funnet i tokenet');
    return rolleFinnes; // bare debug
}

// export function CheckVRoleInToken(value) {
//     const token = decodeToken();
//     if (token) {
//         const pr_id_exists = token[prosessrolleId] ? token[prosessrolleId] === value : false;
//         return pr_id_exists;
//     }
//     return false;
// }

export function UserHasEnkAccess() {
    const enkAdmin = CheckValueExistsInToken(globals.EntreprenorkontrollAdmin);
    const enkEntreprenor = CheckValueExistsInToken(globals.EnkEntreprenor);
    const enkForvalter = CheckValueExistsInToken(globals.EnkForvalter);
    const enkKontrollor = CheckValueExistsInToken(globals.EnkKontrollor);
    return enkAdmin || enkEntreprenor || enkForvalter || enkKontrollor;
}

export function UserIsBymAnsatt() {
    const enkAdmin = CheckValueExistsInToken(globals.EntreprenorkontrollAdmin);
    const enkForvalter = CheckValueExistsInToken(globals.EnkForvalter);
    const enkKontrollor = CheckValueExistsInToken(globals.EnkKontrollor);

    const bymAdmin = CheckValueExistsInToken(globals.bymeldingAdmin);
    const serviceAvdAdmin = CheckValueExistsInToken(globals.serviceAvdelingAdmin);
    const serviceAvd = CheckValueExistsInToken(globals.serviceavdeling);

    return enkAdmin || enkForvalter || enkKontrollor || bymAdmin || serviceAvdAdmin || serviceAvd;
}

export function UserIsBymAdmin() {
    const exists = CheckValueExistsInToken(globals.bymeldingAdmin);
    return exists;
}
export function UserIsEntrepreneurAdmin() {
    const exists = CheckValueExistsInToken(globals.entrepreneurAdmin);
    return exists;
}
export function UserIsServicedeskAdmin() {
    const exists = CheckValueExistsInToken(globals.serviceAvdelingAdmin);
    return exists;
}

export function UserIsServicedesk() {
    const exists = CheckValueExistsInToken(globals.serviceavdeling);
    return exists;
}
export function UserIsEntrepreneur() {
    const exists = CheckValueExistsInToken(globals.entrepreneurs);
    return exists;
}

export function UserIsPublikum() {
    const exists = CheckValueExistsInToken(globals.publikum);
    return exists;
}

export function AdminForWhichApplication() {
    const decodedToken = getAccessToken();
    if (decodedToken) {
        const app = findKeyInObj(decodedToken, '1');
        return app;
    }
}

export function findKeyInObj(obj, val) {
    let res = '';
    map(obj, function(k, v) {
        if (k === val) {
            res = v;
        }
    });
    return res;
}
export function BearerBymToken() {
    //const token = loadBymCookie();
    const token = getToken();
    return `Bearer ${token.access_token}`;
}

export function bymCookieExists() {
    const token = loadBymCookie();

    return token != null ? true : false;
}

export function validateToken() {
    var token = getAccessToken();
    return token && Date.now() < token.exp * 1000;
    // if (bymCookieExists()) {
    //     const token = getAccessToken();
    //     const currentTime = Date.now();
    //     const notBeforeTime = new Date(token.nbf * 1000);
    //     const notAfterTime = new Date(token.exp * 1000);
    //     const expired = notBeforeTime > currentTime || currentTime > notAfterTime;
    //     if (expired) {
    //         console.log('Token expired');
    //         return false;
    //     }
    //     return true;
    // }
    // return false;
}

// export function SetAuthorizationToken() {
//     if (bymCookieExists()) {
//         const bearer = BearerBymToken();
//         axios.defaults.headers.common['Authorization'] = bearer;
//     } else {
//         delete axios.defaults.headers.common['Authorization'];
//     }
// }
