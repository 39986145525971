import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

export default function lastChangedReducer(state = InitialState.lastChanged, action) {
  switch (action.type) {
      case types.LOAD_CONTRACT_SIST_ENDRET:
        return action.sistendret;

    default:
      return state;
  }
}
