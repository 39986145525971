/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../css/Meldinger/Meldinger.css';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import classnames from 'classnames';
import Spinner from 'react-spinkit';
import * as errorMessages from '../../constants/ValidationErrors';

import './MeldingSearch.css';
import closeIcon from '../../images/lukk.png';
import { ingenMeldingerTilInnmelderen } from '../../constants/ResponseMessages';
import MeldingSearchTipsDialog from './MeldingSearchTipsDialog';

class MeldingSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fritekstSok: '',
            errors: {},
            expand: false,
            soketipsOpen: false,
        };
        this.Cancel = this.Cancel.bind(this);
        this.Search = this.Search.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.openSoketips = this.openSoketips.bind(this);
        this.closeSoketips = this.closeSoketips.bind(this);
    }

    componentWillMount() {
        const searchText = this.getQuery('fritekstSok');
        if (searchText) {
            this.setState({ fritekstSok: searchText });
            this.props.toggleHandler();
        }
    }

    forceUpdate() {
        // In order to focus on search input when it expandert
        if (this.searchInput) this.searchInput.focus();
    }

    componentWillReceiveProps(props) {
        if (this.props.expand === false && props.expand === true) {
            setTimeout(this.forceUpdate.bind(this), 1000);
        }
    }
    // componentDidMount(){
    //     setTimeout(this.forceUpdate.bind(this), 1000);
    // }

    getQuery(q) {
        let { query } = this.context.router.location;
        if (query[q]) {
            let value = query[q];
            return value;
        }
    }

    getFilters() {
        let { location } = this.context.router;
        let { search } = location;
        let filter = search.substring(1, search.length);
        return filter;
    }

    queryBuilder(key, value) {
        const { pathname } = this.context.router.location;
        const params = this.context.router.location.query;
        // const qp=queryString.parse(location.search)
        if (value && value !== '0') params[key] = value;
        else delete params[key];
        const qu = queryString.stringify(params);
        const uri = pathname + '?' + qu;
        this.context.router.push(uri);
    }

    onChange(e) {
        if (this.state.errors[e.target.name]) {
            let errors = { ...this.state.errors };
            delete errors[e.target.name];
            this.setState({ [e.target.name]: e.target.value, errors });
        } else {
            this.setState({ [e.target.name]: e.target.value });
            this.queryBuilder(e.target.name, e.target.value);
        }
        // this.props.OnSearch(e.target.value)
    }

    cleanUp() {
        this.setState({ errors: {}, fritekstSok: '' });
        this.queryBuilder('fritekstSok', '');
        this.props.OnSearch('');
        this.props.toggleHandler();
    }

    Cancel(e) {
        this.cleanUp();
        e.preventDefault();
    }

    handleKeyPress(e) {
        if (e.charCode === 13) {
            this.props.OnSearch(e.target.value);
            e.preventDefault();
        }
    }

    Search(e) {
        e.preventDefault();
        if (this.state.fritekstSok === ' ') {
            let errors = {};
            Object.assign(errors, (errors.fritekstSok = errorMessages.searchTextForMap));
            this.setState({ errors });
        }
        let filters = this.getFilters();
        this.props.OnSearch(filters);
    }

    openSoketips(e) {
        this.setState({ soketipsOpen: true });
    }

    closeSoketips(e) {
        this.setState({ soketipsOpen: false });
    }

    render() {
        const { fritekstSok, errors, soketipsOpen } = this.state;
        const { expand, loading, isServiceavdeling } = this.props;
        return (
            // <Collapse in={expand}>
            <div
                className={`messages-search-search ${
                    expand ? 'messages-search-search-container-visible' : 'messages-search-search-container-hidden'
                }`}
            >
                <span className="tips-block">
                    <Button bsStyle="link" onClick={this.openSoketips}>
                        Søketips
                    </Button>
                </span>
                <div
                    className={classnames('messages-search-search-element', 'form-group', {
                        'has-error': errors.fritekstSok,
                    })}
                >
                    <input
                        name="fritekstSok"
                        className="form-control"
                        onChange={this.onChange}
                        onKeyPress={this.handleKeyPress}
                        value={fritekstSok}
                        type="text"
                        placeholder="Søk i meldinger"
                        ref={sok => (this.searchInput = sok)}
                    />
                    {errors.fritekstSok && <span className="help-block">{errors.fritekstSok}</span>}
                </div>
                <Button
                    bsSize="md"
                    bsStyle="primary"
                    className="messages-search-button messages-search-search-element"
                    onClick={this.Search}
                    disabled={loading || fritekstSok.length < 1}
                >
                    {loading ? (
                        <Spinner
                            spinnerName="circle"
                            style={{
                                padding: '0 5px 0 0',
                                width: '15px',
                                height: '15px',
                                margin: '4px',
                            }}
                            noFadeIn
                        />
                    ) : (
                        <span
                            className="glyphicon glyphicon-search"
                            style={{
                                padding: '0 5px 0 0',
                                top: 0,
                                fontSize: 'inherit',
                            }}
                        />
                    )}
                    Søk
                </Button>
                <Button
                    bsSize="xs"
                    bsClass="btn btn-link"
                    className="messages-search-button messages-search-search-element"
                    onClick={this.Cancel}
                >
                    <img src={closeIcon} alt="lukk" />
                </Button>
                <MeldingSearchTipsDialog show={soketipsOpen} onHide={this.closeSoketips} />
            </div>
        );
    }
}

MeldingSearch.propTypes = {
    expand: PropTypes.bool,
    toggleHandler: PropTypes.func.isRequired,
    OnSearch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};
MeldingSearch.contextTypes = {
    router: PropTypes.object,
};
MeldingSearch.defaultProps = {
    loading: false,
    expand: true,
};

export default MeldingSearch;
