import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Glyphicon, Modal } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import _ from 'lodash';
import DokumentasjonLoader from './DokumentasjonLoader';
import { addDokumentasjonToMelding } from '../../actions/MeldingerActions';
import '../../css/common/ImageUploader.css';

const documentFormats = ['application/pdf'];

class Dokumentasjon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      imageData: [],
      loading: false,
      filesNotSupported: false,
    };
  }

  addImage = image => {
    const { documents } = this.state;
    // if (documents.length < 5) {
    const copyimages = documents.concat(image);
    const set = _.uniqBy(copyimages, 'name');
    this.setState({ documents: set });
    // }
  };

  removeImage = (e, name) => {
    const images = this.state.documents;
    const filter = _.filter(images, i => {
      return i.name !== name;
    });
    this.setState({ documents: filter });
  };

  removeAllImages = () => {
    this.setState({ documents: [] });
  };

  onChangeFileUpload = e => {
    const { filesNotSupported } = this.state;
    const files = e.target.files;
    let arr = [];
    this.setState({ filesNotSupported: false });
    if (files.length > 0) {
      _.map(files, f => {
        if (
          _.startsWith(f.type, "image/") ||
          _.includes(documentFormats, f.type)
        ) {
          arr.push(f);
        } else {
          this.setState({ filesNotSupported: true });
        }
      });
    }
    if (arr.length > 0) this.addImage(arr);
  };

  openFileUpload = () => {
    this.FileInputElement.click();
  };

  render() {
    const { documents, loading, filesNotSupported } = this.state;
    const {
      existingDocuments,
      addDokumentasjonToMelding,
      meldingId,
      selskapId,
    } = this.props;
    const size = documents.length;
    const hideImages = size === 0 ? 'hideImages' : '';

    return (
      <div>
        <h4>
          {existingDocuments.length > 0
            ? 'Dokumentert arbeid'
            : 'Det er ikke registrert dokumentasjon på denne meldingen.'}
        </h4>
        {existingDocuments.length > 0 && (
          <DokumentasjonLoader
            meldingId={meldingId}
            numberOfImgs={existingDocuments.length}
          />
        )}
        <div>
          {filesNotSupported && (
            <div
              className="alert-warning"
              style={{
                padding: '15px',
                marginBottom: '20px',
                border: '1px solid transparent',
                borderRadius: '4px',
              }}
            >
              En eller flere av filene du forøkte å legge til støttes ikke.
              <br />
              Støttede filformater: PNG, JPEG, JPG og PDF.
            </div>
          )}
          <Button bsStyle="link" id="buttonLink" onClick={this.openFileUpload}>
            Legg til dokumentasjon...
          </Button>
          <div id="ImageContainer" className={hideImages}>
            <input
              type="file"
              name="file"
              id="file"
              accept={['image/*', ...documentFormats]}
              multiple
              ref={input => (this.FileInputElement = input)}
              style={{ display: 'none' }}
              onChange={this.onChangeFileUpload}
            />
          </div>
        </div>

        <Modal show={documents.length > 0} onHide={this.removeAllImages}>
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Bekreft bildevalg</strong>
            </Modal.Title>
          </Modal.Header>
          <div className="bildeopplasting-modal">
            <div className="bildeopplasting-modal-img-box">
              {size > 0 &&
                _.map(documents, (i, index) => {
                  const src = URL.createObjectURL(i);
                  return (
                    <div key={index} className="imageDiv">
                      {_.startsWith(i.type, "image/") ? (
                        <img
                          className="imageUploaderThumbnails"
                          src={src}
                          alt={i.name}
                        />
                      ) : (
                        <div className="imageUploaderThumbnailsDocument">
                          <Glyphicon
                            className="imageUploaderThumbnailsDocumentIcon"
                            glyph="file"
                          />
                          <span className="imageUploaderThumbnailsDocumentFileName">
                            {i.name}
                          </span>
                        </div>
                      )}
                      <Glyphicon
                        glyph="remove-sign"
                        className="imageUploaderThumbnailsRemove"
                        onClick={e => this.removeImage(e, i.name)}
                      />
                    </div>
                  );
                })}
            </div>
            <Modal.Footer>
              {loading && (
                <div>
                  <Spinner className="three-bounce" />
                </div>
              )}
              <Button onClick={this.removeAllImages} disabled={loading}>
                Avbryt
              </Button>
              <Button
                disabled={loading}
                onClick={() => {
                  this.setState({ loading: true });
                  addDokumentasjonToMelding(
                    meldingId,
                    documents,
                    selskapId
                  ).then(() => {
                    this.removeAllImages();
                    this.setState({ loading: false });
                  });
                }}
              >
                Last opp
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
        {loading && (
          <div>
            <Spinner className="three-bounce" />
          </div>
        )}
      </div>
    );
  }
}

Dokumentasjon.propTypes = {
  existingDocuments: PropTypes.array,
  addDokumentasjonToMelding: PropTypes.func.isRequired,
  meldingId: PropTypes.string.isRequired,
  selskapId: PropTypes.string,
  selskapId: PropTypes.string,
};

Dokumentasjon.defaultProps = {
  existingDocuments: [],
  selskapId: null,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addDokumentasjonToMelding,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Dokumentasjon);
