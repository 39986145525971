import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { saveSelskap, BronnoysundRegistreneGetInfo } from '../../actions/selskaperActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import validateInput from '../../validations/AddNewEntrepreneur';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import * as validationerrors from '../../constants/ValidationErrors';
import { selskapsTypeEntreprenorerId } from '../../constants/Globals';

class NewEntrepreneurModalContainer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: this.props.initialChecked,
            name: '',
            orgnumber: '',
            email: '',
            phonenumber: '',
            initialName: true,
            initialOrgnumber: true,
            initialEmail: true,
            errors: {},
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.validateAndSave = this.validateAndSave.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.GetBrregInfo = this.GetBrregInfo.bind(this);
    }

    openModal() {
        this.setState({ open: true });
        this.props.callbackParent(true);
    }

    closeModal() {
        this.setState({
            open: false,
            errors: {},
            name: '',
            orgnumber: '',
            email: '',
            phonenumber: '',
        });
        this.props.callbackParent(false);
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
        //this.GetBrregInfo(e)
    }
    onNameChange(e) {
        this.setState({ name: e.target.value });
        //this.GetBrregInfo(e)
    }
    GetBrregInfo(e) {
        let name = e.target.name;
        let value = e.target.value.trim();
        if (name === 'orgnumber') {
            if (value.length === 9 && !isNaN(value))
                this.props
                    .BronnoysundRegistreneGetInfo(value)
                    .then(res => {
                        let navn = res;
                        this.setState({ name: navn, errors: {} });
                    })
                    .catch(() => {
                        let errors = { orgnumber: validationerrors.OrgNumberNotExists };
                        this.setState({ errors });
                    });
            else if (value.lengh !== 9) {
                let errors = { orgnumber: validationerrors.orgnumberLength };
                this.setState({ errors });
            } else {
                this.setState({ name: '', errors: {} });
            }
        }
    }
    isValid() {
        let { errors, isValid } = validateInput(this.state);
        //const orgnumber = this.props.organisasjon
        if (!isValid) {
            this.setState({ errors });
        }
        //     // if (!orgnumber)
        //     // {
        //         errors = { orgnumber: validationerrors.OrgNumberNotExists }
        //         this.setState({ errors })
        //         isValid = false
        //    // }
        return isValid;
    }

    validateAndSave() {
        if (this.isValid()) {
            let values = {
                selskapsTypeId: selskapsTypeEntreprenorerId,
                navn: this.state.name,
                orgnr: this.state.orgnumber,
                epost: this.state.email,
                telefon: this.state.phonenumber,
            };
            this.props.saveSelskap(values);
            this.closeModal();
        }
    }

    render() {
        const { name, email, orgnumber, errors } = this.state;
        return (
            <Modal show={this.state.open} onHide={this.closeModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Opprett ny entreprenør</Modal.Title>
                </Modal.Header>
                <form id="AddNewEntrepreneur">
                    <Modal.Body style={{ padding: '30px 20px 20px 20px' }}>
                        <TextFieldGroup
                            label="Navn på entreprenør"
                            name="name"
                            onChange={this.onNameChange}
                            value={name}
                            error={errors.name}
                        />
                        <TextFieldGroup
                            id="orgnumberrrr"
                            label="Organisasjons nummer"
                            name="orgnumber"
                            onChange={this.onChange}
                            value={orgnumber}
                            error={errors.orgnumber}
                        />
                        {/*<button className="btn btn-link" onClick={this.GetBrregInfo}>Hent fra brreg.no</button>*/}

                        <TextFieldGroup
                            label="E-post"
                            name="email"
                            onChange={this.onChange}
                            value={email}
                            error={errors.email}
                        />
                        {/*<TextFieldGroup label="Telefon" name="phonenumber" onChange={this.onChange} value={phonenumber} error={errors.phonenumber} />*/}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-left">
                            <Button bsStyle="link" onClick={this.closeModal}>
                                Avbryt
                            </Button>
                            <Button bsStyle="primary" bsSize="large" onClick={this.validateAndSave}>
                                Opprett ny entreprenør
                            </Button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

NewEntrepreneurModalContainer.propTypes = {
    saveSelskap: PropTypes.func.isRequired,
    BronnoysundRegistreneGetInfo: PropTypes.func,
    organisasjon: PropTypes.object,
    initialChecked: PropTypes.bool.isRequired,
    callbackParent: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    // return {
    //     saveSelskap: (selskap) => { dispatch(saveSelskap(selskap)) },
    // };
    return bindActionCreators({ saveSelskap, BronnoysundRegistreneGetInfo }, dispatch);
}
const mapStateToProps = state => {
    return {
        organisasjon: state.common.organisasjon,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(NewEntrepreneurModalContainer);
