import React from 'react';
import * as routers from "../../constants/clientRoutes"

export default (props) =>
{
    return (
        <div className="Login_center_div responsive" id="LoginDiv">
            <div className="userAdminstrationHeader text-center">Ditt passord er endret!</div>
            <div className="text-center">
                <div>
                    <br />
                    <a className="btn btn-link" href={routers.home}>Trykk her for å logge inn</a>
                </div>
            </div>
        </div>
    );
}