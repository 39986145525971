import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ConfirmModal from '../common/ConfirmModal';
import EditableField from '../common/EditableField';
import * as ResponseMessages from '../../constants/ResponseMessages';

const ProcesscodeListRow = ({ processcode, onClick, onSubmit }) => {
    const [confirmDeleteVisible, toggleConfirmDeleteVisible] = useState(false);

    const triggerClickAndCloseModal = prosesskode => {
        onClick(prosesskode);
        toggleConfirmDeleteVisible(false);
    };

    return (
        <tr>
            <td>
                <EditableField text={processcode.navn} onSubmit={onSubmit} />
            </td>
            <td>{processcode.beskrivelse}</td>
            <td>{processcode.prosesskodeEnhetNavn}</td>
            <td>
                <Button className="danger pull-right" bsStyle="link" onClick={() => toggleConfirmDeleteVisible(true)}>
                    Fjern prosesskode
                </Button>
                {confirmDeleteVisible && (
                    <ConfirmModal
                        modalTitle={ResponseMessages.prosesskoderRemoveprosesskode}
                        bodyText={`${ResponseMessages.prosesskodeRemoveQuestionText}"${processcode.navn}" ?`}
                        onConfirm={triggerClickAndCloseModal}
                        onCancel={() => toggleConfirmDeleteVisible(false)}
                        confirmText="Fjern"
                    />
                )}
            </td>
        </tr>
    );
};

ProcesscodeListRow.propTypes = {
    processcode: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ProcesscodeListRow;
