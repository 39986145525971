import React from 'react';
import WarningIcon from '../../images/warning-triangle.svg';
import SuccessIcon from '../../images/success-square.svg';
import InformationIcon from '../../images/information.svg';
import ErrorIcon from '../../images/error-hexagon.svg';
import './Varsel.css';

const Varsel = varsel => {
    let currentVarsel = varsel.varsel;
    let varselKlasse = 'varsel';
    let ikon = null;

    switch (currentVarsel.varslingstype) {
        case 'Advarsel':
            varselKlasse += ' advarsel';
            ikon = <img src={WarningIcon} alt="Advarsel" aria-hidden />;
            break;
        case 'Feil':
            varselKlasse += ' feil';
            ikon = <img src={ErrorIcon} alt="Feil" aria-hidden />;
            break;
        case 'FeilRettet':
            varselKlasse += ' feil-rettet';
            ikon = <img src={SuccessIcon} alt="Feil Rettet" aria-hidden />;
            break;
        default:
            // Default class and fallback
            varselKlasse += ' informasjon';
            ikon = <img src={InformationIcon} alt="Informasjon" aria-hidden />;
            break;
    }

    const tekstHTML = { __html: currentVarsel.tekst };

    return (
        <section className="varsel-container">
            <div className={varselKlasse}>
                <div className="flex">
                    <div aria-hidden>{ikon}</div>
                    <div className="varsel-tekst-container">
                        <p dangerouslySetInnerHTML={tekstHTML} /> 
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Varsel;
