import { combineReducers } from 'redux';
import flashMessages from './flashMessagesReducer';
import login from './loginReducer';
import { entrepreneurReducer as entreprenorer, saksbehandlereReducer as saksbehandlere } from './selskaperReducers';
import users from './userReducer';
import contracts from './contractReducer';
import processcodeschemes from './processcodeschemeReducer';
import processCodes from './processcodeReducer';
import contractZones from './contractZonesReducer';
import common from './commonReducer';
import prices from './pricesReducer';
import bulkupdates from './bulkUpdatesReducer';
import pricechanges from './priceChangesReducer';
import lastChanged from './lastChangedReducer';
import worm from './meldingReducer';
import meldinger from './meldingerReducer';
import ressursBruk from './resourceBrukReducer';
import kommentarer from './kommentarerReducer';
import historisk from './HistoriskReducer';
import tilbakemeldinger from './tilbakemeldingerReducer';
import { LOGOUT_USER } from '../actions/ActionTypes';
import rapporter from './RapporterReducer';
import lagredSok from './SavedMeldingSearchReducer';
import healthcheck from './HealthCheckReducer';
import { delbydelerReducer as delbydeler } from './delbydelerReducer';
import { feilrettingReducer as feilrettingskoder } from './feilrettingReducer';
import { varselReducer as varsel } from './varselReducer';

const appReducer = combineReducers({
    login,
    entreprenorer,
    saksbehandlere,
    users,
    contracts,
    contractZones,
    processcodeschemes,
    processCodes,
    flashMessages,
    prices,
    common,
    bulkupdates,
    pricechanges,
    lastChanged,
    meldinger,
    worm,
    ressursBruk,
    kommentarer,
    historisk,
    tilbakemeldinger,
    rapporter,
    lagredSok,
    healthcheck,
    delbydeler,
    feilrettingskoder,
    varsel,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
