import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

class AlertModal extends Component {
    render() {
        const { open, onClose, headerText, bodyText, confirmText } = this.props;
        return (
            <div>
                <Modal show={open} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <strong>{headerText}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{bodyText}</Modal.Body>
                    <Modal.Footer>
                        <Button className="pull-left" bsSize="large" type="submit" onClick={onClose}>
                            {confirmText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

AlertModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

export default AlertModal;
