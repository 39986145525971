import React from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { Button } from 'react-bootstrap';
import $ from 'jquery';

import './grunnkartVelger.css';

// import { basemapOptions } from '../../constants/kartGlobals';

class GrunnkartVelger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      // statkartAvailable: true,
    };
  }

  // componentDidMount = () => {
  //   const url = 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom=0&x=0&y=0';
  //   $.ajax(url, {method: 'GET', headers: {"Cache-Control": "no-cache" }}).done().fail(() => {
  //     this.setState({statkartAvailable: false});
  //   });
  // }

  // componentDidUpdate = () => {
  //   const { setGrunnkart, grunnkartOptions, grunnkart } = this.props;
  //   const { statkartAvailable } = this.state;
  //   if (!statkartAvailable && grunnkart !== grunnkartOptions.detaljertGrunnkart)
  //     setGrunnkart(grunnkartOptions.detaljertGrunnkart);
  // };

  toggleOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const {
      position,
      grunnkartOptions,
      grunnkart,
      setGrunnkart,
      statkartAvailable,
    } = this.props;
    if (
      !statkartAvailable &&
      (grunnkart === grunnkartOptions.grunnkart ||
        grunnkart === grunnkartOptions.satelittkart)
    )
      setGrunnkart(grunnkartOptions.detaljertGrunnkart);
    const { open } = this.state;

    return (
      <Control position={position}>
        <div className="grunnkart-velger-container">
          <Button
            onClick={this.toggleOpen}
            className={`grunnkart-options ${
              grunnkart === grunnkartOptions.satelittkart
                ? 'grunnkart-option-kart'
                : 'grunnkart-option-satelitt'
            }`}
          />
          {open && (
            <div className="grunnkart-velger">
              {grunnkart !== grunnkartOptions.grunnkart && (
                <Button
                  disabled={!statkartAvailable}
                  onClick={() => {
                    setGrunnkart(grunnkartOptions.grunnkart);
                    this.toggleOpen();
                  }}
                >
                  Grunnkart
                </Button>
              )}
              {grunnkart !== grunnkartOptions.detaljertGrunnkart && (
                <Button
                  onClick={() => {
                    setGrunnkart(grunnkartOptions.detaljertGrunnkart);
                    this.toggleOpen();
                  }}
                >
                  Detaljert Grunnkart
                </Button>
              )}
              {grunnkart !== grunnkartOptions.satelittkart && (
                <Button
                  disabled={!statkartAvailable}
                  onClick={() => {
                    setGrunnkart(grunnkartOptions.satelittkart);
                    this.toggleOpen();
                  }}
                >
                  Flyfoto
                </Button>
              )}
              {grunnkart !== grunnkartOptions.bymBakgrunnskart && (
                <Button
                  onClick={() => {
                    setGrunnkart(grunnkartOptions.bymBakgrunnskart);
                    this.toggleOpen();
                  }}
                >
                  BYM Grunnkart
                </Button>
              )}
            </div>
          )}
        </div>
      </Control>
    );
  }
}

GrunnkartVelger.propTypes = {
  position: PropTypes.string,
  grunnkartOptions: PropTypes.string,
  grunnkart: PropTypes.string,
  setGrunnkart: PropTypes.func.isRequired,
  statkartAvailable: PropTypes.bool.isRequired,
};

export default GrunnkartVelger;
