import * as globals from '../constants/Globals';

export function getSelskapsTypeFromRolle(rolle) {
    return globals.rolleSelskapsTypeMapping[rolle];
}

export function getRollerForSelskapsType(selskapsType) {
    return globals.rollerForSelskapsType[selskapsType];
}

export function getAllRolles() {
    return globals.bymeldingRoller;
}
