import React,{Component} from 'react'
import PropTypes from "prop-types"
import {Table} from "react-bootstrap"
import {map} from "lodash"

class GenericGrid extends Component{
    state={}
    static propTypes={
        header:PropTypes.array,
        body:PropTypes.array,
        children:PropTypes.any,
        id:PropTypes.string,
    }
    render(){
        const {header,children, id} =this.props
        return (<div className="col-xs-12">
             <Table id={id}>
                    <thead>
                        <tr>
                            {
                                map(header,(i,index)=>{return(<th key={index}>{i}</th>)} )
                            }
                        </tr>
                    </thead>
                    {children}
                    {/* <tbody>
                        {
                            map(body,(i,index)=>{
                            return (<tr key={index}><td>{i.navn}/td><td><div className="pull-right">{i.</div></td></tr>)
                            })
                        }
                    </tbody> */}
                </Table>
        </div>)
    }
}

export default GenericGrid