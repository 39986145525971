import { getAccessToken } from '../utils/cookieHandler';

export const harEnAvRoller = (roller: string[]): boolean => {
    const token = getAccessToken();
    let rolletreff = 0;
    roller.forEach(rolle => {
        if (token && token.roles && token.roles.indexOf(rolle) > -1) rolletreff++;
    });

    return rolletreff > 0;
};

export const harRolle = (rolle: string): boolean => {
    const token = getAccessToken();
    return token && token.roles && token.roles.indexOf(rolle) > -1;
};
