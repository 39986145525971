import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import avtalerList from '../../components/avtaler/avtalerList'
import { loadAvtaler, UpdateAvtale } from '../../actions/avtalerAction'
import { loadEntrepreneurs } from "../../actions/selskaperActions"

const mapStateToProps = (state) =>
{
    return {
        avtaler: state.contractZones,
        entrepreneurs: state.entreprenorer,
    }
}
const mapDispatchToProps = (dispatch) =>
{
    return bindActionCreators({ loadAvtaler, UpdateAvtale, loadEntrepreneurs }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(avtalerList)
