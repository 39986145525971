import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

// eslint-disable-next-line import/prefer-default-export
export function feilrettingReducer(state = InitialState.feilrettingskoder, action) {
    switch (action.type) {
        case types.LOAD_FEILRETTINGSKODER_LIST_SUCCESS:
          return action.feilrettingskoder;

        default:
            return state;
    }
}
