import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import classnames from 'classnames';
import 'react-select/dist/react-select.css';

const GetAvtaler = avtaler => {
    return avtaler.map(avtale => {
        return {
            value: avtale.id,
            label: avtale.navn,
        };
    });
};

const avtaleSelect = ({ selectedValues, onChange, avtaler, error }) => {
    const handleChange = options => {
        const optionsToSoner = options.map(option => {
            return { id: option.value, navn: option.label };
        });
        onChange(optionsToSoner);
    };

    return (
        <FormGroup
            id="AvtaleSelectDropdownList"
            className={classnames('AvtaleSelectDropdownList', { 'has-error': error })}
        >
            <label htmlFor="avtaleSelect">Avtaler</label>
            <Select
                name="avtaleSelect"
                placeholder="Velg avtale..."
                multi={true}
                value={GetAvtaler(selectedValues)}
                options={GetAvtaler(avtaler)}
                onChange={handleChange}
            />
            {error && error.length > 0 && <span className="help-block">{error}</span>}
        </FormGroup>
    );
};

avtaleSelect.propTypes = {
    selectedValues: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    avtaler: PropTypes.array.isRequired,
    error: PropTypes.string,
};

export default avtaleSelect;
