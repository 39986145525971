import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GlobalRessursforbruk } from '../../constants/Globals';
import { getSelskapsIdForLoggedUser } from '../../actions/LoginActions';

import {
    getResources,
    addResource,
    updateResourceList,
    updateGlobalResourceList,
    deleteResourceList,
    deleteGlobalResourceList,
    loadSkiftList,
    getGlobalResources,
    addGlobalResources,
    getProcessCodeSchemaPrices,
} from '../../actions/RessursActions';
import { loadCompanySpecificContracts } from '../../actions/contractActions';
import { loadProcessCodesList } from '../../actions/processcodeActions';
import { addFlashMessage } from '../../actions/FlashMessagesAction';
import GlobalRessursbrukComponent from '../../components/entrepreneur/GlobalRessursbrukComponent';

const mapStateToProps = (state, ownProps) => {
    return {
        resources: state.ressursBruk.resources,
        tjenester: state.processCodes,
        kontrakts: state.contracts,
        skifter: state.ressursBruk.skift,
        prices: state.ressursBruk.prices,
        Type: GlobalRessursforbruk,
        params: ownProps.params,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getResources,
            addResource,
            updateResourceList,
            updateGlobalResourceList,
            deleteResourceList,
            deleteGlobalResourceList,
            loadProcessCodesList,
            loadSkiftList,
            getProcessCodeSchemaPrices,
            loadCompanySpecificContracts,
            getGlobalResources,
            addGlobalResources,
            getSelskapsIdForLoggedUser,
            addFlashMessage,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalRessursbrukComponent);
