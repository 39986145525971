//import XLSX from "xlsx"
import FileSaver from "file-saver"
import { FormatToMonthAndYear } from "./dateTimeHelpers";

export function filnavnFraResponse(response) {
    if(!response) return "";
    const contentDisposition = response.headers["content-disposition"]
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        var filnavnRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var funn = filnavnRegex.exec(contentDisposition);
        if (funn != null && funn[1]){
            return funn[1].replace(/['"]/g, '');
        }
    }
    return "";
}

export function createFileName(filename, month, year, extension)
{
    const dato = FormatToMonthAndYear(parseInt(month, 10) - 1, parseInt(year, 10))
    return `${filename}_${dato}_Rapport.${extension}`
}

export const stringToArrayBuffer = (s) =>
{
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (var i = 0; i !== s.length; ++i)
    {
        view[i] = s.charCodeAt(i) & 0xFF
    }
    return buf
}
//saves csv file from response
export function downloadCSVRapport(response, filename)
{
    if (!response) return null
    try
    {
        const data = response.data
        const s2ab = this.stringToArrayBuffer(data)
        const blob = new Blob([s2ab], { type: "application/octet-stream" })
        FileSaver.saveAs(blob, filename)
    }
    catch (err)
    {
        throw ("Feil ved lagring av csv fil.\n", err)
    }
}
// saves/downloads excel (xlsx) file from response
export function downloadExcelRapport(response, filename)
{
    if (!response) return null
    try
    {
        const contentType = response.headers["content-type"]
        const data = response.data
        const blob = new Blob([data], { type: contentType })
        FileSaver.saveAs(blob, filename)
    }
    catch (err)
    {
        throw ("Feil ved lagring av csv fil.\n", err)
    }
}


export const createObjectUrlToDownloadGeneralFile = (response, fileName) =>
{
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style.visibility = 'hidden'
    try
    {
        const contentType = response.headers["content-type"]
        const data = response.data
        const blob = new Blob([data], { type: contentType })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        document.body.removeChild(a)
    } catch (err)
    {
        throw ("Feil ved lagring av csv fil.\n", err)
    }
}