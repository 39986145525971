/** ******************************* services health check ********************* */

export const AUTENTISERING_SERVICE_HEALTH_CHECK = 'AUTENTISERING_SERVICE_HEALTH_CHECK';
export const BYMELDING_SERVICE_HEALTH_CHECK = 'BYMELDING_SERVICE_HEALTH_CHECK';

/** ******************************* flashmessage for error ********************* */

export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';

/** ******************************* ajax ********************* */

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';

/** ******************************* login ********************* */

export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';
export const AUTH_TOKEN_IS_VALID = 'AUTH_TOKEN_IS_VALID';
export const AUTH_TOKEN_IS_INVALID = 'AUTH_TOKEN_IS_INVALID';
export const AUTH_TOKEN_IS_EXPIRED = 'AUTH_TOKEN_IS_EXPIRED';
export const SET_CURRENT_SERVICE_INFO = 'SET_CURRENT_SERVICE_INFO';
export const SET_SELSKAPSID_FOR_USER = 'SET_SELSKAPSID_FOR_USER';
export const USER_ROLE_LIST_SUCCESS = 'USER_ROLLES_SUCCESS';
export const USER_ROLE_LIST_FAILURE = 'USER_ROLE_LIST_FAILURE';

/** *****************************Meldinger  ***************************** */
export const LOAD_MELDINGER_COUNT = 'LOAD_MELDINGER_COUNT';
export const LOAD_MELDINGER_SUCCESS = 'LOAD_MELDINGER_SUCCESS';
export const LOAD_MELDINGER_FAILURE = 'LOAD_MELDINGER_FAILURE';

export const LOAD_KART_SUCCESS = 'LOAD_KART_SUCCESS';
export const LOAD_KART_FAILURE = 'LOAD_KART_FAILURE';

export const LOAD_MELDING_BY_ID_SUCCESS = 'LOAD_MELDING_BY_ID_SUCCESS';
export const UPDATE_MELDING_BY_ID_SUCCESS = 'UPDATE_MELDING_BY_ID_SUCCESS';

export const LOAD_MELDING_TYPER_SUCCESS = 'LOAD_MELDING_TYPER_SUCCESS';
export const LOAD_MELDING_TYPER_FAILURE = 'LOAD_MELDING_TYPER_FAILURE';

export const LOAD_SONER_SUCCESS = 'LOAD_SONER_SUCCESS';
export const LOAD_SONER_FAILURE = 'LOAD_SONER_FAILURE';

export const LOAD_MEDLING_STATUSER_SUCCESS = 'LOAD_MEDLING_STATUSER_SUCCESS';
export const LOAD_MEDLING_STATUSER_FAILURE = 'LOAD_MEDLING_STATUSER_FAILURE';

export const LOAD_ALL_ENTREPRENEURS = 'LOAD_ALL_ENTREPRENEURS';

export const LOAD_CHECHBOXES_FORPRINT = 'LOAD_CHECHBOXES_FORPRINT';
export const ADD_CHECHBOXES_FORPRINT = 'ADD_CHECHBOXES_FORPRINT';
export const DELETE_CHECHBOXES_FORPRINT = 'DELETE_CHECHBOXES_FORPRINT';
export const LOAD_CATEGORI_GROUPS_SUCCESS = 'LOAD_CATEGORI_GROUPS_SUCCESS';
export const LOAD_SAVED_MELDING_SEARCH = 'LOAD_SAVED_MELDING_SEARCH';
export const ADD_SAVED_MELDING_SEARCH = 'ADD_SAVED_MELDING_SEARCH';
export const DELETE_SAVED_MELDING_SEARCH = 'DELETE_SAVED_MELDING_SEARCH';

/** ******************************* RESSURSBRUK ********************* */
export const ADD_RESSURS_SUCCESS = 'ADD_RESSURS_SUCCESS';
export const LOAD_RESSURS_SKIFTLIST_SUCCESS = 'LOAD_RESSURS_SKIFTLIST_SUCCESS';
export const LOAD_PRICES_FOR_CONTRACT = 'LOAD_PRICES_FOR_CONTRACT';

export const LOAD_RESSURS_LIST_SUCCESS = 'LOAD_RESSURS_LIST_SUCCESS';

export const UPDATE_RESSURS_LIST_SUCCESS = 'UPDATE_RESSURS_LIST_SUCCESS';
export const DELETE_RESSURS_LIST_SUCCESS = 'DELETE_RESSURS_LIST_SUCCESS';
export const LOAD_PROCESSCODE_UNIT = 'LOAD_PROCESSCODE_UNIT';

/** ******************************* Melding Kommentarer & Historisk********************* */
export const LOAD_MELDING_COMMENTS = 'LOAD_MELDING_COMMENTS';
export const ADD_MELDING_COMMENTS = 'ADD_MELDING_COMMENTS';
export const LOAD_MELDING_HISTORIC = 'LOAD_MELDING_HISTORIC';
export const LOAD_MELDING_TILBAKEMELDINGER = 'LOAD_MELDING_TILBAKEMELDINGER';
export const ADD_MELDING_TILBAKEMELDINGER = 'ADD_MELDING_TILBAKEMELDINGER';

/** ******************************* entreprenorer ********************* */
export const LOAD_ORG_INFO = 'LOAD_ORG_INFO';
export const LOAD_ENTREPRENEURS_SUCCESS = 'LOAD_ENTREPRENEURS_SUCCESS';
export const LOAD_ENTREPRENEUR_SUCCESS = 'LOAD_ENTREPRENEUR_SUCCESS';

export const CREATE_ENTREPRENEUR_SUCCESS = 'CREATE_ENTREPRENEUR_SUCCESS';
export const UPDATE_ENTREPRENEUR_SUCCESS = 'UPDATE_ENTREPRENEUR_SUCCESS';

export const LOAD_SAKSBEHANDLERE_SUCCESS = 'LOAD_SAKSBEHANDLERE_SUCCESS';

export const LOAD_COMPANY_CONTRACTS_SUCCESS = 'LOAD_COMPANY_CONTRACTS_SUCCESS';
export const CREATE_COMPANY_CONTRACT_SUCCESS = 'CREATE_COMPANY_CONTRACT_SUCCESS';

/** ******************************* SelskapsBruker ********************* */

// Load Users
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';

// Create new user
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_RESET = 'CREATE_USER_RESET';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

/** ******************************* kontrakt ********************* */

export const LOAD_CONTRACTS_SUCCESS = 'LOAD_CONTRACTS_SUCCESS';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const LOAD_CONTRACT_SIST_ENDRET = 'LOAD_CONTRACT_SIST_ENDRET';
export const LOAD_CONTRACT_VALID_FROM = 'LOAD_CONTRACT_VALID_FROM';
export const LOAD_CONTRACTS_BY_AVTALEID_SUCCESS = 'LOAD_CONTRACTS_BY_AVTALEID_SUCCESS';
/** ******************************* processcodescheme ********************* */

export const LOAD_PROCESSCODESCHEMES_SUCCESS = 'LOAD_PROCESSCODESCHEMES_SUCCESS';
export const CREATE_PROCESSCODESCHEME_SUCCESS = 'CREATE_PROCESSCODESCHEME_SUCCESS';
export const UPDATE_PROCESSCODESCHEME_SUCCESS = 'UPDATE_PROCESSCODESCHEME_SUCCESS';

/** ******************************* processcode ********************* */
// export const LOAD_PROCESSCODES_LIST_SUCCESS="LOAD_PROCESSCODES_LIST_SUCCESS"
export const LOAD_PROCESSCODES_SUCCESS = 'LOAD_PROCESSCODES_SUCCESS';
export const CREATE_PROCESSCODE_SUCCESS = 'CREATE_PROCESSCODE_SUCCESS';
export const UPDATE_PROCESSCODE_SUCCESS = 'UPDATE_PROCESSCODE_SUCCESS';
export const DELETE_PROCESSCODE_SUCCESS = 'DELETE_PROCESSCODE_SUCCESS';

/** ******************************* Avtaler ********************* */

export const LOAD_CONTRACTZONES_SUCCESS = 'LOAD_CONTRACTZONES_SUCCESS';
// export const LOAD_AVTALER_SUCCESS="LOAD_AVTALER_SUCCESS"
export const UPDATE_AVTALE_SUCCESS = 'UPDATE_AVTALE_SUCCESS';
export const UPDATE_AVTALE_FAILURE = 'UPDATE_AVTALE_FAILURE';

/** ******************************* priser ********************* */

export const LOAD_PRICES_SUCCESS = 'LOAD_PRICES_SUCCESS';
export const CREATE_PRICE_SUCCESS = 'CREATE_PRICE_SUCCESS';
export const UPDATE_PRICE_SUCCESS = 'UPDATE_PRICE_SUCCESS';
export const DELETE_PRICE_SUCCESS = 'DELETE_PRICE_SUCCESS';

/** ******************************* bulkupdates ********************* */

export const LOAD_BULKUPDATES_SUCCESS = 'LOAD_BULKUPDATES_SUCCESS';
export const CREATE_BULKUPDATE_SUCCESS = 'CREATE_BULKUPDATE_SUCCESS';
export const UPDATE_BULKUPDATE_SUCCESS = 'UPDATE_BULKUPDATE_SUCCESS';
export const DELETE_BULKUPDATE_SUCCESS = 'DELETE_BULKUPDATE_SUCCESS';

/** ******************************* priserendringer ********************* */

export const LOAD_PRICECHANGES_SUCCESS = 'LOAD_PRICECHANGES_SUCCESS';
export const CREATE_PRICECHANGE_SUCCESS = 'CREATE_PRICECHANGE_SUCCESS';
export const UPDATE_PRICECHANGE_SUCCESS = 'UPDATE_PRICECHANGE_SUCCESS';
export const DELETE_PRICECHANGE_SUCCESS = 'DELETE_PRICECHANGE_SUCCESS';

/** ******************************* Rapporter ********************* */

export const LOAD_RAPPORTER_LIST = 'LOAD_RAPPORTER_LIST';

/** ******************************* Delbydeler ********************* */
export const LOAD_DELBYDELER_LIST = 'LOAD_DELBYDELER_LIST';
export const LOAD_DELBYDELER_LIST_SUCCESS = 'LOAD_DELBYDELER_LIST_SUCCESS';
export const LOAD_DELBYDELER_LIST_ERROR = 'LOAD_DELBYDELER_LIST_ERROR';

/** ******************************* Feilretting ********************* */
export const LOAD_FEILRETTINGSKODER_LIST = 'LOAD_FEILRETTINGSKODER_LIST';
export const LOAD_FEILRETTINGSKODER_LIST_SUCCESS = 'LOAD_FEILRETTINGSKODER_LIST_SUCCESS';
export const LOAD_FEILRETTINGSKODER_LIST_ERROR = 'LOAD_FEILRETTINGSKODER_LIST_ERROR';

/** ******************************* Varsel ********************* */
export const LOAD_VARSEL_SUCCESS = 'LOAD_VARSEL_SUCCESS';
