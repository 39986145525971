import * as types from './ActionTypes';
import selskapApi from '../Api_MockData/mockSelskaperApi';
import { beginAjaxCall, ajaxCallError } from '../Api_MockData/ajaxStatusActions';
import { useMock } from "../constants/settings";
import * as api from "../constants/api";
import { addFlashMessage } from './FlashMessagesAction'
import * as responseMessages from "../constants/ResponseMessages"
import { successData } from "../utils/utils"

export function loadOrganisasjonInfo(org)
{
  return { type: types.LOAD_ORG_INFO, org }
}

export function loadEntrepreneursSuccess(selskaper)
{
  return { type: types.LOAD_ENTREPRENEURS_SUCCESS, selskaper };
}
export function loadEntrepreneurSuccess(selskap)
{
  return { type: types.LOAD_ENTREPRENEUR_SUCCESS, selskap };
}

export function createEntrepreneurSuccess(selskap)
{
  return { type: types.CREATE_ENTREPRENEUR_SUCCESS, selskap };
}

export function updateEntrepreneurSuccess(selskap)
{
  return { type: types.UPDATE_ENTREPRENEUR_SUCCESS, selskap };
}

export function loadSaksbehandlereSuccess(selskaper)
{
  return { type: types.LOAD_SAKSBEHANDLERE_SUCCESS, selskaper };
}

export function BronnoysundRegistreneGetInfo(organisasjonNumber)
{
  let url = api.bronnoysundregistreneUrl(organisasjonNumber)
  return dispatch =>
  {
    return api.getClient().get(url).then(res =>
    {
      let result = res.data
      let navn = result.navn
      dispatch(loadOrganisasjonInfo({ navn: navn }))
      return navn
    })
  }
}

export function updateSelskap(selskap)
{
  return function (dispatch)
  {
    dispatch(beginAjaxCall());

    const updateurl = api.entreprenorerBaseUrl + "/" + selskap.id;
    if (useMock === false)
    {
      return api.getClient().put(updateurl, selskap).then(function (response)
      {
        if (response.data)
        {
          const updatedSelskap = { email: selskap.epost, id: selskap.id, name: selskap.navn, orgnumber: selskap.orgnr, phonenumber: selskap.telefon, type: selskap.type }
          dispatch(updateEntrepreneurSuccess(updatedSelskap));
        }
      })
        .catch(function (error)
        {

          throw error

        });
    }
  };
}

function loadSelskaperByType(selskapsType, successAction)
{
  return function (dispatch)
  {
    dispatch(beginAjaxCall());

    if (useMock === false)
    {
      //Use the web service
      //axios.defaults.withCredentials = false;
      return api.getClient().get(api.selskaperByType(selskapsType))
        .then(function (response)
        {
          const selskaper = response.data.result.map(function (x)
          {
            return { email: x.epost, id: x.id, name: x.navn, orgnumber: x.orgnr, phonenumber: x.telefon, type: x.selskapsType }
          });

          dispatch(successAction(selskaper));

        })
        .catch(function (error)
        {
        });
    }
    else
    {
      return selskapApi.getAllSelskaper(selskapsType).then(selskaper =>
      {
        dispatch(successAction(selskaper));
      }).catch(error =>
      {
        throw (error);
      });
    }
  };
}

export function loadEntrepreneurById(selskapId)
{
  const url = api.entreprenorerByselskapId(selskapId)
  return dispatch =>
  {
    return api.getClient().get(url).then(res =>
    {
      const selskap = successData(res)
      //dispatch(loadEntrepreneurSuccess(selskap))
      return selskap
    })
  }
}
export function loadEntrepreneurs()
{
  return loadSelskaperByType(3, loadEntrepreneursSuccess);
}

export function loadSaksbehandlere()
{
  return loadSelskaperByType(1, loadSaksbehandlereSuccess);
}

export function saveSelskap(selskap)
{
  return function (dispatch)
  {
    dispatch(beginAjaxCall());

    if (useMock === false)
    {
      //axios.defaults.withCredentials = false;
      api.getClient().post(api.entreprenorerBaseUrl, selskap)
        .then(function (response)
        {
          if (response.data)
          {
            if (response.data.result)
            {
              const id = response.data.result.id
              dispatch(createEntrepreneurSuccess({
                name: selskap.navn,
                orgnumber: selskap.orgnr,
                email: selskap.epost,
                phonenumber: selskap.telefon,
                id: id
              }));
              dispatch(addFlashMessage({ type: 'success', text: responseMessages.entrepreneurIsCreated }));

            }
          }
        })
        .catch(function ()
        {
          dispatch(addFlashMessage({ type: 'error', text: responseMessages.entrepreneurIsNotCreated }));

        });
    }
    else
    {
      return selskapApi.saveEntrepreneur(selskap).then(selskap =>
      {
        selskap.id ? dispatch(updateEntrepreneurSuccess(selskap)) :
          dispatch(createEntrepreneurSuccess(selskap));
      }).catch(error =>
      {
        dispatch(ajaxCallError(error));
        throw (error);
      });
    }

  };
}


