import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RapporterComponent from '../../components/Rapporter/RapporterComponent';
import {
    GetInnMeldingerRapport,
    GetAvvisteMeldingerRapport,
    GetMeldingsstatistikkRapport,
    GetRessursforbrukRapport,
    GetMonedsrapportmateriellrapport,
    GetMonedsrapportavviksarbeidrapport,
    GetFakturagrunnlagRapport,
    GetGatelysMonedsrapport,
    GetVinterForbrukGrunnlagRapport,
    GetVinterForbrukOkonomiRapport,
    GetDagmulktRapport,
} from '../../actions/RapporterAction.js';
import { loadCompanySpecificContracts, loadEntrepreneurContracts } from '../../actions/contractActions';
import { loadAvtalerForEntrepreneur, loadAvtalerForEntrepreneurAdmin } from '../../actions/avtalerAction';
import { getSelskapsIdForLoggedUser } from '../../actions/LoginActions';
import { loadEntrepreneurs } from '../../actions/selskaperActions';
import { loadMeldingstyper } from '../../actions/MeldingerActions';

const mapStateToProps = (state, ownProps) => {
    return {
        selskap: state.login.selskap,
        rapporter: state.rapporter,
        kontrakter: state.contracts,
        avtaler: state.contractZones,
        entreprenorer: state.entreprenorer,
        prosesskoder: state.prosesskoder,
        meldingstyper: state.meldinger.meldingstyper,
        params: ownProps.params,
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            GetInnMeldingerRapport,
            GetAvvisteMeldingerRapport,
            GetMeldingsstatistikkRapport,
            GetRessursforbrukRapport,
            GetMonedsrapportmateriellrapport,
            GetMonedsrapportavviksarbeidrapport,
            getSelskapsIdForLoggedUser,
            loadAvtalerForEntrepreneur,
            loadAvtalerForEntrepreneurAdmin,
            loadCompanySpecificContracts,
            loadEntrepreneurContracts,
            loadEntrepreneurs,
            GetFakturagrunnlagRapport,
            GetVinterForbrukGrunnlagRapport,
            GetVinterForbrukOkonomiRapport,
            GetDagmulktRapport,
            GetGatelysMonedsrapport,
            loadMeldingstyper,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RapporterComponent);
