import delay from './delay';

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const processcodes = [
    {
        id: "1",
        name: "P10001",
        beskrivelse: "Brøyting, kosting og salting",
        enhet: "%",
        deactivated: "true"
    },
    {
        id: "hei",
        name: "P10002",
        beskrivelse: "Brøyting av fortau og holdeplasser langs hovedveier",
        enhet: "%",
        deactivated: "false"
    },
    {
        id: "3",
        name: "P10013",
        beskrivelse: "Vask av plastkasser",
        enhet: "stk",
        deactivated: "false"
    }
];

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (processcode) => {
    return replaceAll(processcode.name, ' ', '-');
};

class ProcesscodeApi {
    static getAllProcesscodes() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(Object.assign([], processcodes));
            }, delay);
        });
    }

    static saveProcesscode(processcode) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                //Får ikke tilbake deaktiverte prosesskoder fra server
                processcode.deactivated = "false";

                // Simulate server-side validation
                const minProcesscodeNameLength = 1;
                if (processcode.name.length < minProcesscodeNameLength) {
                    reject(`Name must be at least ${minProcesscodeNameLength} characters.`);
                }

                if (processcode.id) {
                    const existingProcesscodeIndex = processcodes.findIndex(a => a.id === processcode.id);
                    processcodes.splice(existingProcesscodeIndex, 1, processcode);
                } else {
                    //Just simulating creation here.
                    //The server would generate ids for new processcodes in a real app.
                    //Cloning so copy returned is passed by value rather than by reference.
                    processcode.id = generateId(processcode);

                    processcodes.push(processcode);
                }

                resolve(Object.assign({}, processcode));
            }, delay);
        });
    }

    static deleteProcesscode(processcodeId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const indexOfProcesscodeToDelete = processcodes.findIndex(processcode => {
                    return processcode.processcodeId === processcodeId;
                });
                processcodes.splice(indexOfProcesscodeToDelete, 1);
                resolve();
            }, delay);
        });
    }
}

export default ProcesscodeApi;
