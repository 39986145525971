import moment from 'moment'
import dateFormat from "dateformat"
import { map, range } from "lodash"

export const Years = (startyear = 2017) =>
{
    let currYear = new Date().getFullYear()
    const years = []
    while (startyear <= currYear)
    {
        years.push(Object.assign({}, { id: currYear.toString(), navn: currYear.toString() }))
        currYear -= 1
    }
    return years
}
export const Months = () =>
{
    return [
        { id: '1', navn: 'Januar' },
        { id: '2', navn: 'Februar' },
        { id: '3', navn: 'Mars' },
        { id: '4', navn: 'April' },
        { id: '5', navn: 'Mai' },
        { id: '6', navn: 'Juni' },
        { id: '7', navn: 'Juli' },
        { id: '8', navn: 'August' },
        { id: '9', navn: 'September' },
        { id: '10', navn: 'Oktober' },
        { id: '11', navn: 'November' },
        { id: '12', navn: 'Desember' },
    ]
}
//day 32 of March is considered to be day 1 of April. Subtracting 1 from 32 gives the correct number of days in March!!
export const Days = (year, month) =>
{
    //return ((new Date(year, month)).getTime() - (new Date(year, month - 1)).getTime()) / (1000 * 60 * 60 * 24)
    //const daysOfMonth = 32 - new Date(year, month, 32).getDate();
    const date = `${year}-${month}`
    const daysOfMonth = moment(date, "YYYY-MM").daysInMonth()
    const days = []
    map(range(1, daysOfMonth + 1), i =>
    {
        days.push(Object.assign({}, { id: i.toString(), navn: i.toString() }))
    })
    return days
}
export function getMonth()
{
    var currentMonth = new Date().getMonth() + 1
    return currentMonth.toString()
}
export function getYear()
{
    var currentYear = new Date().getFullYear()
    return currentYear.toString()
}
export function getDay()
{
    var currentDay = new Date().getDate()
    return currentDay.toString()
}

// 15. november 2016
export function toDateString(value)
{
    if (value)
    {
        const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(value);
        return date.toLocaleString("nb-NO", optionsDate);
    }
}

// 15. november 2016 kl. 14:24
export function toDateTimeString(value)
{
    if (value)
    {
        const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit' };
        const date = new Date(value);
        return date.toLocaleString("nb-NO", optionsDate)
            + " kl. "
            + date.toLocaleString("nb-NO", optionsTime);
    }
}

// 18.09.2016 kl. 14.01
export function toDateTimeNumericString(value)
{
    if (value)
    {
        const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const optionsTime = { hour: '2-digit', minute: '2-digit' };
        const date = new Date(value);
        return date.toLocaleString("nb-NO", optionsDate)
            + " kl. "
            + date.toLocaleString("nb-NO", optionsTime);
    }
}


// 18.09.2016
export function toDateNumericString(value)
{
    if (value)
    {
        const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(value);
        return date.toLocaleString("nb-NO", optionsDate);
    }
}

// november 2016
export function toMonthYearString(value)
{
    if (value)
    {
        const optionsDate = { year: 'numeric', month: 'long' };
        const date = new Date(value);
        var d = date.toLocaleString("nb-NO", optionsDate);
        return d.substr(0, 1).toString().toUpperCase() + d.substring(1);
    }
}

export function toMonthAndYear(value)
{
    if (value)
    {
        const optionsDate = { year: 'numeric', month: 'long' };
        const date = new Date(value);
        var d = date.toLocaleString("nb-NO", optionsDate);
        return dateFormat(d, "mmmyyyy")
    }
}
export function FormatToMonthAndYear(month, year)
{
    if (month && year)
    {
        const optionsDate = { year: 'numeric', month: 'long' };
        const date = new Date(year, month);
        var d = date.toLocaleString("nb-NO", optionsDate);
        return d.substr(0, 1).toString().toUpperCase() + d.substring(1);
        // return dateFormat(d, "mmmyyyy")
    }
}
// Convert "Til dato"" to "Til og med dato"
export function toToDate(date)
{
    return moment(date).subtract(1, 'days').format("DD.MM.YYYY");
}

export function FormatedForAPICall(date)
{
    //return dateFormat(date, "dd/mm/yyyy HH:MM:ss")
    return dateFormat(date, "yyyy-mm-dd HH:MM:ss")
}

export function StartDateIsLongerThanEndDate(fraDato, tilDato)
{
    return Date.parse(fraDato) > Date.parse(tilDato)
}

export function DateTimeBetweenTwoDatetimes(fraDato, tilDato, datetimeToCompare)
{
    // console.log(fraDato)
    // console.log(tilDato)
    // console.log(datetimeToCompare)
    // console.log("-----------------------")
    return Date.parse(fraDato) <= Date.parse(datetimeToCompare) && Date.parse(tilDato) >= Date.parse(datetimeToCompare)
}