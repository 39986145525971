import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

export default function contractReducer(state = InitialState.contracts, action) {
  switch (action.type) {
    case types.LOAD_CONTRACTS_SUCCESS:
      return action.contracts;
    case types.CREATE_CONTRACT_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.contract)
      ];

    case types.LOAD_CONTRACTS_BY_AVTALEID_SUCCESS:
      return { ...state, contract: action.contract }
    case types.UPDATE_CONTRACT_SUCCESS:
      return [
        ...state.filter(contract => contract.id !== action.contract.id),
        Object.assign({}, action.contract)
      ];

    case types.LOAD_COMPANY_CONTRACTS_SUCCESS:
      return action.contracts;

    case types.CREATE_COMPANY_CONTRACT_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.contract)
      ];

    default:
      return state;
  }
}
