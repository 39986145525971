import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ConfirmModal from '../common/ConfirmModal';
import EditableField from '../common/EditableField';
// import TextFieldGroupDisabled from "../common/TextFieldGroupDisabled"
import * as ResponseMessages from '../../constants/ResponseMessages';
import * as validationErrors from '../../constants/ValidationErrors';

import '../../css/Entrepreneur.css';

const PriceSchemeListRow = ({ rad, onClick, onSubmit }) => {
    const [confirmDeleteVisible, toggleConfirmDeleteVisible] = useState(false);

    const triggerClickAndCloseModal = selectedRad => {
        onClick(selectedRad);
        toggleConfirmDeleteVisible(false);
    };

    return (
        <tr className={rad.updatedPrice ? 'bg-success' : ''}>
            <td>{rad.prosesskode}</td>
            <td>{rad.beskrivelse}</td>
            <td>{rad.enhet}</td>

            <td>
                <center>kr</center>
            </td>

            <td>
                <div>
                    <EditableField
                        isNumber
                        required
                        error={validationErrors.PrisPositivInteger}
                        text={rad.pris.toLocaleString('nb-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        onSubmit={e => onSubmit(e, rad)}
                    />
                </div>
            </td>

            <td>
                <Button className="danger" bsStyle="link" onClick={() => toggleConfirmDeleteVisible(true)}>
                    Fjern
                </Button>
                {confirmDeleteVisible && (
                    <ConfirmModal
                        modalTitle={ResponseMessages.kontraktRemovekontrakt}
                        bodyText={`${ResponseMessages.kontraktRemoveQuestionText}"${rad.prosesskode}" ?`}
                        onConfirm={() => triggerClickAndCloseModal(rad)}
                        onCancel={() => toggleConfirmDeleteVisible(false)}
                        confirmText="Fjern"
                    />
                )}
            </td>
        </tr>
    );
};

PriceSchemeListRow.propTypes = {
    rad: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default PriceSchemeListRow;
