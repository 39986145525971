/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import first from 'lodash/first';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import dateformat from 'dateformat';
import MultiFiltersList from '../common/DropdownListSearchableMultiOptions';
import MultiFiltersWithSubOptionsList from '../common/DropdownListSearchableMultiOptionsWithSubOptions';
import FilterDatepicker from '../common/FilterDatepicker';
import FilterBannerDisplay from './FiltersDiplayBanner';
import SearchDisplayBanner from './SearchDisplayBanner';
import { toToDate } from '../../utils/dateTimeHelpers';
import NewMeldingModal from './NewMeldingModal';
import SavedMeldingSearchModal from './SavedMedlingSearchModal';
import './MeldingerFilter.css';
import { getStatusFilterName } from '../../utils/meldingUtils';

const bydelItems = [
    { id: 'SØNDRE NORDSTRAND', navn: 'Søndre Nordstrand' },
    { id: 'BJERKE', navn: 'Bjerke' },
    { id: 'GRORUD', navn: 'Grorud' },
    { id: 'SENTRUM', navn: 'Sentrum' },
    { id: 'GRUNERLØKKA', navn: 'Grünerløkka' },
    { id: 'NORDSTRAND', navn: 'Nordstrand' },
    { id: 'NORDMARKA', navn: 'Nordmarka' },
    { id: 'VESTRE AKER', navn: 'Vestre Aker' },
    { id: 'ALNA', navn: 'Alna' },
    { id: 'NORDRE AKER', navn: 'Nordre Aker' },
    { id: 'ØSTMARKA', navn: 'Østmarka' },
    { id: 'ULLERN', navn: 'Ullern' },
    { id: 'GAMLE OSLO', navn: 'Gamle Oslo' },
    { id: 'ST.HANSHAUGEN', navn: 'St. Hanshaugen' },
    { id: 'SAGENE', navn: 'Sagene' },
    { id: 'STOVNER', navn: 'Stovner' },
    { id: 'ØSTENSJØ', navn: 'Østensjø' },
    { id: 'FROGNER', navn: 'Frogner' },
];

const sortedBydelerItems = bydelItems.sort((a, b) => (a.navn > b.navn ? 1 : b.navn > a.navn ? -1 : 0));

class MeldingerFilter extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            meldingstypeId: [],
            avtaleId: [],
            meldingsStatusId: [],
            selskapId: [],
            datoFra: null,
            datoTil: null,
            tidsfristDatoFra: null,
            tidsfristDatoTil: null,
            bydelId: [],
            delbydel: [],
        };

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeFilters = this.onChangeFilters.bind(this);
        this.onChangeBydelFilters = this.onChangeBydelFilters.bind(this);
        this.clearDropdownFilters = this.clearDropdownFilters.bind(this);
        this.ClearFilters = this.ClearFilters.bind(this);
        this.queryParamRemove = this.queryParamRemove.bind(this);
    }

    initialFiltersAndPagination() {
        const datoFra = this.getQuery('datoFra') ? new Date(this.getQuery('datoFra')) : null;
        const datoTil = this.getQuery('datoTil') ? new Date(this.getQuery('datoTil')) : null;
        const tidsfristDatoFra = this.getQuery('tidsfristDatoFra') ? new Date(this.getQuery('tidsfristDatoFra')) : null;
        const tidsfristDatoTil = this.getQuery('tidsfristDatoTil') ? new Date(this.getQuery('tidsfristDatoTil')) : null;
        let avtaleId = this.getQuery('avtaleId') ? this.getQuery('avtaleId') : [];
        if (!Array.isArray(avtaleId)) {
            avtaleId = [avtaleId];
        }
        let meldingstypeId = this.getQuery('meldingstypeId') ? this.getQuery('meldingstypeId') : [];
        if (!Array.isArray(meldingstypeId)) {
            meldingstypeId = [meldingstypeId];
        }
        let selskapId = this.getQuery('selskapId') ? this.getQuery('selskapId') : [];
        if (!Array.isArray(selskapId)) {
            selskapId = [selskapId];
        }
        let meldingsStatusId = this.getQuery('meldingsStatusId') ? this.getQuery('meldingsStatusId') : [];
        let bydelId = this.getQuery('bydelId') ? this.getQuery('bydelId') : [];
        if (!Array.isArray(bydelId)) {
            bydelId = [bydelId];
        }
        let delbydel = this.getQuery('delbydel') ? this.getQuery('delbydel') : [];
        if (!Array.isArray(delbydel)) {
            delbydel = [delbydel];
        }
        if (!Array.isArray(meldingsStatusId)) {
            meldingsStatusId = [meldingsStatusId];
        }
        this.setState({
            meldingstypeId,
            avtaleId,
            meldingsStatusId,
            selskapId,
            datoFra,
            datoTil,
            tidsfristDatoFra,
            tidsfristDatoTil,
            bydelId,
            delbydel,
        });
    }

    componentWillReceiveProps(preProp, nextProp) {
        this.initialFiltersAndPagination();
    }

    getQuery(q) {
        const { query } = this.context.router.location;
        return query[q] ? query[q] : null;
    }

    updateCallBack() {
        this.props.filterFunction();
    }

    getLocationSearch() {
        const { location } = this.context.router;
        return location.search;
    }

    getFilters() {
        const search = this.getLocationSearch();
        const filter = search.substring(1, search.length);
        return filter;
    }

    getFilterByKey(key) {
        const search = this.getLocationSearch();
        return key && search[key] ? search[key] : '';
    }

    ClearFilters() {
        const location = this.context.router.location.pathname;
        this.context.router.push(location);
        this.setState({
            meldingstypeId: [],
            avtaleId: [],
            meldingsStatusId: [],
            selskapId: [],
            datoFra: null,
            datoTil: null,
            tidsfristDatoFra: null,
            tidsfristDatoTil: null,
            bydelId: [],
            delbydel: [],
        });
        this.updateCallBack();
    }

    queryBuilder(key, value, callUpdateCallback = true) {
        const { pathname } = this.context.router.location;
        const params = this.context.router.location.query;
        if (value && value !== '0') params[key] = value;
        else {
            delete params[key];
            this.setState({ [key]: null });
        }
        const qu = queryString.stringify(params);
        const uri = `${pathname}?${qu}`;
        this.context.router.push(uri);

        if (callUpdateCallback) {
            this.updateCallBack();
        }
    }

    queryParamArrayRemover(e, key, value) {
        const { pathname } = this.context.router.location;
        const params = this.context.router.location.query;
        if (value && value !== '0') {
            if (params[key] instanceof Array) {
                const filter = params[key].filter(val => val !== value.toString());
                params[key] = filter;
                this.setState({ [key]: filter });
            } else {
                delete params[key];
                this.setState({ [key]: [] });
            }
        }
        const qu = queryString.stringify(params);
        const uri = `${pathname}?${qu}`;
        this.context.router.push(uri);
        this.updateCallBack();
    }

    queryBydelParamArrayRemover(e, key, value) {
        const { delbydeler } = this.props;
        const { pathname } = this.context.router.location;
        const params = this.context.router.location.query;

        if (key === 'bydelId' && value && value !== '0') {
            // Fjerner alle delbydeler for gitt bydel
            const paramsDelbydel =
                params.delbydel instanceof Array ? params.delbydel : params.delbydel ? [params.delbydel] : [];
            const delbydelerItems = delbydeler.filter(dbd => dbd.bydelId === value);
            const delbydelerFilter = paramsDelbydel.filter(val => {
                return !delbydelerItems.find(dbdi => dbdi.id === val);
            });
            params.delbydel = delbydelerFilter;
            this.setState({ delbydel: delbydelerFilter });

            // Fjerner angitt bydel
            if (params.bydelId instanceof Array) {
                const bydelFilter = params.bydelId.filter(val => val !== value.toString());
                params.bydelId = bydelFilter;
                this.setState({ bydelId: bydelFilter });
            } else {
                delete params.bydelId;
                this.setState({ bydelId: [] });
            }
        }

        const qu = queryString.stringify(params);
        const uri = `${pathname}?${qu}`;
        this.context.router.push(uri);
        this.updateCallBack();
    }

    queryParamRemove(key, callUpdateCallBack = true) {
        const { pathname } = this.context.router.location;
        const params = this.context.router.location.query;
        delete params[key];
        this.setState({ [key]: null });
        const qu = queryString.stringify(params);
        const uri = `${pathname}?${qu}`;
        this.context.router.push(uri);

        if (callUpdateCallBack) {
            this.updateCallBack();
        }
    }

    onRemoveSearch(e, payload) {
        this.props.deleteSavedSearch(payload);
        const pathname = this.context.router.location.search;
        if (payload.filter === pathname.substring(1)) {
            this.context.router.push(pathname);
            this.updateCallBack();
        }
    }

    onSelectSearch(e, payload) {
        const { filter } = payload;
        const { pathname } = this.context.router.location;
        const uri = `${pathname}?${filter}`;
        this.context.router.push(uri);
        this.updateCallBack();
    }

    onChangeDate(value, key) {
        this.setState({ [key]: value });
        this.queryBuilder(key, value);
    }

    onChangeCheckboxFilter(name) {
        this.queryBuilder(name, !this.state[name]);
        this.setState({ [name]: !this.state[name] });
    }

    onChangeFilters(e, v, name) {
        const { target } = e;
        const { checked } = target;
        let updateArray = [];
        if (checked) {
            updateArray = [...this.state[name], v.toString()];
        } else {
            updateArray = this.state[name].filter(val => val !== v.toString());
        }

        this.setState({ [name]: updateArray });
        this.queryBuilder(name, updateArray);
    }

    onChangeBydelFilters(e, v, name) {
        const { delbydeler } = this.props;
        const { target } = e;
        const { checked } = target;
        const { delbydel: delbydelFilter } = this.state;

        if (name === 'bydelId' && !checked) {
            // Fjern alle delbydeler for gitt bydel også
            const delbydelerItems = delbydeler.filter(dbd => dbd.bydelId === v);
            const updateArray = delbydelFilter.filter(val => !delbydelerItems.find(dbdi => dbdi.id === val));

            this.setState({ delbydel: updateArray });
            this.queryBuilder('delbydel', updateArray, false);
        }

        this.onChangeFilters(e, v, name);
    }

    clearDropdownFilters(name, callUpdateCallback = true) {
        this.setState({ [name]: [] });
        this.queryBuilder(name, [], callUpdateCallback);
    }

    clearAllFilters() {
        this.clearDropdownFilters('meldingstypeId', false);
        this.clearDropdownFilters('avtaleId', false);
        this.clearDropdownFilters('meldingsStatusId', false);
        this.clearDropdownFilters('selskapId', false);
        this.clearDropdownFilters('bydelId', false);
        this.clearDropdownFilters('delbydel', false);
        this.queryParamRemove('datoFra', false);
        this.queryParamRemove('datoTil', false);
        this.queryParamRemove('tidsfristDatoFra', false);
        this.queryParamRemove('tidsfristDatoTil', false);
        this.updateCallBack();
    }

    selectAll(e, collectionName, values) {
        const allIds = values.map(collection => {
            return collection.id;
        });

        this.setState({
            [collectionName]: allIds,
        });

        this.queryBuilder(collectionName, allIds);
    }

    objectIsEmpty(object) {
        const values = Object.values(object);
        let empty = false;
        map(values, i => {
            if (i.length > 0) empty = true;
        });
        return empty;
    }

    createFilterbanner(filter, arr, key, value) {
        return arr.push(
            <FilterBannerDisplay
                key={key}
                text={key}
                value={value}
                filtername={filter}
                removeFilter={e => this.queryParamArrayRemover(e, filter, value)}
            />
        );
    }

    createBydelFilterbanner(filter, arr, key, value) {
        return arr.push(
            <FilterBannerDisplay
                key={key}
                text={key}
                value={value}
                filtername={filter}
                removeFilter={e => this.queryBydelParamArrayRemover(e, filter, value)}
            />
        );
    }

    renderChoosenFilterBaner() {
        const {
            datoFra,
            datoTil,
            tidsfristDatoFra,
            tidsfristDatoTil,
            meldingstypeId,
            meldingsStatusId,
            avtaleId,
            selskapId,
            bydelId,
            delbydel,
        } = this.state;
        const { meldingstyper, meldingsavtaler, meldingsstatuser, entrepreneurs, delbydeler } = this.props;
        const arr = [];
        size(meldingstypeId) > 0 &&
            map(meldingstypeId, s => {
                const i = first(filter(meldingstyper, { meldingstypeId: s }));
                if (i) this.createFilterbanner('meldingstypeId', arr, i.navn, i.meldingstypeId.toString());
            });
        size(avtaleId) > 0 &&
            map(avtaleId, s => {
                const i = first(filter(meldingsavtaler, { avtaleId: s }));
                if (i) this.createFilterbanner('avtaleId', arr, i.navn, i.avtaleId.toString());
            });
        size(selskapId) > 0 &&
            map(selskapId, s => {
                const i = first(filter(entrepreneurs, { id: s }));
                if (i) this.createFilterbanner('selskapId', arr, i.navn, i.id.toString());
                else {
                    this.createFilterbanner(
                        'selskapId',
                        arr,
                        'Entreprenør ikke satt',
                        '00000000-0000-0000-0000-000000000000'
                    );
                }
            });
        size(meldingsStatusId) > 0 &&
            forEach(meldingsStatusId, s => {
                const i = first(filter(meldingsstatuser, { id: parseInt(s, 10) }));
                if (i) {
                    this.createFilterbanner(
                        'meldingsStatusId',
                        arr,
                        getStatusFilterName(meldingsstatuser, i.id),
                        i.id.toString()
                    );
                }
            });
        size(bydelId) > 0 &&
            forEach(bydelId, s => {
                const i = first(filter(sortedBydelerItems, { id: s }));
                if (i) {
                    const visningsnavn =
                        delbydeler.filter(d => d.navn === i.navn).length > 0 ? i.navn + ' (bydel)' : i.navn;

                    this.createBydelFilterbanner('bydelId', arr, visningsnavn, i.id);
                }
            });
        size(delbydel) > 0 &&
            forEach(delbydel, dbd => {
                const i = first(filter(delbydeler, { id: dbd }));
                if (i) {
                    const visningsnavn =
                        sortedBydelerItems.filter(b => b.navn === i.navn).length > 0 ? i.navn + ' (delbydel)' : i.navn;
                    this.createFilterbanner('delbydel', arr, visningsnavn, i.id);
                }
            });

        datoFra &&
            datoFra !== '' &&
            arr.push(
                <FilterBannerDisplay
                    key={`datoFra_${datoFra}`}
                    text={`Dato fra: ${dateformat(datoFra, 'dd.mm.yyyy')}`}
                    value={dateformat(datoFra, 'dd.mm.yyyy')}
                    filtername="datoFra"
                    removeFilter={this.queryParamRemove}
                />
            );
        datoTil &&
            datoTil !== '' &&
            arr.push(
                <FilterBannerDisplay
                    key={`datoTil_${datoTil}`}
                    text={`Dato til: ${toToDate(datoTil)}`}
                    value={toToDate(datoTil)}
                    filtername="datoTil"
                    removeFilter={this.queryParamRemove}
                />
            );
        tidsfristDatoFra &&
            tidsfristDatoFra !== '' &&
            arr.push(
                <FilterBannerDisplay
                    key={`tidsfristDatoFra_${tidsfristDatoFra}`}
                    text={`Tidsfrist fra: ${dateformat(tidsfristDatoFra, 'dd.mm.yyyy')}`}
                    value={dateformat(datoFra, 'dd.mm.yyyy')}
                    filtername="tidsfristDatoFra"
                    removeFilter={this.queryParamRemove}
                />
            );
        tidsfristDatoTil &&
            tidsfristDatoTil !== '' &&
            arr.push(
                <FilterBannerDisplay
                    key={`tidsfristDatoTil_${tidsfristDatoTil}`}
                    text={`Tidsfrist til: ${dateformat(tidsfristDatoTil, 'dd.mm.yyyy', true)}`}
                    value={dateformat(datoFra, 'dd.mm.yyyy')}
                    filtername="tidsfristDatoTil"
                    removeFilter={this.queryParamRemove}
                />
            );

        return arr;
    }

    updateQueryUrlByDefaultSavedFilters() {
        const defaultsavedsearch = first(filter(this.props.savedsearches, { standard: true }));
        const filter = defaultsavedsearch ? defaultsavedsearch.filter : '';
        if (filter.length === 0) return;
        const { pathname } = this.context.router.location;
        const uri = `${pathname}?${filter}`;
        this.context.router.push(uri);
        this.updateCallBack();
    }

    renderSavedSearches() {
        const searches = this.props.savedsearches;
        return map(searches, i => {
            return (
                <SearchDisplayBanner
                    key={i.navn}
                    text={i.navn}
                    value={i.brukerId.toString()}
                    filtername="search"
                    onRemoveSearch={e => this.onRemoveSearch(e, i)}
                    onSelectSearch={e => this.onSelectSearch(e, i)}
                />
            );
        });
    }

    render() {
        const {
            datoFra,
            datoTil,
            tidsfristDatoFra,
            tidsfristDatoTil,
            meldingstypeId,
            avtaleId,
            meldingsStatusId,
            selskapId,
            bydelId,
            delbydel,
        } = this.state;
        const {
            meldingstyper,
            meldingsavtaler,
            meldingsstatuser,
            entrepreneurs,
            IsServiceavdeling,
            addSavedSearch,
            delbydeler,
        } = this.props;

        // const groupByCategory = products.reduce((group, product) => {
        //     const { category } = product;
        //     group[category] = group[category] ?? [];
        //     group[category].push(product);
        //     return group;
        //   }, {});

        const originalDelbydeler = JSON.parse(JSON.stringify(delbydeler));

        const bydelerMedDelbydeler = bydelItems
            .map(bydelItem => {
                const dbd = originalDelbydeler
                    .filter(odb => odb.bydelId === bydelItem.id)
                    .sort((a, b) => a.navn.localeCompare(b.navn));
                return { ...bydelItem, delbydeler: dbd };
            })
            .sort((a, b) => a.navn.localeCompare(b.navn));

        const meldingstyperItems = sortBy(
            map(meldingstyper, m => {
                return { id: m.meldingstypeId, navn: m.beskrivelse, synligForPublikum: m.synligForPublikum };
            }),
            'navn'
        );

        const avtalerItems = sortBy(
            map(meldingsavtaler, m => {
                return { id: m.avtaleId, navn: m.navn };
            }),
            'navn'
        );

        let entrepreneurItems = sortBy(entrepreneurs, 'navn');
        entrepreneurItems.unshift({
            id: '00000000-0000-0000-0000-000000000000', // not set
            navn: 'Ikke satt',
        });

        const statusItems = sortBy(
            filter(
                map(meldingsstatuser, ms => {
                    return {
                        ...ms,
                        navn: getStatusFilterName(meldingsstatuser, ms.id),
                    };
                }),
                f => f.id !== 8 && f.id !== 100 && f.id !== 200
            ),
            'navn'
        );
        const filterbanner = this.renderChoosenFilterBaner();
        const savedSearchbanner = this.renderSavedSearches();
        const urlQuery = this.getFilters();
        return (
            <div>
                <div className="filterDiv">
                    <div id="tableFilter-flex">
                        <div className="tableFilter-flex-group tableFilter-flex-no-border">
                            <button
                                className="btn btn-default tableFilter-flex-hide-on-mobile tableFilter-flex-no-border"
                                id="PrinterButton"
                                onClick={this.props.onPrint}
                                disabled={this.props.onPrintLoading}
                            >
                                {!this.props.onPrintLoading && (
                                    <span className="glyphicon glyphicon-print" id="printglyphicon" />
                                )}
                                {this.props.onPrintLoading && (
                                    <span className="glyphicon glyphicon-refresh bym-glyphicon-spin" />
                                )}
                                <span className="print-btn-text"> Skriv ut</span>
                            </button>
                        </div>
                        <div className="tableFilter-flex-group tableFilter-flex-no-border">
                            <NewMeldingModal
                                meldingsTypeItems={meldingstyperItems}
                                SendNewMelding={this.props.SendNewMelding}
                                reloadMeldinger={this.props.reloadMeldinger}
                                addFlashMessage={this.props.addFlashMessage}
                            />
                        </div>
                        <div className="tableFilter-flex-group tableFilter-flex-group-date" title="Dato f.o.m">
                            <FilterDatepicker
                                id="filterdatepickerFrom"
                                onChange={e => this.onChangeDate(e, 'datoFra')}
                                placeholder="Dato f.o.m"
                                value={datoFra}
                            />
                        </div>
                        <div
                            className="tableFilter-flex-group tableFilter-flex-group-date tableFilter-flex-no-border"
                            title="Dato t.o.m"
                        >
                            <FilterDatepicker
                                id="filterdatepickerTo"
                                onChange={e => this.onChangeDate(e, 'datoTil')}
                                placeholder="Dato t.o.m"
                                value={datoTil}
                                tilOgMed
                            />
                        </div>
                        <div className="tableFilter-flex-group tableFilter-flex-group-date" title="Tidsfrist f.o.m">
                            <FilterDatepicker
                                id="filtertidsfristpickerFrom"
                                onChange={e => this.onChangeDate(e, 'tidsfristDatoFra')}
                                placeholder="Tidsfrist f.o.m"
                                value={tidsfristDatoFra}
                            />
                        </div>
                        <div
                            className="tableFilter-flex-group tableFilter-flex-group-date tableFilter-flex-no-border"
                            title="Tidsfrist t.o.m"
                        >
                            <FilterDatepicker
                                id="filtertidsfristpickerTo"
                                onChange={e => this.onChangeDate(e, 'tidsfristDatoTil')}
                                placeholder="Tidsfrist t.o.m"
                                value={tidsfristDatoTil}
                                tilOgMed
                            />
                        </div>
                        <div className=" tableFilter-flex-group tableFilter-flex-group-grow">
                            <MultiFiltersList
                                title="Meldingstype"
                                shortTitle="Type"
                                Items={meldingstyperItems}
                                id="meldingstyperMultiFilters"
                                onChange={this.onChangeFilters}
                                selectedItems={meldingstypeId}
                                name="meldingstypeId"
                                clearDropdownFilters={this.clearDropdownFilters}
                                selectAll={e => this.selectAll(e, 'meldingstypeId', meldingstyperItems)}
                            />
                        </div>
                        <div className=" tableFilter-flex-group tableFilter-flex-group-grow">
                            <MultiFiltersList
                                title="Avtale"
                                Items={avtalerItems}
                                onChange={this.onChangeFilters}
                                selectedItems={avtaleId}
                                name="avtaleId"
                                clearDropdownFilters={this.clearDropdownFilters}
                                selectAll={e => this.selectAll(e, 'avtaleId', avtalerItems)}
                            />
                        </div>
                        {IsServiceavdeling && (
                            <div className=" tableFilter-flex-group tableFilter-flex-group-grow">
                                <MultiFiltersList
                                    title="Entreprenør"
                                    shortTitle="Ent."
                                    Items={entrepreneurItems}
                                    onChange={this.onChangeFilters}
                                    selectedItems={selskapId}
                                    name="selskapId"
                                    clearDropdownFilters={this.clearDropdownFilters}
                                    selectAll={e => this.selectAll(e, 'selskapId', entrepreneurItems)}
                                />
                            </div>
                        )}
                        <div className=" tableFilter-flex-group tableFilter-flex-group-grow">
                            <MultiFiltersList
                                title="Status"
                                Items={statusItems}
                                onChange={this.onChangeFilters}
                                selectedItems={meldingsStatusId}
                                name="meldingsStatusId"
                                clearDropdownFilters={this.clearDropdownFilters}
                                selectAll={e => this.selectAll(e, 'meldingsStatusId', statusItems)}
                                pullRight
                            />
                        </div>
                        <div className="tableFilter-flex-group tableFilter-flex-group-grow">
                            <MultiFiltersWithSubOptionsList
                                title="Bydel"
                                Items={bydelerMedDelbydeler}
                                id="bydelMultiFilters"
                                onChange={this.onChangeBydelFilters}
                                onSubChange={this.onChangeBydelFilters}
                                selectedItems={bydelId}
                                selectedSubItems={delbydel}
                                name="bydelId"
                                subName="delbydel"
                                clearDropdownFilters={() => {
                                    this.clearDropdownFilters('bydelId', false);
                                    this.clearDropdownFilters('delbydel', true);
                                }}
                                selectAll={e => this.selectAll(e, 'bydelId', bydelerMedDelbydeler)}
                                pullRight
                            />
                        </div>
                        <div className="tableFilter-flex-group">
                            <SavedMeldingSearchModal addSavedSearch={addSavedSearch} urlQuery={urlQuery} />
                        </div>
                    </div>
                </div>
                <div className="filterDiv-placeholder" />
                <div>
                    <div id="ChoosenfilterDiv" className="selectedFiltersContainer">
                        <span id="filtervalg">Dine filtervalg: </span>
                        {filterbanner}
                        {filterbanner.length > 1 && (
                            <button onClick={() => this.clearAllFilters()} className="filterRemoveAll">
                                <span>Fjern alle filter</span>
                            </button>
                        )}
                    </div>
                    <div id="ChoosenfilterDiv-placeholder" />
                </div>
                <div>
                    <div id="ChoosenfilterDiv" className="saved-filters">
                        <span id="filtervalg">Dine lagrede filtre: </span>
                        {savedSearchbanner}
                    </div>
                    <div id="ChoosenfilterDiv-placeholder" />
                </div>
            </div>
        );
    }
}

MeldingerFilter.contextTypes = {
    router: PropTypes.object,
};
MeldingerFilter.propTypes = {
    onPrint: PropTypes.func,
    meldingstyper: PropTypes.array,
    meldingsavtaler: PropTypes.array,
    meldingsstatuser: PropTypes.array,
    bydeler: PropTypes.array,
    delbydeler: PropTypes.array,
    entrepreneurs: PropTypes.array,
    filterFunction: PropTypes.func,
    onSelectAllChecks: PropTypes.func,
    disabled: PropTypes.string,
    IsServiceavdeling: PropTypes.bool,
    allChecked: PropTypes.bool,
    SendNewMelding: PropTypes.func,
    reloadMeldinger: PropTypes.func,
    addFlashMessage: PropTypes.func,
    savedsearches: PropTypes.array,
    addSavedSearch: PropTypes.func.isRequired,
    deleteSavedSearch: PropTypes.func.isRequired,
    onPrintLoading: PropTypes.bool.isRequired,
};
export default MeldingerFilter;
