import React, { Component } from 'react';
import PropTypes from 'prop-types';
import join from 'lodash/join';
import first from 'lodash/first';
import filter from 'lodash/filter';
import size from 'lodash/size';
import map from 'lodash/map';
import includes from 'lodash/includes';
import classNames from 'classnames';
import { getTidsfristClass } from '../../utils/meldingUtils';
import Checkbox from '../common/Checkbox';
import * as utils from '../../utils/utils';
import * as responseMessages from '../../constants/ResponseMessages';
import './MeldingList.css';
import StatusIkon from '../common/StatusIcon';
import FolgereIkon from '../common/FolgereIcon';

class MeldingList extends Component {
    constructor(props) {
        super(props);
        this.getKat = this.getKat.bind(this);
        this.state = {
            sorterteMeldiger: props.items,
        };
        this.sorterMeldinger = this.sorterMeldinger.bind(this);
        this.getMeldingTypeById = this.getMeldingTypeById.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.state = {
            sorterteMeldiger: nextProps.items,
        };
    }

    getFilters() {
        let { location } = this.context.router;
        let { search } = location;
        let filter = search.substring(1, search.length);
        return filter;
    }

    onClickTableRow(meldingId) {
        const url = this.props.routeTo + '/' + meldingId;
        this.context.router.push(url);
    }

    getShortMeldingHeader() {
        return (
            <thead>
                <tr>
                    <th />
                    <th>Sted</th>
                    <th>Kategori</th>
                    <th>Dato</th>
                </tr>
            </thead>
        );
    }

    getKat(id) {
        const type = this.props.meldingkatagori(id.toString());
        alert(type);
    }

    getKategori(typeId) {
        let meldingkategori = '';
        let kg = this.props.meldingkatagori;
        map(kg, i => {
            if (i.meldingstype.meldingstypeId === typeId) {
                let arr = [];
                map(i.meldingskategorier, item => {
                    arr.push(item.navn);
                });
                meldingkategori = join(arr, ', ');
            }
        });
        return meldingkategori;
    }

    getMeldingTypeById(typeId) {
        const type = first(filter(this.props.meldingstyper, { meldingstypeId: typeId }));
        return type ? type.beskrivelse : '';
    }

    sorterMeldinger(meldinger) {
        this.setState({ sorterteMeldiger: meldinger });
    }

    getShortMeldingList() {
        let meldinger = this.state.sorterteMeldiger;
        const sizeOfMeldinger = size(meldinger);
        let { checkboxes } = this.props;
        let filter = this.getFilters();
        if (sizeOfMeldinger === 0 && filter.length > 0) {
            return <div className="well">{responseMessages.NoFilteredMelding}</div>;
        }
        return map(meldinger, m => {
            const meldingType = m.meldingstypeId ? this.getMeldingTypeById(m.meldingstypeId) : '';
            const date = utils.formatDateTimeShort(m.opprettetTid);
            const tidsfrist = m.tidsfrist && utils.formatDateTimeHavingKL(m.tidsfrist);
            const tidsfristClass = getTidsfristClass(m.meldingsStatusId, m.tidsfrist);
            const id = m.meldingId;
            const Meldingsnr = m.lopeNummer;
            const beskrivelse = m.beskrivelse ? m.beskrivelse.substring(0, 150) : '';
            const isChecked = includes(checkboxes, id.toString());
            return (
                <div
                    id="meldingListRowDiv"
                    key={id}
                    className={classNames('rowCursor col-xs-12', {
                        active: isChecked,
                    })}
                >
                    <Checkbox
                        id="meldingListRowCheckbox"
                        className="meldingListRowCheckbox"
                        value={id}
                        name="checkboxSearchMap"
                        onChange={this.props.changeCheckboxFromList}
                        checked={isChecked ? 'checked' : ''}
                    />
                    <div onClick={() => this.onClickTableRow(id)}>
                        <div className="meldingListRowRow">
                            <div className="meldingListRowLeft">
                                <strong>{m.adresse}</strong>
                            </div>
                            <div className="meldingListRowRight">
                                <span>
                                    <strong>{meldingType}</strong>
                                </span>
                            </div>
                        </div>
                        <div className="meldingListRowRow">
                            <div className="meldingListRowLeft">
                                Meldingsnr.
                                <strong>{Meldingsnr}</strong>
                            </div>
                        </div>
                        <div className="meldingListRowRow">{beskrivelse}</div>
                        {tidsfrist && (
                            <div className="meldingListRowRow">
                                <div className="meldingListRowDueDate">
                                    <div className={tidsfristClass}>{`Tidsfrist: ${tidsfrist}`}</div>
                                </div>
                            </div>
                        )}
                        <div className="meldingListRowRow">
                            <div className="meldingListRowPills">
                                <StatusIkon statusId={m.meldingsStatusId} />
                                {m.antallFolgere > 0 && <FolgereIkon antallFolgere={m.antallFolgere} />}
                            </div>
                            <div className="meldingListRowDate">
                                <span>{date}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <div>
                <div id="meldingerTable">{this.getShortMeldingList()}</div>
            </div>
        );
    }
}

MeldingList.propTypes = {
    items: PropTypes.array.isRequired,
    changeCheckboxFromList: PropTypes.func,
    meldingkatagori: PropTypes.array,
    meldingstyper: PropTypes.array,
    checkboxes: PropTypes.array,
    routeTo: PropTypes.string,
};
MeldingList.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default MeldingList;
