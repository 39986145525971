/** *************************  Login **************************************** */
export const epostNotExists =
  'Det finnes ingen bruker med denne e-postadressen.';
export const BymeldingServerError =
  'En feil oppsto. Vennligst kontakt Servicedesk.';
export const AuthorizationNotAvailabe =
  'Autentiseringstjenesten er ikke tilgjengelig. Vennligst forsøk senere.';
export const UserIsNotAuthorized =
  'Ingen tilgang til denne siden. Vennligst kontakt Servicedesk.';

export const UserIsNPublikumBruker =
  'Eposten du forsøker å logge inn med er registrert som publikumsbruker og du har derfor ikke tilgang til å se innholdet til denne siden. Hvis du er saksbehandler eller entreprenør på oppdrag for Bymiljøetaten må du kontakte din systemadministrator for å få tilgang til siden';

export const searchMeldingError = 'Ditt søk ga ingen treff.';
export const UpdatePasswordFailure =
  'Feil oppsto ved oppdatering av nytt passord. Vennligst kontakt Servicedesk.';
export const LoginFailed = 'E-postadresse eller passord er ikke riktig.';
/** *************************  users **************************************** */
export const NoUsersMessage = 'Ingen brukere er registrert.';
export const NoEntrepreneursMessage = 'Ingen entreprenører er registrert.';
export const NoMeldingMessage = 'Ingen meldinger er registrert.';
export const NoFilteredMelding =
  'Ingen treff for dine filtervalg. Vennligst endre filtervalg.';
export const NoProcessSchema = 'Ingen prosesskodeskjema er registrert.';

export const UsersNotAvailable = 'Feil ved lasting av brukere.';
export const LoadUsersError = 'Feil ved lasting av brukere.';
export const CreateUserNotAvailable = 'Feil ved registrering av ny bruker.';
/** *************************  pricecode **************************************** */

export const FuturePriceSchemaExists =
  'Det er ikke mulig å legge til eller fjerne prossskode. Fjern fremtidg skjema for å gjøre dette.';
export const PrisschemaIsCreated = 'Prisen er lagt til.';
export const PrisschemaIsNotCreated = 'Prisen ble ikke lagt til.';
export const PrisschemaIsUpdated = 'Prisen er oppdatert.';
export const PrisschemaIsNotUpdated = 'Prisen ble ikke oppdatert.';
export const PrisschemaIsRemoved = 'Prisen er fjernet.';
export const PrisschemaIsNotRemoved = 'Prisen ble ikke fjernet.';

export const schemaIsRemoved = 'Prisskjemaet er fjernet.';
export const schemaIsNotRemoved = 'Prisskjemaet ble ikke fjernet.';

/** *************************  Melding **************************************** */
export const meldingIsupdated = 'Meldingen er oppdatert.';
export const meldingIsNotRouted = 'Meldingen kan ikke tildeles automatisk.';
export const meldingIsRouted = 'Meldingen er tildelt automatisk.';
export const ressursBrukUpdated = 'Ressursbruk er oppdatert.';
export const ressursBrukNotUpdated = 'Ressursbruk ble ikke oppdatert.';
export const ressursBrukDeleted = 'Ressursbruk er fjernet.';
export const ressursBrukNotDeleted = 'Ressursbruk ble ikke fjernet.';
export const ressursBrukCreated = 'Ressursbruk er lagt til.';
export const ressursBrukNotCreated = 'Ressursbruk ble ikke lagt til.';
export const NewMessageIsAdded = 'Ny melding er opprettet.';
export const NewMessageIsNotAdded = 'Ny melding ble ikke opprettet.';

/** *************************  avtale **************************************** */
export const avtaleUpdated = 'Avtalen er oppdatert.';
export const avtaleIsNotUpdated =
  'Feil ved oppdatering av avtalen. Endre selskap på avtalen er ikke tillatt.';

/** *************************  entrepreneur **************************************** */
export const entrepreneurIsCreated = 'Ny entreprenør er registrert.';
export const entrepreneurIsNotCreated =
  'Feil ved registrering av ny entreprenør.';

/** ******************************** print ****************************** */
export const PdfFailure = 'Feil oppsto ved utskrift av melding(er).';

/** ******************************** tilbakemeldinger ****************************** */
export const innmelderIsAnnonym = 'Innmelderen er anonym.';
export const ingenMeldingerTilInnmelderen =
  'Ingen meldinger er sendt til innmelderen.';
export const tilbakemeldingIsSent = 'Tilbakemeldingen er sendt.';
export const tilbakemeldingIsNotSent = 'Feil ved sending av tilbakemelding.';

/** ******************************** kommentarer ****************************** */
export const ingenKommentarer =
  'Ingen kommentarer er lagt til for denne meldingen.';
export const commentIsSent = 'Kommentaren er lagt til.';
export const commentIsNotSent = 'Kommentaren ble ikke lagt til.';

/** ******************************** confirmation messages ****************************** */
export const ressursbrukRemoveressursbruk = 'Fjern ressursbruk.';
export const ressursbrukRemoveBodyText =
  'Vil du fjerne ressursbruk for prosesskoden ';

export const prosesskoderRemoveprosesskode = 'Fjern prosesskoden.';
export const prosesskodeRemoveQuestionText = 'Vil du fjerne prosesskoden ';

export const kontraktRemovekontrakt = 'Fjern prosesskoden.';
export const kontraktRemoveQuestionText = 'Vil du fjerne prosesskoden ';

export const serviceavdelingRemoveUser = 'Fjern bruker.';
export const serviceavdelingRemoveUserQuestionText = 'Vil du fjerne brukeren ';

/** ******************************** Processcodes ****************************** */
export const ProcessCodeIsCreated = 'Prosesskoden er lagt til.';
export const ProcessCodeIsRemoved = 'Prosesskoden er fjernet.';

export const UserIsRemoved = 'Brukeren er fjernet.';

/** ******************************** Rapporter ****************************** */
export const GeneralRapportError =
  'Det finnes ikke rapport for din valgte dato.';
export const MeldingsstatistikkRapportError =
  'Det finnes ikke rapport for din valgte dato og AvtaleId.';
export const ressursforbrukRapportError =
  'Det finnes ikke rapport for din valgte dato eller kontrakt.';
export const vinterRapportError =
  'Det finnes ikke rapport for din valgte dato eller kontrakt.';
