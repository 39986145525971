import * as types from './ActionTypes';
import * as api from '../constants/api';

// eslint-disable-next-line import/prefer-default-export
export const loadFeilrettingskoder = () => {
  const url = api.feilrettingskoder;
  return dispatch => {
      return api.getClient().get(url).then(res => {
          const feilrettingskoder = res.data.result;
          dispatch({ type: types.LOAD_FEILRETTINGSKODER_LIST_SUCCESS, feilrettingskoder });
          return feilrettingskoder;
      });
  };
};

export function putFeilrettingskodeForMelding(meldingId, feilrettingskodeId) {
  const url = api.PutFeilrettingskodeForMelding(meldingId);
  return dispatch => {
      return api.getClient().put(url, {
        "feilrettingskodeId": feilrettingskodeId
      }).then(res => {
          // const data = successData(res)
          return res;
      });
  };
}

export function deleteFeilrettingskodeForMelding(meldingId) {
  const url = api.PutFeilrettingskodeForMelding(meldingId);
  return dispatch => {
      return api.getClient().delete(url).then(res => {
          return res;
      });
  };
}