
import { LOAD_RAPPORTER_LIST } from "../actions/ActionTypes"
const initialState = {
    rapporter: []
}

export default function RapporterReducer(state = initialState.rapporter, action) {
    switch (action.type) {
        case LOAD_RAPPORTER_LIST:
            return action.data
        default:
            return state
    }
}