import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import CollapsibleUser from './CollapsibleUser';
import * as ResponseMessages from '../../../constants/ResponseMessages';
import { getRollerForSelskapsType } from '../../../utils/globalsHelper';
import ConfirmModal from '../../common/ConfirmModal';

const EditableUserRow = ({ user, onDelete, isOpen, onOpen, onSave, roles, avtaler, selskapsType }) => {
    const [confirmDeleteVisible, toggleConfirmDeleteVisible] = useState(false);

    const deleteUser = () => {
        onDelete(user);
        toggleConfirmDeleteVisible(false);
    };

    const rollenavn =
        getRollerForSelskapsType(selskapsType)?.find(r => r.rolleId === user.selskapsRolle.rolleId)?.navn ??
        user.selskapsRolle.rolleId;

    return (
        <tr key={user.id}>
            <td>
                <CollapsibleUser
                    user={user}
                    roles={roles}
                    open={isOpen}
                    onOpen={onOpen}
                    onSave={onSave}
                    avtaler={avtaler}
                    selskapsType={selskapsType}
                />
            </td>
            <td>{!isOpen && rollenavn}</td>
            <td>{user.epost}</td>
            <td>
                <Button className="danger pull-right" bsStyle="link" onClick={() => toggleConfirmDeleteVisible(true)}>
                    Fjern bruker
                </Button>
                {confirmDeleteVisible && (
                    <ConfirmModal
                        modalTitle={ResponseMessages.serviceavdelingRemoveUser}
                        bodyText={`${ResponseMessages.serviceavdelingRemoveUserQuestionText}"${user.navn}" ?`}
                        onConfirm={deleteUser}
                        onCancel={() => toggleConfirmDeleteVisible(false)}
                        confirmText="Fjern"
                    />
                )}
            </td>
        </tr>
    );
};

EditableUserRow.defaultTypes = {
    isOpen: false,
};

EditableUserRow.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    avtaler: PropTypes.array,
    onDelete: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    selskapsType: PropTypes.number.isRequired,
};

export default EditableUserRow;
