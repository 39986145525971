import dateFormat from 'dateformat';
import queryString from 'query-string';

export function successData(response) {
    if (response.data) {
        let { data } = response;
        let { status } = response;
        if (status === 200) {
            return data.result;
        }
    }
    return response;
}
export function errorData(err) {
    if (err.response) {
        if (err.response.status && err.response.status === 500) {
            return 'Feil oppsto på server siden med feilkode(500). Vennligst kontakt systemadministrator.';
        }

        if (err.response.data) {
            let res = err.response.data.errorMessage;
            return res;
        }
    }
    return err;
}
/* This function is used for serializing nested object containing arrays as query params */
export function ParametersSerializer(params) {
    return queryString.stringify(params, { arrayFormat: 'brackets' });
}
export function matched(pattern, text) {
    let regex = new RegExp(pattern);
    return regex.match(pattern, text);
}
export function formatPrice(value) {
    const val = value.toString();
    const formattedValue = val.toLocaleString('de-DE', { style: 'currency', currency: 'KRO' });
    return formattedValue.replace(' KRO', '');
    // return 'Kr ' + val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
}
export function formatDateTimeHavingKL(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, " dd.mm.yyyy 'kl.' HH.MM");
    }
    return '';
}
export function formatDateTimeHavingMonthNameAndTime(dato) {
    let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };
    if (dato.length > 0) {
        // return dateFormat(dato.toLocaleString('nb-NO'), " d'. 'mmmm yyyy 'kl' HH.MM")
        return new Date(dato).toLocaleTimeString('nb-NO', options);
    }
    return '';
}
export function formatDateByCommaSepratedDayMonthYear(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'dd.mm.yyyy');
    }
    return '';
}
export function formatDateTime(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'dd.mm.yyyy HH:MM');
    }
    return '';
}
export function formatTimeDate(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'HH:MM dd.mm.yyyy');
    }
    return '';
}
export function formatDateTimeShort(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'dd.mm.yy HH:MM');
    }
    return '';
}
export function formatDateByCommaSepratedYearMonthDay(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'yyyy.mm.dd');
    }
    return '';
}
export function formatDateByHyphenSepratedYearMonthDay(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'yyyy-mm-dd');
    }
    return '';
}
export function formatDateBySlashSepratedDayMonthYear(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'dd/mm/yyyy');
    }
    return '';
}

export function DateTimeNowFormated() {
    return dateFormat(Date.now(), 'yyyy-mm-dd_HH-MM-ss');
}
export function DateTimeNowFormated2() {
    return dateFormat(Date.now(), 'dd.mm.yyyy HH.MM.ss');
}
export function DateTimeFormatedForAPICall(date) {
    return dateFormat(date, 'yyyy-mm-dd HH:MM:ss');
}

export function DateTimeIsAfter(date) {
    let now = dateFormat(Date.now(), 'dd.mm.yyyy');
    let parseDate = Date.parse(date);
    let parseNow = Date.parse(now);
    return parseDate > parseNow;
}

export function formatDateCommaSepratedDayMonthYearAndFullTime(dato) {
    if (dato.length > 0) {
        return dateFormat(dato, 'dd.mm.yyyy  HH:MM:ss');
    }
    return '';
}

export function dateIsAfter(str) {
    let now = dateFormat(Date.now(), 'dd.mm.yyyy');
    let sp1 = str.split('.');
    let sp2 = now.split('.');
    let t1 = new Date(sp1[2], sp1[1] - 1, sp1[0]);
    let t2 = new Date(sp2[2], sp2[1] - 1, sp2[0]);
    let compare = t1 > t2;
    return compare;
}

export function dateFromIsLongerThanDateTo(datefrom, dateto) {
    let sp1 = datefrom.split('.');
    let sp2 = dateto.split('.');
    let t1 = new Date(sp1[2], sp1[1] - 1, sp1[0]);
    let t2 = new Date(sp2[2], sp2[1] - 1, sp2[0]);
    let compare = t1 > t2;
    return compare;
}

export function sleep(ms) {
    // return new Promise(resolve => setTimeout(resolve, ms));
    return setTimeout(ms);
}

export function removeError(state, felt, value) {
    const { errors } = this.state;
    if (value && value !== '0') {
        delete errors[felt];
        state.setState({ errors });
    }
}
export function leftRightAngles(messag) {
    let rightAngle = '»';
    let lefAngle = '«';
    let res = `${lefAngle + messag + rightAngle}`;
    return res;
}

export function queryBuilder(key, value) {
    const { pathname } = this.context.router.location;
    const params = this.context.router.location.query;
    // const qp=queryString.parse(location.search)
    if (value && value !== '0') params[key] = value;
    else delete params[key];
    const qu = queryString.stringify(params);
    const uri = pathname + '?' + qu;
    this.context.router.push(uri);
}
