import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const MeldingSearchTipsDialog = props => {
    const { show, onHide } = props;

    return (
        <Modal className="soketips-dialog" show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Søketips</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Du kan søke i alle meldinger ved å angi forskjellige søkeord med mellomrom mellom. Det vil da bli utført
                et søk etter meldinger som inneholder minst ett av ordene i de søkbare feltene (epost, adresse,
                beskrivelse, løpenummer). Dersom sortering ikke er angitt sorteres resultatene etter relevans. I tillegg
                til det grunnleggende søket kan man benytte forskjellige parametere for å skreddersy søket ytterligere.
                <br />
                <br />
                <b>Jokertegn</b>
                <br />
                Ved å benytte jokertegnet (<i>*</i>) kan du søke med ufullstendige ord:
                <pre>youngstor*</pre>
                <br />
                <b>Logiske uttrykk</b>
                <br />
                Det er mulig å benytte logiske uttrykk (<i>AND</i>, <i>OR</i> og <i>NOT</i>) mellom søkeord. Disse må
                skrives med store bokstaver.
                <pre>(avfall OR søppel) AND pilesteredet AND NOT container</pre>
                Addisjon- og subtraksjonstegn (<i>+ og -</i>) kan også benyttes for å angi om et ord må eller ikke må
                finnes i resultatet:
                <br />
                <pre>+søppel -container</pre>
                <br />
                <b>Fraser / setninger</b>
                <br />
                Der er også mulig å søke på eksakte fraser ved å skrive setninger i hermetegn:
                <pre>"Oslo gate"</pre>
                <br />
                <b>Feltnavn</b>
                <br />
                Dersom man ønsker å søke i ett gitt felt kan man angi feltnavn foran søkeord, med kolon mellom feltnavn
                og søkeord:
                <pre>adresse:pilestredet</pre>
                <pre>epost:some@one.com</pre>
                Gyldige feltnavn er:
                <ul>
                    <li>
                        <i>epost</i>
                    </li>
                    <li>
                        <i>adressse</i>
                    </li>
                    <li>
                        <i>beskrivelse</i>
                    </li>
                    <li>
                        <i>meldingsnr</i>
                    </li>
                </ul>
                <br />
                <b>Kombinasjoner</b>
                <br />
                De forskjellige måtene å søke på som er nevnt ovnefor kan også kombineres i ett og samme søk:
                <pre>adresse:youngst* AND beskrivelse:hull</pre>
                <pre>lopenummer:(121123 OR 121124)</pre>
                <pre>+"Oslo gate" -avfall</pre>
            </Modal.Body>
            <Modal.Footer>
                <Button className="pull-left" bsSize="large" type="submit" onClick={onHide}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

MeldingSearchTipsDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default MeldingSearchTipsDialog;
