import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import inaktivIcon from '../../images/sortering-innaktiv.svg';
import oppIcon from '../../images/sortering-opp.svg';
import './ListSortBtn.css';

const ListSortBtn = ({
    onClick,
    label,
    sortDirection,
    sortByField,
    active,
}) => (
    <Button
        onClick={() => onClick(sortByField)}
        className={`list-sort-btn list-sort-btn-${
            active ? 'active' : 'inactive'
        }`}
    >
        <span className="list-sort-btn-text">{label}</span>
        {active ? (
            <img
                src={oppIcon}
                className={`list-sort-btn-icon list-sort-btn-icon-${
                    sortDirection === 'desc' ? 'desc' : 'asc'
                }`}
                alt="sortering-pil-ikon"
            />
        ) : (
            <img
                src={inaktivIcon}
                className="list-sort-btn-icon"
                alt="sortering-inaktiv-pil-ikon"
            />
        )}
    </Button>
);

ListSortBtn.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    sortByField: PropTypes.string.isRequired,
    sortDirection: PropTypes.string,
    active: PropTypes.bool,
};

ListSortBtn.defaultProps = {
    label: '',
    sortDirection: 'desc',
    active: false,
};

export default ListSortBtn;
