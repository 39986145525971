import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RootCloseWrapper } from 'react-overlays';
import './EditableRessursbruk.css';

class EditableRessursbrukKommentar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newValue: null,
            editActive: false,
        };
    }

    cancel = () => {
        this.setState({ editActive: false, newValue: null });
    };

    save = () => {
        const { onSubmitEditableComment, ressursbruk } = this.props;
        const { newValue } = this.state;
        onSubmitEditableComment(newValue, ressursbruk);
        this.setState({ editActive: false, newValue: null });
    };

    activate = () => {
        const { value } = this.props;
        this.setState({ editActive: true, newValue: value });
    };

    change = e => {
        this.setState({ newValue: e.target.value });
    };

    render() {
        const { value, isEditable } = this.props;
        const { editActive, newValue } = this.state;

        return !editActive ? (
            <Button
                className="editable-ressursbruk-activate-button editable-ressursbruk-activate-buttcommenton-"
                disabled={!isEditable}
                onClick={this.activate}
            >
                {value && value !== '' ? value : <span className="no-comment-value">Ingen kommentar angitt</span>}
            </Button>
        ) : (
            <RootCloseWrapper onRootClose={this.cancel}>
                <form className="editable-ressursbruk editable-ressursbruk-comment-form">
                    <textarea
                        className="form-control"
                        id="editable-ressursbruk-comment"
                        name="editable-ressursbruk-comment"
                        onChange={this.change}
                        autoFocus
                        value={newValue}
                        rows="6"
                        cols="140"
                    />
                    <Button
                        type="submit"
                        id="save"
                        bsSize="small"
                        onClick={this.save}
                    >
                        <Glyphicon glyph="ok" className="button-save" />
                    </Button>
                    <Button id="save" bsSize="small" onClick={this.cancel}>
                        <Glyphicon
                            glyph="remove"
                            className="button-cancel"
                        />
                    </Button>
                </form>
            </RootCloseWrapper>
        );
    }
}

EditableRessursbrukKommentar.propTypes = {
    value: PropTypes.string,
    isEditable: PropTypes.bool,
    onSubmitEditableComment: PropTypes.func.isRequired,
    ressursbruk: PropTypes.shape({}),
};

EditableRessursbrukKommentar.defaultProps = {
    value: '',
    isEditable: false,
    ressursbruk: {},
};

export default EditableRessursbrukKommentar;
