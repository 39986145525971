import {
 map, filter, isEmpty, find, reverse 
} from 'lodash';
import { statusKoder } from '../constants/Globals';

export const groupMeldingstatuser = (statuser, inParent, inGroups) => {
  let groups = typeof inGroups !== 'undefined' ? inGroups : [];
  const parent = typeof inParent !== 'undefined' ? inParent : { id: 0 };

  const children = filter(statuser, child => child.foreldreId === parent.id);
  if (!isEmpty(children)) {
    if (parent.id === 0) {
      groups = children;
    } else {
      parent.children = children;
    }
    map(children, child => {
      groupMeldingstatuser(statuser, child);
    });
  }
  return groups;
};

// export const groupMeldingstatuser = statuser => {
//     return findChildren(statuser);
// };

export const findMeldingPath = (statuser, statusId, statusPath = []) => {
  if (isEmpty(statuser)) return [];
  const statusItem = find(
    statuser,
    s => s.id.toString() === statusId.toString()
  );
  if (statusItem) {
    statusPath.push(statusItem);
    if (statusItem.foreldreId.toString() !== '0') {
      return findMeldingPath(statuser, statusItem.foreldreId, statusPath);
    }
  }
  return reverse(statusPath);
};

export const getStatusFilterName = (statuser, statusId) => {
  const status = find(statuser, s => s.id.toString() === statusId.toString());
  const forelderstatus =
    status.foreldreId !== 0
      ? find(statusKoder, s => s.id.toString() === status.foreldreId.toString())
      : null;
  return `${forelderstatus ? `${forelderstatus.navn} - ` : ''}${status.navn}`;
};

export const getTidsfristClass = (statusId, tidsfrist) => {
  const status = find(statusKoder, kode => kode.id === statusId);
  const tidsfristDate = new Date(tidsfrist);
  const diffMillis = tidsfristDate.getTime() - (new Date()).getTime();
  const diffHours = Math.ceil(diffMillis / (1000 * 3600));

  if (status) {
    if (status.class === 'ferdig-behandlet' || status.class === 'avvist') return '';
  }

  if (diffHours <= 0) return 'overdue';
  if (diffHours <= 48) return 'near-due-date';

  return '';
};
