import * as types from './ActionTypes';
import * as api from '../constants/api';
import { bymeldingKlientNavn } from '../constants/Globals';

function loadVarselAction(varsel) {
    return { type: types.LOAD_VARSEL_SUCCESS, varsel };
}

// eslint-disable-next-line import/prefer-default-export
export const loadVarsel = () => {
    const url = api.getNotificationServiceVarselData(bymeldingKlientNavn);
    return dispatch => {
        return api.getClient()
            .get(url)
            .then(res => {
                const varsel = res.data;
                dispatch(loadVarselAction(varsel));
                return varsel;
            })
            .catch(() => {
                console.error('Det oppstod en feil ved henting av varsler.');
            });
    };
};
