import { CRS } from 'leaflet';
import PBETegnforkalring from '../images/Regulering_PBE_Tegnbeskrivelse.png';

export const sateliteBaseMap = {
    url:
        'https://opencache.statkart.no/gatekeeper/gk/gk.open_nib_web_mercator_wmts_v2?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topo4&STYLE=default&FORMAT=image/jpgpng&TILEMATRIXSET=GoogleMapsCompatible&tilematrix={getMatrix}&TileRow={y}&TileCol={x}',
    getMatrix: data => `${`0${data.z}`.slice(-2)}`,
    attribution: '&copy; <a href="https://www.norgeibilder.no/">Kartverket, NIBIO og Statens vegvesen</a>',
};

export const bymGrunnkart = {
    url:
        'https://geodata.bymoslo.no/arcgis/rest/services/geodata/Oslomap_epsg3857/MapServer/WMTS/tile/1.0.0/geodata_Oslomap_epsg3857/default/GoogleMapsCompatible/{z}/{y}/{x}.png',
    attribution:
        '&copy; <a href="https://www.oslo.kommune.no/etater-foretak-og-ombud/bymiljoetaten/#gref">Bymiljøetaten</a>',
};

export const mapboxUrl =
    'https://api.mapbox.com/styles/v1/webforvaltningen/cirlr93tl0010gyly7o56ugi3/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2ViZm9ydmFsdG5pbmdlbiIsImEiOiJjaXJsczQ5dnAwMDMxaG5rd2xnNGt2MGZvIn0.BGtT-dezZ_5hseqXmkMAoQ';

export const openStreetMapLayer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const OpenStreetContributors = '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors';

export const kartverketBaseMapUrl =
    'https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}';
export const kartverketBaseMapAttribution = '&copy; <a href="href="https://www.kartverket.no">Kartverket</a>';

export const bymOpenstreatContributor = 'Bymiljøetaten';
export const bymbackgroundWMS = 'https://79.125.104.140/bym/services/Bakgrunnskart/MapServer/WMSServer?';
export const layers =
    '0,1,2,3,4,5,6,8,9,10,11,13,14,15,17,18,19,20,21,22,23,24,25,26,27,29,30,31,33,34,35,37,38,39,40,42,43,44,45,46,47,48,49';
export const styles =
    'default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default,default';
export const bymgeodataTilesUrl = bymbackgroundWMS + layers + styles;

export const bymOsloMapBaseLayer =
    'https://geodata.bymoslo.no/arcgis/rest/services/geodata/Oslomap_epsg3857/MapServer/WMTS?';
export const WMSlayerServiceUrl = 'https://geodata.bymoslo.no/arcgis/services/geodata/bymelding/MapServer/WMSServer?';
export const layerInfoUrl =
    'https://geodata.bymoslo.no/arcgis/rest/services/geodata/bymelding/MapServer/legend?f=pjson';
export const layerInfo2Url =
    'https://geodata.bymoslo.no/arcgis/rest/services/geodata/bymelding2/MapServer/legend?f=pjson';

export const format = 'image/png';
export const minZoom = 12;
export const maxZoom = 18;

export const basemapOptions = {
    grunnkart: 'grunnkart',
    detaljertGrunnkart: 'detaljert_grunnkart',
    satelittkart: 'satelittkart',
    bymBakgrunnskart: 'bymBakgrunnskart',
};

export const kartlag = [
    {
        displayName: 'Turveier',
        layerId: 'Turveier',
        ledgendId: 30,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Indre by - ring 1',
        layerId: 'IndreByRing1',
        ledgendId: 1,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Driftsområder',
        layerId: 'Driftsområder_vei61671',
        ledgendId: 7,
        opacity: 0.3,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Bydelsgrenser',
        layerId: 'Bydelsgrenser',
        ledgendId: 2,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Delbydelsgrenser',
        layerId: 'Delbydelsgrenser',
        ledgendId: 48,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Fremmede arter',
        layerId: 'Fremmede_arter624',
        ledgendId: 5,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Parkregioner',
        ledgendId: 6,
        url: WMSlayerServiceUrl,
        layerId: 'Parkregioner',
    },
    {
        displayName: 'Gatelys',
        layerId: 'Armatur',
        ledgendId: 4,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Sykkelservicestasjon',
        layerId: 'Sykkelservicestasjon',
        ledgendId: 35,
        url: WMSlayerServiceUrl,
    },
    // {
    //   displayName: 'Gatelys infrastruktur',
    //   layerId:
    //     'Armatur infrastruktur - VTP, Armatur infrastruktur - Gatelysskap, Armatur infrastruktur - Kabel, Armatur infrastruktur - Gatelyslinjer, Armatur infrastruktur - Wirestrekk',
    //   layerIdList: [
    //     'Armatur infrastruktur - VTP',
    //     'Armatur infrastruktur - Gatelysskap',
    //     'Armatur infrastruktur - Kabel',
    //     'Armatur infrastruktur - Gatelyslinjer',
    //     'Armatur infrastruktur - Wirestrekk',
    //   ],
    //   url: WMSlayerServiceUrl,
    // },
    {
        displayName: 'Eiendomsoversikt',
        layerId: 'Eiendomsoversikt2020',
        ledgendId: 36,
        url: WMSlayerServiceUrl,
        // opacity: 0.5,
    },

    {
        displayName: 'Eiendomsgrenser',
        layerId: 'Eiendomsgrenser',
        ledgendId: 38,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Idrettsanlegg',
        layerId: 'Idrettsomriss',
        ledgendId: 12,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Kummer og sluk',
        layerId: 'Slukredigering_BYM37120',
        ledgendId: 16,
        url: WMSlayerServiceUrl,
    },
    // {
    //   displayName: 'Avfallsbeholdere',
    //   layerId: 'Avfallsbeholdere',
    //   ledgendId: 15,
    //   url: WMSlayerServiceUrl,
    // },
    {
        displayName: 'Veier',
        layerId: 'Veier',
        ledgendId: 11,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Veier - vegflater',
        layerId: 'vegflater',
        ledgendId: 34,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Trær',
        layerId: 'Tredata',
        ledgendId: 9,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Treforvalterregioner',
        layerId: 'Treforvalterregioner',
        ledgendId: 17,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Treanlegg_BYM',
        layerId: 'Treanlegg_BYM',
        ledgendId: 18,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Treregioner',
        layerId: 'Treregioner',
        ledgendId: 45,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Velvei',
        layerId: 'Velvei',
        ledgendId: 19,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Lekeplass',
        layerId: 'Lekeplass',
        ledgendId: 20,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Skiløyper BYM',
        layerId: 'Skiløyper_preparert_av_Bymiljøetaten17728',
        ledgendId: 21,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Skogeiendommer OK',
        layerId: 'Skogeiendommer_Oslo_kommune_eier50344',
        ledgendId: 22,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'HC-parkering',
        layerId: 'HC-parkering',
        ledgendId: 23,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Idrettsregioner',
        layerId: 'Idrettsregioner',
        ledgendId: 8,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Regulering-PBE',
        layerId: 'ki_regulering',
        url: 'https://od2.pbe.oslo.kommune.no/cgi-bin/wms?map=REGULERING&',
        ledgentUrl: PBETegnforkalring,
        styles: 'default',
        crs: CRS.EPSG4326,
        opacity: 0.7,
    },
    {
        displayName: 'Toaletter',
        layerId: 'Toaletter',
        ledgendId: 31,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Parkomriss',
        layerId: 'Parkanlegg_Omriss',
        ledgendId: 43,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Parkflater',
        layerId: 'ParkAnlegg_Flate',
        ledgendId: 44,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Elbil-ladepunkt',
        layerId: 'Elbil-ladepunkt',
        ledgendId: 46,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Betalingsautomater',
        layerId: 'Betalingsautomater',
        ledgendId: 47,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Sykkelparkering',
        layerId: 'Sykkelparkering',
        ledgendId: 49,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Vinterdrift sykkel - Park',
        layerId: 'Vinterdrift_sykkel_-_Park36652',
        ledgendId: 50,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Vinterdrift sykkel - Prioritert veinett',
        layerId: 'Vinterdrift_sykkel_-_Prioritert_veinett37509',
        ledgendId: 51,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Vinterdrift sykkel - Statens vegvesen',
        layerId: 'Vinterdrift_sykkel_-_Statens_vegvesen58065',
        ledgendId: 52,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Prioritert veinett - ekstra løvfeiing',
        layerId: 'Prioritert_veinett_-_ekstra_løvfeiing33747',
        ledgendId: 53,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Prioritert veinett - sommerdrift',
        layerId: 'Prioritert_veinett_-_sommerdrift32807',
        ledgendId: 54,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Vinterdrift - Park og byforvaltning',
        layerId: 'Broytekart',
        ledgendId: 55,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Parkeringsareal utenfor gategrunn',
        layerId: 'Parkeringsareal_utenfor_gategrunn55228',
        ledgendId: 28,
        url: WMSlayerServiceUrl,
    },
    {
        displayName: 'Drift av øyene',
        layerId: 'Drift_av_øyene19456',
        ledgendId: 56,
        url: WMSlayerServiceUrl,
    },
];
