/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Varsel from '../varsel/Varsel';
import MeldingSearch from './MeldingSearch';
import featureIsEnabled, { FEATURE_VIS_VARSEL } from '../../utils/FeatureManager';

const MeldingerPageHeader = ({
    className,
    title,
    toggleHandler,
    showSearchField,
    OnSearch,
    loading,
    isServiceavdeling,
    varsel,
}) => {
    return (
        <div>
            <div className={classnames('page-header', className)}>
                <div className="melding-page-header-search">
                    <div className="melding-page-heading">
                        <h1>{title}</h1>
                    </div>
                    {featureIsEnabled(FEATURE_VIS_VARSEL) && varsel && varsel.length > 0 && (
                        <Varsel id={varsel[0].id} varsel={varsel[0]} key={varsel[0].id} />
                    )}
                    <div className="melding-page-header-search-search">
                        <MeldingSearch
                            toggleHandler={toggleHandler}
                            OnSearch={OnSearch}
                            // loading={true}
                            loading={loading}
                            isServiceavdeling={isServiceavdeling}
                        />
                    </div>
                </div>
            </div>
            <div className="page-header-placeholder" />
        </div>
    );
};

MeldingerPageHeader.propTypes = {
    title: PropTypes.string,
    OnSearch: PropTypes.func,
    toggleHandler: PropTypes.func,
    showSearchField: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    varsel: PropTypes.array,
};

export default MeldingerPageHeader;
