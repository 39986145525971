import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RapportModal from './RapportModal';
import RapportModalForVinter from './RapportModalForVinter';
import * as cookieHandler from '../../utils/cookieHandler';
import Table from '../../components/common/Table';
import _ from 'lodash';

class RapporterComponent extends Component {
    state = {
        path: '',
        selskapId: '',
    };
    static propTypes = {
        GetGatelysMonedsrapport: PropTypes.func,
        GetMonedsrapportmateriellrapport: PropTypes.func,
        GetMonedsrapportavviksarbeidrapport: PropTypes.func,
        GetMeldingsstatistikkRapport: PropTypes.func,
        GetFakturagrunnlagRapport: PropTypes.func,
        GetRessursforbrukRapport: PropTypes.func,
        GetAvvisteMeldingerRapport: PropTypes.func,
        GetInnMeldingerRapport: PropTypes.func,
        GetVinterForbrukGrunnlagRapport: PropTypes.func,
        GetVinterForbrukOkonomiRapport: PropTypes.func,
        GetDagmulktRapport: PropTypes.func,
        loadEntrepreneurs: PropTypes.func,
        loadAvtalerForEntrepreneur: PropTypes.func,
        loadAvtalerForEntrepreneurAdmin: PropTypes.func,
        loadCompanySpecificContracts: PropTypes.func,
        loadEntrepreneurContracts: PropTypes.func,
        loadMeldingstyper: PropTypes.func,
        getSelskapsIdForLoggedUser: PropTypes.func,
        selskap: PropTypes.object,
        loadProcessCodesList: PropTypes.func,
        params: PropTypes.object,
        entreprenorer: PropTypes.array,
        kontrakter: PropTypes.array,
        meldingstyper: PropTypes.array,
        prosesskoder: PropTypes.array,
        avtaler: PropTypes.array,
        selskapId: PropTypes.string,
    };
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    componentDidMount() {
        // cookieHandler.SetAuthorizationToken();
        if (cookieHandler.UserIsEntrepreneurAdmin() || cookieHandler.UserIsEntrepreneur()) {
            this.props.getSelskapsIdForLoggedUser().then(selskap => {
                this.setState({ selskapId: selskap.selskapId });
                if (selskap.selskapId && cookieHandler.UserIsEntrepreneurAdmin()) {
                    this.props.loadAvtalerForEntrepreneurAdmin(selskap.selskapId);
                    this.props.loadCompanySpecificContracts(selskap.selskapId);
                } else if (selskap.selskapId && cookieHandler.UserIsEntrepreneur()) {
                    this.props.loadAvtalerForEntrepreneur(selskap.selskapId, cookieHandler.UserId());
                }
            });
        } else if (cookieHandler.UserIsBymAdmin()) {
            this.props.loadEntrepreneurs().then(() => {
                this.props.loadAvtalerForEntrepreneurAdmin(this.getInfratekSeslkapId());
            });
        }

        this.props.loadMeldingstyper();
    }
    getSelskaper = () => {
        if (!cookieHandler.UserIsBymAdmin()) return [];
        const selskaper = this.props.entreprenorer;
        return _.map(selskaper, res => {
            return { id: res.id, navn: res.name };
        });
    };
    getProsessCodesForSelskap = () => {
        const kontrakter = this.props.kontrakter;
        const priser = _.flatten(
            _.map(kontrakter, i => {
                return i.priser;
            })
        );
        const prosesskoder = _.map(priser, i => {
            return i.prosesskode;
        });
        return prosesskoder;
    };
    getAvviksprosessKodeForSelskap = () => {
        const prosesskoder = this.getProsessCodesForSelskap();
        const regex = new RegExp(/[A-Z](3)\.\d{1,10}\.[0-9]/g);
        return _.filter(prosesskoder, i => {
            if (i.navn.match(regex)) return i;
        });
    };
    getInfratekSeslkapId = () => {
        const selskaper = this.props.entreprenorer;
        const selskap = _.first(_.filter(selskaper, { name: 'Infratek AS' }));
        return selskap ? selskap.id : '';
    };
    getAvtaler = () => {
        const avtaler = this.props.avtaler;
        return _.map(avtaler, i => {
            return { id: i.avtaleId, navn: i.navn };
        });
    };
    getMeldingstyper = () => {
        const meldingstyper = this.props.meldingstyper;
        return _.map(meldingstyper, i => {
            return { id: i.meldingstypeId, navn: i.beskrivelse };
        });
    };
    renderTableRow = (title, modal) => {
        return (
            <tr>
                <td>{title}</td>
                <td>
                    <div className="pull-right">{modal}</div>
                </td>
            </tr>
        );
    };
    renderRapporterByRoleAccess = shouldUseOldReportApi => {
        const {
            GetGatelysMonedsrapport,
            GetMonedsrapportavviksarbeidrapport,
            GetMeldingsstatistikkRapport,
            loadCompanySpecificContracts,
            GetFakturagrunnlagRapport,
            GetRessursforbrukRapport,
            GetAvvisteMeldingerRapport,
            GetInnMeldingerRapport,
            kontrakter,
            GetVinterForbrukGrunnlagRapport,
        } = this.props;
        const entreprenorer = this.getSelskaper();
        const infratekSelskapId = this.getInfratekSeslkapId();
        const avtaler = this.getAvtaler();
        const meldingstyper = this.getMeldingstyper();
        if (cookieHandler.UserIsServicedesk() || cookieHandler.UserIsServicedeskAdmin()) {
            return (
                <tbody>
                    {this.renderTableRow(
                        'Avvistemeldinger',
                        <RapportModal
                            createRapport={GetAvvisteMeldingerRapport}
                            buttonText="Lag rapport"
                            headerText="Lag måneds avvistemeldinger rapport"
                            filename="MånedsAvvistemeldinger"
                            fileFormat="xlsx"
                            useOld={shouldUseOldReportApi}
                        />
                    )}{' '}
                    {this.renderTableRow(
                        'Innmeldinger',
                        <RapportModal
                            createRapport={GetInnMeldingerRapport}
                            buttonText="Lag rapport"
                            headerText="Lag måneds innmeldinger rapport"
                            filename="MånedsInnmeldinger"
                            fileFormat="xlsx"
                            useOld={shouldUseOldReportApi}
                        />
                    )}
                </tbody>
            );
        } else if (cookieHandler.UserIsEntrepreneurAdmin() || cookieHandler.UserIsBymAdmin()) {
            return (
                <tbody>
                    {this.renderTableRow(
                        'Ressursforbruk',
                        <RapportModal
                            createRapport={GetRessursforbrukRapport}
                            selskaper={entreprenorer}
                            kontrakter={kontrakter}
                            loadCompanySpecificContracts={loadCompanySpecificContracts}
                            buttonText="Lag rapport"
                            headerText="Lag måneds ressursforbruk rapport"
                            filename="MånedsRessursforbruk"
                            fileFormat="xlsx"
                            useOld={shouldUseOldReportApi}
                            meldingstyper={meldingstyper}
                            selectedMeldingstyper={meldingstyper}
                            showInkluderForbrukUtenMeldingstype={true}
                        />
                    )}
                    {this.renderTableRow(
                        'Fakturagrunnlag',
                        <RapportModal
                            createRapport={GetFakturagrunnlagRapport}
                            selskaper={entreprenorer}
                            kontrakter={kontrakter}
                            loadCompanySpecificContracts={loadCompanySpecificContracts}
                            buttonText="Lag rapport"
                            headerText="Lag måneds fakturagrunnlag rapport"
                            filename="FakturaGrunnlag"
                            fileFormat="xlsx"
                            useOld={shouldUseOldReportApi}
                            meldingstyper={meldingstyper}
                            selectedMeldingstyper={meldingstyper}
                            showInkluderForbrukUtenMeldingstype={true}
                        />
                    )}
                    {this.renderTableRow(
                        'Vinterforbruk',
                        <RapportModalForVinter
                            createRapport={GetVinterForbrukGrunnlagRapport}
                            selskaper={entreprenorer}
                            kontrakter={kontrakter}
                            loadCompanySpecificContracts={loadCompanySpecificContracts}
                            buttonText="Lag rapport"
                            headerText="Lag vinterforbruk rapport"
                            filename="Vinterforbruk"
                            fileFormat="xlsx"
                            selskapId={this.state.selskapId}
                            useOld={shouldUseOldReportApi}
                        />
                    )}
                </tbody>
            );
        }
    };
    render() {
        return (
            <div>
                <Table id="rapporterTable" header={['Rapportnavn', '']}>
                    {this.renderRapporterByRoleAccess()}
                </Table>
            </div>
        );
    }
}
export default RapporterComponent;
