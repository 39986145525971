import * as types from "../actions/ActionTypes"

const initialState = {
    meldinger: []
}

export default function meldingReducer(state = initialState, action = {})
{
    switch (action.type)
    {
        case types.LOAD_MELDINGER_SUCCESS:
            return {
                meldinger: action.meldinger
            }
        default:
            return state;
    }
}