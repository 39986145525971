import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { addressIsOutSideOslo } from '../../constants/ValidationErrors';
import customIcon from '../../images/marker-white.png';
import '../../css/Kart/kart.css';
import 'leaflet/dist/leaflet.css';
import { Glyphicon } from 'react-bootstrap';

import $ from 'jquery';
import { Map, Marker, Popup, ZoomControl } from 'react-leaflet';
import Control from 'react-leaflet-control';
import * as kartglobals from '../../constants/kartGlobals';

import KartlagControl from './KartlagControl';
import KartlagVelger from './KartlagVelger';
import GrunnkartVelger from './GrunnkartVelger';

import AdresseSok from './AdresseSok';
import { getAdresseFraPunkt } from './adresseUtils';

class LeafletMapWithSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseFilters: false,
            checkboxes: [],
            newposition: [],
            OsloLat: 59.9139,
            OsloLong: 10.7522,
            zoom: this.props.zoom,
            grunnkart: kartglobals.basemapOptions.grunnkart,
            statkartAvailable: true,
        };
        //this.onChange = this.onChange.bind(this)
        this.handleClickOnMap = this.handleClickOnMap.bind(this);
        this.ListDisplay = this.ListDisplay.bind(this);
        this.onSelectCheckBox = this.onSelectCheckBox.bind(this);
        this.addConfirmPopup = this.addConfirmPopup.bind(this);
        this.updateNewGPS = this.updateNewGPS.bind(this);
        this.locationSeleted = this.locationSeleted.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.setStatkartAvailable = this.setStatkartAvailable.bind(this);
    }
    componentDidMount() {
        this.setInitialMapToOslo();
    }

    setInitialMapToOslo() {
        const { OsloLat, OsloLong } = this.state;
        const Oslo = [OsloLat, OsloLong];
        const { latitude, longitude } = this.props;
        if (latitude === 0 && longitude === 0) this.map.leafletElement.setView(Oslo, this.state.zoom);
    }
    closePopup() {
        this.map.leafletElement.closePopup();
    }
    updateNewGPS(e, v) {
        const currentZoom = (v.target && v.target._zoom) || this.state.zoom;
        this.handleClickOnMap(v);
        this.closePopup();
        this.setState({ zoom: currentZoom });
    }
    checkCoordinateInsideOslo(result) {
        if (result) {
            return result.includes('Oslo') && (result.includes('Norway') || result.includes('Norge'));
        }
        return false;
    }

    setStatkartAvailable = available => {
        this.setState({ statkartAvailable: available });
    };

    handleClickOnMap(e) {
        getAdresseFraPunkt(e.latlng.lat, e.latlng.lng).then(res => {
            if (res.error) {
                this.props.addFlashMessage({
                    type: 'error',
                    text: addressIsOutSideOslo,
                });
                return;
            }
            console.log('LeafletMapWithSearch -> handleClickOnMap -> res', res);
            const address = res.adresse;
            this.props.updateGeoPosition(e.latlng.lat, e.latlng.lng);
            this.props.updateAddress(address);
            if (this.props.updateMelding) {
                this.props.updateMelding();
            }
        });
    }

    addConfirmPopup(e) {
        if (e) {
            const buttoncontainer = $('<div />');
            buttoncontainer.on('click', '#moveMarkeren', ev => this.updateNewGPS(ev, e));
            buttoncontainer.on('click', '#closeMoveMarkeren', this.closePopup);
            buttoncontainer.html(`<div><p>Vil du flytte markeren hit? </p>
                <button ref="moveMarkeren" id="closeMoveMarkeren" class="btn btn-link">Avbryt</button>
                <button ref="moveMarkeren" id="moveMarkeren" class="btn btn-default pull-right">Ja</button>
                </div>
            `);
            this.map &&
                L.popup()
                    .setLatLng(e.latlng)
                    .setContent(buttoncontainer[0])
                    .openOn(this.map.leafletElement);
            this.map && this.map.leafletElement.setView(e.latlng, this.state.zoom);
        }
    }
    onSelectCheckBox(e) {
        const target = e.target;
        const checked = target.checked;
        const value = target.value;
        if (checked) {
            this.setState({ checkboxes: [...this.state.checkboxes, value] });
        } else {
            const filter = this.state.checkboxes.filter(val => val !== value);
            this.setState({ checkboxes: filter });
        }
    }
    locationSeleted(e) {
        if (e && e.display_name !== '') {
            const address = e.display_name;
            const lat = e.lat;
            const lon = e.lon;

            this.props.updateAddress(address);
            this.props.updateGeoPosition(lat, lon);
            this.addConfirmPopup({ ...e, latlng: { lat: lat, lng: lon } });

            this.setState({ zoom: 18 });
        } else {
            this.props.updateAddress('');
            this.props.updateGeoPosition(0, 0);
            this.setState({ zoom: 13 });
        }
    }
    ListDisplay(e) {
        this.setState({ collapseFilters: !this.state.collapseFilters });
    }

    invalidateMap = () => {
        this.map.leafletElement.invalidateSize(false);
    };

    getGeoData = () => {
        const { latitude, longitude, address } = this.props;
        return Object.assign({}, { display_name: address, lat: latitude, lon: longitude });
    };

    setGrunnkart = grunnkart => {
        this.setState({
            grunnkart: grunnkart,
        });
    };

    /*******************************************/
    render() {
        const { latitude, longitude, addressInput, address, error } = this.props;
        const { OsloLat, OsloLong, zoom, checkboxes, grunnkart, statkartAvailable } = this.state;
        let position = [];
        if (latitude !== 0 && longitude !== 0) position = [latitude, longitude];
        else position = [OsloLat, OsloLong];
        const showMarker = latitude !== 0 && longitude !== 0;
        const markerIcon = L.icon({
            iconUrl: customIcon,
            iconSize: [24, 30],
            iconAnchor: [12, 30],
            popupAnchor: [0, -26],
        });
        const maxBounds = L.LatLngBounds(new L.LatLng(49.41097, -11.29395), new L.LatLng(62.85515, 2.50488));
        let geodata = this.getGeoData();

        return (
            <div className="meldingDetailleafletDiv">
                {addressInput === 'outside' && (
                    <div>
                        <AdresseSok
                            locationSeleted={this.locationSeleted}
                            geodata={geodata}
                            label="Adresse"
                            placeholder="Søk etter adresse"
                            error={error}
                        />
                        <div>
                            <a
                                href={`http://maps.google.com/maps?q=&layer=c&cbll=${geodata.lat},${geodata.lon}`}
                                target="_blank"
                            >
                                <Glyphicon glyph="map-marker" id="apneIStreetView" />
                                Åpne Google street view
                            </a>
                        </div>
                    </div>
                )}
                <Map
                    center={position}
                    zoom={zoom}
                    minZoom={kartglobals.minZoom}
                    maxZoom={kartglobals.maxZoom}
                    scrollWheelZoom={false}
                    zoomControl={false}
                    onClick={this.addConfirmPopup}
                    bounds={maxBounds}
                    ref={r => (this.map = r)}
                >
                    <ZoomControl position="bottomleft" />

                    {(!addressInput || addressInput !== 'outside') && (
                        <Control position="topleft">
                            <AdresseSok
                                locationSeleted={this.locationSeleted}
                                geodata={geodata}
                                placeholder="Søk etter adresse"
                                error={error}
                            />
                        </Control>
                    )}
                    {showMarker && (
                        <Marker position={position} icon={markerIcon}>
                            <Popup>
                                <span>{address}</span>
                            </Popup>
                        </Marker>
                    )}
                    <KartlagVelger
                        position="topright"
                        onSelectKartlag={this.onSelectCheckBox}
                        ListDisplay={this.ListDisplay}
                        collapseFilters={this.state.collapseFilters}
                        valgteKartlag={checkboxes}
                    />
                    <KartlagControl
                        position="bottomleft"
                        checkboxes={checkboxes}
                        grunnkart={grunnkart}
                        setStatkartAvailable={this.setStatkartAvailable}
                    />
                    <GrunnkartVelger
                        position="bottomleft"
                        grunnkartOptions={kartglobals.basemapOptions}
                        grunnkart={grunnkart}
                        setGrunnkart={this.setGrunnkart}
                        statkartAvailable={statkartAvailable}
                    />
                </Map>
            </div>
        );
    }
}
LeafletMapWithSearch.defaultProps = {
    zoom: 18,
};
LeafletMapWithSearch.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    address: PropTypes.string,
    updateAddress: PropTypes.func,
    updateGeoPosition: PropTypes.func,
    updateMelding: PropTypes.func,
    zoom: PropTypes.number,
    changeZoom: PropTypes.func,
    addressInput: PropTypes.string,
    error: PropTypes.string,
    addFlashMessage: PropTypes.func,
};
export default LeafletMapWithSearch;
