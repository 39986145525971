import initialState from "./initialState"
import {LOAD_MELDING_COMMENTS, ADD_MELDING_COMMENTS} from "../actions/ActionTypes"

export default function kommentarerReducer(state=initialState.kommentarer, action){
       switch(action.type){
           case LOAD_MELDING_COMMENTS:
                return action.comments
            
            case ADD_MELDING_COMMENTS:{
                return [...state, action.comment]
            }
           default:
              return state
       }
}