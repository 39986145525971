import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Glyphicon } from 'react-bootstrap';
import TextFieldGroup from '../common/TextFieldGroup';
import NewMeldingDropdownList from './NewMeldingDropdownList';
import LeafletMapWithSearch from '../Kart/LeafletMapWithSearch';
import ImageUploader from '../common/ImageUploader';
import validateInput from '../../validations/AddNewMeldingValidator';
import TextArea from '../common/TextArea';
import '../../css/Meldinger/newMeldingModal.css';
import _ from 'lodash';

const state = {
    openModal: false,
    innsenderEpost: '',
    beskrivelse: '',
    meldingstypeId: '0',
    adresse: '',
    errors: {},
    bilder: [],
    latitude: 0,
    longitude: 0,
    zoom: 14,
    setstring: '',
    editing: true,
    loading: false,
};

const MAX_DESCRIPTION_LENGTH = 300;
const SHOW_MAX_DESCRIPTION_LENGTH = 270;

class NewMeldingModal extends Component {
    constructor(props) {
        super(props);
        this.state = state;
        this.toggleNewMeldingModal = this.toggleNewMeldingModal.bind(this);
    }
    static propTypes = {
        SendNewMelding: PropTypes.func.isRequired,
        meldingsTypeItems: PropTypes.array.isRequired,
        reloadMeldinger: PropTypes.func.isRequired,
        addFlashMessage: PropTypes.func,
        editing: PropTypes.bool,
    };
    static defaultProps = {};
    toggleNewMeldingModal = () => {
        this.setState({ openModal: !this.state.openModal });
        this.setState({
            innsenderEpost: '',
            adresse: '',
            beskrivelse: '',
            meldingstypeId: '0',
            bilder: [],
            errors: {},
            zoom: 14,
            latitude: 0,
            longitude: 0,
            loading: false,
        });
    };
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onChangeText = e => {
        const { editing } = this.state;
        let text = e.target.value;
        let textCountString = '';
        if (!editing && text == null) {
            return null;
        }
        if (editing && text.length <= MAX_DESCRIPTION_LENGTH) {
            this.setState({ [e.target.name]: e.target.value });
        }
        if (text.length <= MAX_DESCRIPTION_LENGTH && text.length > SHOW_MAX_DESCRIPTION_LENGTH) {
            textCountString += `(${MAX_DESCRIPTION_LENGTH - text.length} tegn igjen)`;
            this.setState({ setstring: textCountString });
        }
        if (text.length <= SHOW_MAX_DESCRIPTION_LENGTH) {
            textCountString += '';
            this.setState({ setstring: textCountString });
        }
    };

    updateGeoPosition = (lat, lng) => {
        const geoposition = lat.toString() + ' , ' + lng.toString();
        this.setState({
            latitude: lat,
            longitude: lng,
            GPSCoordinate: geoposition,
            zoom: 18,
        });
    };
    updateAddress = address => {
        this.setState({ adresse: address, zoom: 18 });
    };
    onSelectMeldingsType = e => {
        this.setState({ meldingstypeId: e.target.value });
    };

    addImage = image => {
        const currentImages = this.state.bilder;
        if (currentImages.length < 5) {
            const copyimages = currentImages.concat(image);
            const set = _.take(_.uniqBy(copyimages, 'name'), 4);
            this.setState({ bilder: set });
        }
    };
    removeImage = (e, name) => {
        const images = this.state.bilder;
        const filter = _.filter(images, i => {
            return i.name !== name;
        });
        this.setState({ bilder: filter });
    };
    IsValid = () => {
        const { errors, isValid } = validateInput(this.state);
        if (!isValid) {
            //window.scrollTo(0, 0)
            this.setState({ errors });
        }
        return isValid;
    };
    appendData = (form, key, value) => {
        if (value != null) {
            form.append(key, value);
        }
    };
    createFormData = message => {
        let form = new FormData();
        this.appendData(form, 'meldingstypeId', message.meldingstypeId);
        this.appendData(form, 'innsenderEpost', message.innsenderEpost);
        this.appendData(form, 'beskrivelse', message.beskrivelse);
        this.appendData(form, 'adresse', message.adresse);
        this.appendData(form, 'latitude', message.latitude);
        this.appendData(form, 'longitude', message.longitude);
        message.bilder.forEach(bilde => form.append('bilder', bilde));
        return form;
    };

    SubmitNewMelding = () => {
        const message = this.createFormData(this.state);
        if (this.IsValid()) {
            this.setState({ loading: true });
            this.props
                .SendNewMelding(message)
                .then(() => {
                    this.props.reloadMeldinger();
                    this.setState(state);
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    };

    render = () => {
        const {
            setstring,
            openModal,
            innsenderEpost,
            beskrivelse,
            meldingstypeId,
            adresse,
            latitude,
            longitude,
            bilder,
            zoom,
            errors,
            loading,
        } = this.state;
        const beskrivelseLabel = 'Beskrivelse av meldingen' + setstring;
        return (
            <div>
                <Button className="btn btn-default" id="NyMeldingButton" onClick={this.toggleNewMeldingModal}>
                    <Glyphicon glyph="plus" />
                    <span className="plus-btn-text">Ny melding</span>
                </Button>
                <Modal className="newMeldingModal" show={openModal} onHide={this.toggleNewMeldingModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Registrer melding for publikum</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="newMeldingForm">
                            <TextFieldGroup
                                label="E-postadresse"
                                value={innsenderEpost}
                                name="innsenderEpost"
                                onChange={this.onChange}
                                id="epost"
                                error={errors.innsenderEpost}
                            />
                            <NewMeldingDropdownList
                                label="Meldingstype"
                                Items={this.props.meldingsTypeItems.filter(t => {
                                    return t.synligForPublikum;
                                })}
                                selected={meldingstypeId}
                                title="Velg meldingstype"
                                name="meldingstypeId"
                                onChange={this.onSelectMeldingsType}
                                id="Dropdownlist"
                                error={errors.meldingstypeId}
                            />
                            <TextArea
                                label={beskrivelseLabel}
                                value={beskrivelse}
                                name="beskrivelse"
                                onChange={this.onChangeText}
                                id="beskrivelse"
                                error={errors.beskrivelse}
                                placeHolder={`Unngå personopplysninger i kommentarfeltet.`}
                            />
                            <LeafletMapWithSearch
                                ref={r => (this.map = r)}
                                address={adresse}
                                latitude={latitude}
                                longitude={longitude}
                                error={errors.adresse}
                                updateAddress={this.updateAddress}
                                updateGeoPosition={this.updateGeoPosition}
                                zoom={zoom}
                                addressInput="outside"
                                addFlashMessage={this.props.addFlashMessage}
                            />

                            <ImageUploader bilder={bilder} addImage={this.addImage} removeImage={this.removeImage} />
                            <br />

                            <div className="clearfix"></div>
                            <p id="personopplysning-warning-alert">Unngå personopplysninger i bilde(r).</p>
                            <br />
                            <div>
                                <Button bsStyle="link" onClick={this.toggleNewMeldingModal} id="buttonLink">
                                    Avbryt
                                </Button>
                                <Button
                                    bsStyle="primary"
                                    bsSize="large"
                                    onClick={this.SubmitNewMelding}
                                    disabled={loading}
                                >
                                    Opprett melding
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    };
}
export default NewMeldingModal;
