import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, first, isEmpty, sortBy } from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import DropDownListEditableNoRootClose from '../components/common/DropDownListEditableNoRootClose';
import { groupMeldingstatuser, findMeldingPath } from '../utils/meldingUtils';

class MeldingsstatusVelger extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    state = {
        active: false,
        valgtPath: [],
        currentValgtStatus: 0,
        statuserCopy: [],
        closed: false,
    };

    componentDidMount = () => {
        const { valgtStatus, statuser } = this.props;
        const statuserCopy = JSON.parse(JSON.stringify(statuser));
        const groups = groupMeldingstatuser(statuserCopy);
        const valgtPath = findMeldingPath(statuserCopy, valgtStatus.toString());
        this.setState({
            groups,
            valgtPath,
            currentValgtStatus: valgtStatus.toString(),
            statuserCopy: statuserCopy,
        });
    };

    componentWillReceiveProps = () => {
        // eslint-disable-next-line no-unused-vars
        const { valgtStatus, statuser, meldingsStatusId, popupAddComment } = this.props;
        const { currentValgtStatus, statuserCopy } = this.state;

        if (currentValgtStatus.toString() !== valgtStatus.toString() && !popupAddComment) {
            const valgtPath = findMeldingPath(statuserCopy, valgtStatus.toString());
            this.setState({
                currentValgtStatus: valgtStatus.toString(),
                valgtPath,
            });
        }
    };

    onChange = valgtStatus => {
        const { statuserCopy } = this.state;
        const valgtPath = findMeldingPath(statuserCopy, valgtStatus.toString());
        this.setState({
            valgtPath,
            active: true,
            currentValgtStatus: valgtStatus.toString(),
            closed: false,
        });
    };
    getWithoutButtons1 = valgtPath => {
        const { closed } = this.state;
        if (closed) return true;
        return valgtPath.length === 0 || (valgtPath[0] && valgtPath[0].children && valgtPath[0].children.length > 0);
    };
    getWithoutButtons2 = (valgtPath, index) => {
        const { closed } = this.state;
        if (closed) return true;
        return (
            valgtPath &&
            valgtPath[index + 1] &&
            valgtPath[index + 1].children &&
            valgtPath[index + 1].children.length > 0
        );
    };
    cancel = () => {
        this.onClose();
        if (this.selectElement0) this.selectElement0.cancel();
        if (this.selectElement1) this.selectElement1.cancel();
        if (this.selectElement2) this.selectElement2.cancel();
        if (this.selectElement3) this.selectElement3.cancel();
        if (this.selectElement4) this.selectElement4.cancel();
    };

    onClose = () => {
        const { active, statuserCopy } = this.state;
        const { valgtStatus, statuser } = this.props;
        if (active) {
            const valgtPath = findMeldingPath(statuserCopy, valgtStatus);
            this.setState({
                valgtPath,
                active: false,
                closed: true,
            });
        }
    };

    submitChanges = e => {
        const { onSubmit } = this.props;
        onSubmit(e);
        this.setState({
            active: false,
        });
    };

    render() {
        const { error } = this.props;
        const { groups, valgtPath } = this.state;
        return (
            <RootCloseWrapper onRootClose={this.cancel}>
                <div>
                    <div id="status-list-editable" className="dropdown-list-editable">
                        <DropDownListEditableNoRootClose
                            Items={!isEmpty(groups) ? sortBy(groups, 'navn') : []}
                            selected={!isEmpty(valgtPath) ? valgtPath[0].id.toString() : { id: '0' }}
                            title="Velg hvilken status"
                            id="melding-status"
                            label="Status"
                            error={error}
                            onChange={e => this.onChange(e.target.value)}
                            onSubmit={this.submitChanges}
                            withoutButtons={
                                this.getWithoutButtons1(valgtPath)
                                // valgtPath.length === 0 ||
                                // (valgtPath[0] && valgtPath[0].children && valgtPath[0].children.length > 0)
                            }
                            onClose={this.onClose}
                            ref={node => (this.selectElement0 = node)}
                        />
                        {map(valgtPath, (p, index) => (
                            <div key={`dropdow-num-${index}`} className={`dropdow-num-${index}`}>
                                {p.children && (
                                    <DropDownListEditableNoRootClose
                                        Items={sortBy(p.children, 'navn')}
                                        selected={valgtPath[index + 1] ? valgtPath[index + 1].id.toString() : ''}
                                        title="Velg hvilken status"
                                        id={`dropdow-num-${index + 1}`}
                                        label=""
                                        error={error}
                                        onChange={e => this.onChange(e.target.value)}
                                        onSubmit={this.submitChanges}
                                        withoutButtons={
                                            this.getWithoutButtons2(valgtPath, index)
                                            // valgtPath &&
                                            // valgtPath[index + 1] &&
                                            // valgtPath[index + 1].children &&
                                            // valgtPath[index + 1].children.length > 0
                                        }
                                        onClose={this.onClose}
                                        ref={node => (this[`selectElement${index}`] = node)}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </RootCloseWrapper>
        );
    }
}

MeldingsstatusVelger.propTypes = {
    statuser: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.shape({}),
    valgtStatus: PropTypes.number.isRequired,
    meldingsStatusId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    popupAddComment: PropTypes.bool.isRequired,
};

MeldingsstatusVelger.defaultProps = {
    error: null,
    statuser: [],
};

export default MeldingsstatusVelger;
