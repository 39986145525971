import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import map from 'lodash/map';
import { Nav, NavItem, NavDropdown, MenuItem, Navbar, Image, Glyphicon, Button } from 'react-bootstrap';
import { FaUser, FaTachometerAlt } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import * as cookieHandler from '../../utils/cookieHandler';
import { signOutAndRemoveCookie } from '../../actions/LoginActions';
import * as routes from '../../constants/clientRoutes';
import { driftClientUrl } from '../../constants/api';
import { getAccessToken } from '../../utils/cookieHandler';
import { withAuth } from 'react-oidc-context';

import featureIsEnabled, { FEATURE_DASHBOARD_STATISTIKK } from '../../utils/FeatureManager';

import '../../css/layout/Header.css';

const logo = require('../../images/byvaap-cmyk.png');

class HeaderContainer extends Component {
    state = {
        selected: true,
    };

    static propTypes = {
        user: PropTypes.object,
        signOutAndRemoveCookie: PropTypes.func.isRequired,
        getSelskapsIdForLoggedUser: PropTypes.func,
        selskap: PropTypes.object,
    };

    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    onSelect = e => {
        this.setState({ selected: e });
    };

    onClickNavDropdown = (e, v) => {
        this.setState({ selected: v });
    };

    logout = e => {
        const auth = this.props.auth;
        auth.signoutRedirect();
        // window.location.href = 'https://sandbox-oslo.onelogin.com/oidc/2/logout';
    };

    reloadMeldinger = () => {
        window.location.reload(true);
    };

    renderUsername = () => {
        const usernameInBymCookie = cookieHandler.Username();
        const username = this.props.user.sub ? this.props.user.sub : usernameInBymCookie;
        return username;
    };

    renderNavigationBarForWormMeldinger = () => {
        const { selected } = this.state;
        return (
            (cookieHandler.UserIsEntrepreneurAdmin() || cookieHandler.UserIsEntrepreneur()) && (
                <LinkContainer to={routes.entrepreneurMeldinger}>
                    <NavItem
                        className="divider-vertical"
                        eventKey={1}
                        id={selected === 1 ? 'bg-navbar' : 'bg-darkgray'}
                    >
                        Meldinger
                    </NavItem>
                </LinkContainer>
            )
        );
    };

    IsInfratekAs = () => {
        const { selskap } = this.props;
        return selskap.selskapNavn ? selskap.selskapNavn.toLowerCase().startsWith('infratek') : false;
    };

    renderMenuLinks = () => {
        const token = getAccessToken();
        const { selected } = this.state;
        return (
            <Nav pullRight onSelect={this.onSelect}>
                {cookieHandler.UserHasEnkAccess() && (
                    <li role="presentation" className="divider-vertical">
                        <a className="nav-link" href={driftClientUrl}>
                            DRIFT
                        </a>
                    </li>
                )}
                {cookieHandler.UserIsBymAdmin() && (
                    <LinkContainer to={routes.admin}>
                        <NavItem
                            className="divider-vertical"
                            eventKey={1}
                            id={selected === 1 ? 'bg-navbar' : 'bg-darkgray'} /* onClick={this.reloadMeldinger*} */
                        >
                            ADMIN
                        </NavItem>
                    </LinkContainer>
                )}
                {(cookieHandler.UserIsEntrepreneurAdmin() || cookieHandler.UserIsEntrepreneur()) && (
                    <LinkContainer to={routes.entrepreneurMeldinger}>
                        <NavItem
                            className="divider-vertical"
                            eventKey={1}
                            id={selected === 1 ? 'bg-navbar' : 'bg-darkgray'} /* onClick={this.reloadMeldinger*} */
                        >
                            Meldinger
                        </NavItem>
                    </LinkContainer>
                )}
                {cookieHandler.UserIsEntrepreneurAdmin() && (
                    <LinkContainer to={routes.selskapBrukere}>
                        <NavItem
                            className="divider-vertical"
                            eventKey={2}
                            id={selected === 2 ? 'bg-navbar' : 'bg-darkgray'}
                        >
                            Brukere
                        </NavItem>
                    </LinkContainer>
                )}
                {cookieHandler.UserIsEntrepreneurAdmin() && (
                    <NavDropdown
                        className="divider-vertical"
                        eventKey={3}
                        title="Kontrakter"
                        onClick={e => this.onClickNavDropdown(e, 3)}
                        id={[3.1, 3.2].indexOf(selected) >= 0 ? 'bg-navbar' : 'bg-darkgray'}
                    >
                        <LinkContainer to={routes.selskapKontrakter}>
                            <MenuItem eventKey={3.1}>Kontrakter</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={routes.globalressursbruk}>
                            <MenuItem eventKey={3.2}>Ressursbruk</MenuItem>
                        </LinkContainer>
                    </NavDropdown>
                )}
                {(cookieHandler.UserIsServicedeskAdmin() || cookieHandler.UserIsServicedesk()) && (
                    <LinkContainer to={routes.saksbehandlerMeldinger}>
                        <NavItem
                            className="divider-vertical"
                            eventKey={1}
                            id={selected === 1 ? 'bg-navbar' : 'bg-darkgray'} /* onClick={this.reloadMeldinger} */
                        >
                            Meldinger
                        </NavItem>
                    </LinkContainer>
                )}
                {(cookieHandler.UserIsServicedeskAdmin() || cookieHandler.UserIsBymAdmin()) && (
                    <LinkContainer to={routes.serviceAvdelingen}>
                        <NavItem
                            className="divider-vertical"
                            eventKey={2}
                            id={selected === 2 ? 'bg-navbar' : 'bg-darkgray'}
                        >
                            SERVICEAVDELINGEN
                        </NavItem>
                    </LinkContainer>
                )}
                {cookieHandler.UserIsBymAdmin() && (
                    <NavDropdown
                        className="divider-vertical"
                        eventKey={4}
                        title="ENTREPRENØRER"
                        onClick={e => this.onClickNavDropdown(e, 4)}
                        id={[4.1, 4.2, 4.3].indexOf(selected) >= 0 ? 'bg-navbar' : 'bg-darkgray'}
                    >
                        <LinkContainer to={routes.selskaper}>
                            <MenuItem eventKey={4.1}>ENTREPRENØRER</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={routes.prosessKodeSkjema}>
                            <MenuItem eventKey={4.2}>PROSESSKODER</MenuItem>
                        </LinkContainer>
                        <LinkContainer to={routes.avtaler}>
                            <MenuItem eventKey={4.3}>Avtaler</MenuItem>
                        </LinkContainer>
                    </NavDropdown>
                )}

                {(cookieHandler.UserIsBymAdmin() ||
                    cookieHandler.UserIsEntrepreneurAdmin() ||
                    cookieHandler.UserIsServicedeskAdmin() ||
                    cookieHandler.UserIsServicedesk()) && (
                    <LinkContainer to={routes.rapporter}>
                        <NavItem
                            className="divider-vertical"
                            eventKey={6}
                            id={selected === 5 ? 'bg-navbar' : 'bg-darkgray'}
                        >
                            Rapporter
                        </NavItem>
                    </LinkContainer>
                )}

                {featureIsEnabled(FEATURE_DASHBOARD_STATISTIKK) && cookieHandler.UserIsBymAnsatt() && (
                    <li className="divider-vertical drift-link">
                        <form method="post" action={`${driftClientUrl}/admin/dashboard`}>
                            <input
                                type="text"
                                hidden
                                value={cookieHandler.loadBymCookie()}
                                id="access_token"
                                name="access_token"
                            />
                            <input type="text" hidden value={token.exp} id="expires_in" name="expires_in" />
                            <Button
                                bsClass=""
                                style={{ color: '#4d4c4d', paddingTop: 20 }}
                                className="btn btn-link"
                                type="submit"
                            >
                                <FaTachometerAlt size={21} />
                            </Button>
                        </form>
                    </li>
                )}
                {cookieHandler.bymCookieExists() && (
                    <NavDropdown
                        className="divider-vertical"
                        eventKey={7}
                        title={
                            <span>
                                <FaUser size={16} /> {this.renderUsername()}
                            </span>
                        }
                        id="user-profile"
                    >
                        <MenuItem eventKey={6} onClick={this.logout}>
                            Logg ut
                        </MenuItem>
                    </NavDropdown>
                )}
            </Nav>
        );
    };

    renderNavigationBar = () => {
        return (
            <Navbar fluid fixedTop expand="xl">
                <Navbar.Header>
                    <Navbar.Brand className="bymelding-navbar-brand">
                        <div>
                            <Image src={logo} />
                            <span className="header-text header-text-longname">Bymelding saksbehandling</span>
                            <span className="header-text header-text-shortname">Bymelding sak</span>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>{this.renderMenuLinks()}</Navbar.Collapse>
            </Navbar>
        );
    };

    render() {
        return <div>{this.renderNavigationBar()}</div>;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.login.user ? state.login.user : state.login.token,
        selskap: state.login.selskap ? state.login.selskap : {},
        params: ownProps.params,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ signOutAndRemoveCookie }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(HeaderContainer));
