import validator from "validator"
import * as validatorMessages from "../constants/ValidationErrors"
import {dateFromIsLongerThanDateTo} from "../utils/utils"
import isEmpty from "lodash/isEmpty"

export default function  validateInput(data){
    let errors={}

    if(validator.isEmpty(data.kontraktsnavn)){
        errors.kontraktsnavn=validatorMessages.ContractNameIsRequired
    }
    if(validator.isEmpty(data.gyldigfra)){
        errors.gyldigfra=validatorMessages.DateFromIsRequired
    }
    if(validator.isEmpty(data.gyldigtil)){
        errors.gyldigtil=validatorMessages.DateToIsRequired
    }
    if(dateFromIsLongerThanDateTo(data.gyldigfra, data.gyldigtil)){
        errors.comparetDates=validatorMessages.FromDateIsLongerThanNow
    }
    if (validator.isNumeric(data.avtaleId.toString())) {
		if (data.avtaleId < 1) {
			errors.avtaleId = validatorMessages.avtaleIsRequired;
		}
	}
    if (validator.isNumeric(data.prosesskodeskjemaId.toString())) {
		if (data.prosesskodeskjemaId < 1) {
			errors.prosesskodeskjemaId = validatorMessages.ProcesscodeSchemaIsRequired;
		}
	}
	return {
		errors,
		isValid: isEmpty(errors)
	}

}

