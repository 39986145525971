import {LOAD_MELDING_HISTORIC} from "../actions/ActionTypes"
import  initialState from "./initialState"

export default function historiskReducer(state=initialState.historisk, action){
    switch (action.type){

        case LOAD_MELDING_HISTORIC:
            return action.historic

        default:
      return state;
    }

}