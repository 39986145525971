import { LOAD_SAVED_MELDING_SEARCH, DELETE_SAVED_MELDING_SEARCH, ADD_SAVED_MELDING_SEARCH } from "./ActionTypes"
import { addFlashMessage } from "./FlashMessagesAction"
import { loadSavedMeldingSearchUrl, SavedMeldingSearchUrl, DeleteMeldingSearchUrl } from "../constants/api"
import { successData, errorData } from "../utils/utils"
import mockData from "../Api_MockData/mockSavedMeldingSearchApi"
import { mock } from "../constants/settings";
import * as api from '../constants/api';

function loadSavedMeldingerSearchSuccess(payload)
{
    return { type: LOAD_SAVED_MELDING_SEARCH, payload };
}
function addSavedMeldingerSearchSuccess(payload)
{
    return { type: ADD_SAVED_MELDING_SEARCH, payload };
}
function deleteSavedMeldingerSearchSuccess(payload)
{
    return { type: DELETE_SAVED_MELDING_SEARCH, payload };
}
export const GetSavedMeldingSearchByBrukerId = (brukerId) =>
{
    const url = loadSavedMeldingSearchUrl(brukerId)
    return dispatch =>
    {
        if (!mock)
        {
            return mockData.getSavedSearchMeldinger().then(payload =>
            {
                dispatch(loadSavedMeldingerSearchSuccess(payload))
            })
        }
        else
        {
            return api.getClient().get(url).then(res =>
            {
                const payload = successData(res)
                dispatch(loadSavedMeldingerSearchSuccess(payload))
                return payload
            })
        }
    }
}

export const AddToSavedMeldingSearch = (payload) =>
{
    const url = SavedMeldingSearchUrl(payload.brukerId)
    return dispatch =>
    {
        if (!mock)
        {
            return mockData.addSavedSearch(payload).then(() =>
            {
                dispatch(addSavedMeldingerSearchSuccess(payload))
            })
        }
        else
        {
            return api.getClient().post(url, payload)
                .then((res) =>
                {
                    const data = successData(res)
                    payload.id = data.id
                    dispatch(addSavedMeldingerSearchSuccess(payload))
                })
                .catch(err =>
                {
                    const error = errorData(err)
                    dispatch(addFlashMessage({ type: 'error', text: error }))
                })
        }
    }
}

export const DeleteFromSavedMeldingSearch = (payload) =>
{
    const url = DeleteMeldingSearchUrl(payload.brukerId, payload.id)
    return dispatch =>
    {
        if (!mock)
        {
            return mockData.deleteSavedSearch(payload)
                .then(() =>
                {
                    dispatch(deleteSavedMeldingerSearchSuccess(payload))
                })

        } else
        {
            return api.getClient().delete(url)
                .then(() =>
                {
                    dispatch(deleteSavedMeldingerSearchSuccess(payload))
                })
                .catch(err =>
                {
                    const error = errorData(err)
                    dispatch(addFlashMessage({ type: 'error', text: error }))
                })
        }
    }
} 