import delay from './delay';

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const bulkupdates = [  
    {
        id: "1",
        navn: "Bulk 1",
        prosessertDato: null
    },
    {
        id: "2",
        navn: "Bulk 2",
        prosessertDato: "2011.01.01"
    },
    {
        id: "3",
        navn: "Bulk 3",
        prosessertDato: "2012.02.02"
    }
];

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (contract) => {
    return replaceAll(contract.Kontraktsnavn, ' ', '-');
};

class bulkupdateApi {
    static getAllBulkUpdates() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(Object.assign([], bulkupdates));
            }, delay);
        });
    }

    static saveContract(contract) {
        var cntrct = {
            Kontraktsnavn: contract.kontraktsnavn,
            Område: contract.område,
            Gyldighet: contract.gyldigfra + " - " + contract.gyldigtil
        };
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Simulate server-side validation
                const minContractNameLength = 1;
                if (cntrct.Kontraktsnavn.length < minContractNameLength) {
                    reject(`Kontraktsnavn must be at least ${minContractNameLength} characters.`);
                }
                if (cntrct.id) {
                    const existingContractIndex = bulkupdates.findIndex(a => a.id === contract.id);
                    bulkupdates.splice(existingContractIndex, 1, contract);
                } else {
                    //Just simulating creation here.
                    //The server would generate ids for new contracts in a real app.
                    //Cloning so copy returned is passed by value rather than by reference.
                    cntrct.id = generateId(cntrct);
                    bulkupdates.push(cntrct);
                }
                resolve(Object.assign({}, cntrct));
            }, delay);
        });
    }

    static deleteContract(contractId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const indexOfContractToDelete = bulkupdates.findIndex(contract => {
                    return contract.contractId === contractId;
                });
                bulkupdates.splice(indexOfContractToDelete, 1);
                resolve();
            }, delay);
        });
    }
}

export default bulkupdateApi;
