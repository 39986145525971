import { connect } from 'react-redux'
import HealthCheck from '../components/Layout/HealthCheck'
import { bindActionCreators } from "redux"
import { GetAutentiseringServiceinfoTilstand, GetBymeldingServiceinfoTilstand } from '../actions/HealthCheckAction'

const mapStateToProps = (state) =>
{
    return {
        servicesstatus: state.healthcheck
    }
}

const mapDispatchToProps = (dispatch) =>
{
    return bindActionCreators({ GetAutentiseringServiceinfoTilstand, GetBymeldingServiceinfoTilstand }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(HealthCheck)