import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import TextArea from './TextArea';

const AddCommentForm = ({
    showModal,
    modalTitle,
    kommentarTekst,
    onShow,
    handleChange,
    submittekst,
    error,
    onCancel,
}) => (
    <Modal show={showModal} onHide={onShow} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>
                <strong>{modalTitle}</strong>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TextArea
                error={error}
                label="Kommentar*"
                onChange={handleChange}
                value={kommentarTekst}
                name="tekst"
                rows="7"
                cols="70"
            />
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-link" onClick={onCancel}>
                Avbryt
            </button>
            <button className="btn btn-primary btn-lg" id="paddedButton" onClick={submittekst}>
                Endre status
            </button>
        </Modal.Footer>
    </Modal>
);

AddCommentForm.propTypes = {
    showModal: PropTypes.bool,
    modalTitle: PropTypes.string,
    kommentarTekst: PropTypes.string,
    onShow: PropTypes.func,
    onCancel: PropTypes.func,
    handleChange: PropTypes.func,
    submittekst: PropTypes.func,
    error: PropTypes.string,
};
export default AddCommentForm;
