import delay from "./delay"
import _ from "lodash"

let meldinger = []
_.map(_.range(1, 9), index =>
{
    let navn = "Search-0" + index
    meldinger.push({ id: index.toString(), navn: navn, filter: 'filter ' + index, standard: false })
})

class SavedMeldingSearchAPI
{

    static getSavedSearchMeldinger()
    {
        return new Promise((resolve, reject) =>
        {
            setTimeout(() =>
            {
                resolve(Object.assign([], meldinger));
            }, delay);
        });
    }
    static deleteSavedSearch(savedsearch)
    {
        const savedsearchId = savedsearch.id;
        return new Promise((resolve) =>
        {
            setTimeout(() =>
            {
                const indexOfObjectToDelete = meldinger.findIndex(s =>
                {
                    return s.id === savedsearchId;
                });
                meldinger.splice(indexOfObjectToDelete, 1);
                resolve(meldinger);
            }, delay);
        });
    }
    static addSavedSearch(savedsearch)
    {
        return new Promise((resolve) =>
        {
            setTimeout(() =>
            {
                meldinger.push(savedsearch)
                resolve(meldinger);
            }, delay);
        });
    }
}

export default SavedMeldingSearchAPI