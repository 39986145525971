import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ProcessCodeListRow from './ProcesscodeListRow';

const ProsessCodeList = ({ processcodes, onClick, onSubmit }) => {
    const sortProsesskoder = pkListe => {
        return pkListe.sort((a, b) => a.navn.localeCompare(b.navn, undefined, { numeric: true, sensitivity: 'base' }));
    };

    return (
        <div>
            <Row className="show-grid">
                <Col md={12}>
                    <table className="prosesskode-table table">
                        <thead>
                            <tr>
                                <th>Prosesskode</th>
                                <th>Beskrivelse</th>
                                <th>Enhet</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody className="prosesskode-table-tbody">
                            {sortProsesskoder(processcodes).map(processcode => (
                                <ProcessCodeListRow
                                    key={processcode.id}
                                    processcode={processcode}
                                    onClick={() => onClick(processcode)}
                                    onSubmit={e => onSubmit(e, processcode)}
                                />
                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div>
    );
};

ProsessCodeList.propTypes = {
    processcodes: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ProsessCodeList;
