import React from 'react';
import { useAuth } from 'react-oidc-context';

const LoginButton = (): JSX.Element => {
    const auth = useAuth();

    return (
        <div>
            <button className="btn btn-success" onClick={() => auth.signinRedirect()}>
                Logg inn
            </button>
        </div>
    );
};

export default LoginButton;
