import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../common/NorwegianDatepicker';
import classnames from 'classnames';

const Datepicker = ({ label, name, style, value, id, placeholder, onChange, showClearButton, error, useBody }) => {
    const extraProps = {};
    if (useBody) {
        extraProps.calendarContainer = document.body;
    }
    return (
        <div className={classnames('form-group', { 'has-error': error })}>
            {label && (
                <label className="control-label" htmlFor="date">
                    {label}
                </label>
            )}
            <DatePicker
                id={id}
                dateFormat="DD.MM.YYYY"
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                style={style}
                showClearButton={showClearButton}
                {...extraProps}
            />
            {error && <span className="help-block">{error}</span>}
        </div>
    );
};
Datepicker.defaultProps = {
    showClearButton: false,
};
Datepicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    showClearButton: PropTypes.bool,
    error: PropTypes.string,
    useBody: PropTypes.bool,
};
export default Datepicker;
