import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import AppContainer from './containers/App';
import AdminPage from './components/admin/AdminPage';
import Login from './containers/Account/Login';
import forgotpass from './containers/Account/ForgotPassword';
import resetpass from './containers/Account/ResetPassword';
import activateuser from './containers/Account/ActivateUser';
import forgotpasswordEpostrecieved from './components/Login/ForgotPasswordRequestReciept';
import UpdatePasswordConfirmed from './components/Login/UpdatePasswordConfirmed';
import EntrepreneursPage from './components/entrepreneur/EntrepreneursPage';
import EntrepreneurDetailsContainer from './containers/Entrepreneur/EntrepreneurDetailContainer';
import EntrepreneurAdminContracts from './containers/Entrepreneur/EntrepreneurAdminContracts';
import ContractDetailsContainer from './containers/Contract/ContractDetailContainer';
import EntrepreneurAdminContractPriser from './containers/Entrepreneur/EntrepreneurAdminContractPriser';
import ProcessCodeSchemesPage from './components/processcodescheme/ProcessCodeSchemesPage';
import ProcessCodeDetailsContainer from './containers/ProcessCode/ProcessCodeDetailContainer';
import ServiceAvdelingenContainer from './containers/Saksbehandler/SaksbehandlerContainer';
import notFoundPage from './components/Layout/notFoundPage';
import SaksbehandlingMeldingsDetail from './containers/Saksbehandler/SaksbehandlerMeldingDetails';
import SakbehandlingMeldinger from './containers/Saksbehandler/SakbehandlingMeldinger';
import * as routes from './constants/clientRoutes';
import { checkToken } from './utils/AuthorizedRouting';
import * as Authorize from './utils/Authorization';
import EntrepreneurMeldinger from './containers/Entrepreneur/EntrepreneurMeldinger';
import EntrepreneurMeldingsDetail from './containers/Entrepreneur/EntrepreneurMeldingDetails';
import GlobalRessursbrukContainer from './containers/Entrepreneur/GlobalRessursbrukContainer';
import Avtaler from './containers/Avtaler/avtalerListContainer';
import RapporterContainer from './containers/Rapporter/RapportersContainer';
import HealthCheck from './containers/HealthCheckContainer';
import Meldingseksport from './components/admin/Meldingeksport';

export const getRoutes = store => {
    return (
        <Route path={routes.home} component={AppContainer}>
            <IndexRedirect to={routes.login} />
            <Route path={routes.login} component={Login} onEnter={checkToken} />
            <Route path={routes.glemtpass} component={forgotpass} onEnter={checkToken} />
            <Route path={routes.resetpass} component={resetpass} />
            <Route path={routes.activateuser} component={activateuser} />
            <Route
                path={routes.forgotpasswordEpostrecieved}
                component={forgotpasswordEpostrecieved}
                onEnter={checkToken}
            />
            <Route path={routes.UpdatePasswordConfirmed} component={UpdatePasswordConfirmed} onEnter={checkToken} />
            <Route path={routes.selskaper} component={Authorize.entrepreneurs(EntrepreneursPage)} />
            <Route
                path={routes.entrepreneursDetail}
                component={Authorize.entrepreneurDetails(EntrepreneurDetailsContainer)}
            />
            <Route
                path={routes.selskapKontrakter}
                component={Authorize.selskapKontrakterDetail(EntrepreneurAdminContracts)}
            />
            <Route
                path={routes.selskapKontrakPriser}
                component={Authorize.selskapKontrakPriser(EntrepreneurAdminContractPriser)}
            />
            <Route path={routes.kontrakterDetail} component={Authorize.contractDetails(ContractDetailsContainer)} />
            <Route path={routes.prosessKodeSkjema} component={Authorize.processcodes(ProcessCodeSchemesPage)} />
            <Route path={routes.avtaler} component={Authorize.avtaler(Avtaler)} />
            <Route
                path={routes.prosessKodeSkjemaDetail}
                component={Authorize.processcodeDetails(ProcessCodeDetailsContainer)}
            />
            <Route path={routes.serviceAvdelingen} component={Authorize.bymiljoetaten(ServiceAvdelingenContainer)} />
            <Route
                path={routes.saksbehandlerMeldinger}
                component={Authorize.saksbehandlerMeldinger(SakbehandlingMeldinger)}
            />
            <Route
                path={routes.saksbehandlerMeldingsDetail}
                component={Authorize.saksbehandlerMeldingsDetail(SaksbehandlingMeldingsDetail)}
            />
            <Route path={routes.selskapBrukere} component={Authorize.selskapBrukere(ServiceAvdelingenContainer)} />
            <Route
                path={routes.entrepreneurMeldinger}
                component={Authorize.entrepreneurMeldinger(EntrepreneurMeldinger)}
            />
            <Route
                path={routes.entrepreneurMeldingsDetail}
                component={Authorize.entrepreneurMeldingsDetail(EntrepreneurMeldingsDetail)}
            />
            <Route
                path={routes.globalressursbruk}
                key="aha"
                component={Authorize.globalressursbruk(GlobalRessursbrukContainer)}
            />
            <Route path={routes.admin} component={Authorize.bymeldingAdmin(AdminPage)} />
            <Route path={routes.meldingseksport} component={Authorize.bymeldingAdmin(Meldingseksport)} />
            <Route path={routes.rapporter} component={RapporterContainer} />
            <Route name="Rapporter">
                <Route path={routes.fakturagrunnlagrapport} component={RapporterContainer} />
                <Route path={routes.meldingsstatistikkrapport} component={RapporterContainer} />
                <Route path={routes.monedsrapportavviksarbeidrapport} component={RapporterContainer} />
                <Route path={routes.monedsrapportmateriellrapport} component={RapporterContainer} />
                <Route path={routes.ressursforbrukrapport} component={RapporterContainer} />
                <Route path={routes.innmeldingerrapport} component={RapporterContainer} />
                <Route path={routes.avvistemeldingerrapport} component={RapporterContainer} />
                <Route path={routes.vinterforbrukfakturagrunnlag} component={RapporterContainer} />
                <Route path={routes.vinterforbrukokonomi} component={RapporterContainer} />
                <Route path={routes.dagmulktrapport} component={RapporterContainer} />
                <Route path={routes.gatelysmonedsrapporter} component={RapporterContainer} />
            </Route>
            <Route path={routes.nedtid} component={HealthCheck} />
            <Route path="*" component={notFoundPage} />
        </Route>
    );
};
