import InitialState from "./initialState"
import * as types from "../actions/ActionTypes"

const meldingerReducer=(state=InitialState.meldinger, action={})=>{

    switch(action.type){        
        case types.LOAD_MELDINGER_COUNT:
            return {...state, meldingcounter:action.count}
        case types.LOAD_MELDINGER_SUCCESS:
            return{...state,meldingerList:action.meldinger}
        
        case types.LOAD_MELDING_BY_ID_SUCCESS:
            return{...state,melding:action.melding}
        
        case types.UPDATE_MELDING_BY_ID_SUCCESS:           
            return {...state, melding:action.melding}
        
        case types.LOAD_MEDLING_STATUSER_SUCCESS:
            return {...state,meldingsstatuser:action.meldingsstatuser}
        
        case types.LOAD_MELDING_TYPER_SUCCESS:
            return {...state, meldingstyper:action.meldingstyper}
        
        case types.LOAD_SONER_SUCCESS:
            return { ...state,meldingssoner:action.meldingssoner}
        
        case types.LOAD_ALL_ENTREPRENEURS:
            return {...state,entrepreneurs:action.entrepreneurs}
        case types.LOAD_CATEGORI_GROUPS_SUCCESS:
            return {...state,  kategorigrupper: action.kategorigrupper}

        case types.SET_SELSKAPSID_FOR_USER:
            return {...state, selskapId:action.selskap.selskapId}
        default:
            return state
    }
}
export default meldingerReducer