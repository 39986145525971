import React from 'react';
import PropTypes from 'prop-types';
import * as cookieHandler from '../utils/cookieHandler';
import Login from '../containers/Account/Login';
import _ from 'lodash';
import { UserIsNotAuthorized } from '../constants/ResponseMessages';

//wrapped component: each route component is wrapped with allowedRoles commponent
const AuthorizationHandler = allowedRoles => ComponentToRoute => {
    class AuthorizedComponent extends React.Component {
        render() {
            const jwt = cookieHandler.getAccessToken(); // cookieHandler.validateToken();
            if (!jwt || !cookieHandler.validateToken()) {
                return <Login {...this.props} />;
            } else {
                // cookieHandler.SetAuthorizationToken();
                //const roles = cookieHandler.UsersRoleAndProcessroleRoles();
                const roles = jwt.roles;
                //console.log("roles", roles)
                //console.log("allowed roles", allowedRoles)
                if (_.intersection(allowedRoles, roles).length > 0) {
                    return <ComponentToRoute {...this.props} />;
                } else {
                    return (
                        <div className="well">
                            <center>{UserIsNotAuthorized}</center>
                        </div>
                    );
                }
            }
        }
    }
    AuthorizedComponent.contextTypes = {
        router: PropTypes.object,
    };
    return AuthorizedComponent;
};
export default AuthorizationHandler;
