import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../../css/common/FlashMessages.css';

class FlashMessage extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            visibile: true,
        };
    }

    componentDidMount() {
        setTimeout(() => this.props.deleteFlashMessage(this.props.message.id), 7000);
    }

    onClick() {
        this.props.deleteFlashMessage(this.props.message.id);
    }

    render() {
        const { type, text } = this.props.message;
        const className = classnames('alert', {
            'alert-success': type === 'success',
            'alert-danger': type === 'error',
        });
        return (
            <div>
                {this.state.visibile && (
                    <div className={className}>
                        <button onClick={this.onClick} className="close">
                            <span>&times;</span>
                        </button>
                        {text}
                    </div>
                )}
            </div>
        );
    }
}
FlashMessage.propTypes = {
    message: PropTypes.object.isRequired,
    deleteFlashMessage: PropTypes.func.isRequired,
};

export default FlashMessage;
