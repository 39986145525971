import { SET_CURRENT_SERVICE_INFO,LOAD_ORG_INFO } from '../actions/ActionTypes';
import InitialState from './initialState';

export default function commonReducer(state = InitialState.common, action = {})
{

    switch (action.type)
    {
        case SET_CURRENT_SERVICE_INFO:
            return {
                serviceinfo: action.service
            }
        case LOAD_ORG_INFO:
            return {...state,organisasjon:action.org}
        default:
            return state;
    }
}