import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

// eslint-disable-next-line import/prefer-default-export
export function delbydelerReducer(state = InitialState.delbydeler, action) {
    switch (action.type) {
        case types.LOAD_DELBYDELER_LIST_SUCCESS:
          return action.delbydeler;

        default:
            return state;
    }
}
