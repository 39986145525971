import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlashMessage from '../../containers/common/FlashMessage';
import { deleteFlashMessage } from '../../actions/FlashMessagesAction';

class FlashMessagesList extends Component {
    render() {
        return (
            <div>
                {this.props.messages.map(message => (
                    <FlashMessage
                        key={message.id}
                        message={message}
                        deleteFlashMessage={this.props.deleteFlashMessage}
                    />
                ))}
            </div>
        );
    }
}

FlashMessagesList.propTypes = {
    messages: PropTypes.array.isRequired,
    deleteFlashMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        messages: state.flashMessages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteFlashMessage: bindActionCreators(deleteFlashMessage, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessagesList);
