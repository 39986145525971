import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from "react-router"
import * as routes from '../../constants/clientRoutes';
import AnchorLink from '../common/AnchorLink';
import * as cookieHandler from '../../utils/cookieHandler';
import StatusIcon from '../common/StatusIcon';
import { formatDateTimeHavingKL } from '../../utils/utils';
import { getTidsfristClass } from '../../utils/meldingUtils';

const LinkToSelskap = (enterpreneurId, selskapNavn) => {
    if (selskapNavn === '') return '-';
    if (cookieHandler.UserIsEntrepreneurAdmin()) {
        return (
            <AnchorLink
                to={routes.selskapKontrakter}
                pageTitle={routes.selskapKontrakter}
                pageLink={routes.selskapKontrakter}
                children={selskapNavn}
                linkLabel={selskapNavn}
            />
        );
    }
    return selskapNavn;
};
const LinkToKontrakt = (kontraktId, selskapNavn, kontraktNavn, enterpreneurId) => {
    if (!kontraktId || kontraktId === '0') return '-';
    if (cookieHandler.UserIsEntrepreneurAdmin()) {
        return (
            <AnchorLink
                to={routes.selskapKontrakter + '/' + enterpreneurId + routes.kontrakter + '/' + kontraktId}
                pageLink={routes.selskapKontrakter}
                pageTitle={selskapNavn}
                children={kontraktNavn}
                linkLabel={kontraktNavn}
            />
        );
    }
    return kontraktNavn;
};

const MeldingDetailHeader = ({
    innsenderNavn,
    opprettetAv,
    innsenderEpost,
    lopeNummer,
    mottattDato,
    enterpreneurId,
    selskapNavn,
    meldingsStatusId,
    kontraktNavn,
    kontraktId,
    avtaleNavn,
    data,
    tidsfrist,
    address,
    GPSCoordinate,
    isEntreprenor,
}) => {
    const tidsfristDato = tidsfrist && tidsfrist.length > 0 ? formatDateTimeHavingKL(tidsfrist) : '-';
    const tidsfristClass = tidsfrist ? getTidsfristClass(meldingsStatusId, tidsfrist) : '';

    return (
        <div className="topBannerSendingMelding col-xs-12">
            <div className="col-md-4">
                {!isEntreprenor ? (
                    <div>
                        <p>
                            <span className="col-md-3">Innsender: </span>
                            <strong id="sendmeldingStrongmeldingId">{innsenderNavn || '-'}</strong>
                        </p>
                        <p>
                            <span className="col-md-3">
                                <nobr> Opprettet-av:</nobr>{' '}
                            </span>
                            <strong id="sendmeldingStrongmeldingId">
                                <a href={`mailto:${opprettetAv}`}>{opprettetAv !== '' ? opprettetAv : '-'}</a>
                            </strong>
                        </p>
                        <p>
                            <span className="col-md-3">E-post: </span>
                            <strong id="sendmeldingStrongmeldingId">
                                <a href={`mailto:${innsenderEpost}`}>{innsenderEpost || '-'}</a>
                            </strong>
                        </p>
                    </div>
                ) : (
                    <div>
                        <p>
                            <span className="col-md-3">
                                <nobr> Opprettet-av:</nobr>{' '}
                            </span>
                            <strong id="sendmeldingStrongmeldingId">{opprettetAv !== '' ? opprettetAv : '-'}</strong>
                        </p>
                        <p>
                            <span className="col-md-3">E-post: </span>
                            <strong id="sendmeldingStrongmeldingId">{innsenderEpost || '-'}</strong>
                        </p>
                    </div>
                )}
                <p>
                    <span className="col-md-3">Sted: </span>
                    <strong id="sendmeldingStrongmeldingId">{address}</strong>
                </p>
            </div>
            <div className="col-md-4">
                <p>
                    <span className="col-md-3">Meldingsnr.: </span>
                    <strong id="sendmeldingStrongmeldingId">{lopeNummer}</strong>
                </p>
                <p>
                    <span className="col-md-3">Mottatt: </span>
                    <strong id="sendmeldingStrongmeldingId">{mottattDato}</strong>
                </p>
                <p>
                    <span className="col-md-3">Entreprenør: </span>
                    <strong id="sendmeldingStrongmeldingId">{LinkToSelskap(enterpreneurId, selskapNavn)}</strong>
                </p>
                <p>
                    <span className="col-md-3">GPS: </span>
                    <strong id="sendmeldingStrongmeldingId">{GPSCoordinate}</strong>
                </p>
            </div>
            <div className="col-md-4">
                <div>
                    <div className="meldingsdetaljer-section">
                        <span className="meldingsdetaljer-section-label">Status:</span>
                        <StatusIcon statusId={meldingsStatusId} />
                    </div>
                </div>
                <div>
                    <div className="meldingsdetaljer-section">
                        <span className="meldingsdetaljer-section-label">Kontrakt:</span>
                        <strong id="sendmeldingStrongmeldingId">
                            {LinkToKontrakt(kontraktId, selskapNavn, kontraktNavn, enterpreneurId)}
                        </strong>
                    </div>
                </div>
                <div>
                    <div className="meldingsdetaljer-section">
                        <span className="meldingsdetaljer-section-label">Avtale:</span>
                        <strong id="sendmeldingStrongmeldingId">{avtaleNavn || '-'}</strong>
                    </div>
                </div>
                {tidsfrist && tidsfrist.length > 0 && (
                    <div>
                        <div className="meldingsdetaljer-section">
                            <span className="meldingsdetaljer-section-label">Tidsfrist:</span>
                            <strong className={tidsfristClass}>{tidsfristDato}</strong>
                        </div>
                    </div>
                )}
                {data && data.length > 0 && (
                    <div>
                        <div className="meldingsdetaljer-section">
                            <span className="meldingsdetaljer-section-label">Ekstra data:</span>
                            <strong>{data || ''}</strong>
                        </div>
                    </div>
                )}
            </div>
            <div className="clearfix" />
        </div>
    );
};

MeldingDetailHeader.propTypes = {
    innsenderNavn: PropTypes.string,
    opprettetAv: PropTypes.string,
    innsenderEpost: PropTypes.string,
    lopeNummer: PropTypes.string,
    mottattDato: PropTypes.string,
    enterpreneurId: PropTypes.string,
    selskapNavn: PropTypes.string,
    meldingsStatusNavn: PropTypes.string,
    meldingsStatusId: PropTypes.number,
    kontraktNavn: PropTypes.string,
    kontraktId: PropTypes.string,
    avtaleNavn: PropTypes.string,
    data: PropTypes.string,
    tidsfrist: PropTypes.string,
    GPSCoordinate: PropTypes.string,
    address: PropTypes.string,
    isEntreprenor: PropTypes.bool,
};
export default MeldingDetailHeader;
