import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RootCloseWrapper } from 'react-overlays';
import 'rc-time-picker/assets/index.css';
import './EditableRessursbruk.css';

class EditableRessursbrukTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newValue: null,
            editActive: false,
        };
    }

    cancel = () => {
        this.setState({ editActive: false, newValue: null });
    };

    save = () => {
        const { onSubmitTid, ressursbruk } = this.props;
        const { newValue } = this.state;
        onSubmitTid(newValue, ressursbruk);
        this.setState({ editActive: false, newValue: null });
    };

    activate = () => {
        const { value } = this.props;
        this.setState({ editActive: true, newValue: value });
    };

    change = e => {
        const { value } = this.props;
        const time = moment(e.target.value, 'HH:mm');
        const date = moment(value);
        date.set({ hour: time.get('hour'), minute: time.get('minute') });
        this.setState({ newValue: moment(date).format() });
    };

    render() {
        const { value, isEditable } = this.props;
        const { editActive, newValue } = this.state;

        return !editActive ? (
            <Button
                className="editable-ressursbruk-activate-button editable-ressursbruk-activate-button-time"
                disabled={!isEditable}
                onClick={this.activate}
            >
                {`${moment(value).format('HH:mm')}`}
            </Button>
        ) : (
            <RootCloseWrapper onRootClose={this.cancel}>
                <form className="editable-ressursbruk editable-ressursbruk-time">
                    <input
                        type="time"
                        id="editable-ressursbruk-time"
                        name="editable-ressursbruk-time"
                        onChange={this.change}
                        autoFocus
                        value={moment(newValue).format('HH:mm')}
                    />
                    <Button
                        id="save"
                        bsSize="small"
                        type="submit"
                        onClick={this.save}
                    >
                        <Glyphicon glyph="ok" className="button-save" />
                    </Button>
                    <Button id="save" bsSize="small" onClick={this.cancel}>
                        <Glyphicon glyph="remove" className="button-cancel" />
                    </Button>
                </form>
            </RootCloseWrapper>
        );
    }
}

EditableRessursbrukTime.propTypes = {
    value: PropTypes.string,
    isEditable: PropTypes.bool,
    onSubmitTid: PropTypes.func.isRequired,
    ressursbruk: PropTypes.shape({ ressursforbrukId: PropTypes.string }),
};

EditableRessursbrukTime.defaultProps = {
    value: '',
    isEditable: false,
    ressursbruk: {},
};

export default EditableRessursbrukTime;
