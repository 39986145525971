/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { TileLayer, WMSTileLayer, LayersControl } from 'react-leaflet';
import _ from 'lodash';
import {
  kartverketBaseMapUrl,
  kartverketBaseMapAttribution,
  sateliteBaseMap,
  kartlag,
  format,
  OpenStreetContributors,
  openStreetMapLayer,
  bymGrunnkart,
  basemapOptions,
} from '../../constants/kartGlobals';

class KartlagControl extends React.Component {
  render() {
    const {
      grunnkart,
      checkboxes,
      position,
      setStatkartAvailable,
    } = this.props;
    return (
      <LayersControl
        className="basmeap-layers-toggle-control"
        position="bottomright"
      >
        <LayersControl.BaseLayer
          key="grunnkart"
          name="Grunnkart"
          checked={grunnkart === basemapOptions.grunnkart}
        >
          <TileLayer
            url={kartverketBaseMapUrl}
            ontileerror={() => setStatkartAvailable(false)}
            attribution={kartverketBaseMapAttribution}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer
          key="detaljert_grunnkart"
          name="Detaljert grunnkart"
          checked={grunnkart === basemapOptions.detaljertGrunnkart}
        >
          <TileLayer
            url={openStreetMapLayer}
            attribution={OpenStreetContributors}
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer
          key="satelittkart"
          name="satelittkart"
          checked={grunnkart === basemapOptions.satelittkart}
        >
          <TileLayer
            url={sateliteBaseMap.url}
            ontileerror={() => setStatkartAvailable(false)}
            getMatrix={sateliteBaseMap.getMatrix}
            attribution={sateliteBaseMap.attribution}
          />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay
          key="gatenavn"
          checked={grunnkart === basemapOptions.satelittkart}
        >
          <WMSTileLayer
            key="gatenavn-2"
            url="https://openwms.statkart.no/skwms1/wms.vegnett"
            layers="vegnavn"
            format={format}
            transparent
          />
        </LayersControl.Overlay>

        <LayersControl.BaseLayer
          key={basemapOptions.bymBakgrunnskart}
          name={basemapOptions.bymBakgrunnskart}
          checked={grunnkart === basemapOptions.bymBakgrunnskart}
        >
          <TileLayer
            url={bymGrunnkart.url}
            attribution={bymGrunnkart.attribution}
            resourceType="tile"
            style="default"
          />
        </LayersControl.BaseLayer>

        {checkboxes.map(e => {
          const lag = _.find(kartlag, l => l.layerId === e);
          if (lag) {
            const layerGroup = lag.layerId.split(',');
            if (layerGroup.length > 1) {
              return layerGroup.map(l => (
                <LayersControl.Overlay key={l} checked>
                  <WMSTileLayer
                    key={lag.layerId}
                    url={lag.url}
                    layers={l.trim()}
                    format={format}
                    transparent
                    opacity={lag.opacity ? lag.opacity : 1}
                  />
                </LayersControl.Overlay>
              ));
            }

            return (
              <LayersControl.Overlay key={lag.layerId} checked>
                <WMSTileLayer
                  key={lag.layerId}
                  url={lag.url}
                  layers={lag.layerId}
                  format={format}
                  transparent
                  opacity={lag.opacity ? lag.opacity : 1}
                  crs={lag.crs || undefined}
                  styles={lag.styles || ''}
                />
              </LayersControl.Overlay>
            );
          }
        })}
      </LayersControl>
    );
  }
}

KartlagControl.propTypes = {
  grunnkart: PropTypes.string.isRequired,
  checkboxes: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  setStatkartAvailable: PropTypes.func.isRequired,
};

export default KartlagControl;
