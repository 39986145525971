import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Modal } from 'react-bootstrap';
//import Modal from "react-bootstrap-modal"
import AvtaleSelectDropdownList from '../user/AvtaleSelectDropdownList';
import * as validationErrors from '../../constants/ValidationErrors';

class AddAvtalerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedAvtaler: [],
            errors: {},
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.onChangeAvtale = this.onChangeAvtale.bind(this);
        this.submit = this.submit.bind(this);
    }

    toggleModal() {
        this.setState({ open: !this.state.open, errors: {} });
    }
    onChangeAvtale(values) {
        this.setState({ selectedAvtaler: values });
    }
    isValid() {
        const selectedAvtaler = this.state.selectedAvtaler;
        let validated = true;
        let errors = {};
        if (selectedAvtaler.length === 0) {
            validated = false;
            errors.selectedAvtaler = validationErrors.avtaleIsRequired;
            this.setState({ errors });
        }
        return validated;
    }
    submit(e) {
        e.preventDefault();
        if (this.isValid()) {
            this.props.submitAvtaler(this.state.selectedAvtaler);
            this.setState({ open: false, selectedAvtaler: [] });
        }
    }

    render() {
        const { avtaler } = this.props;
        const { selectedAvtaler, errors } = this.state;
        return (
            <div>
                <div>
                    <button className="bym-add-btn btn btn-default" onClick={this.toggleModal}>
                        <span className="glyphicon glyphicon-plus glyphiconplus" id="glyphiconplus"></span>{' '}
                        <span>Legg til avtaler</span>
                    </button>
                </div>
                <Modal show={this.state.open} onHide={this.toggleModal}>
                    <Modal.Body>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h2>
                                    <strong>Legg til avtaler </strong>
                                </h2>{' '}
                            </div>
                            <div className="panel-body">
                                <FormGroup id="AvtaleSelectDropdownList">
                                    <AvtaleSelectDropdownList
                                        selectedValues={selectedAvtaler}
                                        onChange={this.onChangeAvtale}
                                        avtaler={avtaler}
                                        error={errors.selectedAvtaler}
                                    />
                                </FormGroup>
                                <div className="form-group">
                                    <button className="btn btn-success pull-right" onClick={this.submit}>
                                        {' '}
                                        Legg til{' '}
                                    </button>
                                    <button className="btn btn-link pull-right" onClick={this.toggleModal}>
                                        Avbryt
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

AddAvtalerModal.propTypes = {
    avtaler: PropTypes.array.isRequired,
    submitAvtaler: PropTypes.func.isRequired,
};

export default AddAvtalerModal;
