import { toNorwegianDate } from '../../utils/datepickerHelper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDownList from '../common/DropDownList';
import Spinner from 'react-spinkit';
import FilterDatepicker from '../common/FilterDatepicker';

class FilterRessursbruk extends Component {
    
    componentDidMount() {
        const { filter, updateFilter } = this.props;
        updateFilter({ ...filter });
    };

    render() {
        const { prosesskoder, filter, updateFilter } = this.props;
        return (
            <div>
                <div className="filterdRessursbruk">
                    <div>
                        <h3>Oversikt over ressursforbruk</h3>
                    </div>
                    <div className="ressursbruk-filter-container">
                        <div className="ressursbruk-filter-element">
                            <label htmlFor="ressursFilterFraDato">
                                Fra dato
                            </label>
                            <FilterDatepicker
                                id="ressursFilterFraDato"
                                className="no-border-right datepicker-cell"
                                onChange={e =>
                                    updateFilter({
                                        ...filter,
                                        datoFra: toNorwegianDate(
                                            e
                                        ).toISOString(),
                                    })
                                }
                                showClearButton
                                placeholder="Dato f.o.m"
                                value={filter && filter.datoFra}
                                useBody
                            />
                        </div>
                        <div className="ressursbruk-filter-element">
                            <label htmlFor="ressursFilterTilDato">
                                Til dato
                            </label>
                            <FilterDatepicker
                                id="ressursFilterTilDato"
                                className="no-border-right datepicker-cell"
                                onChange={e =>
                                    updateFilter({
                                        ...filter,
                                        datoTil: toNorwegianDate(
                                            e
                                        ).toISOString(),
                                    })
                                }
                                placeholder="Dato t.o.m"
                                value={filter && filter.datoTil}
                                showClearButton
                                useBody
                                tilOgMed
                            />
                        </div>
                        <div className="ressursbruk-filter-element input-group ">
                            <label htmlFor="FilterProsesskoderSearch">
                                Søk i prosesskoder
                                <div className="ressursbruk-filter-datepicker-input-group">
                                    <input
                                        type="text"
                                        className="form-control filter-prosesskoder-search"
                                        style={{ borderRight: 'none' }}
                                        onChange={e => {
                                            updateFilter({
                                                ...filter,
                                                prosesskodeSok: e.target.value,
                                            });
                                        }}
                                        value={filter.prosesskodeSok}
                                        name="FilterProsesskoderSearch"
                                    />
                                    <button
                                        disabled={filter.prosesskodeSok === ''}
                                        onClick={e => {
                                            updateFilter({
                                                ...filter,
                                                prosesskodeSok: '',
                                            });
                                        }}
                                        className="input-group-addon"
                                    >
                                        <span>×</span>
                                    </button>
                                </div>
                            </label>
                            {/* 
                            <DropDownList
                                name="FilteredProsesskodeId"
                                selected={filter && filter.prosesskode}
                                title="Alle prosesskoder"
                                id="FilterProsesskoderDropDownList"
                                Items={prosesskoder}
                                onChange={e => updateFilter({...filter, prosesskode:  e.target.value})}
                            /> 
                            */}
                        </div>
                        {filter.isLoading && (
                            <Spinner
                                spinnerName="circle"
                                style={{
                                    width: '46px',
                                    height: '46px',
                                    margin: '20px auto',
                                }}
                                noFadeIn={true}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

FilterRessursbruk.contextTypes = {
    router: PropTypes.object,
};

FilterRessursbruk.defaultProps = {
    filter: null,
    updateFilter: null,
};

FilterRessursbruk.propTypes = {
    prosesskoder: PropTypes.array,
    filterFunction: PropTypes.func.isRequired,
    kontraktId: PropTypes.string.isRequired,
    filter: PropTypes.shape({}),
    updateFilter: PropTypes.func,
};

export default FilterRessursbruk;
