import _ from 'lodash';
import * as types from './ActionTypes';
import * as api from '../constants/api';
import { successData } from '../utils/utils';
import * as cookieHandler from '../utils/cookieHandler';
import { NewMessageIsAdded, NewMessageIsNotAdded } from '../constants/ResponseMessages';
import { addFlashMessage } from './FlashMessagesAction';

function loadMeldingerCounter(count) {
    return { type: types.LOAD_MELDINGER_COUNT, count };
}

function loadMeldingerSuccess(meldinger) {
    return { type: types.LOAD_MELDINGER_SUCCESS, meldinger };
}
function loadServiceavdelingMeldingByIdSucess(melding) {
    return { type: types.LOAD_MELDING_BY_ID_SUCCESS, melding };
}
function loadKatagorigrupper(kategorigrupper) {
    return { type: types.LOAD_CATEGORI_GROUPS_SUCCESS, kategorigrupper };
}
function loadMeldingsTyperSuccess(meldingstyper) {
    return { type: types.LOAD_MELDING_TYPER_SUCCESS, meldingstyper };
}
function loadAvtalerSuccess(meldingssoner) {
    return { type: types.LOAD_SONER_SUCCESS, meldingssoner };
}
function loadMeldingsStatusSuccess(meldingsstatuser) {
    return { type: types.LOAD_MEDLING_STATUSER_SUCCESS, meldingsstatuser };
}
function loadEntrepreneuresSuccess(entrepreneurs) {
    return { type: types.LOAD_ALL_ENTREPRENEURS, entrepreneurs };
}

function loadSelskapsIdForLoggedUser(selskap) {
    return { type: types.SET_SELSKAPSID_FOR_USER, selskap };
}

export function getSelskapsIdForLoggedUser() {
    const userId = cookieHandler.UserId();
    const url = api.getselskapsIdForUserUrl(userId);
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const data = successData(res);
            dispatch(loadSelskapsIdForLoggedUser(data));
            return data.selskapId;
        });
    };
}

export function loadServiceavdelingerMeldinger(filter, whenLoaded) {
    const url = api.getFilteredMeldingerForServiceavdelingUrl(filter);
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const result = successData(res);
            const { meldinger } = result;
            const count = result.totaltAntall;
            dispatch(loadMeldingerCounter(count));
            dispatch(loadMeldingerSuccess(meldinger));
            whenLoaded();
        });
    };
}

export function getImageById(imageId) {
    const url = api.imageUrl(imageId);
    return api.getClient().get(url);
}
export function loadServiceavdelingerMeldingById(meldingId) {
    const url = api.serviceAvdelingMeldingByIdUrl(meldingId);
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const melding = successData(res);
            dispatch(loadServiceavdelingMeldingByIdSucess(melding));
            return melding;
        });
    };
}
export function loadEntrepreneurMelding(selskapId, meldingId) {
    const url = api.getEntrepreneursMeldingByIdUrl(selskapId, meldingId);
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const melding = successData(res);
            dispatch(loadServiceavdelingMeldingByIdSucess(melding));
            return melding;
        });
    };
}
export function PutServiceavdelingerMeldingById(meldingId, melding) {
    const url = api.PutserviceAvdelingMeldingByIdUrl(meldingId);
    return dispatch => {
        return api.getClient().put(url, melding).then(res => {
            // const data = successData(res)
            return res;
        });
    };
}
export function PutEntrepreneurMelding(selskapId, meldingId, melding) {
    const url = api.PutEntrepreneuMeldingByIdUrl(selskapId, meldingId);
    return dispatch => {
        return api.getClient().put(url, melding).then(res => {
            // const data = successData(res)
            return res;
        });
    };
}
// sending image file in post
export function PostNewMelding(melding) {
    const url = api.PostNewMeldingUrl;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return dispatch => {
        return api.getClient()
            .post(url, melding, config)
            .then(() => {
                // const data = successData(res)
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: NewMessageIsAdded,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text: NewMessageIsNotAdded,
                    })
                );
            });
    };
}

export function loadEntrepreneurMeldinger(selskapsId, filter, whenLoaded) {
    const url = api.getFilteredMeldingerForEntrepreneurs(selskapsId, filter);
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const result = successData(res);
            const { meldinger } = result;
            const count = result.totaltAntall;
            dispatch(loadMeldingerCounter(count));
            dispatch(loadMeldingerSuccess(meldinger));
            whenLoaded();
        });
    };
}
// export function loadSamarbeidspartnereMeldinger(selskapsId, filter) {
//     const url = api.getFilteredMeldingerForEntrepreneurs(selskapsId, filter);
//     return dispatch => {
//         return axios.get(url).then(res => {
//             const meldinger = successData(res);
//             dispatch(loadMeldingerSuccess(meldinger));
//         });
//     };
// }

export function loadAvtaler() {
    const url = api.avtaler;
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const meldingsavtaler = successData(res);
            dispatch(loadAvtalerSuccess(meldingsavtaler));
        });
    };
}

export function loadkatagoriGroups() {
    const url = api.meldingkatagorigrupper;
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const kg = successData(res);
            dispatch(loadKatagorigrupper(kg));
        });
    };
}
export function loadMeldingstyper() {
    const url = api.meldingstyper;

    return dispatch => {
        return api.getClient().get(url).then(res => {
            const meldingstyper = successData(res);

            // meldingstyper synlige for publikum blir filtrert i SøkService, bruk dette til å sette et synlig-flagg
            return api.getClient().get(api.meldingstyperSynligeForPublikum('')).then(mt => {
                const publikumsmeldingstyper = _.map(meldingstyper, item => {
                    const synligForPublikum =
                        mt.data.result.filter(e => {
                            return e.id === item.meldingstypeId;
                        }).length > 0;

                    return {
                        ...item,
                        synligForPublikum,
                    };
                });
                dispatch(loadMeldingsTyperSuccess(publikumsmeldingstyper));
            });
        });
    };
}

export function loadMeldingsstatuser() {
    const url = api.meldingstatuser;
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const meldingsstatuser = successData(res);
            const status = _.map(meldingsstatuser, i => {
                if (i.id === 1) i.navn = 'Ikke tildelt';
                return i;
            });
            dispatch(loadMeldingsStatusSuccess(status));
        });
    };
}
export function loadEntrepreneurs() {
    const url = api.entreprenorerBaseUrl;
    return dispatch => {
        return api.getClient().get(url).then(res => {
            const entrepreneurs = successData(res);
            dispatch(loadEntrepreneuresSuccess(entrepreneurs));
        });
    };
}

export function sendMeldingToRoute(meldingId) {
    const url = api.routeMeldingerUrl(meldingId);
    return dispatch => {
        return api.getClient().put(url);
    };
}

export function addDokumentasjonToMelding(meldingId, dokumenter, selskapId) {
    let form = new FormData();
    dokumenter.forEach(dokument => form.append('dokumentasjon', dokument));

    const url = api.dokumentasjonMeldingerUrl(meldingId);
    return dispatch => {
        return api.getClient()
            .put(url, form)
            .then(() => {
                if (selskapId) dispatch(loadEntrepreneurMelding(selskapId, meldingId));
                else dispatch(loadServiceavdelingerMeldingById(meldingId));
            })
            .catch(() => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text: 'Noe gikk galt ved bildeopplasiting',
                    })
                );
            });
    };
}
