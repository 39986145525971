import * as types from '../actions/ActionTypes';
import InitialState from './initialState';
// const initialState = {
//     entreprenorer: [],
//     entreprenor: {}

// }
export function entrepreneurReducer(state = InitialState.entreprenorer, action)
{
    switch (action.type)
    {
        case types.LOAD_ENTREPRENEURS_SUCCESS:
            return action.selskaper
        // case types.LOAD_ENTREPRENEUR_SUCCESS:
        //     return { entreprenor: action.selskap }
        case types.CREATE_ENTREPRENEUR_SUCCESS:
            return [...state, Object.assign({}, action.selskap)]

        case types.UPDATE_ENTREPRENEUR_SUCCESS:
            return [
                ...state.filter(entrepreneur => entrepreneur.id !== action.selskap.id),
                Object.assign({}, action.selskap)
            ];
        default:
            return state;
    }
}

export function saksbehandlereReducer(state = InitialState.saksbehandlere, action)
{
    switch (action.type)
    {
        case types.LOAD_SAKSBEHANDLERE_SUCCESS:
            return action.selskaper;

        default:
            return state;
    }
}