import {
    GetRessursforbrukRapportByContractIdUrl,
    GetRessursforbrukRapportByContractIdUrlOld,
    GetFakturagrunnlagRapportByContractIdUrl,
    GetFakturagrunnlagRapportByContractIdUrlOld,
    GetVinterForbrukGrunnlagRapportUrl,
    GetVinterForbrukGrunnlagRapportUrlOld,
    GetAvvisteMeldingerRapportForServiceAvdelingUrl,
    GetAvvisteMeldingerRapportForServiceAvdelingUrlOld,
    GetInnmeldingerRapportForServiceAvdelingUrl,
    GetInnmeldingerRapportForServiceAvdelingUrlOld,
    GetMeldingsstatistikkRapportUrl,
    GetGatelysMonedsrapportUrl,
    GetMonedsrapportmateriellrapportUrl,
    GetMonedsrapportavviksarbeidrapportUrl,
    GetDagmulktRapportUrl,
    GetVinterForbrukOkonomiRapportUrl,
} from '../constants/api';
import { LOAD_RAPPORTER_LIST } from './ActionTypes';
import { addFlashMessage } from './FlashMessagesAction';
import { errorData, ParametersSerializer } from '../utils/utils';
import { mock } from '../constants/settings';
import * as api from '../constants/api';
import mockData from '../Api_MockData/mockRapporterApi';
import {
    GeneralRapportError,
    MeldingsstatistikkRapportError,
    ressursforbrukRapportError,
    vinterRapportError,
} from '../constants/ResponseMessages';

const LoadRapporterSuccess = data => {
    return { type: LOAD_RAPPORTER_LIST, data };
};
export const GetFakturagrunnlagRapport = (payload, useOld) => {
    const url = useOld ? GetFakturagrunnlagRapportByContractIdUrlOld : GetFakturagrunnlagRapportByContractIdUrl;
    if (mock) {
        return dispatch => {
            return api.getClient()
                .get(url, {
                    params: payload,
                    responseType: 'arraybuffer',
                    paramsSerializer: () => ParametersSerializer(payload),
                })
                .then(res => {
                    return res;
                })
                .catch(err => {
                    const errorMessage = errorData(err);
                    dispatch(addFlashMessage({ type: 'error', text: errorMessage }));
                });
        };
    } else {
        return dispatch => {
            mockData.getRapporter().then(res => {
                dispatch(LoadRapporterSuccess(res));
            });
        };
    }
};

export const GetRessursforbrukRapport = (payload, useOld) => {
    const url = useOld ? GetRessursforbrukRapportByContractIdUrlOld : GetRessursforbrukRapportByContractIdUrl;
    return dispatch => {
        return api.getClient()
            .get(url, {
                params: payload,
                responseType: 'arraybuffer',
                paramsSerializer: () => ParametersSerializer(payload),
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(
                    addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : ressursforbrukRapportError })
                );
            });
    };
};

export const GetAvvisteMeldingerRapport = (payload, useOld) => {
    const url = useOld
        ? GetAvvisteMeldingerRapportForServiceAvdelingUrlOld
        : GetAvvisteMeldingerRapportForServiceAvdelingUrl;
    return dispatch => {
        return api.getClient()
            .get(url, { params: payload, responseType: 'arraybuffer' })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : GeneralRapportError }));
            });
    };
};
export const GetInnMeldingerRapport = (payload, useOld) => {
    const url = useOld ? GetInnmeldingerRapportForServiceAvdelingUrlOld : GetInnmeldingerRapportForServiceAvdelingUrl;
    return dispatch => {
        return api.getClient()
            .get(url, { params: payload, responseType: 'arraybuffer' })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : GeneralRapportError }));
            });
    };
};
export const GetMeldingsstatistikkRapport = payload => {
    const url = GetMeldingsstatistikkRapportUrl;
    return dispatch => {
        return api.getClient()
            .get(url, { params: payload, responseType: 'arraybuffer' })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text: errorMessage ? errorMessage : MeldingsstatistikkRapportError,
                    })
                );
            });
    };
};

export const GetGatelysMonedsrapport = payload => {
    const url = GetGatelysMonedsrapportUrl;
    return dispatch => {
        return api.getClient()
            .get(url, {
                params: payload,
                responseType: 'arraybuffer',
                paramsSerializer: () => ParametersSerializer(payload),
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : GeneralRapportError }));
            });
    };
};

export const GetMonedsrapportmateriellrapport = payload => {
    const url = GetMonedsrapportmateriellrapportUrl;
    return dispatch => {
        return api.getClient()
            .get(url, {
                params: payload,
                responseType: 'arraybuffer',
                paramsSerializer: () => ParametersSerializer(payload),
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : GeneralRapportError }));
            });
    };
};
export const GetMonedsrapportavviksarbeidrapport = payload => {
    const url = GetMonedsrapportavviksarbeidrapportUrl;
    return dispatch => {
        return api.getClient()
            .get(url, {
                params: payload,
                responseType: 'arraybuffer',
                paramsSerializer: () => ParametersSerializer(payload),
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : GeneralRapportError }));
            });
    };
};
export const GetVinterForbrukGrunnlagRapport = (payload, useOld) => {
    const url = useOld ? GetVinterForbrukGrunnlagRapportUrlOld : GetVinterForbrukGrunnlagRapportUrl;
    return dispatch => {
        return api.getClient()
            .get(url, {
                params: payload,
                responseType: 'arraybuffer',
                paramsSerializer: () => ParametersSerializer(payload),
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : vinterRapportError }));
            });
    };
};

export const GetVinterForbrukOkonomiRapport = payload => {
    const url = GetVinterForbrukOkonomiRapportUrl;
    return dispatch => {
        return api.getClient()
            .get(url, {
                params: payload,
                responseType: 'arraybuffer',
                paramsSerializer: () => ParametersSerializer(payload),
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : vinterRapportError }));
            });
    };
};
export const GetDagmulktRapport = payload => {
    const url = GetDagmulktRapportUrl;
    return dispatch => {
        return api.getClient()
            .get(url, {
                params: payload,
                responseType: 'arraybuffer',
                paramsSerializer: () => ParametersSerializer(payload),
            })
            .then(res => {
                return res;
            })
            .catch(err => {
                const errorMessage = errorData(err);
                dispatch(addFlashMessage({ type: 'error', text: errorMessage ? errorMessage : GeneralRapportError }));
            });
    };
};
