import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import HeaderContainer from './Layout/HeaderContainer';
import Breadcrumbs from '../containers/Layout/Breadcrumbs';
import FlashMessagesList from '../components/common/FlashMessagesList';

export default class App extends Component {
    render() {
        return (
            <div className="responsive">
                <HeaderContainer />
                <Breadcrumbs location={this.props.location} />
                <FlashMessagesList />
                <Grid>{this.props.children}</Grid>
            </div>
        );
    }
}
App.contextTypes = {
    router: PropTypes.object,
};
App.propTypes = {
    // children: PropTypes.arrayOf(PropTypes.node),
    children: PropTypes.object,
    location: PropTypes.object,
};

//import PageHeader from '../components/Layout/PageHeader';
//import * as routes from "../constants/clientRoutes"
//import _ from 'lodash';
// constructor(props, context)
// {
//     super(props, context);
//     this.setPageHeaderInfo = this.setPageHeaderInfo.bind(this);
//     this.state = {
//         pageTitle: "",
//         subTitle: "",
//         pageTitleLink: ""
//     }
// }
// setPageHeaderInfo(location)
// {
//     //console.log(location)
//     if (!location.pathname) return;
//     let pageTitle = (location.state && location.state.pageLink) ? routes.getDisplayName(location.state.pageLink) : routes.getDisplayName(location.pathname);
//     const pageTitleLink = (location.state && location.state.pageLink) ? location.state.pageLink : ""
//     const subTitle = (location.state && location.state.subTitle) ? location.state.subTitle : ""
//     this.setState({ pageTitle: pageTitle, pageTitleLink: pageTitleLink, subTitle: subTitle });
// }

// componentWillMount()
// {
//     if (this.props.location)
//         this.setPageHeaderInfo(this.props.location);
// }
// componentWillReceiveProps(nextProps)
// {
//     //get properties from route location object and set state for PageHeader
//     if (nextProps.location && nextProps.location !== this.props.location)
//     {
//         this.setPageHeaderInfo(nextProps.location);
//     }
// }
/*<PageHeader pageTitle={this.state.pageTitle} pageTitleLink={this.state.pageTitleLink} subTitle={this.state.subTitle} />*/
