import React, { Component } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import moment from 'moment';
import DropDownList from '../common/DropDownList';
import RessursbrukComponent from './RessursbrukComponent';
import { toNorwegianDate } from '../../utils/datepickerHelper';
import '../../css/Ressursbruk/style.css';

class GlobalRessursbrukComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            selskapId: '',
            kontraktId: '0',
            filter: {
                isLoading: false,
                datoFra: toNorwegianDate(new Date()).toISOString(),
                datoTil: null,
                prosesskode: '0',
                prosesskodeSok: '',
            },
            visIngenGyldigKontrakt: false,
        };
    }

    getAktiveKontrakter = function(contracts) {
        return filter(contracts, c => moment().isBefore(c.gyldigTilDato) && moment().isAfter(c.gyldigFraDato));
    };

    componentDidMount() {
        const { getSelskapsIdForLoggedUser, loadCompanySpecificContracts } = this.props;
        getSelskapsIdForLoggedUser().then(selskap => {
            loadCompanySpecificContracts(selskap.selskapId).then(contracts => {
                const activeContracts = this.getAktiveKontrakter(contracts);

                if (!activeContracts || activeContracts.length == 0) {
                    // entreprenøren har ingen gyldige, aktive kontrakter
                    this.setState({
                        visIngenGyldigKontrakt: true,
                    });
                    return;
                }

                if (contracts && contracts.length === 1) {
                    this.setState({
                        kontraktId: contracts[0].id,
                        display: contracts[0].id !== '0',
                    });
                } else if (activeContracts && activeContracts.length === 1) {
                    this.setState({
                        kontraktId: activeContracts[0].id,
                        display: activeContracts[0].id !== '0',
                    });
                }
            });
        });
    }

    onSelectKontrakt = e => {
        const { filter } = this.state;
        const { value } = e.target;
        this.lastGlobalRessursforbruk(value, filter);
        this.setState({
            kontraktId: value,
            display: value !== '0',
        });
    };

    // displayRessursbruk = () => {
    //     const { kontraktId } = this.state;
    //     const { getProcessCodeSchemaPrices } = this.props;
    //     if (kontraktId !== '0') {
    //         getProcessCodeSchemaPrices(kontraktId);
    //         this.setState({ display: true });
    //     }
    // };

    filterResults = (datoFra, datoTil, prosesskode, prosesskodeSok) => {
        return `${datoFra ? `datoFra=${datoFra}` : ''}${datoTil ? `&datoTil=${datoTil}` : ''}${
            prosesskode ? `&prosesskode=${prosesskode}` : ''
        }${prosesskodeSok ? `&prosesskodeSok=${prosesskodeSok}` : ''}`;
    };

    onAddGlobalResources = (kontraktId, payload) => {
        const { addGlobalResources } = this.props;
        const { filter } = this.state;
        return addGlobalResources(
            kontraktId,
            payload,
            this.filterResults(filter.datoFra, filter.datoTil, filter.prosesskode, filter.prosesskodeSok)
        );
    };

    onDeleteGlobalResources = (kontraktId, payload) => {
        const { deleteGlobalResourceList } = this.props;
        const { filter } = this.state;
        return deleteGlobalResourceList(
            kontraktId,
            payload,
            this.filterResults(filter.datoFra, filter.datoTil, filter.prosesskode, filter.prosesskodeSok)
        );
    };

    onUpdateGlobalResources = (kontraktId, resourcebrukId, resourceUpdate) => {
        const { updateGlobalResourceList } = this.props;
        const { filter } = this.state;
        return updateGlobalResourceList(
            kontraktId,
            resourcebrukId,
            resourceUpdate,
            this.filterResults(filter.datoFra, filter.datoTil, filter.prosesskode, filter.prosesskodeSok)
        );
    };

    lastGlobalRessursforbruk = (kontraktId, filter, callback) => {
        const { getGlobalResources } = this.props;
        // const { kontraktId } = this.state;
        getGlobalResources(
            kontraktId,
            this.filterResults(filter.datoFra, filter.datoTil, filter.prosesskode, filter.prosesskodeSok)
        ).then(() => (callback ? callback() : null));
    };

    timeout = null;

    updateFilter = filter => {
        const { kontraktId } = this.state;
        this.setState({ filter });
        this.setState({ filter: { ...filter, isLoading: true } });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.lastGlobalRessursforbruk(kontraktId, filter, () => {
                this.setState({ filter: { ...filter, isLoading: false } });
            });
        }, 500);
    };

    render() {
        const { kontrakts, prices } = this.props;
        const { display, kontraktId, filter, visIngenGyldigKontrakt } = this.state;

        const aktiveKontrakter = this.getAktiveKontrakter(kontrakts);

        return (
            <div className="globalRessursbruk">
                <div className="col-xs-12 form-inline kontrakt-velger">
                    <DropDownList
                        label="Velg kontrakt"
                        title="Velg kontrakt"
                        Items={aktiveKontrakter}
                        selected={kontraktId}
                        onChange={this.onSelectKontrakt}
                        id="GlobalRessursbrukDropdownlist"
                    />
                </div>
                <div className="col-xs-12">
                    {visIngenGyldigKontrakt && <div className="text-center">Ingen gyldige kontrakter</div>}
                    {display && (
                        <RessursbrukComponent
                            {...this.props}
                            prices={prices}
                            kontraktId={kontraktId}
                            filter={filter}
                            updateFilter={this.updateFilter}
                            onAddGlobalResources={this.onAddGlobalResources}
                            onDeleteGlobalResources={this.onDeleteGlobalResources}
                            onUpdateGlobalResources={this.onUpdateGlobalResources}
                        />
                    )}
                </div>
            </div>
        );
    }
}

GlobalRessursbrukComponent.propTypes = {
    getSelskapsIdForLoggedUser: PropTypes.func.isRequired,
    loadCompanySpecificContracts: PropTypes.func.isRequired,
    loadProcessCodesList: PropTypes.func.isRequired,
    getProcessCodeSchemaPrices: PropTypes.func.isRequired,
    addResource: PropTypes.func.isRequired,
    getResources: PropTypes.func.isRequired,
    updateResourceList: PropTypes.func.isRequired,
    updateGlobalResourceList: PropTypes.func.isRequired,
    deleteGlobalResourceList: PropTypes.func.isRequired,
    getGlobalResources: PropTypes.func.isRequired,
    addGlobalResources: PropTypes.func.isRequired,
    loadSkiftList: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
    resources: PropTypes.object,
    tjenester: PropTypes.array,
    skifter: PropTypes.array,
    prices: PropTypes.array,
    kontrakts: PropTypes.array,
    avtaleId: PropTypes.string,
    Type: PropTypes.string,
    editable: PropTypes.bool,
};

export default GlobalRessursbrukComponent;
