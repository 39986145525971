import isEmpty from "lodash/isEmpty"
import validator from "validator"
import * as validationErrors from "../constants/ValidationErrors"
import { StartDateIsLongerThanEndDate } from "../utils/dateTimeHelpers"

export const validateInput = (state) =>
{
    let errors = {}
    const { selectedMonth, selectedYear, selectedAvtale, selectedKontrakt, selectedSelskap, selectedProsesskoder, selectedTab, FraDato, TilDato } = state
    if (selectedYear === "0")
    {
        errors.selectedYear = validationErrors.chooseYearIsRequired
    }
    if (selectedMonth === "0")
    {
        errors.selectedMonth = validationErrors.chooseMonthIsRequired
    }
    if (selectedTab && selectedTab === 3)
    {
        if (validator.isEmpty(FraDato))
        {
            errors.FraDato = validationErrors.DateFromIsRequired
        }
        if (validator.isEmpty(TilDato))
        {
            errors.TilDato = validationErrors.DateToIsRequired
        }
        if (StartDateIsLongerThanEndDate(FraDato, TilDato))
        {
            errors.FraDato = validationErrors.FraDatoAndToDate
        }
    }
    if (selectedAvtale && selectedAvtale === '0')
    {
        errors.selectedAvtale = validationErrors.avtaleIsRequired
    }
    if (selectedSelskap && selectedSelskap === '0')
    {
        errors.selectedSelskap = validationErrors.entrepreneurIsRequired
    }
    if (selectedKontrakt && selectedKontrakt === '0')
    {
        errors.selectedKontrakt = validationErrors.contractIsRequired
    }
    if (selectedProsesskoder && selectedProsesskoder.length === 0)
    {
        errors.selectedProsesskoder = validationErrors.ProcesscodeIsRequired
    }
    return {
        isValid: isEmpty(errors),
        errors
    }
}

