import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import RessursbrukForm from '../ressursbruk/RessursbrukForm';
import RessursbrukList from '../ressursbruk/RessursbrukList';
import validateInput from '../../validations/AddRessursbrukValidator';
import FilterRessursbruk from './FilterRessursbruk';
import * as cookieHanlder from '../../utils/cookieHandler';
import {
    MeldingRessursforbruk,
    GlobalRessursforbruk,
} from '../../constants/Globals';
import { DateTimeBetweenTwoDatetimes } from '../../utils/dateTimeHelpers';
import {
    toNorwegianDate,
    toNorwegianDatetime,
} from '../../utils/datepickerHelper';
import '../../css/Ressursbruk/RessursbrukFilters.css';
import 'moment/locale/nb';

class RessursbrukComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addFormisLoading: false,
            selectedTjeneste: '',
            selectedSkift: '',
            PrisId: '',
            Kommentar: '',
            Dato: toNorwegianDate(new Date()),
            Tid: '',
            //  formattedDateFra: '',
            kontraktId: props.kontraktId,
            meldingId: props.meldingId,
            resourceId: '',
            updateDateFra: '',
            updateSelectedSkift: '',
            updateAmount: '',
            updateComment: '',
            antall: '',
            errors: {},
            enhet: '',
            enhetId: 0,
            enhetNavn: '',
            FilteredProsesskodeId: '0',
            FilteredDateFrom: '',
            FilteredDateTo: '',
            Disabled: false,
        };
    }

    componentDidMount() {
        const {
            Type,
            kontraktId,
            meldingId,
            loadProcessCodesList,
            loadSkiftList,
            getResources,
        } = this.props;
        loadProcessCodesList();
        loadSkiftList();
        if (Type && Type === MeldingRessursforbruk) {
            getResources(meldingId);
        }
        // this.setState({
        //     Dato: toNorwegianDate(new Date()).toISOString(),
        // });
        // else if (Type && Type === GlobalRessursforbruk)
        // {
        //     this.props.getGlobalResources(kontraktId)
        // }
        this.loadProsessCodes(kontraktId);
    }

    componentWillReceiveProps = nextProps => {
        const { kontraktId, getResources } = this.props;
        if (nextProps.kontraktId !== kontraktId) {
            this.loadProsessCodes(nextProps.kontraktId);
            this.setState({
                selectedTjeneste: '',
                selectedSkift: '',
                PrisId: '',
                Kommentar: '',
                Dato: toNorwegianDate(new Date()),
                Tid: '',
            });
            // this.props.loadProcessCodesList()
            // this.props.loadSkiftList()

            if (nextProps.Type && nextProps.Type === MeldingRessursforbruk) {
                getResources(nextProps.meldingId);
            }
            // else if (Type && Type === GlobalRessursforbruk)
            // {
            //     this.props.getGlobalResources(kontraktId)
            // }
        }
    };

    loadProsessCodes = kontraktId => {
        const { getProcessCodeSchemaPrices } = this.props;
        if (
            cookieHanlder.UserIsEntrepreneur() ||
            cookieHanlder.UserIsEntrepreneurAdmin()
        )
            getProcessCodeSchemaPrices(kontraktId);
    };

    skiftitems = () => {
        const { skifter } = this.props;
        return _.map(skifter, item => {
            return { id: item.skiftId, navn: item.navn };
        });
    };

    findSelectedProcessCodeSkema = id => {
        const { prices } = this.props;
        const item = _.first(
            _.filter(prices, i => {
                return i.prosesskode.id === id;
            })
        );
        if (item)
            return {
                priceId: item.id,
                price: item.pris,
                prosskodeEnhetId: item.prosesskode.prosesskodeEnhet.id,
                prosesskodeEnhetNavn: item.prosesskode.prosesskodeEnhet.navn,
            };
    };

    handleChangeTjeneste = e => {
        // console.log("TCL: RessursbrukComponent -> e", e);
        // const value = e && e.target ? e.target.value : ''
        const value = e ? e.value : '';
        const p = this.findSelectedProcessCodeSkema(value);
        if (p)
            this.setState({
                selectedTjeneste: value,
                PrisId: p.priceId,
                enhetId: p.prosskodeEnhetId,
                enhetNavn: p.prosesskodeEnhetNavn,
            });
        else
            this.setState({
                selectedTjeneste: value,
                PrisId: '',
                enhetId: '',
                enhetNavn: '',
            });
    };

    onChange = (field, value) => {
        this.setState({ [field]: value });
    };

    onRemove = (e, resourceId) => {
        const { Type, kontraktId, meldingId } = this.props;
        if (Type && Type === MeldingRessursforbruk)
            this.props.deleteResourceList(resourceId, meldingId);
        else this.props.onDeleteGlobalResources(resourceId, kontraktId);
    };

    handleChangeSkift = e => {
        if (this.state.Dato === '') {
            this.setState.Disabled = true;
        } else {
            const { target } = e;
            const { value } = target;
            this.updateSkift(this.state.Dato, value);
        }
    };

    onChangeDateFrom = e => {
        moment.locale('nb');
        const newDate = moment.isMoment(e) ? moment.tz(e, 'Europe/Oslo') : e;
        this.setState({
            Dato: newDate,
        });

        // const skift = this.state.selectedSkift;
        // if (skift !== '') this.updateSkift(e, skift);
    };

    onChangeTimepicker = e => {
        moment.locale('nb');
        const newTime = moment.isMoment(e) ? moment.tz(e, 'Europe/Oslo') : e;
        this.setState({
            Tid: newTime,
        });
    };

    updateSkift = (date, value) => {
        moment.locale('nb');
        // const date = this.state.Dato ? this.state.Dato : moment()
        this.setState({ selectedSkift: value });
        const m = moment.tz(date, 'Europe/Oslo');
        let Tid;
        switch (value) {
            case '1':
                Tid = m.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
                break;
            case '2':
                Tid = m.set({ hour: 16, minute: 0, second: 0, millisecond: 0 });
                break;
            case '3':
                Tid = m.set({ hour: 24, minute: 0, second: 0, millisecond: 0 });
                break;
            default:
                Tid = m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        }
        this.updateTimepicker(Tid);
    };

    updateTimepicker = e => {
        this.setState({ Dato: e.toISOString(), Tid: e });
        /* this.setState({}) */
    };

    changeSkiftByChangingTime = hours => {
        const h = hours;
        let skift;
        if (h < 8) skift = '3';
        else if (h >= 8 && h <= 15) skift = '1';
        else if (h >= 16 && h <= 23) skift = '2';
        this.setState({ selectedSkift: skift });
    };

    addTimeToDate = (Dato, Tid) => {
        moment.locale('nb');
        const date = new Date(Dato);
        const hours = Tid.format('HH');
        const mins = Tid.format('mm');
        date.setHours(parseInt(hours - 2, 10));
        date.setMinutes(parseInt(mins, 10));
        const m = moment.tz(date, 'Europe/Oslo');
        return m.toISOString();
    };

    createUpdateObjectForRessursbruk = (field, value, r) => {
        const resourceUpdate = {
            prosesskodeEnhetsVerdi: r.prosesskodeEnhetsVerdi,
            skiftId: r.SkiftId,
            prisId: r.prisId,
            kommentar: r.Kommentar,
            dato: r.Dato,
        };
        switch (field) {
            case 'kommentar':
                resourceUpdate.kommentar = value;
                break;
            case 'enhet':
                resourceUpdate.prosesskodeEnhetsVerdi = value.replace(',', '.');
                break;
            case 'dato':
                resourceUpdate.dato = value;
                break;
            default:
                return '';
        }
        return resourceUpdate;
    };

    updateResourceBruk = (resourcebrukId, resourceUpdate) => {
        const {
            Type,
            kontraktId,
            meldingId,
            updateResourceList,
            onUpdateGlobalResources,
        } = this.props;
        if (Type && Type === MeldingRessursforbruk) {
            updateResourceList(meldingId, resourcebrukId, resourceUpdate);
        } else {
            onUpdateGlobalResources(kontraktId, resourcebrukId, resourceUpdate);
        }
    };

    onSubmitEditableDate = (value, r) => {
        const resourcebrukId = r.ressursforbrukId;
        const resourceUpdate = this.createUpdateObjectForRessursbruk(
            'dato',
            value,
            r
        );
        this.updateResourceBruk(resourcebrukId, resourceUpdate);
    };

    onSubmitTid = (value, r) => {
        const resourcebrukId = r.ressursforbrukId;
        const resourceUpdate = this.createUpdateObjectForRessursbruk(
            'dato',
            value,
            r
        );
        this.updateResourceBruk(resourcebrukId, resourceUpdate);
    };

    onSubmitEditableComment = (value, r) => {
        const resourcebrukId = r.ressursforbrukId;
        const resourceUpdate = this.createUpdateObjectForRessursbruk(
            'kommentar',
            value,
            r
        );
        this.updateResourceBruk(resourcebrukId, resourceUpdate);
    };

    onSubmitEditableSkift = (value, r) => {
        const resourcebrukId = r.ressursforbrukId;
        const resourceUpdate = this.createUpdateObjectForRessursbruk(
            'skift',
            value,
            r
        );
        this.updateResourceBruk(resourcebrukId, resourceUpdate);
    };

    onSubmitEditableEnhent = (value, r) => {
        const resourcebrukId = r.ressursforbrukId;
        const resourceUpdate = this.createUpdateObjectForRessursbruk(
            'enhet',
            value,
            r
        );
        this.updateResourceBruk(resourcebrukId, resourceUpdate);
    };

    isValid = () => {
        moment.locale('nb');
        const { Dato, Tid } = this.state;
        const { isValid, errors } = validateInput({
            ...this.state,
            Dato: moment.isMoment(Dato) ? Dato.format() : Dato,
            Tid: moment.isMoment(Tid) ? Tid.format() : Tid,
        });
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    };

    clearState = () => {
        this.setState({
            errors: {},
            // selectedTjeneste: '',
            selectedSkift: '',
            Kommentar: '',
            // Dato: '',
            antall: '',
            addFormisLoading: false,
        });
    };

    onClickLeggtil = e => {
        moment.locale('nb');
        const { Dato, Tid, PrisId, antall, Kommentar } = this.state;
        const {
            Type,
            meldingId,
            kontraktId,
            addResource,
            onAddGlobalResources,
        } = this.props;
        e.preventDefault();
        if (this.isValid()) {
            const date = moment(Dato);
            const time = moment(Tid);
            const dateTime = date.set({
                hour: time.hour(),
                minute: time.minute(),
            });
            const payload = {
                prisId: PrisId,
                skiftId: 2,
                kommentar: Kommentar,
                dato: dateTime,
                prosesskodeEnhetsverdi: antall.replace(',', '.'),
            };
            this.setState({ addFormisLoading: true });
            if (Type && Type === MeldingRessursforbruk) {
                addResource(meldingId, payload)
                    .then(() => {
                        this.clearState();
                    })
                    .catch(() => {
                        this.setState({ addFormisLoading: false });
                    });
            } else {
                onAddGlobalResources(kontraktId, payload)
                    .then(() => {
                        this.clearState();
                    })
                    .catch(() => {
                        this.setState({ addFormisLoading: false });
                    });
            }
        }
    };

    mapSkiftName = id => {
        const { skifter } = this.props;
        if (skifter.length === 0) return;
        const obj = _.first(_.filter(skifter, { skiftId: id }));
        return obj.navn;
    };

    getPrice = priceId => {
        const priceObj = _.first(_.filter(this.props.prices, { id: priceId }));
        if (priceObj) return priceObj.pris;
    };

    getTjenester = () => {
        return _.map(this.props.prices, itm => {
            return {
                id: itm.prosesskode.id,
                navn: `${itm.prosesskode.navn}  ${itm.prosesskode.beskrivelse}`,
                kode: itm.prosesskode.navn, 
                beskrivelse: itm.prosesskode.beskrivelse,
            };
        });
    };

    formatCalculatedPrice = (enhetVerdi, prisId) => {
        const price = this.getPrice(prisId);
        const multiply = parseFloat(enhetVerdi) * parseFloat(price);
        return Math.floor(multiply * 100) / 100;
    };

    calculateSumOfPricesOnClinetSide = resources => {
        let sum = 0;
        _.map(resources, item => {
            const multiply = this.formatCalculatedPrice(
                item.prosesskodeEnhetsVerdi,
                item.prisId
            );
            sum += multiply;
        });
        return sum ? Number(sum) : 0;
    };

    aggregertOppdatertPris = () => {
        const sum = this.props.resources.aggregertOppdatertPris;
        return sum ? Number(sum) : 0;
    };

    aggregertHistoriskPris = () => {
        const sum = this.props.resources.aggregertHistoriskPris;
        return sum ? Number(sum) : 0;
    };

    CalculateSumOfPrises = resources => {
        const { Type } = this.props;
        if (Type && Type === MeldingRessursforbruk) {
            return this.aggregertHistoriskPris();
        }
        return this.calculateSumOfPricesOnClinetSide(resources);
    };

    onChangeFilterProsesskode = e => {
        const { value } = e.target;
        this.setState({ FilteredProsesskodeId: value });
    };

    onChangeFilterDateFrom = e => {
        this.setState({ FilteredDateFrom: e || '' });
    };

    onChangeFilterDateTo = e => {
        this.setState({ FilteredDateTo: e || '' });
    };

    renderResourcesList = () => {
        const { resources } = this.props;
        return _.map(resources.ressursforbruk, item => {
            return {
                ...item,
                ressursforbrukId: item.ressursforbrukId,
                prisId: item.prisId,
                prosesskodeId: item.prosesskode.id,
                prosesskodeNavn: item.prosesskode.navn,
                prosesskodeBeskrivelse: item.prosesskode.beskrivelse,
                Dato: item.dato,
                SkiftId: item.skift.skiftId,
                skiftName: item.skift.navn,
                prosesskodeEnhetsVerdi: item.prosesskodeEnhetsVerdi
                    ? parseFloat(item.prosesskodeEnhetsVerdi)
                    : 0,
                calculatedPrice: item.historiskPris
                    ? Number(item.historiskPris)
                    : 0,
                prosesskodeEnhetNavn: item.prosesskode.prosesskodeEnhet.navn,
                Kommentar: item.kommentar,
                erGlobaltRessursforbruk: item.erGlobaltRessursforbruk,
            };
        });
    };

    renderFilteredRessourcesList = () => {
        const {
            FilteredDateFrom,
            FilteredDateTo,
            FilteredProsesskodeId,
        } = this.state;
        let filteredRessursbruk = this.renderResourcesList();
        if (FilteredProsesskodeId !== '0')
            filteredRessursbruk = _.filter(filteredRessursbruk, {
                prosesskodeId: FilteredProsesskodeId,
            });
        if (FilteredDateFrom !== '' && FilteredDateTo !== '') {
            filteredRessursbruk = _.filter(filteredRessursbruk, item => {
                if (
                    DateTimeBetweenTwoDatetimes(
                        FilteredDateFrom,
                        FilteredDateTo,
                        item.Dato
                    )
                ) {
                    return item;
                }
            });
        }
        return filteredRessursbruk;
    };

    render() {
        const {
            editable,
            Type,
            resources,
            kontraktId,
            filter,
            updateFilter,
            prices
        } = this.props;
        const {
            selectedTjeneste,
            selectedSkift,
            Kommentar,
            Dato,
            Tid,
            antall,
            enhetNavn,
            errors,
            addFormisLoading,
        } = this.state;
        const resourceBruklist = this.renderFilteredRessourcesList();
        const sum = resources.aggregertHistoriskPris
            ? resources.aggregertHistoriskPris
            : this.CalculateSumOfPrises(resourceBruklist);
        return (
            <div>
                {editable && (
                    <div className="ressursbruk-global-innmelding-container">
                        <RessursbrukForm
                            selectedTjeneste={selectedTjeneste}
                            priceListItems={prices}
                            skiftItems={this.skiftitems()}
                            selectedSkift={selectedSkift}
                            antall={antall}
                            Kommentar={Kommentar}
                            Dato={Dato}
                            Tid={Tid}
                            enhetNavn={enhetNavn}
                            handleChangeTjeneste={this.handleChangeTjeneste}
                            handleChangeSkift={this.handleChangeSkift}
                            /*  handleChangeRessursBruk={this.handleChangeRessursBruk} */
                            onChangeDateFrom={this.onChangeDateFrom}
                            onChange={this.onChange}
                            onClickLeggtil={this.onClickLeggtil}
                            errors={errors}
                            onChangeTimepicker={this.onChangeTimepicker}
                            addFormisLoading={addFormisLoading}
                            kontraktId={kontraktId}
                        />
                    </div>
                )}

                {Type && Type === GlobalRessursforbruk && (
                    <FilterRessursbruk
                        prosesskoder={[]}
                        kontraktId={kontraktId}
                        filter={filter}
                        updateFilter={updateFilter}
                    />
                )}
                <RessursbrukList
                    editable={editable}
                    resourcebrukList={resourceBruklist}
                    skiftItems={this.skiftitems()}
                    onRemoveRessursbruk={this.onRemove}
                    sum={sum}
                    onSubmitEditableComment={this.onSubmitEditableComment}
                    onSubmitEditableSkift={this.onSubmitEditableSkift}
                    onSubmitEditableEnhent={this.onSubmitEditableEnhent}
                    onSubmitEditableDate={this.onSubmitEditableDate}
                    onSubmitTid={this.onSubmitTid}
                    typeOfRessursList={Type}
                />
            </div>
        );
    }
}

RessursbrukComponent.defaultProps = {
    resources: {},
    tjenester: [],
    skifter: [],
    prices: [],
    editable: true,
    // filter: null,
    // updateFilter: null,
};

RessursbrukComponent.propTypes = {
    getResources: PropTypes.func.isRequired,
    updateResourceList: PropTypes.func.isRequired,
    onUpdateGlobalResources: PropTypes.func.isRequired,
    deleteResourceList: PropTypes.func.isRequired,
    onDeleteGlobalResources: PropTypes.func.isRequired,
    getGlobalResources: PropTypes.func.isRequired,
    onAddGlobalResources: PropTypes.func.isRequired,
    loadProcessCodesList: PropTypes.func.isRequired,
    loadSkiftList: PropTypes.func.isRequired,
    getProcessCodeSchemaPrices: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
    addResource: PropTypes.func.isRequired,
    prices: PropTypes.array,
    resources: PropTypes.object,
    tjenester: PropTypes.array,
    skifter: PropTypes.array,
    meldingId: PropTypes.string,
    kontraktId: PropTypes.string,
    avtaleId: PropTypes.string,
    Type: PropTypes.string,
    editable: PropTypes.bool,
    filter: PropTypes.shape({}),
    updateFilter: PropTypes.func,
};

RessursbrukComponent.contextTypes = {
    router: PropTypes.object,
};

export default RessursbrukComponent;
