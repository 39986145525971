import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

export default function processcodeschemeReducer(state = InitialState.processcodeschemes, action) {
  switch (action.type) {
    case types.LOAD_PROCESSCODESCHEMES_SUCCESS:
      return action.processcodeschemes;

    case types.CREATE_PROCESSCODESCHEME_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.processcodescheme)
      ];

    case types.UPDATE_PROCESSCODESCHEME_SUCCESS:
      return [
        ...state.filter(processcodescheme => processcodescheme.id !== action.processcodescheme.id),
        Object.assign({}, action.processcodescheme)
      ];

    default:
      return state;
  }
}
