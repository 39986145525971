import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Glyphicon } from 'react-bootstrap';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import DropDownList from '../../components/common/DropDownList';
import classnames from 'classnames';
import validateInput from '../../validations/AddNewContractValidator';
import DatePicker from '../common/NorwegianDatepicker';

import '../../css/Contract.css';

//TODO legg til validering som i NewUserModal
class NewContractModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            errors: {},
            open: false,
            kontraktsnavn: '',
            gyldigfraIso: '',
            gyldigfra: '',
            gyldigtilIso: '',
            gyldigtil: '',
            avtaleId: '0',
            kontractZoneName: '',
            prosesskodeskjemaId: '0',
        };

        this.handleModal = this.handleModal.bind(this);
        this.validateAndSave = this.validateAndSave.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleDateFromChange = this.handleDateFromChange.bind(this);
        this.handleDateToChange = this.handleDateToChange.bind(this);
        this.handleProsesskodeskjema = this.handleProsesskodeskjema.bind(this);
        this.handleAvtaleChange = this.handleAvtaleChange.bind(this);
    }

    handleModal() {
        this.setState({ open: !this.state.open });
    }
    cleanUp() {
        this.setState({
            kontraktsnavn: '',
            gyldigfraIso: '',
            gyldigfra: '',
            gyldigtilIso: '',
            gyldigtil: '',
            avtaleId: '0',
            prosesskodeskjemaId: '0',
            errors: {},
        });
    }
    ProcesscodesSchema() {
        return this.props.processcodeschemes.map(k => {
            return Object.assign({}, { id: k.id, navn: k.name });
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleProsesskodeskjema(e) {
        this.setState({ prosesskodeskjemaId: e.target.value });
    }
    handleAvtaleChange(e) {
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        this.setState({ avtaleId: e.target.value, kontractZoneName: text });
    }
    isValid() {
        const { errors, isValid } = validateInput(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }
    validateAndSave(e) {
        e.preventDefault();
        if (this.isValid()) {
            let {
                kontraktsnavn,
                avtaleId,
                kontractZoneName,
                prosesskodeskjemaId,
                gyldigfraIso,
                gyldigtilIso,
            } = this.state;
            let payload = {
                navn: kontraktsnavn,
                avtaleId: avtaleId,
                områdenavn: kontractZoneName,
                gyldigFraDato: gyldigfraIso,
                gyldigTilDato: gyldigtilIso,
                prosesskodeSkjemaId: parseInt(prosesskodeskjemaId, 10),
                selskapsId: this.props.selskapsId,
            };
            const avtale = _.find(this.props.avtaler, avtale => avtale.id === avtaleId);
            const prosesskodeSkjema = _.find(this.props.processcodeschemes, code => code.id === prosesskodeskjemaId);
            this.props.onSaveContract(payload, avtale, prosesskodeSkjema);
            this.handleModal();
            this.cleanUp();
        }
    }
    handleDateFromChange(value, formattedValue) {
        this.setState({ gyldigfraIso: value, gyldigfra: formattedValue });
    }
    handleDateToChange(value, formattedValue) {
        this.setState({ gyldigtilIso: value, gyldigtil: formattedValue });
    }
    render() {
        const { kontraktsnavn, gyldigfraIso, gyldigtilIso, avtaleId, prosesskodeskjemaId, errors } = this.state;
        const processcodeSchemasItems = this.ProcesscodesSchema();
        return (
            <div>
                <Button className="bym-add-btn" onClick={this.handleModal}>
                    <Glyphicon glyph="plus" />
                    {this.props.buttonText}
                </Button>
                <Modal show={this.state.open} onHide={this.handleModal} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Opprett ny kontrakt</Modal.Title>
                    </Modal.Header>
                    <form id="AddNewContract">
                        <Modal.Body>
                            <TextFieldGroup
                                label="Kontraktsnavn"
                                id="kontraktsnavn"
                                name="kontraktsnavn"
                                value={kontraktsnavn}
                                onChange={this.onChange}
                                error={errors.kontraktsnavn}
                            />
                            <DropDownList
                                label="Avtale:"
                                title="Velg en avtale"
                                id="Dropdownlist"
                                Items={this.props.avtaler}
                                onChange={this.handleAvtaleChange}
                                error={errors.avtaleId}
                                selected={avtaleId}
                                name="avtaleId"
                            />
                            <div className="form-inline">
                                <div className={classnames('form-group', { 'has-error': errors.gyldigfra })}>
                                    <label htmlFor="date">Gyldig fra og med:</label>
                                    <DatePicker
                                        id="gyldigfra-datepicker"
                                        dateFormat="DD.MM.YYYY"
                                        value={gyldigfraIso}
                                        name="gyldigfra"
                                        onChange={this.handleDateFromChange}
                                        style={{ width: '90%' }}
                                        showClearButton={false}
                                    />
                                    {errors.gyldigfra && <span className="help-block">{errors.gyldigfra}</span>}
                                </div>
                                <div className={classnames('form-group', { 'has-error': errors.gyldigtil })}>
                                    <label htmlFor="date">Gyldig til og med:</label>
                                    <DatePicker
                                        id="gyldigfra-datepicker"
                                        dateFormat="DD.MM.YYYY"
                                        value={gyldigtilIso}
                                        name="gyldigtil"
                                        onChange={this.handleDateToChange}
                                        style={{ width: '90%' }}
                                        showClearButton={false}
                                        tilOgMed
                                    />
                                    {errors.gyldigtil && <span className="help-block">{errors.gyldigtil}</span>}
                                </div>
                            </div>
                            <DropDownList
                                label="Prosesskodeskjema"
                                title="Velg prosesskodeskjema"
                                id="Dropdownlist"
                                Items={processcodeSchemasItems}
                                onChange={this.handleProsesskodeskjema}
                                error={errors.prosesskodeskjemaId}
                                selected={prosesskodeskjemaId}
                                name="prosesskodeskjemaId"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="pull-left">
                                <Button bsStyle="link" onClick={this.handleModal}>
                                    Avbryt
                                </Button>
                                <Button bsStyle="primary" bsSize="large" onClick={this.validateAndSave}>
                                    Opprett ny kontrakt
                                </Button>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

NewContractModal.defaultProps = {
    processcodeschemes: [],
    avtaler: [],
};

NewContractModal.propTypes = {
    processcodeschemes: PropTypes.array.isRequired,
    avtaler: PropTypes.array.isRequired,
    selskapsId: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onSaveContract: PropTypes.func.isRequired,
};

export default NewContractModal;
