import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

const Loading = ({ noFadeIn }) => {
    return <Spinner className="three-bounce" noFadeIn={noFadeIn} />;
};

Loading.defaultProps = {
    noFadeIn: false,
};

Loading.propTypes = {
    noFadeIn: PropTypes.bool,
};

export default Loading;
