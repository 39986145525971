import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Panel, Row, Col } from 'react-bootstrap';
import TextFieldGroupDisabled from '../../components/common/TextFieldGroupDisabled';
import DropDownListEditable from '../../components/common/DropDownListEditable';
import MeldingComments from '../../components/Meldinger/MeldingComments';
import MeldingDetailHeader from '../../components/Meldinger/MeldingDetailHeader';
import LeafletMapWithSearch from '../../components/Kart/LeafletMapWithSearch';
import MeldingsstatusVelger from '../MeldingsstatusVelger';
import Tilbakemeldinger from '../../components/Meldinger/MeldingTilbakemeldinger';
import '../../css/Meldinger/meldingDetail.css';
import Gallery from '../../components/common/Gallery';
import { addFlashMessage } from '../../actions/FlashMessagesAction';
import { getMeldingKommentarer, addMeldingKommentarer } from '../../actions/KommentarerAction';
import { getMeldingTilbakeMeldinger, addMeldingTilbakeMelding } from '../../actions/TilbakemeldingerAction';
import { getMeldingHistorisk } from '../../actions/HistoricAction';
import {
    loadEntrepreneurs,
    loadMeldingstyper,
    loadMeldingsstatuser,
    getSelskapsIdForLoggedUser,
    loadEntrepreneurMelding,
    PutEntrepreneurMelding,
} from '../../actions/MeldingerActions';
import {
    loadFeilrettingskoder,
    putFeilrettingskodeForMelding,
    deleteFeilrettingskodeForMelding,
} from '../../actions/feilrettingActions';
import { loadAvtalerForEntrepreneur, loadAvtalerForEntrepreneurAdmin } from '../../actions/avtalerAction';
import { genererPdf } from '../../actions/pdfgeneratorAction';
import dateFormat from 'dateformat';
import _ from 'lodash';
import { formatDateByCommaSepratedDayMonthYear, formatDateTimeHavingKL } from '../../utils/utils';
import validateInput from '../../validations/updateEntrepreneurMeldingValidator';
import RessursbrukContainter from './ressursbrukContainer';
import * as responseMessages from '../../constants/ResponseMessages';
import { imageUrl, imageThumbUrl } from '../../constants/api';
import * as cookieHandler from '../../utils/cookieHandler';
import { getResources } from '../../actions/RessursActions';
import AddCommentModal from '../../components/common/AddCommentModal';
import Dokumentasjon from '../../components/common/Dokumentasjon';
import './EntrepreneurMeldingDetails.css';
import { statuskoderSomIkkeSkalTriggeTilbakemeldingsdialog } from '../../constants/Globals';

class EntrepreneurMeldingDetails extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            meldingId: '',
            meldingstypeId: '',
            meldingsStatusId: 0,
            meldingsStatusNavn: '',
            meldingsStatusTekstmal: '',
            adresse: '',
            latitude: 0,
            longitude: 0,
            GPSCoordinate: '',
            selskapId: '',
            selskapNavn: '-',
            feilrettingskodeId: '',
            innsenderNavn: '',
            opprettetTid: '',
            innsenderEpost: '',
            avtaleNavn: '',
            data: '',
            tidsfrist: '',
            avtaleId: '',
            opprettetAv: '',
            kontraktId: '',
            kontraktNavn: '',
            lopeNummer: '',
            errors: {},
            expandedPanel: '',
            isGeneratingPdf: false,
            touched: false,
            collapsibleKart: false,
            zoom: 18,
        };
        this.onChange = this.onChange.bind(this);
        this.resetChanges = this.resetChanges.bind(this);
        this.nearByMeldinger = this.nearByMeldinger.bind(this);
        this.collapseKart = this.collapseKart.bind(this);
        this.updateGeoPosition = this.updateGeoPosition.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.changeZoom = this.changeZoom.bind(this);
        this.updateMelding = this.updateMelding.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.isExpanded = this.isExpanded.bind(this);
        this.Printout = this.Printout.bind(this);
        this.showPopUpModal = this.showPopUpModal.bind(this);
        this.showAddCommentModal = this.showAddCommentModal.bind(this);
        this.updateMeldingStatusRejected = this.updateMeldingStatusRejected.bind(this);
    }

    loadAvtalerForLoggedUser(selskapId) {
        const userId = cookieHandler.UserId();
        if (selskapId && userId) {
            if (cookieHandler.UserIsEntrepreneur()) {
                this.props.loadAvtalerForEntrepreneur(selskapId, userId);
            } else if (cookieHandler.UserIsEntrepreneurAdmin()) {
                this.props.loadAvtalerForEntrepreneurAdmin(selskapId);
            }
        }
    }

    componentWillMount() {
        const meldingId = this.context.router.params.id;
        this.props.getSelskapsIdForLoggedUser().then(selskapId => {
            this.props.loadEntrepreneurMelding(selskapId, meldingId).then(res => {
                this.updateState(res);
            });
            this.loadAvtalerForLoggedUser(selskapId);
            this.setState({ selskapId });
        });
        this.props.loadEntrepreneurs();
        this.props.loadMeldingstyper();
        this.props.loadMeldingsstatuser();
        this.props.loadFeilrettingskoder();
        this.props.getResources(meldingId);
        // this.props.loadAvtaler()
        this.props.getMeldingKommentarer(meldingId);
        this.props.getMeldingTilbakeMeldinger(meldingId);
    }

    updateState(melding) {
        if (melding) {
            this.setState({
                meldingId: melding.meldingId,
                meldingstypeId: melding.meldingstypeId,
                meldingsStatusId: melding.meldingsStatus ? melding.meldingsStatus.id : '0',
                meldingsStatusNavn: melding.meldingsStatus ? melding.meldingsStatus.navn : '',
                meldingsStatusTekstmal: melding.meldingsStatus ? melding.meldingsStatus.tekstmal : '',
                adresse: melding.adresse,
                latitude: melding.latitude,
                longitude: melding.longitude,
                GPSCoordinate: `${melding.latitude.toFixed(6).toString()} ${melding.longitude.toFixed(6).toString()}`,
                selskapId: melding.selskap ? melding.selskap.id : '',
                selskapNavn: melding.selskap ? melding.selskap.navn : '',
                feilrettingskodeId: melding.feilrettingskodeId,
                innsenderNavn: melding.innsenderNavn,
                opprettetAv: melding.opprettetAvEpost ? melding.opprettetAvEpost : '',
                opprettetTid: melding.opprettetTid,
                innsenderEpost: melding.innsenderEpost,
                avtaleNavn: melding.avtale ? melding.avtale.navn : '',
                avtaleId: melding.avtale ? melding.avtale.avtaleId : '',
                data: melding.data || '',
                tidsfrist: melding.tidsfrist || '',
                beskrivelse: melding.beskrivelse,
                kontraktId: melding.kontrakt ? melding.kontrakt.kontraktId : '',
                kontraktNavn: melding.kontrakt ? melding.kontrakt.navn : '',
                lopeNummer: melding.lopeNummer,
            });
        }
    }

    toggleExpanded(panelToExpand) {
        const { expandedPanel } = this.state;
        this.setState({
            expandedPanel: expandedPanel !== panelToExpand ? panelToExpand : '',
        });
    }

    isExpanded(panelToCheck) {
        const { expandedPanel } = this.state;
        return panelToCheck === expandedPanel;
    }

    updateGeoPosition(lat, lng) {
        const geoposition = `${lat.toString()} , ${lng.toString()}`;
        this.setState({
            latitude: lat,
            longitude: lng,
            GPSCoordinate: geoposition,
            touched: true,
        });
    }

    updateAddress(address) {
        this.setState({ adresse: address, touched: true });
    }

    updateMelding() {
        this.updateMeldingApiCall();
    }

    showPopUpModal() {
        this.setState({ popupTilbakeMelding: !this.state.popupTilbakeMelding });
    }

    // updateMeldingStatusId = (val) =>
    // {
    //     this.updateMeldingApiCall(val)
    //     if (val === "5") this.showPopUpModal()
    // }

    showAddCommentModal() {
        this.setState({ popupAddComment: !this.state.popupAddComment });
    }

    updateMeldingStatusId = val => {
        const { meldingsstatuser } = this.props;
        if (val === '3') {
            this.showAddCommentModal();
        } else {
            const status = _.find(meldingsstatuser, m => m.id.toString() === val);
            this.setState({
                meldingsStatusId: Number(val),
                meldingsStatusTekstmal: status ? status.tekstmal : '',
            });
            this.updateMeldingApiCall(val);

            if (Number(val) >= 4 && statuskoderSomIkkeSkalTriggeTilbakemeldingsdialog.indexOf(Number(val)) === -1) {
                this.showPopUpModal();
        }
        }
    };

    updateMeldingStatusRejected() {
        this.setState({ meldingsStatusId: 3 });
        this.updateMeldingApiCall(3);
    }

    onCancelUpdateStatus = () => {
        const { melding } = this.props;
        this.setState({
            meldingsStatusId: melding.meldingsStatus ? melding.meldingsStatus.id : 0,
        });
        this.statusvelger.onChange(melding.meldingsStatus ? melding.meldingsStatus.id : 0);
    };

    updateViewModel(m, meldingsStatusId) {
        return Object.assign(
            {},
            {
                adresse: m.adresse,
                latitude: m.latitude,
                longitude: m.longitude,
                meldingsStatusId: meldingsStatusId || m.meldingsStatusId,
            }
        );
    }

    isValid() {
        const { isValid, errors } = validateInput(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    updateMeldingApiCall(meldingsStatusId) {
        const melding = this.updateViewModel(this.state, meldingsStatusId);
        const { meldingId, selskapId } = this.state;
        const { PutEntrepreneurMelding, addFlashMessage, loadEntrepreneurMelding, getMeldingHistorisk } = this.props;

        if (this.isValid()) {
            PutEntrepreneurMelding(selskapId, meldingId, melding)
                .then(() => {
                    addFlashMessage({
                        type: 'success',
                        text: responseMessages.meldingIsupdated,
                    });
                    loadEntrepreneurMelding(selskapId, meldingId).then(res => {
                        this.updateState(res);
                    });
                    getMeldingHistorisk(meldingId);
                    this.setState({ touched: false, errors: {} });
                })
                .catch(err => {
                    this.props.addFlashMessage({
                        type: 'error',
                        text: err.response.data.errorMessage,
                    });
                });
        }
    }

    resetFeilrettingskode = async () => {
        const { meldingId } = this.state;
        this.setState({ feilrettingskodeId: undefined, touched: true });
        await this.props.deleteFeilrettingskodeForMelding(meldingId);
    };

    onSaveFeilrettingskode = async val => {
        const { meldingId } = this.state;
        try {
            if (val == '0') {
                await this.resetFeilrettingskode();
            } else {
                await this.props.putFeilrettingskodeForMelding(meldingId, val);
            }

            this.props.addFlashMessage({
                type: 'success',
                text: responseMessages.meldingIsupdated,
            });

            this.setState({ feilrettingskodeId: val, touched: true });
            return false;
        } catch (err) {
            this.props.addFlashMessage({
                type: 'error',
                text: err?.response?.data ? err.response.data.errorMessage : 'Ukjent feil oppstått!',
            });
            return true;
        }
    };

    collapseKart() {
        this.setState({ collapsibleKart: !this.state.collapsibleKart });
    }

    changeZoom() {
        this.setState({ zoom: 18 });
    }

    resetChanges(e) {
        e.preventDefault();
        // this.props.loadEntrepreneurMelding(this.state.selskapId, this.state.meldingId)
        this.setState({ touched: false, zoom: 18 });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, touched: true });
    }

    nearByMeldinger(e) {
        e.preventDefault();
        alert('Dette er ikke implementert på backend');
    }

    Printout(lopenummer) {
        this.setState({ isGeneratingPdf: true });
        const meldingId = this.context.router.params.id;
        const join = `MeldingId=${meldingId}`;
        this.props
            .genererPdf(join, 'Bymelding_' + dateFormat(Date.now(), 'yyyymmdd_HHMMss') + '_' + lopenummer + '.pdf')
            .finally(() => {
                this.setState({ isGeneratingPdf: false });
            })
            .catch(err => {
                throw err;
            });
    }

    meldingKategori(meldingsTypeId) {
        const meldingsKategori = _.first(
            _.filter(this.props.meldingstyper, {
                meldingstypeId: meldingsTypeId,
            })
        );
        return meldingsKategori ? meldingsKategori.beskrivelse : '';
    }

    getSizeOfImages(melding) {
        return melding.bildeIds ? _.size(melding.bildeIds) : 0;
    }

    getRessursCount() {
        const ressursforbruk = this.props.resources ? this.props.resources.ressursforbruk : {};
        return ressursforbruk ? _.size(ressursforbruk) : 0;
    }

    getTilbakeMeldingerCount() {
        const counttilbakemeldinger = this.props.tilbakemeldinger;
        return counttilbakemeldinger ? _.size(counttilbakemeldinger) : 0;
    }

    renderImages(images) {
        const imagesData = [];
        if (images) {
            _.map(images, i => {
                const imgUrl = imageUrl(i);
                const thumbUrl = imageThumbUrl(i);
                imagesData.push(Object.assign({}, { src: imgUrl, thumbSrc: thumbUrl }));
            });
            return <Gallery images={imagesData} id="meldingDetailsGallery" />;
        }
    }

    getSizeOfDokumentasjon(melding) {
        return melding.dokumentasjonIds ? _.size(melding.dokumentasjonIds) : 0;
    }

    render() {
        const {
            meldingId,
            meldingstypeId,
            meldingsStatusNavn,
            meldingsStatusTekstmal,
            innsenderNavn,
            innsenderEpost,
            adresse,
            lopeNummer,
            latitude,
            avtaleId,
            selskapId,
            avtaleNavn,
            feilrettingskodeId,
            data,
            tidsfrist,
            beskrivelse,
            longitude,
            selskapNavn,
            opprettetAv,
            kontraktId,
            kontraktNavn,
            errors,
            meldingsStatusId,
            GPSCoordinate,
            isGeneratingPdf,
            popupTilbakeMelding,
            popupAddComment,
        } = this.state;
        const { melding, meldingsstatuser, feilrettingskoder } = this.props;
        if (meldingId.length === 0) return null;
        const mottattDato = melding ? formatDateTimeHavingKL(melding.opprettetTid) : '-';
        // const meldingstyperItems = _.sortBy(_.map(meldingstyper, m => { return { id: m.meldingstypeId, navn: m.beskrivelse } }), 'navn')

        // Meldingstyper tilgjengelig for entreprenør:

        // - Utbedring bestilt
        // - Sporveien AS
        // - Videresendt BYM områdeansvarlig
        // - Vann og avløpsetaten

        const kategori = this.meldingKategori(meldingstypeId);

        const melidngstatus = _.filter(meldingsstatuser, i => {
            return (
                (i.id !== 1 && i.id !== 10 && Number(i.id) < 15) ||
                i.id === 16 ||
                i.id === 17 ||
                i.id === 18 ||
                i.id === 21 ||
                i.id === 22 ||
                i.id === 24 ||
                i.id === 52 ||
                i.id === 54 ||
                i.id === 57 ||
                i.id === 71 ||
                i.id === 72 ||
                i.id === 100 ||
                i.id === 150 ||
                i.id === 200 ||
                i.id === 300 ||
                i.id === 301 ||
                i.id === 302 ||
                i.id === 303 ||
                i.id === 310
            );
        });
        // const statusItems = _.sortBy(melidngstatus, 'navn');
        const bilder = `Bilder (${this.getSizeOfImages(melding)})`;
        const dokumentasjon = `Dokumentasjon (${this.getSizeOfDokumentasjon(melding)})`;
        const ressurs = `Ressursbruk (${this.getRessursCount()})`;
        const tilbakeMeldinger = `Meldinger til innmelder (${this.getTilbakeMeldingerCount()})`;
        const pildown = 'glyphicon-chevron-down';
        const pilright = 'glyphicon-chevron-right';

        const filteredFeilrettingskoder = feilrettingskoder?.filter(f => f.avtaleId === this.state.avtaleId) ?? [];
        const isArbeidUtfortVisible = filteredFeilrettingskoder.length > 0;

        return (
            <div>
                <div className="mainContainer">
                    <button
                        className="btn btn-default pull-right tilbakeMeldingBackButton active"
                        id="tilbakeMeldingBackButton"
                        onClick={() => {
                            this.context.router.goBack();
                        }}
                    >
                        <span className="glyphicon glyphicon-remove color-orange" />
                        <b>LUKK</b>
                    </button>
                    <div className="submainContainer" id="submainContainer">
                        <div className="tilbakemeldingerContainer">
                            <button
                                className="btn btn-default pull-left"
                                id="printOutButton"
                                disabled={isGeneratingPdf ? 'disabled' : ''}
                                onClick={() => this.Printout(lopeNummer)}
                            >
                                {isGeneratingPdf && <span className="glyphicon glyphicon-refresh bym-glyphicon-spin" />}
                                {!isGeneratingPdf && <span className="glyphicon glyphicon-print" />}{' '}
                                <span>Skriv ut</span>
                            </button>
                            <div className="topBannerWrapper" id="topBannerWrapper">
                                <MeldingDetailHeader
                                    innsenderNavn={innsenderNavn}
                                    opprettetAv={opprettetAv}
                                    innsenderEpost={innsenderEpost}
                                    lopeNummer={lopeNummer}
                                    mottattDato={mottattDato}
                                    enterpreneurId={selskapId}
                                    selskapNavn={selskapNavn}
                                    meldingsStatusNavn={meldingsStatusNavn}
                                    kontraktId={kontraktId}
                                    kontraktNavn={kontraktNavn}
                                    avtaleNavn={avtaleNavn}
                                    data={data}
                                    tidsfrist={tidsfrist}
                                    address={adresse}
                                    GPSCoordinate={GPSCoordinate}
                                    meldingsStatusId={meldingsStatusId}
                                    isEntreprenor={true}
                                />
                            </div>
                            <div className="clearfix" />

                            <div>
                                <div className="meldingDescription">
                                    <span>Beskrivelse</span>
                                    <br />
                                    <strong>{beskrivelse}</strong>
                                </div>
                                <Row className="sendMeldingDropdownBoxes message-description-entrepreneur">
                                    <Col md={3}>
                                        <TextFieldGroupDisabled
                                            label="Meldingstype"
                                            id="label-16px"
                                            name="kategori"
                                            value={kategori}
                                        />
                                    </Col>
                                    <Col md={isArbeidUtfortVisible ? 2 : 3}>
                                        {melidngstatus && melidngstatus.length > 0 && (
                                            <MeldingsstatusVelger
                                                statuser={melidngstatus}
                                                valgtStatus={meldingsStatusId}
                                                error={errors.meldingsStatusId}
                                                onSubmit={this.updateMeldingStatusId}
                                                ref={node => (this.statusvelger = node)}
                                            />
                                        )}
                                    </Col>
                                    {isArbeidUtfortVisible && (
                                        <Col md={2}>
                                            <DropDownListEditable
                                                Items={filteredFeilrettingskoder}
                                                id="melding-arbeid-utfort"
                                                label="Arbeid utført"
                                                title="-----------"
                                                selected={feilrettingskodeId}
                                                onSubmit={this.onSaveFeilrettingskode}
                                                error={errors.feilrettingskodeId}
                                            />
                                        </Col>
                                    )}
                                    <Col md={isArbeidUtfortVisible ? 2 : 3}>
                                        <TextFieldGroupDisabled
                                            label="Entreprenør"
                                            id="label-16px"
                                            name="kategori"
                                            value={selskapNavn}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <TextFieldGroupDisabled
                                            label="Avtale"
                                            id="label-16px"
                                            name="kategori"
                                            value={avtaleNavn}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div className="clearfix" />
                            <div className="tilbakeMeldingerMap">
                                <div className="clearfix" />
                                <Accordion id="tilbakepanelheading">
                                    <Panel
                                        onSelect={() => this.toggleExpanded('ressurs')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('ressurs') ? pildown : pilright
                                                    }`}
                                                />
                                                {ressurs}
                                            </strong>
                                        }
                                        eventKey="1"
                                    >
                                        {kontraktId.length !== 0 ? (
                                            <RessursbrukContainter
                                                meldingId={meldingId}
                                                avtaleId={avtaleId}
                                                kontraktId={kontraktId}
                                            />
                                        ) : (
                                            <div>Ingen priskjemaer for denne kontrakten.</div>
                                        )}
                                    </Panel>

                                    <Panel
                                        onSelect={() => this.toggleExpanded('bilder')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('bilder') ? pildown : pilright
                                                    }`}
                                                />
                                                {bilder}
                                            </strong>
                                        }
                                        eventKey="2"
                                    >
                                        <div className="photoGallery">
                                            <div className="clearfix" />
                                            {this.renderImages(melding.bildeIds)}
                                        </div>
                                    </Panel>

                                    <Panel
                                        onSelect={() => this.toggleExpanded('dokumentasjon')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('dokumentasjon') ? pildown : pilright
                                                    }`}
                                                />
                                                {dokumentasjon}
                                            </strong>
                                        }
                                        eventKey="20"
                                    >
                                        {this.isExpanded('dokumentasjon') && (
                                            <Dokumentasjon
                                                existingDocuments={melding.dokumentasjonIds}
                                                meldingId={meldingId}
                                                selskapId={selskapId}
                                            />
                                        )}
                                    </Panel>
                                    <Panel
                                        onSelect={() => this.toggleExpanded('tilbakeMeldinger')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('tilbakeMeldinger') ? pildown : pilright
                                                    }`}
                                                />
                                                {tilbakeMeldinger}
                                            </strong>
                                        }
                                        eventKey="3"
                                    >
                                        <Tilbakemeldinger
                                            meldingId={meldingId}
                                            lopenummer={lopeNummer}
                                            meldingStatusId={meldingsStatusId}
                                            meldingsstatuser={meldingsstatuser}
                                            tekstmal={meldingsStatusTekstmal}
                                            tilbakemeldinger={this.props.tilbakemeldinger}
                                            innsenderNavn={opprettetAv}
                                            innsenderEpost={innsenderEpost}
                                            addTilbakemelding={this.props.addMeldingTilbakeMelding}
                                            popup={popupTilbakeMelding}
                                            showPopUpModal={this.showPopUpModal}
                                            mottattDato={mottattDato}
                                            meldingsType={kategori}
                                            adresse={adresse}
                                            beskrivelse={beskrivelse}
                                        />
                                    </Panel>

                                    <Panel
                                        onSelect={() => this.toggleExpanded('kart')}
                                        header={
                                            <strong>
                                                <span
                                                    className={`glyphicon ${
                                                        this.isExpanded('kart') ? pildown : pilright
                                                    }`}
                                                />
                                                Kart
                                            </strong>
                                        }
                                        eventKey="4"
                                        onEntering={() => this.map.invalidateMap()}
                                    >
                                        {/* <button className="btn btn-link form-group" id="meldingerButton" onClick={this.showSearchField}>Vis meldinger i nærheten</button> */}
                                        <LeafletMapWithSearch
                                            ref={r => (this.map = r)}
                                            addressInput="outside"
                                            address={adresse}
                                            latitude={latitude}
                                            longitude={longitude}
                                            updateMelding={this.updateMelding}
                                            updateAddress={this.updateAddress}
                                            updateGeoPosition={this.updateGeoPosition}
                                        />
                                    </Panel>
                                </Accordion>

                                <div className="row">
                                    <div className="col-md-12">
                                        <MeldingComments
                                            isEntreprenor
                                            meldingId={meldingId}
                                            kommentarer={this.props.kommentarer}
                                            getMeldingHistorisk={this.props.getMeldingHistorisk}
                                            historicList={this.props.historic}
                                            addKommentar={this.props.addMeldingKommentarer}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddCommentModal
                    confirmText=""
                    modalTitle="Legg inn årsak til status Avvist"
                    showAddComment={this.showAddCommentModal}
                    popup={popupAddComment}
                    addKommentar={this.props.addMeldingKommentarer}
                    meldingId={meldingId}
                    updateStatus={this.updateMeldingStatusRejected}
                    onCancelUpdateStatus={this.onCancelUpdateStatus}
                />
            </div>
        );
    }
}

EntrepreneurMeldingDetails.propTypes = {
    melding: PropTypes.object,
    loadEntrepreneurMelding: PropTypes.func.isRequired,
    getSelskapsIdForLoggedUser: PropTypes.func.isRequired,
    PutEntrepreneurMelding: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func,
    meldingstyper: PropTypes.array,
    meldingsavtaler: PropTypes.array,
    meldingsstatuser: PropTypes.array,
    entrepreneurs: PropTypes.array,
    feilrettingskoder: PropTypes.array,
    putFeilrettingskodeForMelding: PropTypes.func.isRequired,
    deleteFeilrettingskodeForMelding: PropTypes.func.isRequired,
    loadEntrepreneurs: PropTypes.func.isRequired,
    loadMeldingstyper: PropTypes.func.isRequired,
    loadMeldingsstatuser: PropTypes.func.isRequired,
    // loadAvtaler: PropTypes.func.isRequired,
    loadAvtalerForEntrepreneur: PropTypes.func,
    loadAvtalerForEntrepreneurAdmin: PropTypes.func,
    genererPdf: PropTypes.func.isRequired,
    kommentarer: PropTypes.array,
    getMeldingKommentarer: PropTypes.func.isRequired,
    addMeldingKommentarer: PropTypes.func.isRequired,
    tilbakemeldinger: PropTypes.array,
    getMeldingTilbakeMeldinger: PropTypes.func.isRequired,
    addMeldingTilbakeMelding: PropTypes.func,
    getMeldingHistorisk: PropTypes.func.isRequired,
    historic: PropTypes.array,
    resources: PropTypes.object,
    getResources: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        melding: state.meldinger.melding,
        meldinger: state.meldinger.meldingerList,
        meldingstyper: state.meldinger.meldingstyper,
        meldingsavtaler: state.meldinger.meldingssoner,
        meldingsstatuser: state.meldinger.meldingsstatuser,
        entrepreneurs: state.meldinger.entrepreneurs,
        feilrettingskoder: state.feilrettingskoder,
        kommentarer: state.kommentarer,
        resources: state.ressursBruk.resources,
        tilbakemeldinger: state.tilbakemeldinger,
        historic: state.historisk,
    };
};
EntrepreneurMeldingDetails.contextTypes = {
    router: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            loadEntrepreneurMelding,
            addFlashMessage,
            PutEntrepreneurMelding,
            getSelskapsIdForLoggedUser,
            loadEntrepreneurs,
            loadMeldingstyper,
            loadMeldingsstatuser,
            loadAvtalerForEntrepreneur,
            loadAvtalerForEntrepreneurAdmin,
            loadFeilrettingskoder,
            putFeilrettingskodeForMelding,
            deleteFeilrettingskodeForMelding,
            genererPdf,
            getMeldingKommentarer,
            addMeldingKommentarer,
            getMeldingTilbakeMeldinger,
            addMeldingTilbakeMelding,
            getMeldingHistorisk,
            getResources,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneurMeldingDetails);
