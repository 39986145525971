import validator from 'validator';
import moment from 'moment';
//import {dateFromIsLongerThanDateTo} from "../utils/utils"
import isEmpty from 'lodash/isEmpty';
import * as validatorMessages from '../constants/ValidationErrors';

export default function validateInput(data) {
    const errors = {};
    if (
        validator.isEmpty(data.antall) ||
        !parseFloat(data.antall.replace(',', '.'))
    ) {
        errors.antall = validatorMessages.mengdeIsNotDouble;
    }
    if (!data.Tid || validator.isEmpty(data.Tid.toString())) {
        errors.Tid = validatorMessages.timeIsRequired;
    }
    if (data.Tid && !moment(data.Tid).isValid()) {
        errors.Tid = validatorMessages.timeNotValid;
    }
    // if(validator.isEmpty(data.Kommentar)){
    //     errors.Kommentar=validatorMessages.commentsIsRequired
    // }
    if (validator.isEmpty(data.Dato)) {
        errors.Dato = validatorMessages.DatoIsRequired;
    }
    if (data.Dato && !moment(data.Dato).isValid()) {
        errors.Dato = validatorMessages.DatoNotValid;
    }
    // if(dateFromIsLongerThanDateTo(data.gyldigfra, data.gyldigtil)){
    //     errors.comparetDates=validatorMessages.FromDateIsLongerThanNow
    // } {
    if (
        validator.isEmpty(data.selectedTjeneste) ||
        data.selectedTjeneste === '0'
    ) {
        errors.selectedTjeneste =
            validatorMessages.ProsseskodeprisschemaIsRequired;
    }
    // if (validator.isEmpty(data.selectedSkift) || data.selectedSkift === "0")
    // {
    //     errors.selectedSkift = validatorMessages.ShiftIsRequired;
    // }
    return {
        errors,
        isValid: isEmpty(errors),
    };
}
