import delay from "./delay"
import _ from "lodash"

let rapportList = []
_.map(_.range(1, 3), index =>
{
    rapportList.push({ kategori: "Kategori" + index, subKategori: "SubKategori" + index, avtale: "Avtale" + index })
})
class resourceAPI
{

    static getRapporter()
    {
        return new Promise((resolve, reject) =>
        {
            setTimeout(() =>
            {
                resolve(Object.assign([], rapportList));
            }, delay);
        });
    }
}

export default resourceAPI