import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

export default function userReducer(state = InitialState.users, action) {
    switch (action.type) {

        //load users
        case types.LOAD_USERS:
            return {...state, userList: {users: [], error: null, loading: true}};
        case types.LOAD_USERS_SUCCESS:
            return {...state, userList: {users: action.users, error: null, loading: false}};
        case types.LOAD_USERS_FAILURE:
            return {...state, userList: {users: [], error: action.error, loading: false}};

        //create user
        case types.CREATE_USER:
            return {...state, newUser: {user: action.user, error: null, loading: true}};
        case types.CREATE_USER_SUCCESS:
            return {...state,
                newUser: {user: action.user, error: null, loading: false},
                userList: {users: [...state.userList.users, action.user], error: null, loading: false}
            };
        case types.CREATE_USER_FAILURE:
            return {...state, newUser: {user: state.newUser.user, error: action.error, loading: false}};
        case types.CREATE_USER_RESET:
            return {...state, newUser: {user: null, error: null, loading: false}};

        // update user
        case types.UPDATE_USER_SUCCESS: {
            let index = state.userList.users.findIndex(x => x.id === action.user.id);
            let users = [...state.userList.users];
            users.splice(index, 1, action.user);
            return {...state, userList: {users: users, error: null, loading: false}};
        }
        //delete user
        case types.DELETE_USER_SUCCESS: {
            const indexToRemove = state.userList.users.findIndex(x => x.id === action.user.id)
            const users = [
                ...state.userList.users.slice(0, indexToRemove),
                ...state.userList.users.slice(indexToRemove + 1)
            ];

            return {...state, userList: {users, error: null, loading: false}}
        }

        default:
            return state;
    }
}
