import delay from './delay';

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const processcodeschemes = [
  {
    id: "Veiforvaltning",  
    name: "Veiforvaltning",
  },
  {
    id: "Belysning",
    name: "Belysning",
  }
];

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (processcodescheme) => {
  return replaceAll(processcodescheme.name, ' ', '-');
};

class ProcesscodeschemeApi {
  static getAllProcesscodeschemes() {
    return new Promise((resolve, reject) => {
      
      setTimeout(() => {
        resolve(Object.assign([], processcodeschemes));
      }, delay);
    });
  }

  static saveProcesscodescheme(processcodescheme) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Simulate server-side validation
        const minProcesscodeschemeTitleLength = 1;
        if (processcodescheme.name.length < minProcesscodeschemeTitleLength) {
          reject(`Name must be at least ${minProcesscodeschemeTitleLength} characters.`);
        }

        if (processcodescheme.id) {
          const existingProcesscodeschemeIndex = processcodeschemes.findIndex(a => a.id === processcodescheme.id);
          processcodeschemes.splice(existingProcesscodeschemeIndex, 1, processcodescheme);
        } else {
           //Just simulating creation here.
          //The server would generate ids for new processcodeschemes in a real app.
          //Cloning so copy returned is passed by value rather than by reference.
          processcodescheme.id = generateId(processcodescheme);

          processcodeschemes.push(processcodescheme);
        }

        resolve(Object.assign({}, processcodescheme));
      }, delay);
    });
  }

  static deleteProcesscodescheme(processcodeschemeId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const indexOfProcesscodeschemeToDelete = processcodeschemes.findIndex(processcodescheme => {
            return processcodescheme.processcodeschemeId === processcodeschemeId;
        });
        processcodeschemes.splice(indexOfProcesscodeschemeToDelete, 1);
        resolve();
      }, delay);
    });
  }
}

export default ProcesscodeschemeApi;
