
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { MeldingRessursforbruk } from "../../constants/Globals"
import
{
    getResources, addResource, updateResourceList, deleteResourceList, loadSkiftList,
    getGlobalResources, addGlobalResources, getProcessCodeSchemaPrices
} from "../../actions/RessursActions"
import { loadProcessCodesList } from "../../actions/processcodeActions"
import { addFlashMessage } from "../../actions/FlashMessagesAction"
import RessursbrukComponent from '../../components/entrepreneur/RessursbrukComponent'


const mapStateToProps = (state, ownProps) =>
{
    return {
        resources: state.ressursBruk.resources,
        tjenester: state.processCodes,
        skifter: state.ressursBruk.skift,
        prices: state.ressursBruk.prices,
        Type: MeldingRessursforbruk,
        params: ownProps.params,
    }
}

const mapDispatchToProps = (dispatch) =>
{
    return bindActionCreators({
        getResources, addResource, updateResourceList, deleteResourceList,
        loadProcessCodesList, loadSkiftList, getProcessCodeSchemaPrices,
        getGlobalResources, addGlobalResources, addFlashMessage
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RessursbrukComponent)


