import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import DropDownList from '../common/DropDownList';

const defaultCategoryOption = {
    navn: 'Vis alle prosesskoder',
    id: '*',
    value: '*',
    label: 'Vis alle prosesskoder',
};
const mostUsedCategoryOption = {
    navn: 'Mest brukte prosesskoder',
    id: '**',
    value: '**',
    label: 'Mest brukte prosesskoder',
};

class RessursbrukFormProsesskodeVelger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: '*',
            groupedItems: [],
            groups: [],
            filteredCodes: [],
        };
    }

    getTjenester = prices =>
        map(prices, itm => {
            return {
                id: itm.prosesskode.id,
                value: itm.prosesskode.id,
                navn: `${itm.prosesskode.navn}  ${itm.prosesskode.beskrivelse}`,
                label: `${itm.prosesskode.navn}  ${itm.prosesskode.beskrivelse}`,
                kode: itm.prosesskode.navn,
                beskrivelse: itm.prosesskode.beskrivelse,
            };
        });

    componentDidMount = () => {
        const { selectedCategory } = this.state;
        this.setState({
            selectedCategory: '*',
        });
    };

    componentDidUpdate = prevProps => {
        const { Items, kontraktId } = this.props;
        const { groupedItems, selectedCategory } = this.state;

        const processCodes = this.getTjenester(Items);

        // Grupper prosesskoder basert på kode og deler av beskrivele (Arbeidstype) for lettere å kunne finne riktig kode i dropdawn
        const groups = groupBy(processCodes, i => {
            const { kode, beskrivelse } = i;
            let typeArbeidKode = '';
            if (kode.indexOf('BYM') === 0) {
                typeArbeidKode = 'BYM';
            } else if (kode.indexOf('ENT') === 0) {
                typeArbeidKode = 'ENT';
            } else {
                typeArbeidKode = kode[0];
            }
            let typeArbeidBeskrivelse = beskrivelse.substring(0, i.beskrivelse.indexOf(' - '));

            return `${typeArbeidKode} - ${typeArbeidBeskrivelse}`;
        });

        if (!isEmpty(processCodes) && isEmpty(groupedItems)) {
            this.setState({
                groups: [
                    ...map(groups, (e, k) => {
                        return { navn: k, id: k, value: k, label: k };
                    }),
                ],
                groupedItems: groups,
            });
        }
        if (kontraktId !== prevProps.kontraktId) {
            this.setState({
                selectedCategory: '*',
                groupedItems: [],
                groups: [],
                filteredCodes: [],
            });
        }
    };

    render() {
        const { selected, error, Items, handleChangeTjeneste } = this.props;
        const { groupedItems, selectedCategory, groups } = this.state;
        const processCodes = this.getTjenester(Items);

        let filterdProcessCodes = [];
        const mostUsedProcessCodes = filter(processCodes, item => mestBrukte.indexOf(item.id) !== -1);

        if (!selectedCategory || selectedCategory === '*') {
            filterdProcessCodes = orderBy(processCodes, ['navn'], ['asc']);
        } else if (selectedCategory === '**') {
            filterdProcessCodes = orderBy(mostUsedProcessCodes, ['navn'], ['asc']);
        } else {
            filterdProcessCodes = orderBy(groupedItems[selectedCategory], ['navn'], ['asc']);
        }

        const filterOptions = [defaultCategoryOption, ...orderBy(groups, ['navn'], ['asc'])];

        if (mostUsedProcessCodes.length > 0) filterOptions.splice(1, 0, mostUsedCategoryOption);

        return (
            <div className="kategori-velger">
                <div className="form-group kategori-velger-first">
                    <label className="control-label" htmlFor="category-select">
                        Velg type arbeid
                    </label>
                    <Select
                        id="category-select"
                        options={filterOptions}
                        value={selectedCategory}
                        placeholder="Velg type arbeid"
                        label=""
                        onChange={e => {
                            this.setState({ selectedCategory: e ? e.value : '*' });
                            handleChangeTjeneste();
                        }}
                    />
                </div>
                <div className={`form-group ${error && 'has-error'}`}>
                    <label className="control-label" htmlFor="selectedTjenesteVelger">
                        Prosesskode *
                    </label>
                    <Select
                        id="selectedTjenesteVelger"
                        value={selected}
                        options={filterdProcessCodes}
                        placeholder="Velg prosesskode"
                        onChange={handleChangeTjeneste}
                    />
                    {error && <span className="help-block">{error}</span>}
                </div>
            </div>
        );
    }
}

RessursbrukFormProsesskodeVelger.propTypes = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    Items: PropTypes.array.isRequired,
    kontraktId: PropTypes.string.isRequired,
    handleChangeTjeneste: PropTypes.func.isRequired,
};

export default RessursbrukFormProsesskodeVelger;

const mestBrukte = [
    // Skilt (NB: hold disse kronologisk ved endringer, og sørg for å fjerne koder som ikke lengre finnes i prosesskodeskjema 23)
    '8c0bd747-281a-4153-bd8c-586e0fb5d766',	 // A1.1.1
    '86852a65-6b94-4295-b762-6957f2705949',	 // A1.1.2
    '5622e798-738b-467b-8cc0-5d8af4946b56',	 // A1.2.1
    'e2f81cad-2719-4821-99df-141a78576e46',	 // A1.2.2
    '8baef5ea-69fc-4c52-bed2-a5fd223e2df7',	 // A1.3.1
    '5d2875f9-4951-46a5-bf12-946b474cbc65',	 // B2.3.1
    'f58e6012-09bf-4daf-b34b-dd15b1a195db',	 // B2.3.6
    '353626a6-5c79-4e32-8164-e9590a4ee3d8',	 // B2.3.8
    'e8cdb00b-85fd-4855-8865-6d437efddfb5',	 // B2.4.6
    'ebc26a83-7a87-4361-b099-47b2d2e6db43',	 // B2.6.15
    '62f27c7f-5f8c-4618-9db0-8887ca4c19b4',	 // C3.1.5
    '90504e6b-0fc1-4519-8dc4-2abae00a44e9',	 // C3.1.6
    '5de6419d-9fac-4421-825a-4069926c51d3',	 // C3.2.7
    '2ed51327-74f8-4afc-bf4a-89c4dbf02be3',	 // C3.2.8
    '7785c438-4803-40a7-b7d0-445c64a912b0',	 // E5.1.1
    '54f40534-63c1-4fc9-8342-ec22b34a6650',	 // F6.1.1
    '62d4d901-3cbb-4d87-b15b-684e95e45b6d',	 // G7.1.1
    '6b21581d-6b9b-47eb-8cf1-c4590d93a31c',	 // G7.1.3
    'd84df7fc-8b61-4c2a-b48b-500493fd1acb',	 // H8.1.3
    'eb2953f0-a790-47ad-8219-e821d5cff38e',	 // H8.1.7
    '13871cf9-2524-42a2-9f2b-3e51db5dc401',	 // H8.1.10
    'eebf0325-c8bc-46fb-9dda-cd12560c1df8',	 // H8.1.11
    'c5128057-6fd4-4634-9862-0ea70baa1376',	 // H8.1.12
    '2a65d662-ed01-4eaa-bc1c-5d7e217755bc',	 // H8.1.15
    'af96a06a-9c03-4245-b62b-4bad4f1efaff',	 // I9.1.5
    '738357a1-7ac7-41f5-8756-e900225876ec',	 // I9.1.8
    '71c2bc39-9f7a-4d38-8170-bc1793d45df4',	 // I9.1.15
    '3f886e79-c2f0-4819-acbd-0619f4826489',	 // I9.1.16
    '32202a34-20f1-40af-8907-086029deab9d',	 // I9.1.18
    '1b3a1f97-6f30-4559-a8fe-40be301926b7',	 // I9.1.19
    '98671ab5-576b-4513-a4cf-a82f35969ab0',	 // J10.1.2
    'ead7f16a-3703-46c5-90b5-f29afb42f2a8',	 // K11.1.8
    'd3160154-eabb-479c-b476-b14704cf55c6',	 // K11.1.14
    '1b54996e-cf13-49b7-bccd-11388f81fe54',	 // K11.1.15
    'b643d51e-52a8-45fb-bdeb-169bb5a075b7',	 // K11.1.23
    'c56efb0b-8fa1-4eee-b913-43590b2da9c8',	 // K11.1.24
];
