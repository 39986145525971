import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Glyphicon } from 'react-bootstrap';
import EditUserForm from './EditUserForm';
import '../../../css/user/CollapsibleUser.css';

const CollapsibleUser = ({ user, open, onOpen, onSave, roles, avtaler, selskapsType }) => {
    const toggleOpen = () => {
        onOpen(open ? null : user.id);
    };
    const toggleIcon = () => {
        if (open)
            return (
                <div className="collapsibleDiv">
                    <Glyphicon glyph="chevron-down" />
                    {user.navn}
                </div>
            );
        else
            return (
                <div className="collapsibleDiv">
                    <Glyphicon glyph="chevron-right" />
                    {user.navn}
                </div>
            );
    };

    return (
        <div>
            <div id="chevronDiv" onClick={toggleOpen} className="btn">
                {toggleIcon()}
            </div>
            <div>
                <Collapse in={open}>
                    <div>
                        <EditUserForm
                            user={user}
                            roles={roles}
                            onSave={user => {
                                toggleOpen();
                                onSave(user);
                            }}
                            onCancel={() => {
                                toggleOpen();
                            }}
                            avtaler={avtaler}
                            selskapsType={selskapsType}
                        />
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

CollapsibleUser.defaultTypes = {
    open: false,
};

CollapsibleUser.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    avtaler: PropTypes.array.isRequired,
    open: PropTypes.bool,
    onOpen: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    selskapsType: PropTypes.number.isRequired,
};

export default CollapsibleUser;
