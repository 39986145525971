import Cookies from 'js-cookie';

const featureIsEnabled = featureKey => {
    const value = Cookies.get(featureKey);
    return value === 'true';
};

// Features
export const FEATURE_DASHBOARD_STATISTIKK = 'FEATURE_DASHBOARD_STATISTIKK';
export const FEATURE_VIS_VARSEL = 'FEATURE_VIS_VARSEL';

export default featureIsEnabled;
