export const home = '/';
export const admin = '/admin';
export const meldingseksport = '/admin/meldingseksport';
export const login = '/login';
export const glemtpass = '/konto/glemtpassord';
export const resetpassordBase = '/konto/passord';
export const resetpass = '/konto/passord/:id';
export const activateuser = '/passordaktivering/:id';
export const forgotpasswordEpostrecieved = '/konto/linkforglemtpassordersent';
export const UpdatePasswordConfirmed = '/konto/endretpassordbekreftet';
export const meldinger = '/meldinger';
export const selskaper = '/entreprenorer';
export const entrepreneursDetail = selskaper + '/:id';
export const selskapKontrakter = '/entreprenorkontrakter';
export const globalressursbruk = '/globalressursbruk';
export const selskapKontrakterDetail = selskapKontrakter + '/:id';
export const selskapKontrakPriser = selskapKontrakter + '/:selskapsId/kontrakter/:kontraktsId';
export const kontrakter = '/Kontrakter';
export const kontrakterDetail = selskaper + '/:selskapsId' + kontrakter + '/:kontraktsId';
export const brukere = '/bruker';
export const samarbeiderpartnere = '/samarbeiderpartnere';
export const prosessKodeSkjema = '/prosesskoder';
export const prosessKodeSkjemaDetail = prosessKodeSkjema + '/:id';
export const serviceAvdelingen = '/serviceavdelingen';
export const selskapBrukere = '/entreprenorbrukere';
export const saksbehandlerMeldinger = '/saksbehandlermeldinger';
export const saksbehandlerMeldingsDetail = saksbehandlerMeldinger + '/:id';
export const entrepreneurMeldinger = '/entreprenormeldinger';
export const entrepreneurMeldingsDetail = entrepreneurMeldinger + '/:id';

export const meldingerv2 = '/meldingerv2';
export const meldingermapDisplay = '/kartvisning';
export const avtaler = '/avtaler';

export const rapporter = '/rapporter';
export const meldingsstatistikkrapport = '/meldingsstatistikkrapport';
export const monedsrapportmateriellrapport = '/monedsrapportmateriellrapport';
export const monedsrapportavviksarbeidrapport = '/monedsrapportavviksarbeidrapport';
export const fakturagrunnlagrapport = '/fakturagrunnlagrapport';
export const avvistemeldingerrapport = '/avvistemeldingerrapport';
export const innmeldingerrapport = '/innmeldingerrapport';
export const ressursforbrukrapport = '/ressursforbrukrapport';
export const gatelysmonedsrapporter = '/gatelysmonedsrapporter';
export const vinterforbrukfakturagrunnlag = '/vinterforbrukfakturagrunnlagrapport';
export const vinterforbrukokonomi = '/vinterforbrukokonomirapport';
export const dagmulktrapport = '/dagmulktrapport';
//export const meldingDetaljer =meldingDetail+"/:id"
export const lightbox = '/lightbox';
export const ressursbruk = '/ressursbruk';
export const notFound = '/notFound';
export const nedtid = '/nedtid';

export function getDisplayName(path) {
    const pathMapping = {
        [meldinger]: 'Meldinger',
        [selskaper]: 'Entreprenører',
        [kontrakter]: 'Kontrakter',
        [brukere]: 'Bruker',
        [prosessKodeSkjema]: 'Prosesskoder',
        [serviceAvdelingen]: 'Serviceavdelingen',
        [avtaler]: 'Avtaler',
        [selskapBrukere]: 'Brukere',
        [selskapKontrakter]: 'Kontrakter',
        [meldingsstatistikkrapport]: 'Meldingsstatistikk rapport',
        [monedsrapportmateriellrapport]: 'Måneds materiell rapport',
        [monedsrapportavviksarbeidrapport]: 'Måneds avviksarbeid rapport',
        [fakturagrunnlagrapport]: 'Fakturagrunnlag rapport',
        [ressursforbrukrapport]: 'Ressursforbruk rapport',
        [globalressursbruk]: 'Ressursbruk',
        [rapporter]: 'Rapporter',
        [fakturagrunnlagrapport]: 'Fakturagrunnlag rapport',
        [avvistemeldingerrapport]: 'Avvistemeldinger rapport',
        [innmeldingerrapport]: 'Innmeldinger rapport',
        [vinterforbrukokonomi]: 'Vinterforbruk økonomi rapport',
        [vinterforbrukfakturagrunnlag]: 'Vinterforbruk fakturagrunnlag rapport',
        [dagmulktrapport]: 'Dagmulkt rapport',
        [gatelysmonedsrapporter]: 'Måneds rapport for gatelys',
        [admin]: 'Admin',
    };
    const indexOfNextSlash = path.substring(1).indexOf('/');
    if (indexOfNextSlash > 0) path = path.substring(0, indexOfNextSlash + 1);

    if (!(path in pathMapping)) return '';
    return pathMapping[path];
}
