const users = {
    userList: { users: [], error: null, loading: false },
    newUser: { user: null, error: null, loading: false },
    deletedUser: { user: null, error: null, loading: false },
    updatedUser: { user: null, error: null, loading: false },
};

export default {
    entreprenorer: [],
    saksbehandlere: [],
    contracts: [],
    contractZones: [],
    processcodeschemes: [],
    processCodes: [],
    flashMessages: [],
    common: {},
    lastChanged: '',
    users,
    meldinger: {},
    ressursBruk: {},
    kommentarer: [],
    historisk: [],
    tilbakemeldinger: [],
    lagredSok: [],
    delbydeler: [],
    feilrettingskoder: [],
    varsel: [],
};
