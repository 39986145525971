
import * as types from "../actions/ActionTypes"
//import InitialState from "./initialState"

const initialState={
    resources:{},
    skift:[],
    prices:[],
    processcodeunit:[]
}

const ressursBrukReducer=(state = initialState, action={})=>{
    switch (action.type)
    {
        case types.LOAD_RESSURS_SKIFTLIST_SUCCESS:
        return {
            ...state,skift:action.skift
        }
        case types.LOAD_PRICES_FOR_CONTRACT:{
            return {
                ...state, prices:action.prices
            }
        }
         case types.LOAD_PROCESSCODE_UNIT:{
            return {
                ...state, processcodeunit:action.processcodeunit
            }
        }
        case types.LOAD_RESSURS_LIST_SUCCESS:
            return {
               ...state, resources: action.resources 
            }
        // case types.ADD_RESSURS_SUCCESS:
        //     return {
        //          ...state, resources: [...state.resources,action.resource]
        //     }
        
        //  case types.UPDATE_RESSURS_LIST_SUCCESS:{
        //     const index= state.resources.findIndex(x=>x.prosesskodeEnhetId===action.resource.prosesskodeEnhetId)
        //     const copyresources=[...state.resources]
        //     copyresources.splice(index,1,action.resource)
        //     return{
        //         ...state, resources: copyresources
        //     }
        //  }

        // case types.DELETE_RESSURS_LIST_SUCCESS:{
        //     const filter= state.resources.ressursforbruk.filter(x=>x.ressursforbrukId!==action.ressursforbrukId)
        //     console.log(filter)
        //     return{
        //         ...state, resources: {filter}
        //     }
        // }

       /* case types.LOAD_RESSURS_TJENESTELIST_SUCCESS:
            return {
               ...state, resources: action.resources 
            }
*/

        default:
            return state;
    }
}
export default ressursBrukReducer