import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDateTimeHavingKL } from '../../utils/utils';
//import { toDateTimeString } from "../../utils/dateTimeHelpers"
import _ from 'lodash';

class MeldingHistorisk extends Component {
    renderRow(feltName, i) {
        return (
            <div className="row historic-div-values" id={feltName === 'Rutingsammendrag' ? 'hideRutingsammendrag' : ''}>
                <div className="col-xs-4"> {feltName} </div>
                <div className="col-xs-4 HistoricOldValue"> {i.fra} </div>
                <div className="col-xs-4 HistoricNewValue"> {i.til} </div>
            </div>
        );
    }

    extractFeltsThatChanged(objFelter) {
        const list = _.get(objFelter, 'felter');
        return _.map(list, (value, key) => {
            return this.renderRow(key, value);
        });
    }

    const;

    renderMeldingHistoric() {
        const list = this.props.historicList;

        return _.map(list, (i, index) => {
            return (
                <div key={index} className="meldingHistoric">
                    <div className="HistoricRow">
                        <div className="row">
                            <span className="img-circle glyphicon glyphicon-user"></span>
                            &nbsp;
                            <strong>
                                {i.historikkBrukerNavn ? i.historikkBrukerNavn : ''} —{' '}
                                {i.historikkBrukerSelskap
                                    ? i.historikkBrukerSelskap != 'Serviceavdelingen'
                                        ? i.historikkBrukerSelskap
                                        : 'BYM'
                                    : ''}
                            </strong>
                            &nbsp;&nbsp;
                            <span> {formatDateTimeHavingKL(i.historikkTidspunkt)}</span>
                        </div>
                        <div className="row historic-row-felts">
                            <div className="col-xs-4"> {'Felt'} </div>
                            <div className="col-xs-4"> {'Opprinnelig verdi'} </div>
                            <div className="col-xs-4"> {'Ny verdi'} </div>
                        </div>
                        {_.map(this.extractFeltsThatChanged(i), (item, index) => {
                            return <div key={index}> {item}</div>;
                        })}
                        <br />
                    </div>
                </div>
            );
        });
    }
    render() {
        const size = _.size(this.props.historicList);
        return (
            <div>
                <div className="meldingerLIST">
                    {size === 0 && <span>Ingen historisk for denne meldingen ennå. </span>}
                    {size > 0 && this.renderMeldingHistoric()}
                </div>
                <br />
            </div>
        );
    }
}

MeldingHistorisk.propTypes = {
    historicList: PropTypes.array.isRequired,
    isEntreprenor: PropTypes.bool,
};

export default MeldingHistorisk;
