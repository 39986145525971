import * as types from './ActionTypes';
import { kontrakterUrl } from "../constants/api";
import * as api from '../constants/api';


export function loadPricesSuccess(prices)
{
  return { type: types.LOAD_PRICES_SUCCESS, prices };
}

export function createPriceSuccess(price)
{
  return { type: types.CREATE_PRICE_SUCCESS, price };
}

export function updatePriceSuccess(price)
{
  return { type: types.UPDATE_PRICE_SUCCESS, price };
}

export function deletePriceSuccess(price)
{
  return { type: types.DELETE_PRICE_SUCCESS, price };
}


const url_priser_base = kontrakterUrl; //+ /kontraktid/priser : ba46a1da-46af-48e9-a6af-38299aba3eb9/priser"


export function loadPrices(contractId)
{
  return function (dispatch)
  {
    var url_priser = url_priser_base + "/" + contractId + "/priser";
    var priser = [];
    //axios.defaults.withCredentials = false;
    api.getClient().get(url_priser)
      .then(function (response)
      {
        priser = response.data.result;
        dispatch(loadPricesSuccess(priser));
      })
      .catch(function (error)
      {
        throw (error);
      });
  };
}

export function createPrice(pris, prosesskodeId, kontraktId, ny_pris_obj)
{
  return dispatch =>
  {
    var url_priser = url_priser_base + "/" + kontraktId + "/priser";
    var postobjekt = { prosesskodeId: prosesskodeId, pris: pris };
    return api.getClient().post(url_priser, postobjekt)
      .then(response =>
      {
        if (response.data)
        {
          var res = response.data.result;
          ny_pris_obj.id = res.id;
          dispatch(loadPrices(kontraktId));
        }
      })
      .catch(function (error)
      {
        throw (error);
      });
  };
}



export function updatePrice(pris, prisObj, kontraktId)
{
  return function (dispatch)
  {

    var url_priser = url_priser_base + "/" + kontraktId + "/priser/" + prisObj.id;  //PUT /api/kontrakter/{kontraktId}/priser/{prisId}

    var p = { "pris": pris };
    return api.getClient().put(url_priser, p)
      .then(() =>
      {
        dispatch(loadPrices(kontraktId));
      })
      .catch(function (error)
      {
        throw (error);
      });
  };
}


export function deaktiverPrice(prisId, kontraktId, this_pris_obj)
{
  return dispatch =>
  {
    const url_priser = url_priser_base + "/" + kontraktId + "/priser/" + prisId;
    //axios.default.withCredentials = false;
    return api.getClient().delete(url_priser)
      .then(() =>
      {
        dispatch(loadPrices(kontraktId));
      })
      .catch(function (error)
      {
        throw (error);
      });
  };
}