/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Control from 'react-leaflet-control';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import $ from 'jquery';

import { kartlag, layerInfoUrl } from '../../constants/kartGlobals';
import Checkbox from '../common/Checkbox';
import { userHasAccessWithRole } from '../../utils/Authorization';

class KartlagVelger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      symboler: [],
      collapseFilters: false,
      expandedLayerDetail: '',
    };
  }

  componentDidMount() {
    const kartlagInfo = layerInfoUrl;
    $.getJSON(kartlagInfo, res => {
      const { symboler } = this.state;
      this.setState({ symboler: [...symboler, ...res.layers] });
    });
  }

  modifyKartSymbolNames(label) {
    switch (label) {
      case 'E':
        return 'Europavei';
      case 'K':
        return 'Kommunal vei';
      case 'P':
        return 'Privat vei';
      case 'R':
        return 'Riksvei';
      case 'S':
        return 'Skogsvei';
      case 'Armatur infrastruktur - VTP':
        return 'VPT';
      case 'Tilstand ukjent':
        return 'Gatelysskap - Tilstand ukjent';
      case 'Byttes snarest':
        return 'Gatelysskap - Byttes snarest';
      case 'Gammel OK':
        return 'Gatelysskap - Gammel OK';
      case 'OK':
        return 'Gatelysskap - OK';
      case 'Armatur infrastruktur - Kabel':
        return 'Kabel';
      case 'Armatur infrastruktur - Gatelyslinjer':
        return 'Gatelyslinjer';
      case 'Armatur infrastruktur - Wirestrekk':
        return 'Wirestrekk';
      default:
        return label;
    }
  }

  render() {
    const {
      position,
      onSelectKartlag,
      ListDisplay,
      valgteKartlag,
    } = this.props;
    const { symboler, collapseFilters, expandedLayerDetail } = this.state;

    const pildown = 'glyphicon-chevron-down';
    const pilright = 'glyphicon-chevron-right';

    const sortedKartlag = _.sortBy(kartlag, k => k.displayName);

    return (
      <Control position={position}>
        <div
          className="filterButtonWithBorder"
          id={collapseFilters ? 'filterButtonWithoutBorder' : ''}
        >
          <Button
            className="btn filterButton"
            onClick={() => {
              this.setState({
                collapseFilters: !collapseFilters,
              });
            }}
          >
            <i className="material-icons" id="materialIcon">
              layers
            </i>
            Kartlag
          </Button>
          {collapseFilters &&
            sortedKartlag.map(e => {
              const kartlagBeskrivelseSymboler = _.first(
                _.filter(symboler, { layerId: e.ledgendId })
              );
              const availableForRole = e.onlyAvailableForRoles
                ? userHasAccessWithRole(e.onlyAvailableForRoles)
                : true;
              const isExpanded =
                expandedLayerDetail && expandedLayerDetail === e.displayName;
              const isChecked = _.find(valgteKartlag, c => c === e.layerId);

              return availableForRole ? (
                <div key={e.displayName}>
                  <span
                    style={{ fontSize: '10px', float: 'left' }}
                    onClick={() =>
                      this.setState({
                        expandedLayerDetail: isExpanded ? '' : e.displayName,
                      })
                    }
                    className={`glyphicon ${isExpanded ? pildown : pilright}`}
                    title={e.layerId}
                  />
                  <Checkbox
                    style={{ float: 'left' }}
                    key={e.layerId}
                    value={e.layerId}
                    name="checkboxSearchMap"
                    title={e.displayName}
                    onChange={onSelectKartlag}
                    checked={isChecked}
                    tooltip={e.layerId}
                  />
                  {isExpanded && (
                    <div>
                      {e.ledgentUrl && (
                        <a target="_" href={e.ledgentUrl}>
                          Tegnbeskrivelse
                        </a>
                      )}
                      {kartlagBeskrivelseSymboler &&
                        kartlagBeskrivelseSymboler.legend.map(legend => {
                          if (legend) {
                            const baseString64 =
                              'data:' +
                              legend.contentType +
                              ';base64,' +
                              legend.imageData;
                            return (
                              <p key={legend.label}>
                                <img
                                  className="pull-left"
                                  style={{
                                    marginLeft: '20px',
                                    marginRight: '5px',
                                  }}
                                  src={baseString64}
                                  alt={legend.label}
                                />
                                <span>
                                  {legend.label
                                    ? this.modifyKartSymbolNames(legend.label)
                                    : this.modifyKartSymbolNames(
                                        kartlagBeskrivelseSymboler.layerName
                                      )}
                                </span>
                                <br />
                              </p>
                            );
                          }
                          return '';
                        })}
                    </div>
                  )}
                </div>
              ) : (
                <span />
              );
            })}
        </div>
      </Control>
    );
  }
}

export default KartlagVelger;
