export const EmailIsRequired = 'E-post må fylles ut.';
export const EmailIsNotValid = 'E-post er ikke gyldig.';
export const NameIsRequired = 'Navn må fylles ut.';
export const AddressIsRequired = 'Adresse må fylles ut.';
export const GeoPositionIsRequired = 'GPS-koardinater må fylles ut.';
export const RoleIsRequired = 'En rolle må velges.';
export const PasswordIsRequired = 'Passord må fylles ut.';
export const UserIdIsrequired = 'Brukerid må fylles ut.';
export const FromDateIsLongerThanNow = 'Fradato må ikke være større enn idag.';
export const MessageIdIsRequired = 'Meldingsnr. må fylles ut.';
export const PhoneNumberIsRequired = 'Telefonnummer må fylles ut.';
export const PhoneNumberIsNotValid = 'Telefonnummer må være minst 8 sifre.';
export const OrgNumberIsRequired = 'Organisasjonnummer må fylles ut.';
export const OrgNumberIsNotValid = 'Organisasjonnummer må være 9 sifre.';
export const OrgNumberNotExists = 'Organisasjonnummer er ikke gyldig.';
export const orgnumberLength = 'Organisasjonnummer må være 9 sifre.';
export const ContractNameIsRequired = 'Kontraktnavn må fylles ut.';
export const selskapHasNoContracts = 'Selskap har ingen kontrakt.';
export const RegionIsRequired = 'Et område må velges.';
export const DateFromIsRequired = 'Fradato må velges.';
export const DateToIsRequired = 'Tildato må velges.';
export const FraDatoAndToDate = 'Fradato må ikke være lenger en til dato.';
export const ProcesscodeSchemaIsRequired = 'Et prosesskodeskjema må velges.';
export const ProcesscodeIsRequired = 'Prosesskode må velges.';
export const searchTextForMap = 'Søketekst må fylles ut.';
export const tilbakeMeildingNotValid = 'Tilbakemelding må fylles ut.';
export const mengdeIsRequired = 'Mengde må fylles ut.';
export const mengdeIsNotDouble = 'Mengde må være et tall.';
export const timeIsRequired = 'Tid må velges';
export const timeNotValid = 'Tid må være i formatet TT:MM';
export const commentsIsRequired = 'Kommentar må fylles ut.';
export const DatoIsRequired = 'Dato må fylles ut.';
export const DatoNotValid = 'Dato må være i formatet DD.MM.ÅÅÅÅ';

export const ShiftIsRequired = 'Skift må fylles ut.';
export const ProsseskodeprisschemaIsRequired = 'Prosesskode ikke valgt.';
export const avtaleIsRequired = 'En avtale må velges.';
export const entrepreneurIsRequired = 'En entreprenør må velges.';
export const contractIsRequired = 'En kontrakt må velges.';
export const meldingstatusIsRequired = 'En meldingstatus må velges.';
export const meldingsTypeIsRequired = 'En meldingstype må velges.';
export const PrisPositivInteger = 'Pris kan ikke være negativ.';
export const NumberShouldBeInteger = 'Angi et positivt tall.';
export const meldingBeskrivelseTextLength =
    'Melding beskrivelse teksten må være mindre en 300 tegn.';
export const addressIsOutSideOslo = 'Adressen er utenfor Oslo.';
export const chooseYearIsRequired = 'Vennligst velg et år.';
export const chooseMonthIsRequired = 'Vennligst velg en måned.';
export const searchNameIsRequired = 'Navn er påkrevd.';

/****************************************remove errors on validation **********************/
export const removeTextError = (errors, key, value) => {
    if (value && value.length > 0) {
        delete errors[key];
    }
};
export const removeDropdownError = (errors, key, value) => {
    if (value !== '0' && value !== '') {
        delete errors[key];
    }
};
/****************************************************************************************** */
