import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as processcodeActions from '../../actions/processcodeActions';
import { loadProcesscodes } from '../../actions/processcodeActions';
import ProsessCodeAdd from '../../components/processcode/ProsessCodeAdd';
import ProsessCodeList from '../../components/processcode/ProcessCodeList';
import { getProcesscodeUnits } from '../../actions/RessursActions';
import '../../css/Entrepreneur.css';
import '../../css/processcode.css';

class ProcessCodeDetailsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            code: '',
            selectedEnhet: '0',
            description: '',
        };

        this.changeUnit = this.changeUnit.bind(this);
        this.validateAndSave = this.validateAndSave.bind(this);
        this.onChangeKode = this.onChangeKode.bind(this);
        this.onChangeBeskrivelse = this.onChangeBeskrivelse.bind(this);
    }

    componentDidMount() {
        this.props.loadProcesscodes(this.props.params.id);
        this.props.getProcesscodeUnits();
    }

    mapProcesscodeenhet(id) {
        const p = _.first(_.filter(this.props.prosesskodeenhet, { id }));
        return p.navn;
    }

    validateAndSave() {
        const { selectedEnhet, code, description } = this.state;
        const processcodeenhetId = parseInt(selectedEnhet, 10);
        const prosesskodeEnhetNavn = this.mapProcesscodeenhet(processcodeenhetId);
        let values = {
            navn: code,
            beskrivelse: description,
            prosesskodeEnhetId: processcodeenhetId,
            prosesskodeEnhetNavn,
            prosesskodeSkjemaId: parseInt(this.props.params.id, 10),
        };
        if (code !== '' && description !== '' && selectedEnhet !== '0') {
            this.props.actions.saveProcesscode(values);
        }

        this.setState({
            changed: false,
            code: '',
            description: '',
            selectedEnhet: '0',
        });

        document.getElementById('codeinput').value = '';
        document.getElementById('descriptioninput').value = '';
    }

    deaktiver(processcode) {
        this.props.actions.deaktiverProcesscode(processcode);
    }

    changeName(e, processcode) {
        let values = {
            id: processcode.id,
            prosesskodeSkjemaId: this.props.params.id,
            navn: e,
            beskrivelse: processcode.beskrivelse,
            prosesskodeEnhetId: processcode.prosesskodeEnhetId,
            prosesskodeEnhetNavn: processcode.prosesskodeEnhetNavn,
        };

        this.props.actions.changeName(values);
    }

    changeUnit(e) {
        const { value } = e.target;
        this.setState({ selectedEnhet: value });
    }

    onChangeKode(e) {
        return this.setState({ code: e.target.value, initialcode: false });
    }

    onChangeBeskrivelse(e) {
        return this.setState({ description: e.target.value, initialdescription: false });
    }

    render() {
        if (!this.props.processcodes) return false;
        const { processcodes } = this.props;
        const enheter = this.props.prosesskodeenhet;
        if (!enheter) return null;
        return (
            <div className="mainContainer">
                <div className="processcode">
                    <ProsessCodeAdd
                        onChangeKode={this.onChangeKode}
                        onChangeBeskrivelse={this.onChangeBeskrivelse}
                        changeUnit={this.changeUnit}
                        selected={this.state.selectedEnhet}
                        enheter={enheter}
                        validateAndSave={this.validateAndSave}
                    />
                    <ProsessCodeList
                        processcodes={processcodes}
                        enheter={enheter}
                        onClick={processcode => this.deaktiver(processcode)}
                        onSubmit={(e, processcode) => this.changeName(e, processcode)}
                    />
                </div>
            </div>
        );
    }
}

ProcessCodeDetailsContainer.defaultProps = {
    processcodes: [],
    prosesskodeenhent: [],
};

ProcessCodeDetailsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    lastChangedDate: PropTypes.string,
    processcodes: PropTypes.array.isRequired,
    loadProcesscodes: PropTypes.func.isRequired,
    prosesskodeenhet: PropTypes.array,
    getProcesscodeUnits: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        processcodes: state.processCodes,
        prosesskodeenhet: state.ressursBruk.processcodeunit,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(processcodeActions, dispatch),
        loadProcesscodes: bindActionCreators(loadProcesscodes, dispatch),
        getProcesscodeUnits: bindActionCreators(getProcesscodeUnits, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessCodeDetailsContainer);
