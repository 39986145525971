import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import EditableField from '../../components/common/EditableField';
//import { formatDateByCommaSepratedDayMonthYear } from "../../utils/utils"
import '../../css/Contract.css';
import FromToDate from '../common/FromToDate';
const ContractDetailHeader = ({ contract, onSubmitName, onSubmitEditableDateTo, byAdmin }) => {
    return (
        <div>
            <br />
            <h1>
                <b>{contract.navn}</b>
            </h1>
            <br />
            <div className="detail-header">
                <Row>
                    <Col md={1}>
                        <div className="text-12px">
                            <strong>Kontraktsnavn:</strong>
                        </div>
                    </Col>
                    <Col md={4} className="editableKontraktNavn">
                        {byAdmin ? (
                            <EditableField
                                inputLength={3}
                                text={contract.navn}
                                onSubmit={onSubmitName}
                                id="editablewidth"
                            />
                        ) : (
                            <div className="text-12px">{contract.navn}</div>
                        )}
                    </Col>
                    <Col md={1}>
                        <div className="text-12px">
                            <strong>Gyldig:</strong>
                        </div>
                    </Col>
                    <Col md={4} className="editableDate">
                        <div className="text-12px" id="dateRange">
                            <FromToDate
                                editable={true}
                                onSubmitEditableDateTo={onSubmitEditableDateTo}
                                from={contract.gyldigFraDato}
                                to={contract.gyldigTilDato}
                                id="ediatableToDate"
                            />
                        </div>
                    </Col>
                </Row>

                <div className="gap-10"></div>
                <Row>
                    <Col md={1}>
                        <div className="text-12px">
                            <strong>Avtale:</strong>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="text-12px">{contract.avtale.navn}</div>
                    </Col>
                    <Col md={1}>
                        <div className="text-12px">
                            <strong>Prosesskodeskjema:</strong>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="text-12px">{contract.prosesskodeSkjema.navn}</div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

ContractDetailHeader.propTypes = {
    contract: PropTypes.object.isRequired,
    onSubmitName: PropTypes.func.isRequired,
    onclickDate: PropTypes.func,
    onSubmitEditableDateTo: PropTypes.func,
};

export default ContractDetailHeader;
