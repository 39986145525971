import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadEntrepreneurs } from '../../actions/selskaperActions';
import EntrepreneurListRow from './EntrepreneurListRow';
import { Button, Glyphicon } from 'react-bootstrap';
import NewEntrepreneurModalContainer from './NewEntrepreneurModalContainer';
import _ from 'lodash';
import * as responseMessages from '../../constants/ResponseMessages';
import '../../css/Entrepreneur.css';

class EntrepreneursPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillMount() {
        this.props.loadEntrepreneurs();
    }

    handleClick() {
        this.newentrepreneurmodel.getWrappedInstance().openModal();
    }
    render() {
        let entrepreneurs = this.props.entreprenorer;
        let size = _.size(entrepreneurs);
        return (
            <div className="mainContainer">
                <div className="col-xs-12">
                    <div>
                        <Button bsSize="large" className="bym-add-btn" onClick={this.handleClick}>
                            <Glyphicon glyph="plus" />
                            Opprett entreprenør
                        </Button>
                    </div>
                    {/*<Row>
                        <Col md={12}>
                            <span aria-hidden="true"/> &nbsp;
                        </Col>
                    </Row>*/}
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Entreprenør</th>
                                <th>Organisasjonsnr.</th>
                                <th>E-post</th>
                            </tr>
                        </thead>
                        <tbody>
                            {size > 0 &&
                                _.map(this.props.entreprenorer, (entrepreneur, index) => (
                                    <EntrepreneurListRow key={index} selskap={entrepreneur} />
                                ))}
                            {size === 0 && (
                                <tr>
                                    <td>{responseMessages.NoEntrepreneursMessage}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <NewEntrepreneurModalContainer
                        initialChecked={false}
                        callbackParent={() => {}}
                        ref={m => (this.newentrepreneurmodel = m)}
                    />
                </div>
            </div>
        );
    }
}

EntrepreneursPage.defaultProps = {
    entreprenorer: [],
};

EntrepreneursPage.propTypes = {
    entreprenorer: PropTypes.array.isRequired,
    loadEntrepreneurs: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        entreprenorer: state.entreprenorer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadEntrepreneurs: bindActionCreators(loadEntrepreneurs, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneursPage);
