import validator from "validator";
import isEmpty from "lodash/isEmpty";
import * as validationErrors from "../constants/ValidationErrors"

export default function validateInput(data)
{
	let errors = {};
	if (validator.isEmpty(data.adresse))
	{
		errors.adresse = validationErrors.AddressIsRequired;
	}
	if (validator.isEmpty(data.GPSCoordinate))
	{
		errors.GPSCoordinate = validationErrors.GeoPositionIsRequired;
	}

	if (data.selskapId && data.selskapId !== "0")
	{
		if (validator.isEmpty(data.avtaleId.toString()) || data.avtaleId.toString() === "0")
		{
			errors.avtaleId = validationErrors.avtaleIsRequired;
		}
	}

	if (validator.isEmpty(data.meldingsStatusId.toString()) || data.meldingsStatusId.toString() === "0")
	{
		errors.meldingsStatusId = validationErrors.meldingstatusIsRequired
	}

	return {
		errors,
		isValid: isEmpty(errors)
	}
}