import * as types from './ActionTypes';
// import contractApi from '../Api_MockData/mockContractsApi';
import { beginAjaxCall } from '../Api_MockData/ajaxStatusActions';
// import { useMock } from "../constants/settings";
import * as api from '../constants/api';
import { successData } from '../utils/utils';
import { addFlashMessage } from './FlashMessagesAction';

export function loadContractByAvtaleIdSucces(contract) {
    return { type: types.LOAD_CONTRACTS_BY_AVTALEID_SUCCESS, contract };
}

export function loadContractsSuccess(contracts) {
    return { type: types.LOAD_CONTRACTS_SUCCESS, contracts };
}

export function createContractSuccess(contract) {
    return { type: types.CREATE_CONTRACT_SUCCESS, contract };
}

export function updateContractSuccess(contract) {
    return { type: types.UPDATE_CONTRACT_SUCCESS, contract };
}

export function loadCompanyContractsSuccess(contracts) {
    return { type: types.LOAD_COMPANY_CONTRACTS_SUCCESS, contracts };
}

export function createCompanyContractSuccess(contract) {
    return { type: types.CREATE_COMPANY_CONTRACT_SUCCESS, contract };
}

export function loadContractSistEndretSuccess(sistendret) {
    return { type: types.LOAD_CONTRACT_SIST_ENDRET, sistendret };
}

// const url = kontrakterUrl;
const url_selskaper = api.selskaper;

export function loadContractSistEndret(selskapsId, kontraktsId) {
    return dispatch => {
        var url =
            url_selskaper +
            '/' +
            selskapsId +
            '/kontrakter/' +
            kontraktsId +
            '/sistendret';

        return api.getClient()
            .get(url)
            .then(function(response) {
                if (response.data) {
                    let res = response.data.result;
                    dispatch(loadContractSistEndretSuccess(res.sistEndret));
                }
            })
            .catch(function(error) {
                throw error;
            });
    };
}
export function loadContractByAvtaleId(avtaleId) {
    const url = api.getkontraktByAvtaleIdUrl(avtaleId);
    return dispatch => {
        return api.getClient().get(url).then(response => {
            const contract = successData(response);
            return contract;
        });
    };
}

export function loadContracts() {
    let url = api.kontrakterUrl;
    return function(dispatch) {
        return api.getClient()
            .get(url)
            .then(res => dispatch(loadContractsSuccess(successData(res))))
            .catch(error => {
                throw error;
            });
    };
}
export function loadEntrepreneurContracts(selskapId) {
  const url = api.getEntrepreneurKontrakterUrl(selskapId)
    return dispatch => {
        return api.getClient()
            .get(url)
            .then(res => dispatch(loadContractsSuccess(successData(res))))
            .catch(error => {
                throw error;
            });
    };
}
export function loadCompanySpecificContracts(id) {
    const url_kontrakter = api.getContractBySelskapsId(id);
    return dispatch => {
        return api.getClient()
            .get(url_kontrakter)
            .then(response => {
                const contracts = successData(response);
                dispatch(loadCompanyContractsSuccess(contracts));
                return contracts;
            })
            .catch(error => {
                throw error;
            });
    };
}

export function saveContract(contract, avtale, prosesskodeSkjema) {
    return function(dispatch) {
        dispatch(beginAjaxCall());
        const url = api.addEntrepreneurKontraktUrl(contract.selskapsId);
        api.getClient()
            .post(url, contract)
            .then(response => {
                dispatch(
                    createContractSuccess({
                        ...contract,
                        id: response.data.result.id,
                        avtale: avtale,
                        prosesskodeSkjema: prosesskodeSkjema,
                    })
                );
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: 'Kontrakt lagt til.',
                    })
                );
            })
            .catch(error => {
                const errorMessage = getErrorMessageForSaveContract(error);
                dispatch(
                    addFlashMessage({ type: 'error', text: errorMessage })
                );
            });
    };
}

export function updateKontrakt(contract) {
    return function(dispatch) {
        dispatch(beginAjaxCall());
        const url = api.updateEntrepreneurKontraktUrl(
            contract.SelskapId,
            contract.KontraktId
        );
        api.getClient()
            .put(url, contract)
            .then(function(response) {
                if (response.data) {
                    // const updatedKontract = { avtaleId: contract.avtaleId, navn: contract.navn, gyldigFraDato: contract.gyldigFraDato, gyldigTilDato: contract.gyldigTilDato }
                    dispatch(loadEntrepreneurContracts(contract.SelskapId));
                }
            })
            /* .catch(function (error) {
         throw error */
            .catch(error => {
                const errorMessage = getErrorMessageForUpdateContract(error);
                dispatch(
                    addFlashMessage({ type: 'error', text: errorMessage })
                );
            });
    };
}
function getErrorMessageForSaveContract(error) {
    if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
    ) {
        const {errorMessage} = error.response.data;
        if (
            errorMessage.startsWith(
                'Duplisert objekt Gyldig kontrakt for Avtale id'
            )
        ) {
            return 'Avtale for gjeldende datoer har allerede en kontrakt.';
        }
    }
    return 'Kunne ikke legge til kontrakt.';
}

function getErrorMessageForUpdateContract(error) {
    if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
    ) {
        const {errorMessage} = error.response.data;
        if (
            errorMessage.startsWith(
                'Registrert ressursforbruk er utenfor gyldighetsperioden'
            )
        ) {
            return 'Registrert ressursforbruk er utenfor gyldighetsperioden, og blokkerer endringer av gyldighetsdato på kontrakt';
        }
    }
}
