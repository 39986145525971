import * as types from '../actions/ActionTypes';
import InitialState from './initialState';

// eslint-disable-next-line import/prefer-default-export
export function varselReducer(state = InitialState.varsel, action = {}) {
    switch (action.type) {
        case types.LOAD_VARSEL_SUCCESS:
            return {
                varsel: action.varsel,
            };
        default:
            return state;
    }
}
