import * as types from './ActionTypes';
import * as api from '../constants/api';
import {kontrakterUrl} from '../constants/api';
import { useMock } from "../constants/settings";
import bulkupdateApi from '../Api_MockData/mockBulkupdatesApi';


export function loadBulkUpdateSuccess(bulkupdates) {
    return { type: types.LOAD_BULKUPDATES_SUCCESS, bulkupdates };
}

export function createBulkUpdateSuccess(bulkupdate) {
    return { type: types.CREATE_BULKUPDATE_SUCCESS, bulkupdate };
}

export function updateBulkUpdateSuccess(bulkupdate) {
    return { type: types.UPDATE_BULKUPDATE_SUCCESS, bulkupdate };
}

export function deleteBulkUpdateSuccess(bulkupdate) {
    return { type: types.DELETE_BULKUPDATE_SUCCESS, bulkupdate };
}


export function loadBulkUpdates(contractid) {
  return dispatch => {
    var url = kontrakterUrl + "/" + contractid + "/bulkoppdateringer";
    var bulkoppdateringer = [];

      if (useMock === false) {
          return api.getClient().get(url)
              .then(response => {
                if (response.data) {
                      bulkoppdateringer = response.data.result;
                      dispatch(loadBulkUpdateSuccess(bulkoppdateringer));
                  }
                  else {
                      console.log("no response.data");
                      console.log(response);
                  }
              })
              .catch(function (error) {
                  console.log("catch error in loadBulkUpdates");
                  console.log(error);
                  throw (error);
              });
      }
      else {
          return bulkupdateApi.getAllBulkUpdates().then(bulkoppdateringer => {
              dispatch(loadBulkUpdateSuccess(bulkoppdateringer));
          }).catch(error => {
              console.log("loadBulkUpdates ERROR (in mock)");
              throw (error);
          });
      }

  };
}


export function createBulkUpdate(kontraktsId, date) {
  return dispatch => {
    var url_bulkoppdatering = kontrakterUrl + "/" + kontraktsId + "/bulkoppdateringer";
    var postobjekt = { gyldigFraDato: date.date_iso };

    return api.getClient().post(url_bulkoppdatering, postobjekt)
      .then(response => {
        if (response.data) {

          var res = response.data.result.id;
          var ny_bulkoppdatering_obj = { id: res, gyldigFraDato: date.date_iso, prosessertDato: null };
          dispatch(createBulkUpdateSuccess(ny_bulkoppdatering_obj));
        }
        else {
          console.log("No valid response.data");
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error);
        console.log("into catch");
        throw(error);
      });
  };
}


export function deleteBulkUpdate(kontraktsId, bulkOppdateringsId, old_bulkupdateobj) {
  return dispatch => {
    var url_bulkoppdatering = kontrakterUrl + "/" + kontraktsId + "/bulkoppdateringer/" + bulkOppdateringsId;
    return api.getClient().delete(url_bulkoppdatering)
      .then(response => {
        if (response.data) {
          dispatch(deleteBulkUpdateSuccess(old_bulkupdateobj));
        }
        else {
          console.log("No valid response.data");
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log("catch error in deleteBulkUpdate");
        console.log("into catch");
        throw(error);
      });
  };
}

