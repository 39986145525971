import React from 'react';
import PropTypes from 'prop-types';
import './FolgereIcon.css';

const StatusIcon = ({ antallFolgere }) => {

    return (
      <div name="folgereIcon" className="folgere-icon" alt="Antall følgere">
        <span className="glyphicon glyphicon-eye-open" id="eyeopenglyphicon" />
        <span className="folgere-icon-text">{antallFolgere}</span>
      </div>
    );
};

StatusIcon.propTypes = {
  antallFolgere: PropTypes.number.isRequired,
};

export default StatusIcon;
