import React from 'react';
import { home } from "../../constants/clientRoutes"
import { Link } from "react-router"
export default (props) =>
{
    return (
        <div className="Login_center_div responsive" id="LoginDiv">
            <div className="userAdminstrationHeader text-center">Sjekk e-posten din!</div>
            <div className="text-center">
                <div>
                    <h3>Lenke for å gjenopprette passordet ditt er sendt til din e-postadresse.</h3>
                    <p><strong><Link to={home}>Tilbake til innlogging</Link></strong></p>
                </div>
            </div>
        </div>
    );
}