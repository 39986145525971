import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MeldingerFilter from '../../components/Meldinger/MeldingerFilter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import MeldingList from '../../components/Meldinger/MeldingList';
import MeldingerPageHeader from '../../components/Meldinger/MeldingerPageHeader';
import LeafletmapWithFilters from '../../components/Kart/LeafletMapWithFilters';
import Pagination from '../../components/common/Pagination';
import queryString from 'query-string';
import dateFormat from 'dateformat';
import * as routes from '../../constants/clientRoutes';
import { UserId } from '../../utils/cookieHandler';
import {
    loadServiceavdelingerMeldinger,
    loadEntrepreneurs,
    loadMeldingstyper,
    loadMeldingsstatuser,
    loadAvtaler,
    loadkatagoriGroups,
    PostNewMelding,
} from '../../actions/MeldingerActions';
import { loadDelbydeler } from '../../actions/delbydelerActions';
import {
    GetSavedMeldingSearchByBrukerId,
    AddToSavedMeldingSearch,
    DeleteFromSavedMeldingSearch,
} from '../../actions/SavedMeldingSearchAction';
import { genererPdf } from '../../actions/pdfgeneratorAction';
import Spinner from 'react-spinkit';
import { addFlashMessage } from '../../actions/FlashMessagesAction';
import '../../css/Meldinger/Meldinger.css';
import MeldingSortering from './../../components/Meldinger/MeldingSortering';
import { loadVarsel } from '../../actions/VarselActions';
import featureIsEnabled, { FEATURE_VIS_VARSEL } from '../../utils/FeatureManager';

class SaksbehandlerMeldinger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            allchecked: false,
            checkboxes: [],
            printOut: false,
            disabled: 'disabled',
            fritekstSok: '',
            activePage: 1,
            margins: 100,
            markerId: '',
            spinnerLoad: false,
            usersLoaded: false,
            filterChanged: false,
            updatedMapBounds: [],
        };
        this.Expandable = this.Expandable.bind(this);
        this.Search = this.Search.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.handleSelectPagination = this.handleSelectPagination.bind(this);
        this.forceUpdate = this.forceUpdate.bind(this);
        this.filterFunction = this.filterFunction.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.meldingerLoaded = this.meldingerLoaded.bind(this);
        this.removeCheckedOnFilterChange = this.removeCheckedOnFilterChange.bind(this);
    }

    getQuery(q) {
        let query = this.context.router.location.query;
        if (query[q]) return query[q];
    }

    getFilters() {
        let location = this.context.router.location;
        let search = location.search;
        let filter = search.substring(1, search.length);
        return filter;
    }

    queryBuilder(key, value) {
        const pathname = this.context.router.location.pathname;
        const params = this.context.router.location.query;
        if (value && value !== '0') params[key] = value;
        else delete params[key];
        const qu = queryString.stringify(params);
        const uri = pathname + '?' + qu;
        this.context.router.push(uri);
    }

    componentDidMount() {
        const page = this.getQuery('page');

        if (page) this.setState({ activePage: parseInt(page, 10) });
        this.getMeldinger();

        this.props.loadEntrepreneurs();
        this.props.loadMeldingstyper();
        this.props.loadkatagoriGroups();
        this.props.loadMeldingsstatuser();
        this.props.loadAvtaler();
        this.props.loadDelbydeler();

        if (featureIsEnabled(FEATURE_VIS_VARSEL)) {
            this.props.loadVarsel();
        }
    }

    componentDidUpdate() {
        const page = parseInt(this.getQuery('page') || 1);
        const counter = this.props.meldingercounter;
        const pagescount = this.pages(counter);

        if (page > pagescount) {
            this.setActivePage(pagescount);
            this.getMeldinger();
        }
    }

    getMeldinger() {
        this.props.GetSavedMeldingSearchByBrukerId(UserId()).then(filters => {
            const defaultFilter = this.getDefaultSavedFiltersForUser(filters);
            const currentFilter = this.getFilters();
            const filter = defaultFilter ? defaultFilter : currentFilter;
            this.props.loadServiceavdelingerMeldinger(filter, this.meldingerLoaded);
        });
    }

    getDefaultSavedFiltersForUser() {
        const defaultsavedsearch = _.first(_.filter(this.props.lagredsok, { standard: true }));
        const filter = defaultsavedsearch ? defaultsavedsearch.filter : '';
        if (filter.length === 0) return;
        const pathname = this.context.router.location.pathname;
        const uri = pathname + '?' + filter;
        this.context.router.push(uri);
        return filter;
    }

    onMarkerClick(id) {
        this.setState({ markerId: id });
    }

    Expandable(e) {
        this.setState({ toggle: !this.state.toggle });
    }

    getCurrentPageMeldingIds = () => {
        let ids = _.map(this.props.meldinger, 'meldingId');
        return _.map(ids, i => {
            return i.toString();
        });
    };

    onChangeCheckBox(e) {
        const target = e.target;
        const checked = target.checked;
        const value = target.value;
        if (checked) {
            this.setState({
                checkboxes: [...this.state.checkboxes, value.toString()],
                disabled: '',
            });
        } else {
            const filter = this.state.checkboxes.filter(val => val !== value.toString());
            this.setState({ checkboxes: filter });
            if (_.size(filter) === 0) {
                this.setState({ disabled: 'disabled' });
            }
        }
    }

    meldingerLoaded() {
        const { meldinger } = this.props;
        let updatedState = {
            usersLoaded: true,
            checkboxes: this.removeCheckedOnFilterChange(),
        };
        if (this.state.filterChanged) {
            // Calculate bounds og the recieved markers and post it to map component
            updatedState = {
                ...updatedState,
                filterChanged: false,
                updatedMapBounds: meldinger,
            };
        }
        this.setState(updatedState);
    }

    forceUpdate() {
        this.setState({ usersLoaded: false });
        this.props.loadServiceavdelingerMeldinger(this.getFilters(), this.meldingerLoaded);
    }

    filterFunction(page = 1) {
        this.setState({ filterChanged: true });
        this.setActivePage(page);
        this.forceUpdate();
    }

    removeCheckedOnFilterChange() {
        //fjerner de meldingene som filteres bort fra print-liste
        let shownMessages = [];
        if (this.state.checkboxes.length > 0) {
            var allMessages = this.getCurrentPageMeldingIds();
            this.state.checkboxes.map(item => {
                if (allMessages.indexOf(item) !== -1) shownMessages.push(item);
            });
        }
        return shownMessages;
    }

    Search(text) {
        this.setState({ fritekstSok: text });
        this.filterFunction();
    }

    handleSelectPagination(number) {
        this.filterFunction(number);
    }

    setActivePage(number) {
        this.setState({ activePage: number });
        this.queryBuilder('page', number);
        window.scrollTo(0, 0);
    }

    pages(counter) {
        const numberOfPages = counter / this.state.margins;
        if (counter > this.state.margins) return Math.ceil(numberOfPages);
        return 1;
    }

    onPrint() {
        // vis varsel om ingen meldinger er valgt
        if (this.state.checkboxes.length == 0) {
            this.props.addFlashMessage({
                text: 'Ingen meldinger valgt for utskrift',
                type: 'error',
            });
            return;
        }

        this.setState({ spinnerLoad: true });
        const meldingIds = this.state.checkboxes.length > 0 ? this.state.checkboxes : this.getCurrentPageMeldingIds();
        const format = _.map(meldingIds, i => {
            return 'MeldingId=' + i;
        });
        const join = _.join(format, '&');

        this.props
            .genererPdf(join, 'Bymelding_' + dateFormat(Date.now(), 'yyyymmdd_HHMMss') + '.pdf')
            .then(res => {})
            .finally(() => {
                this.setState({
                    spinnerLoad: false,
                    allchecked: false,
                    checkboxes: [],
                    errors: {},
                    disabled: 'disabled',
                });
            });
    }

    render() {
        const {
            toggle,
            fritekstSok,
            checkboxes,
            disabled,
            activePage,
            allchecked,
            spinnerLoad,
            usersLoaded,
            updatedMapBounds,
        } = this.state;
        const { PostNewMelding, lagredsok, AddToSavedMeldingSearch, DeleteFromSavedMeldingSearch } = this.props;
        const meldinger = this.props.meldinger;
        const counter = this.props.meldingercounter;
        const size = _.size(meldinger);
        const pagescount = this.pages(counter);
        const routeToDetails = routes.saksbehandlerMeldinger;
        const varsel = this.props.varsel;

        return (
            <div>
                <MeldingerPageHeader
                    className="page-header-fixed"
                    title={`${'Meldinger (' + counter + ')'}`}
                    toggleHandler={this.Expandable}
                    OnSearch={this.Search}
                    showSearchField={!toggle}
                    loading={!usersLoaded}
                    isServiceavdeling={true}
                    varsel={varsel}
                />
                <div className="mainContainer">
                    <MeldingerFilter
                        {...this.props}
                        filterFunction={this.filterFunction}
                        IsServiceavdeling={true}
                        SendNewMelding={PostNewMelding}
                        reloadMeldinger={this.forceUpdate}
                        freetext={fritekstSok}
                        onSelectAllChecks={this.onChangeChecboxes}
                        disabled={disabled}
                        onPrint={this.onPrint}
                        allchecked={allchecked}
                        savedsearches={lagredsok}
                        addSavedSearch={AddToSavedMeldingSearch}
                        deleteSavedSearch={DeleteFromSavedMeldingSearch}
                        onPrintLoading={spinnerLoad}
                    />
                    <div className="meldingerAndkartContainer">
                        <div className="col-xs-12 col-sm-6">
                            {!usersLoaded && meldinger.length === 0 && (
                                <center>
                                    <Spinner
                                        spinnerName="circle"
                                        className="spinner-circle-div"
                                        style={{ width: '75px', height: '75px' }}
                                    />
                                </center>
                            )}
                            {meldinger.length > 1 && <MeldingSortering filterFunction={this.filterFunction} />}
                            <MeldingList
                                items={meldinger}
                                changeCheckboxFromList={this.onChangeCheckBox}
                                checkboxes={checkboxes}
                                meldingkatagori={this.props.kategorigrupper}
                                routeTo={routeToDetails}
                                meldingstyper={this.props.meldingstyper}
                                numberOfPages={pagescount}
                            />
                            <div className="text-center">
                                {pagescount > 1 && (
                                    <div className="pagination-container-fixed">
                                        <Pagination
                                            pages={pagescount}
                                            activePage={activePage}
                                            onSelect={this.handleSelectPagination}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div id="leafletDiv">
                            <LeafletmapWithFilters
                                markercluster={meldinger}
                                meldingsstatuser={this.props.meldingsstatuser}
                                count={size}
                                onMarkerClick={this.onMarkerClick}
                                meldingkatagori={this.props.kategorigrupper}
                                routeTo={routeToDetails}
                                updatedMapBounds={updatedMapBounds}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SaksbehandlerMeldinger.contextTypes = {
    router: PropTypes.object,
};

SaksbehandlerMeldinger.defaultProps = {
    meldingercounter: 0,
    meldinger: [],
    meldingstyper: [],
    meldingsavtaler: [],
    meldingsstatuser: [],
    entrepreneurs: [],
    kategorigrupper: [],
    varsel: [],
};

SaksbehandlerMeldinger.propTypes = {
    meldingercounter: PropTypes.number,
    meldinger: PropTypes.array.isRequired,
    meldingstyper: PropTypes.array.isRequired,
    kategorigrupper: PropTypes.array.isRequired,
    meldingsavtaler: PropTypes.array.isRequired,
    meldingsstatuser: PropTypes.array.isRequired,
    entrepreneurs: PropTypes.array.isRequired,
    loadServiceavdelingerMeldinger: PropTypes.func.isRequired,
    loadEntrepreneurs: PropTypes.func.isRequired,
    loadMeldingstyper: PropTypes.func.isRequired,
    loadkatagoriGroups: PropTypes.func.isRequired,
    loadMeldingsstatuser: PropTypes.func.isRequired,
    loadAvtaler: PropTypes.func.isRequired,
    loadDelbydeler: PropTypes.func.isRequired,
    genererPdf: PropTypes.func.isRequired,
    PostNewMelding: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
    GetSavedMeldingSearchByBrukerId: PropTypes.func.isRequired,
    AddToSavedMeldingSearch: PropTypes.func.isRequired,
    DeleteFromSavedMeldingSearch: PropTypes.func.isRequired,
    lagredsok: PropTypes.array.isRequired,
    delbydeler: PropTypes.array.isRequired,
    loadVarsel: PropTypes.func.isRequired,
    varsel: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return {
        meldingercounter: state.meldinger.meldingcounter,
        meldinger: state.meldinger.meldingerList,
        meldingstyper: state.meldinger.meldingstyper,
        kategorigrupper: state.meldinger.kategorigrupper,
        meldingsavtaler: state.meldinger.meldingssoner,
        meldingsstatuser: state.meldinger.meldingsstatuser,
        entrepreneurs: state.meldinger.entrepreneurs,
        lagredsok: state.lagredSok,
        delbydeler: state.delbydeler,
        varsel: state.varsel.varsel,
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            loadServiceavdelingerMeldinger,
            loadEntrepreneurs,
            loadMeldingstyper,
            loadMeldingsstatuser,
            loadAvtaler,
            loadDelbydeler,
            loadkatagoriGroups,
            genererPdf,
            PostNewMelding,
            addFlashMessage,
            GetSavedMeldingSearchByBrukerId,
            DeleteFromSavedMeldingSearch,
            AddToSavedMeldingSearch,
            loadVarsel,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SaksbehandlerMeldinger);
