import * as types from '../actions/ActionTypes';

export default function contractZonesReducer(state = [], action) {
  switch (action.type) {
    
    case types.LOAD_CONTRACTZONES_SUCCESS:
      return  action.contractZones
    
    case types.UPDATE_AVTALE_SUCCESS: {
      const index= state.findIndex( a=> a.avtaleId===action.payload.avtaleId)
      return [...state.slice(0, index), action.payload , ...state.slice(index+1)]
    }
    default:
      return state;
  }
}
