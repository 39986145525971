import React from 'react';
import PropTypes from 'prop-types';

const SearchDisplayBanner = ({ filtername, text, value, onRemoveSearch, onSelectSearch }) => {
    return (
        <span className="filterRemoveButtons" id="filterRemoveButtons">
            <span onClick={e => onSelectSearch(e, filtername, value)} role="button">
                {text}
            </span>
            <span
                className="glyphicon glyphicon-remove-sign"
                id="glyphicon-remove-sign"
                role="button"
                onClick={e => onRemoveSearch(e, filtername, value)}
            ></span>
        </span>
    );
};

SearchDisplayBanner.propTypes = {
    filtername: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
    onSelectSearch: PropTypes.func,
    onRemoveSearch: PropTypes.func,
};

export default SearchDisplayBanner;
