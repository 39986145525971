import { AUTENTISERING_SERVICE_HEALTH_CHECK, BYMELDING_SERVICE_HEALTH_CHECK } from "../actions/ActionTypes";


export default function serviceHealthCheckReducer(state = {}, action = {})
{
    switch (action.type)
    {
        case AUTENTISERING_SERVICE_HEALTH_CHECK:
            return { ...state, autentisering: action.status }
        case BYMELDING_SERVICE_HEALTH_CHECK:
            return { ...state, bymelding: action.status }
        default:
            return state
    }
}