import * as types from '../actions/ActionTypes';
//import initialState from './initialState';

const InitialState = [];

export default function pricesReducer(state = InitialState, action) {
  switch (action.type) {
    case types.LOAD_PRICES_SUCCESS:
      return action.prices;

    case types.CREATE_PRICE_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.price)
      ];

    case types.UPDATE_PRICE_SUCCESS: {
      const indexToRemove = state.findIndex(price => price.id === action.price.id)
      return [
        ...state.slice(0, indexToRemove), Object.assign({}, action.price),
        ...state.slice(indexToRemove + 1)
      ];
    }

    case types.DELETE_PRICE_SUCCESS:
      return [
        ...state.filter(price => price.id !== action.price.id)
      ];

    default:
      return state;
  }
}
