import { SIGNIN_USER_SUCCESS, LOGOUT_USER, AUTH_TOKEN_IS_VALID, USER_ROLE_LIST_SUCCESS, SET_SELSKAPSID_FOR_USER } from '../actions/ActionTypes';

const initialState = {
    user: {},
    token: {},
    userRoles: [],
    selskap: {}
};

export default function loginReducer(state = initialState, action = {})
{
    switch (action.type)
    {
        case SIGNIN_USER_SUCCESS:
            return {
                ...state, user: action.user
            };
        case LOGOUT_USER:
            return {};

        case AUTH_TOKEN_IS_VALID:
            return {
                ...state, token: action.payload
            };
        case USER_ROLE_LIST_SUCCESS:
            return { ...state, userRoles: action.userRoles }

        case SET_SELSKAPSID_FOR_USER:
            return { ...state, selskap: action.selskap }
        default:
            return state;
    }
}