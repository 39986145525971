import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

const DropDownList = ({ label, title, id, Items, onChange, error, selected }) => {
    return (
        <div className={classnames('form-group', { 'has-error': error })}>
            {label && (
                <label className="control-label" htmlFor={id}>
                    {label}
                </label>
            )}
            <select value={selected ? selected.toString() : ''} onChange={onChange} className="form-control" id={id}>
                {title && (
                    <option key="0" value="0">
                        {title}
                    </option>
                )}
                {_.map(Items, (items, index) => {
                    return (
                        <option key={index} value={items.id}>
                            {items.navn}
                        </option>
                    );
                })}
            </select>
            {error && <span className="help-block">{error}</span>}
        </div>
    );
};
DropDownList.defaultProps = {
    id: '',
};

DropDownList.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    Items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    selected: PropTypes.string,
};
export default DropDownList;
