import moment from 'moment'
import moment_timezone from 'moment-timezone'

// Converts a local 12:00 date to norwegian 00:00 date
export function toNorwegianDate(isoString)
{
    // req: isoString should be Z with Noon (12:00) as time-part
    // 1) Parse ISO String using same culture as used in datepicker
    const datetime = moment(isoString);
    // 2) Get local Year, Month and Date
    const year = datetime.year();
    const month = datetime.month();
    const day = datetime.date();

    // 3) Create new date using Oslo as timezone
    return moment_timezone.tz({ year: year, month: month, day: day }, "Europe/Oslo")
}
export function toNorwegianDatetime(year, month, day)
{
    return moment_timezone.tz({ year: year, month: month, day: day }, "Europe/Oslo")
}

// Convert from Norwegian date to local date
export function fromNorwegianDate(isoString)
{
    // 1) Find year, month, and day in Norwegian
    const datetime = moment_timezone.tz(isoString, "Europe/Oslo");
    const year = datetime.year();
    const month = datetime.month();
    const day = datetime.date();

    // 2) Convert to local date, and ignore time
    return moment({
        year: year,
        month: month,
        day: day,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
    });
}
export function AddOnMonthToDatetime(year, month, day)
{
    const currentDate = moment_timezone.tz({ year: year, month: month, day: day, hours: 0 }, "Europe/Oslo")
    let nextMonth = moment(currentDate).add(1, 'M')
    const nextMontEndDay = moment(nextMonth).endOf('month')
    if (currentDate.date() !== nextMonth.date() && nextMonth.isSame(nextMontEndDay.format('YYYY-MM-DD')))
    {
        nextMonth = nextMonth.add(1, 'd')
    }
    return nextMonth;//.subtract(1, 'hours')
}

// export function AddOnDayToDatetime(year, month, day)
// {
//     const currentDate = moment_timezone.tz({ year: year, month: month, day: day, hours: 0 }, "Europe/Oslo")
//     let nextMonth = moment(currentDate).add(1, 'D')
//     const nextMontEndDay = moment(nextMonth).endOf('month')
//     if (currentDate.date() !== nextMonth.date() && nextMonth.isSame(nextMontEndDay.format('YYYY-MM-DD')))
//     {
//         nextMonth = nextMonth.add(1, 'd')
//     }
//     return nextMonth//.subtract(1, 'hours')
// }
