import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
class EntrepreneurAvtalerList extends Component {
    renderAvtaler() {
        return _.map(this.props.avtaler, (i, index) => {
            return (
                <tr key={index}>
                    <td>{i.navn}</td>
                    <td>{i.beskrivelse}</td>
                </tr>
            );
        });
    }
    render() {
        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Beskrivelse</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderAvtaler()}</tbody>
                </Table>
            </div>
        );
    }
}

EntrepreneurAvtalerList.propTypes = {
    avtaler: PropTypes.array.isRequired,
};

export default EntrepreneurAvtalerList;
