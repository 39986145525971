import { AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

// import { getHostFromEnv } from './utlis/environment';

const getHostFromEnv = (url_name: string): string => {
    const key = `${url_name}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const host = (window as any)[key] || process.env[key];

    if (host != null) {
        return host;
    }

    throw new Error(`Missing environment configuration with key '${key}'`);
};

export const oidcConfig: AuthProviderProps = {
    authority: getHostFromEnv('REACT_APP_ONELOGIN_AUTHORITY'),
    client_id: getHostFromEnv('REACT_APP_ONELOGIN_CLIENT_ID'),
    redirect_uri: getHostFromEnv('REACT_APP_ONELOGIN_REDIRECT_URI'),
    silent_redirect_uri: getHostFromEnv('REACT_APP_ONELOGIN_SILENT_REDIRECT_URI'),
    post_logout_redirect_uri: getHostFromEnv('REACT_APP_ONELOGIN_POST_LOGOUT_REDIRECT_URI'),
    automaticSilentRenew: true,
    response_type: 'code',
    scope: 'openid profile groups email params',
    resource: getHostFromEnv('REACT_APP_ONELOGIN_RESOURCE').split(','),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback: () => {
        /**
         * You must provide an implementation of onSigninCallback to oidcConfig to remove the payload from the URL upon successful login.
         * Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
         */
        window.history.replaceState({}, document.title, "/");
      }
};
