import axios from 'axios';
import { getAdresseApi } from '../../constants/api';

// eslint-disable-next-line import/prefer-default-export
export const getAdresseFraPunkt = async (lat, lng) => {
  const instance = axios.create();

  try {   
    const res = await instance.get(getAdresseApi(lat, lng));
    return { adresse: res.data.result };
  } catch (error) {
    return {
      adresse: 'Utenfor Oslo',
      error: error.response ? { status: error.response.status, text: error.response.result } : { status: 1, text: 'En feil har oppstått' },
    };
  }
};
