import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';
//import {Link} from "react-router"
import LinkSetSubtitle from '../common/LinkSetSubtitle';

import '../../css/layout/PageHeader.css';

const PageHeader = ({ pageTitle, pageTitleLink, subTitle, hasSubTitle }) => {
    const renderPageTitle = (pageTitle, subTitle) => {
        if (!subTitle)
            return (
                <h1>
                    <strong>{pageTitle}</strong>
                </h1>
            );
        return (
            <h1>
                <LinkSetSubtitle to={pageTitleLink} pageTitle={pageTitle} children={hasSubTitle ? subTitle : ''} />
                <span>
                    <strong>
                        <Glyphicon glyph="chevron-right" />
                        {subTitle}
                    </strong>
                </span>
            </h1>
        );
    };

    return pageTitle && pageTitle !== '' ? (
        <div className="page-header">{renderPageTitle(pageTitle, subTitle)}</div>
    ) : (
        <div></div>
    );
};

PageHeader.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    hasSubTitle: PropTypes.bool,
    subTitle: PropTypes.string,
    pageTitleLink: PropTypes.string,
};

export default PageHeader;
