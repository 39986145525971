import * as types from '../actions/ActionTypes';

const initialState = [];

export default function bulkUpdatesReducer(state = initialState, action) {
    switch(action.type) {
        case types.LOAD_BULKUPDATES_SUCCESS:
            return action.bulkupdates;

        case types.CREATE_BULKUPDATE_SUCCESS:
            return [
                ...state, Object.assign({}, action.bulkupdate)
            ];

        case types.UPDATE_BULKUPDATE_SUCCESS:
            var indexToRemove  = state.find( bulkupdate => bulkupdate.id === action.bulkupdate.id);
            return [
                ...state.slice(0, indexToRemove), Object.assign({}, action.bulkupdate),
                ...state.slice(indexToRemove + 1)
            ];

        case types.DELETE_BULKUPDATE_SUCCESS:
            return [
                ...state.filter(bulkupdate => bulkupdate.id !== action.bulkupdate.id)
            ];

        default:
            return state;
    }
}