import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCompanySpecificContracts, loadContractSistEndret, updateKontrakt } from '../../actions/contractActions';
import PrisSkjema from '../../components/contract/PrisSkjema';
import { loadUsers } from '../../actions/userActions';
import { loadProcesscodes } from '../../actions/processcodeActions';
import { getProcesscodeUnits } from '../../actions/RessursActions';
import { loadPrices, createPrice, deaktiverPrice, updatePrice } from '../../actions/pricesAction';
import ContractDetailHeader from '../../components/contract/ContractDetailHeader';
import { loadBulkUpdates, createBulkUpdate, deleteBulkUpdate } from '../../actions/BulkUpdateAction';
import { addFlashMessage } from '../../actions/FlashMessagesAction';
import {
    loadPriceChanges,
    createPriceChange,
    deaktiverPriceChange,
    updatePriceChange,
} from '../../actions/pricesChangesAction';
import { toDateNumericString } from '../../utils/dateTimeHelpers';
import * as responseMessages from '../../constants/ResponseMessages';
import '../../css/Contract.css';
import * as cookieHandler from '../../utils/cookieHandler';
import { getSelskapsTypeFromRolle } from '../../utils/globalsHelper';
import { browserHistory } from 'react-router';

class ContractDetailsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectcontent: '',
            prosesskodeId: '',
            initialprosesskodeId: true,
            bulkOppdateringId: '',
            initialbulkOppdateringId: true,
            validFrom: '',
            initialvalidFrom: true,
            validFromFormatted: '',
            pris: '',
            initialpris: true,
            contract: {},
            spinnerLoad: false,
        };
        this.onSelectContentUsers = this.onSelectContentUsers.bind(this);
        this.onSelectContentPrice = this.onSelectContentPrice.bind(this);
        this.handleKontraktSaveName = this.handleKontraktSaveName.bind(this);
        this.handleKontraktSaveDate = this.handleKontraktSaveDate.bind(this);
    }

    APIErrorHandling(err) {
        if (err) {
            //let error = utils.errorData(err)
            this.props.addFlashMessage({ type: 'error', text: 'error' });
        } else {
            this.props.addFlashMessage({ type: 'error', text: 'feil kode ' + err.response.status });
        }
    }

    componentDidMount() {
        const params = this.props.params;
        this.props.loadUsers(getSelskapsTypeFromRolle(cookieHandler.RoleId()), params.selskapsId);
        this.props.getProcesscodeUnits();
        this.props.loadBulkUpdates(params.kontraktsId);
        this.props
            .loadCompanySpecificContracts(params.selskapsId)
            .then(response => {
                const contract = response.find(c => c.id === params.kontraktsId);
                if (contract) this.props.loadProcesscodes(contract.prosesskodeSkjema.id);
                this.setState({ spinnerLoad: true });
                this.props.loadPrices(params.kontraktsId);
                this.setState({ spinnerLoad: false });
            })
            .catch(() => {
                this.setState({ spinnerLoad: false });
            });
        if (this.props.bulkupdates.length > 0 && this.state.initialbulkOppdateringId) {
            const bulk = this.props.bulkupdates[0];
            if (bulk) {
                this.setState({ bulkOppdateringId: bulk.id, initialbulkOppdateringId: false });
                this.setState({ validFrom: bulk.gyldigFraDato, initialvalidFrom: false });
                this.setState({ validFromFormatted: toDateNumericString(bulk.gyldigFraDato) });
                this.props.loadPriceChanges(bulk.id);
            }
        }
    }

    componentWillReceiveProps() {
        if (this.props.bulkupdates.length > 0) {
            const bulk = this.props.bulkupdates[0];
            this.setState({ bulkOppdateringId: bulk.id, initialbulkOppdateringId: false });
            this.setState({ validFrom: bulk.gyldigFraDato, initialvalidFrom: false });
            this.setState({ validFromFormatted: toDateNumericString(bulk.gyldigFraDato) });
        }
    }

    onSelectContentPrice() {
        this.setState({ selectcontent: 'price' });
    }

    onSelectContentUsers() {
        this.setState({ selectcontent: 'users' });
    }

    onMapPriceScheme() {
        //En tab for nåværende og en tab for Fremtidig
        let tabno = 0;
        const skjema = [];

        const nowscheme = {
            tab: ++tabno,
            title: 'Nåværende',
            validfrom: toDateNumericString(this.props.lastchanged),
            scheme: this.props.prices.map(function(ss) {
                return {
                    id: ss.id,
                    prosesskodeid: ss.prosesskode.id,
                    prosesskode: ss.prosesskode.navn,
                    beskrivelse: ss.prosesskode.beskrivelse,
                    enhet: ss.prosesskode.prosesskodeEnhet.navn,
                    pris: ss.pris,
                };
            }),
        };
        skjema.push(nowscheme);

        let futurescheme = {};

        if (this.props.bulkupdates && this.props.bulkupdates.length > 0) {
            futurescheme = {
                id: this.props.bulkupdates[0].id,
                tab: ++tabno,
                title: 'Fremtidig',
                validfrom: toDateNumericString(this.props.bulkupdates[0].gyldigFraDato),
                scheme:
                    this.props.bulkupdates[0].prisEndringer && this.props.bulkupdates[0].prisEndringer.length > 0
                        ? this.props.bulkupdates[0].prisEndringer.map(prisendring => {
                              return {
                                  id: prisendring.id,
                                  prosesskodeid: prisendring.prosesskode.id,
                                  prosesskode: prisendring.prosesskode.navn,
                                  beskrivelse: prisendring.prosesskode.beskrivelse,
                                  enhet: prisendring.prosesskode.prosesskodeEnhet.navn,
                                  pris: prisendring.pris,
                              };
                          })
                        : [],
            };
            skjema.push(futurescheme);
            this.updatedPrices(skjema[0].scheme, skjema[1].scheme);
        }

        return skjema;
    }

    handleprocesscode(prosesscode) {
        this.setState({ prosesskodeId: prosesscode.id, initialprosesskodeId: false });
    }

    handleChange(e) {
        this.setState({ pris: e.target.value });
    }

    handleValidateAndSave(e, tab) {
        if (this.state.prosesskodeId && this.state.pris) {
            const pcode = this.props.prosesscodes.find(p => p.id === this.state.prosesskodeId);
            const pris = parseFloat(this.state.pris);
            const prosesscode_obj = {
                id: pcode.id,
                navn: pcode.navn,
                beskrivelse: pcode.beskrivelse,
                prosesskodeEnhetId: pcode.prosesskodeEnhetId,
                prosesskodeEnhetNavn: pcode.prosesskodeEnhetNavn,
            };

            if (tab === 1) {
                //Nåværende , pris
                const ny_pris_obj = { id: '', pris: pris, prosesskode: prosesscode_obj };
                this.props
                    .createPrice(pris, this.state.prosesskodeId, this.props.params.kontraktsId, ny_pris_obj)
                    .then(() => {
                        this.props.addFlashMessage({ type: 'success', text: responseMessages.PrisschemaIsCreated });
                        this.setState({ pris: '', prosesskodeId: '' });
                    })
                    .catch(() => {
                        //const error = errorData(err)
                        this.props.addFlashMessage({ type: 'error', text: responseMessages.PrisschemaIsNotCreated });
                    });
            }
            if (tab === 2) {
                //Fremtidig , prisendring
                const bulkOppdateringId = this.props.bulkupdates[0].id;
                const ny_prisendring_obj = { id: '', pris: pris, prosesskode: prosesscode_obj };
                this.props
                    .createPriceChange(pris, this.state.prosesskodeId, bulkOppdateringId, this.props.params.kontraktsId)
                    .then(() => {
                        this.props.addFlashMessage({ type: 'success', text: responseMessages.PrisschemaIsCreated });
                    })
                    .catch(() => {
                        this.props.addFlashMessage({ type: 'error', text: responseMessages.PrisschemaIsNotCreated });
                    });
            }
        }
    }

    deaktiver(rad, tab) {
        const pcode = this.props.prosesscodes.find(p => p.id === rad.prosesskodeid);
        const prosesscode_obj = { id: pcode.id, navn: pcode.name, beskrivelse: pcode.beskrivelse, enhet: pcode.enhet };

        if (tab === 1) {
            //Nåværende
            const this_pris_obj = { id: rad.id, pris: this.state.pris, prosesskode: prosesscode_obj };
            this.props
                .deaktiverPrice(rad.id, this.props.params.kontraktsId, this_pris_obj)
                .then(() => {
                    this.props.addFlashMessage({ type: 'success', text: responseMessages.PrisschemaIsRemoved });
                })
                .catch(() => {
                    this.props.addFlashMessage({ type: 'error', text: responseMessages.PrisschemaIsNotRemoved });
                });
        }
        if (tab === 2) {
            //Fremtidig
            const this_prisendring_obj = { id: rad.id, pris: this.state.pris, prosesskode: prosesscode_obj };
            this.props
                .deaktiverPriceChange(
                    rad.id,
                    this.state.bulkOppdateringId,
                    this_prisendring_obj,
                    this.props.params.kontraktsId
                )
                .catch(err => {
                    this.APIErrorHandling(err);
                });
        }
    }

    handleValidateAndChange(e, rad, tab) {
        //Change Price
        if (tab === 1) {
            const old_priceobj = this.props.prices.find(p => p.id === rad.id);
            this.props
                .updatePrice(e, old_priceobj, this.props.params.kontraktsId)
                .then(() => {
                    this.props.addFlashMessage({ type: 'success', text: responseMessages.PrisschemaIsUpdated });
                })
                .catch(() => {
                    this.props.addFlashMessage({ type: 'error', text: responseMessages.PrisschemaIsNotUpdated });
                });
        }
        if (tab === 2) {
            const old_pricechangeobj = this.props.bulkupdates[0].prisEndringer.find(p => p.id === rad.id);
            this.props.updatePriceChange(
                e,
                old_pricechangeobj,
                this.state.bulkOppdateringId,
                this.props.params.kontraktsId
            );
        }
    }

    handleNewScheme(date) {
        console.log('hello');
        this.props
            .createBulkUpdate(this.props.params.kontraktsId, date)
            .then(() => {
                this.props.loadBulkUpdates(this.props.params.kontraktsId);
            })
            .catch(err => {
                this.APIErrorHandling(err);
            });
    }

    updatedPrices(scheme1, scheme2) {
        if (typeof scheme2 === 'undefined' || typeof scheme1 === 'undefined') {
            return;
        }
        if (scheme1.length === 0 || scheme2.length === 0) {
            return;
        }

        scheme2.forEach(element => {
            if (scheme1.some(e => e.prosesskode === element.prosesskode)) {
                let scheme1Element = scheme1.find(p => p.prosesskode === element.prosesskode);
                element.updatedPrice = scheme1Element.pris !== element.pris;
            } else {
                element.updatedPrice = true;
            }
        });
    }

    handleRemoveBulk() {
        let old_bulkupdateobj = this.props.bulkupdates[0]; //this.props.bulkupdates.find(b => b.id === this.state.bulkOppdateringId);

        this.props
            .deleteBulkUpdate(this.props.params.kontraktsId, this.state.bulkOppdateringId, old_bulkupdateobj)
            .then(() => {
                this.setState({ bulkOppdateringId: '', initialbulkOppdateringId: true });
                this.props.loadBulkUpdates(this.props.params.kontraktsId);
                this.props.addFlashMessage({ type: 'success', text: responseMessages.schemaIsRemoved });
            })
            .catch(() => {
                this.props.addFlashMessage({ type: 'error', text: responseMessages.schemaIsNotRemoved });
            });
    }

    handleKontraktSaveName(input) {
        const { selskapsId, kontraktsId } = this.props.params;
        const contract = this.props.contracts.find(c => c.id === kontraktsId);
        const payload = Object.assign({
            SelskapId: selskapsId,
            KontraktId: kontraktsId,
            navn: input,
            avtaleId: contract.avtale.avtaleId,
            gyldigFraDato: contract.gyldigFraDato,
            gyldigTilDato: contract.gyldigTilDato,
        });
        this.props.updateKontrakt(payload);
        this.updateUrlState(input);
    }

    handleKontraktSaveDate(newDate) {
        const { selskapsId, kontraktsId } = this.props.params;
        const contract = this.props.contracts.find(c => c.id === kontraktsId);
        const payload = Object.assign({
            SelskapId: selskapsId,
            KontraktId: kontraktsId,
            navn: contract.navn,
            avtaleId: contract.avtale.avtaleId,
            gyldigFraDato: contract.gyldigFraDato,
            gyldigTilDato: newDate,
        });
        this.props.updateKontrakt(payload);
    }

    updateUrlState(input) {
        const subtitle = input;
        const pathname = this.context.router.location.pathname;
        const pagetitle = this.context.router.location.state.pageTitle;
        const pagetitleLink = this.context.router.location.state.pageLink;
        browserHistory.push({
            pathname: pathname,
            state: { pageTitle: pagetitle, pageLink: pagetitleLink, subTitle: subtitle },
        });
    }
    render() {
        const { contracts, prosesscodes, params } = this.props;
        const { spinnerLoad } = this.state;
        const byAdmin = cookieHandler.UserIsBymAdmin();
        if (!contracts) return null;
        const currentContract = contracts.find(c => c.id === params.kontraktsId);
        if (!currentContract) return null;
        return (
            <div className="mainContainer">
                <div className="col-md-12">
                    <ContractDetailHeader
                        contract={currentContract}
                        byAdmin={byAdmin}
                        onSubmitName={this.handleKontraktSaveName}
                        onSubmitEditableDateTo={this.handleKontraktSaveDate}
                    />
                    <PrisSkjema
                        schemes={this.onMapPriceScheme()}
                        prosesscodes={prosesscodes}
                        enheter={this.props.prosesskodeenhent}
                        deAktiver={(rad, tab) => this.deaktiver(rad, tab)}
                        onClick={(e, tab) => this.handleValidateAndSave(e, tab)}
                        onChange={e => this.handleChange(e)}
                        onSelect={prosesscode => this.handleprocesscode(prosesscode)}
                        onSubmit={(e, rad, tab) => this.handleValidateAndChange(e, rad, tab)}
                        onNewScheme={date => this.handleNewScheme(date)}
                        onRemoveBulk={() => this.handleRemoveBulk()}
                        spinnerLoad={spinnerLoad}
                        addFlashMessage={this.props.addFlashMessage}
                    />
                </div>
            </div>
        );
    }
}

ContractDetailsContainer.defaultProps = {
    prosesscodes: [],
    contracts: [],
    prices: [],
    pricechanges: [],
    lastchanged: '',
    users: [],
    prosesskodeenhent: [],
};

ContractDetailsContainer.contextTypes = {
    router: PropTypes.object,
};

ContractDetailsContainer.propTypes = {
    params: PropTypes.shape({
        selskapsId: PropTypes.string.isRequired,
        kontraktsId: PropTypes.string.isRequired,
    }).isRequired,
    lastChangedDate: PropTypes.string,
    contracts: PropTypes.array.isRequired,
    updateKontrakt: PropTypes.func.isRequired,
    prosesscodes: PropTypes.array.isRequired,
    loadProcesscodes: PropTypes.func.isRequired,
    contractId: PropTypes.string.isRequired,
    loadingContracts: PropTypes.bool.isRequired,
    loadCompanySpecificContracts: PropTypes.func.isRequired,
    loadPrices: PropTypes.func.isRequired,
    prices: PropTypes.array.isRequired,
    createPrice: PropTypes.func.isRequired,
    createPriceChange: PropTypes.func.isRequired,
    createBulkUpdate: PropTypes.func.isRequired,
    deleteBulkUpdate: PropTypes.func.isRequired,
    deaktiverPrice: PropTypes.func.isRequired,
    deaktiverPriceChange: PropTypes.func.isRequired,
    updatePrice: PropTypes.func.isRequired,
    updatePriceChange: PropTypes.func.isRequired,
    loadBulkUpdates: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
    loadingBulkupdates: PropTypes.bool.isRequired,
    bulkupdates: PropTypes.array.isRequired,
    loadPriceChanges: PropTypes.func.isRequired,
    pricechanges: PropTypes.array.isRequired,
    loadContractSistEndret: PropTypes.func.isRequired,
    lastchanged: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    loadUsers: PropTypes.func.isRequired,
    prosesskodeenhent: PropTypes.array.isRequired,
    getProcesscodeUnits: PropTypes.func.isRequired,
    rapporter: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        contractId: ownProps.params.kontraktsId,
        contracts: state.contracts,
        prosesscodes: state.processCodes,
        loadingContracts: state.contracts === null || state.contracts.length === 0,
        prices: state.prices,
        loadingBulkupdates: state.bulkupdates === null || state.bulkupdates.length === 0,
        bulkupdates: state.bulkupdates,
        pricechanges: state.pricechanges,
        lastchanged: state.lastChanged,
        users: state.users.userList.users,
        prosesskodeenhent: state.ressursBruk.processcodeunit,
        rapporter: state.rapporter,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            loadContractSistEndret,
            loadProcesscodes,
            loadCompanySpecificContracts,
            loadPrices,
            createPrice,
            createPriceChange,
            createBulkUpdate,
            deleteBulkUpdate,
            deaktiverPrice,
            deaktiverPriceChange,
            updatePrice,
            updatePriceChange,
            loadBulkUpdates,
            loadPriceChanges,
            addFlashMessage,
            loadUsers,
            getProcesscodeUnits,
            updateKontrakt,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetailsContainer);
