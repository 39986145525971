import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap';
import DropDownList from '../common/DropDownList';

class ProsessCodeAdd extends Component {
    focus = () => {
        ReactDOM.findDOMNode(this.input).focus();
        this.setState({ editing: true, error: '', ignoreCancelOnce: true });
    };

    render() {
        const { onChangeKode, onChangeBeskrivelse, changeUnit, selected, enheter, error, validateAndSave } = this.props;
        return (
            <div className="add-processcode">
                <Row className="show-grid">
                    <Col md={2}>
                        <p>
                            <strong>Prosesskode</strong>
                        </p>
                    </Col>
                    <Col md={3}>
                        <p>
                            <strong>Beskrivelse</strong>
                        </p>
                    </Col>
                    <Col md={2}>Enhet</Col>
                    <Col md={1} />
                </Row>
                <Row className="show-grid">
                    <Col md={2}>
                        <FormGroup>
                            <FormControl
                                type="text"
                                placeholder="Kode"
                                id="codeinput"
                                onChange={onChangeKode}
                                ref={r => (this.input = r)}
                                autoFocus
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <FormControl
                                type="text"
                                placeholder="Beskrivelse"
                                id="descriptioninput"
                                onChange={onChangeBeskrivelse}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <DropDownList
                            Items={enheter}
                            title="Velg en enhet"
                            onChange={changeUnit}
                            id="Dropdownlist"
                            error={error}
                            selected={selected}
                        />
                    </Col>
                    <Col md={1}>
                        <Button
                            bsStyle="success"
                            onClick={e => {
                                validateAndSave(e);
                                this.focus();
                            }}
                            id="add-process-btn"
                        >
                            Legg til
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

ProsessCodeAdd.propTypes = {
    onChangeKode: PropTypes.func.isRequired,
    onChangeBeskrivelse: PropTypes.func.isRequired,
    changeUnit: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    validateAndSave: PropTypes.func.isRequired,
    enheter: PropTypes.array,
    error: PropTypes.string,
};

export default ProsessCodeAdd;
