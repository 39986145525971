import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import PriceSchemeListRow from './PriceSchemeListRow';
import { Row, Col, FormControl, FormGroup, HelpBlock, Button } from 'react-bootstrap';
import _ from 'lodash';
import '../../css/Contract.css';
import Spinner from 'react-spinkit';

class PriceSchemeList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            selectdrop: 'Velg prosesskode',
            initialselect: true,
            price: '',
            priceState: null,
            codeState: null,
            initialprice: true,
            beskrivelse: '',
            initialbeskrivelse: true,
            enhetNavn: '',
            enhetId: 0,
            initialenhet: true,
        };
        this.submitCreatePrice = this.submitCreatePrice.bind(this);
        this.focus = this.focus.bind(this);
    }

    cleanUp() {
        this.setState({
            selectdrop: 'Velg prosesskode',
            price: '',
            beskrivelse: '',
            enhetNavn: '',
            enhetId: 0,
            initialenhet: true,
            initialselect: true,
            initialprice: true,
        });
    }

    submitCreatePrice(event) {
        if (!this.codeIsValid()) {
            this.props.addFlashMessage({
                text: 'Prosesskode mangler. Kode ble ikke lagret',
                type: 'error',
            });
            this.setState({ codeState: 'error' });
        } else if (!this.priceIsValid()) {
            this.setState({ priceState: 'error' });
            this.props.addFlashMessage({
                text: 'Pris mangler. Kode ble ikke lagret',
                type: 'error',
            });
        } else {
            this.props.onClick(event);
            this.cleanUp();
        }
    }

    codeIsValid() {
        let b = this.state.selectdrop !== 'Velg prosesskode';
        return b;
    }

    // Eventhandler Med select options
    handleprocesscode(e) {
        if (e.target.value === '0') return;
        this.setState({ selectdrop: e.target.value, initialselect: false });

        let prosesscode = this.props.prosesscodes.find(pcode => pcode.id === e.target.value);
        this.setState({
            beskrivelse: prosesscode.beskrivelse,
            initialbeskrivelse: false,
            enhetNavn: prosesscode.prosesskodeEnhetNavn,
            enhetId: prosesscode.prosesskodeEnhetId,
            initialenhet: false,
        });

        this.props.onSelect(prosesscode);
    }

    handleprice(e) {
        e.target.value = e.target.value.replace(/,/g, '.');
        e.target.value = e.target.value.replace(/\s/g, '');
        this.setState({
            price: e.target.value,
            priceState: this.priceIsValidByPrice(e.target.value) ? null : 'error',
            initialprice: false,
        });
        this.props.onChange(e);
    }

    priceIsValidByPrice(price) {
        price = price.replace(/,/g, '.');
        price = price.replace(/\s/g, '');

        return price > 0 && price !== null && price !== '';
    }

    priceIsValid() {
        this.state.price = this.state.price.replace(/,/g, '.');
        this.state.price = this.state.price.replace(/\s/g, '');

        return this.state.price > 0 && this.state.price !== null && this.state.price !== '';
    }

    renderRemainedProcessCodes() {
        let processcode = this.props.prosesscodes;
        let { pricescheme } = this.props;
        let pids = _.map(pricescheme, 'prosesskodeid');
        let prices = _.filter(processcode, item => {
            return !_.includes(pids, item.id);
        });
        return prices || [];
    }

    focus() {
        ReactDOM.findDOMNode(this.input).focus();
        this.setState({ editing: true, error: '', ignoreCancelOnce: true });
    }

    render() {
        const { priceState, codeState } = this.state;
        let { spinnerLoad } = this.props;

        const sortProcessCodes = (processCodes, sortField) => {
            return processCodes.sort((a, b) => {
                if (a[sortField]) {
                    return a[sortField].localeCompare(b[sortField], undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }
                return -1;
            });
        };

        const remainedprocesscode = sortProcessCodes(this.renderRemainedProcessCodes(), 'navn');
        const size = _.size(remainedprocesscode);
        const pricescheme = sortProcessCodes(this.props.pricescheme, 'prosesskode');
        const TopLevel_1 = (
            <div>
                <Row>
                    <Col md={12}>
                        <span className="indent">
                            <strong>Sist endret</strong>
                        </span>
                    </Col>
                </Row>
                <div className="gap-5" />
                <Row>
                    <Col md={12}>
                        <span className="indent">{this.props.lastChanged}</span>
                    </Col>
                </Row>
            </div>
        );

        const TopLevel_2 = (
            <div>
                <Row>
                    <Col md={12}>
                        <span className="indent">
                            <strong>Gyldig fra</strong>
                        </span>
                    </Col>
                </Row>
                <div className="gap-5" />
                <Row>
                    <Col md={12}>
                        <span className="indent">{this.props.lastChanged}</span>
                    </Col>
                </Row>
            </div>
        );

        return (
            <div>
                <div className="gap-20" />
                {this.props.tabNo === 1 && TopLevel_1}
                {this.props.tabNo === 2 && TopLevel_2}

                <div className="gap-20" />
                <div>
                    <center>
                        {spinnerLoad && (
                            <Spinner
                                spinnerName="circle"
                                className="spinner-circle-div"
                                style={{ width: '75px', height: '75px' }}
                            />
                        )}
                    </center>
                </div>
                <table className="table table-fixed processcode-table">
                    <thead>
                        <tr>
                            <th>Prosesskode</th>
                            <th>Beskrivelse</th>
                            <th>Enhet</th>
                            <th>Pris</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {size > 0 && (
                            <tr id="addProsessCode">
                                <td>
                                    <FormGroup controlId="formControlsSelect" validationState={codeState}>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            value={this.state.selectdrop}
                                            onChange={e => {
                                                this.handleprocesscode(e);
                                                this.focus();
                                            }}
                                        >
                                            <option key={0} value={0}>
                                                Velg Prosesskode
                                            </option>
                                            {remainedprocesscode.map(prosesscode => (
                                                <option key={prosesscode.id} value={prosesscode.id}>
                                                    {prosesscode.navn}
                                                </option>
                                            ))}
                                        </FormControl>
                                        {!this.codeIsValid() && !this.state.initialselect && (
                                            <HelpBlock>Prosesskode må være valgt</HelpBlock>
                                        )}
                                    </FormGroup>
                                </td>

                                <td>
                                    <FormControl
                                        type="text"
                                        id={'beskrivelse' + this.props.tabNo}
                                        value={this.state.beskrivelse}
                                        readOnly
                                        onChange={e => this.props.onChange(e)}
                                    />
                                </td>

                                <td>
                                    <FormControl
                                        type="text"
                                        id={'enhet' + this.props.tabNo}
                                        value={this.state.enhetNavn}
                                        readOnly
                                        onChange={e => this.props.onChange(e)}
                                    />
                                </td>
                                <td>
                                    <center> kr</center>
                                </td>
                                <td>
                                    <FormGroup controlId="formControlsPris" validationState={priceState}>
                                        <FormControl
                                            type="text"
                                            onChange={e => this.handleprice(e)}
                                            value={this.state.price}
                                            ref={r => (this.input = r)}
                                        />
                                        {!this.priceIsValid() && !this.state.initialprice && (
                                            <HelpBlock>Angi et positivt tall</HelpBlock>
                                        )}
                                    </FormGroup>
                                </td>

                                <td>
                                    <Button bsStyle="success" onClick={this.submitCreatePrice} id="add-process-btn">
                                        Legg til
                                    </Button>
                                </td>
                            </tr>
                        )}
                        {pricescheme.map(rad => (
                            <PriceSchemeListRow
                                key={rad.id}
                                rad={rad}
                                onClick={() => this.props.deAktiver(rad)}
                                onSubmit={e => this.props.onSubmit(e, rad)}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

PriceSchemeList.propTypes = {
    pricescheme: PropTypes.array.isRequired,
    lastChanged: PropTypes.string,
    prosesscodes: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    deAktiver: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    tabNo: PropTypes.number.isRequired,
    spinnerLoad: PropTypes.bool,
    addFlashMessage: PropTypes.func.isRequired,
};

export default PriceSchemeList;
