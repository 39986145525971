import delay from './delay';

const entreprenorer = [
    {
        id: "1",
        name: "AsfaltSelskap",
        orgnumber: "111222333",
        phonenumber: "99887665",
        email: "post@asfaltselskap.no"
    },
    {
        id: "2",
        name: "BetongSelskap",
        orgnumber: "333222111",
        phonenumber: "99966666",
        email: "post@betongselskap.no"
    },
    {
        id: "3",
        name: "MåkeSelskap",
        orgnumber: "999888777",
        phonenumber: "99955555",
        email: "post@måkeselskap.no"
    }
];

const saksbehandlere = [
    {
        id: "4",
        name: "ServiceAvdelingen",
        orgnumber: "999888333",
        phonenumber: "99955333",
        email: "post@bym.serviceavdelingen.no"
    },
    {
        id: "5",
        name: "Teknisk drift",
        orgnumber: "999888113",
        phonenumber: "77755333",
        email: "post@bym.tekniskdrift.no"
    }
];

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (selskap) => {
    return replaceAll(selskap.navn, ' ', '-');
};

class SelskaperApi {
    static getAllSelskaper(selskapsType) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (selskapsType === 3)
                    resolve(entreprenorer);
                else if (selskapsType === 1)
                    resolve(saksbehandlere);
                else
                    throw new Error("No mock data exists for selskapstype " + selskapsType);
            }, delay);
        });
    }

    static saveSelskap(selskap) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Simulate server-side validation
                const minEntrepreneurTitleLength = 1;
                if (selskap.navn.length < minEntrepreneurTitleLength) {
                    reject(`Name must be at least ${minEntrepreneurTitleLength} characters.`);
                }

                //Just simulating creation here.
                //Cloning so copy returned is passed by value rather than by reference.
                selskap.id = generateId(selskap);
                entreprenorer.push(selskap);

                resolve(Object.assign({}, selskap));
            }, delay);
        });
    }

    static deleteEntrepreneur(selskapId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const indexOfEntrepreneurToDelete = entreprenorer.findIndex(entrepreneur => {
                    return entrepreneur.entrepreneurId === selskapId;
                });
                entreprenorer.splice(indexOfEntrepreneurToDelete, 1);
                resolve();
            }, delay);
        });
    }
}

export default SelskaperApi;
