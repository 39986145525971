import React, { useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import { Panel, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-oidc-context';
import LoginButton from './LoginButton';
import { harEnAvRoller, harRolle } from '../../constants/brukerRoller';
import * as globals from '../../constants/Globals';
import * as routes from '../../constants/clientRoutes';
import { oidcConfig } from '../../oidcConfig.ts';

class Login extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const auth = this.props.auth;
        if (!auth && auth?.isLoading) {
            return <div>Vennligst vent...</div>;
        }

        if (auth && auth.isAuthenticated) {
            // bruker er innlogget, redirect basert på rolle
            if (harRolle(globals.bymeldingAdmin)) {
                // return this.context.router.push(routes.serviceAvdelingen);
                window.location.href = routes.serviceAvdelingen;
                return;
            }

            if (harEnAvRoller([globals.serviceAvdelingAdmin, globals.serviceavdeling])) {
                // return this.context.router.push(routes.saksbehandlerMeldinger);
                window.location.href = routes.saksbehandlerMeldinger;
                return;
            }

            if (harEnAvRoller([globals.entrepreneurAdmin, globals.entrepreneurs])) {
                // return this.context.router.push(routes.entrepreneurMeldinger);
                window.location.href = routes.entrepreneurMeldinger;
                return;
            }
        }

        const logout = () => {
            // const auth = this.props.auth;
            // await auth.removeUser();
            // window.location.href = '/login'

            const authorityRootUrl = auth.settings.authority;

            const newWindow = window.open(
                authorityRootUrl + '/logout',
                '_blank',
                'noopener,noreferrer',
            );
            if (newWindow) newWindow.opener = null;
            window.location.href = '/login'
          
            //window.location.href = authorityRootUrl + '/logout';
        };

        const getLoginButton = () => {
            if (auth && !auth.isAuthenticated && window.location.search.indexOf('End-user+does+not+have+access+to+this+application') > 0) {
                // brukeren er innlogget, men med en bruker uten tilgang til Bymelding Client
                return (
                    <div>
                        Du har en pågående sesjon med en bruker som ikke har tilgang til denne tjenesten. <br /><br />
                        <button className="btn btn-success" onClick={logout}>
                            Logg inn med ny bruker
                        </button>
                    </div>);
            }

            return (
                <div className="pt-2">
                    <LoginButton />
                </div>
            )
        };

        return (
            <div className="Login_center_div responsive" id="LoginDiv">
                <Panel>
                    <Row>
                        <Col md={4} mdOffset={4}>
                            <h1 className="text-center col-md-11">
                                <strong>Bymelding Saksbehandling</strong>
                            </h1>
                            <div className="text-center col-md-11">
                                <br />
                                <br />
                                { getLoginButton() }
                            </div>
                        </Col>
                    </Row>
                </Panel>
            </div>
        );
    }
}

Login.defaultProps = {
    serviceinfo: {},
};
Login.propTypes = {
    serviceinfo: PropTypes.object.isRequired,
};
Login.contextTypes = {
    router: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        serviceinfo: state.common.serviceinfo,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(Login));
