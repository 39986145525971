import * as types from '../actions/ActionTypes';
//import initialState from './initialState';

const InitialState = [];

export default function pricechangesReducer(state = InitialState, action) {
  switch (action.type) {
    case types.LOAD_PRICECHANGES_SUCCESS:
      return action.pricechanges;

    case types.CREATE_PRICECHANGE_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.pricechange)
      ];

    case types.UPDATE_PRICECHANGE_SUCCESS: {
      const indexToRemove = state.findIndex(pricechange => pricechange.id === action.pricechange.id)
      return [
        ...state.slice(0, indexToRemove), Object.assign({}, action.pricechange),
        ...state.slice(indexToRemove + 1)
      ];
    }

    case types.DELETE_PRICECHANGE_SUCCESS:
      return [
        ...state.filter(pricechange => pricechange.id !== action.pricechange.id)
      ];

    default:
      return state;
  }
}
