import axios from 'axios';
import { User } from 'oidc-client-ts';
import { oidcConfig } from '../oidcConfig.ts';

function getHostFromEnv(url_name) {
    const key = 'REACT_APP_' + url_name;
    const host = window[key] || process.env[key];
    if (host != null) return host;
    throw new Error(`Missing environment configuration with key '${key}'`);
}

export const getClient = () => {
    const instance = axios.create();

    const oidcStorage = localStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
    const token = oidcStorage ? User.fromStorageString(oidcStorage) : undefined;
    
    instance.defaults.headers['X-Bymelding-Client'] = 'BymeldingClient_1.0:1.0';
    instance.defaults.headers.common['Authorization'] = `Bearer ${token?.access_token}`;

    return instance;
};

export const driftClientUrl = getHostFromEnv('URL_DRIFT_CLIENT');

export const autentiseringServiceBaseUrl = getHostFromEnv('URL_AUTENTISERING_SERVICE');
export const bymeldingServiceBaseUrl = getHostFromEnv('URL_BYMELDING_SERVICE');

export const bymeldingRutingServiceBaseUrl = getHostFromEnv('URL_BYMELDING_RUTING_SERVICE');

export const bymeldingSokServiceBaseUrl = getHostFromEnv('URL_BYMELDING_SOK_SERVICE');
export const bymeldingPortalUrl = getHostFromEnv('URL_BYMELDING_PORTAL');
/** ********************************** Authentication ********************************** */
export const loginApi = function() {
    const uri = '/api/token';
    return autentiseringServiceBaseUrl + uri;
};
export const AuthenticationServiceInfo = bymeldingServiceBaseUrl + '/api/serviceinfo';
export const glemtPassword = `${autentiseringServiceBaseUrl}/api/glemtpassord`;
export const aktiverBruker = key => `${autentiseringServiceBaseUrl}/api/brukeraktiveringer/${key}/passord`;

/** ********************************** Geolokasjon ********************************** */
export const getBydel = (lat, lng) => bymeldingServiceBaseUrl + `/api/kart/feature/arcgis/${lng}/${lat}?layerId=2`;
export const getAdresseApi = (lat, lng) =>
    `${bymeldingServiceBaseUrl}/api/adresseoppslag?latitude=${lat}&longitude=${lng}`;

/** ********************************** serviceavdelinger ********************************** */
export const selskaperUrl = `${bymeldingServiceBaseUrl}/api/serviceavdelinger`;
export const selskaper = `${bymeldingServiceBaseUrl}/api/selskaper`;
export const selskapsTyperUrl = `${bymeldingServiceBaseUrl}/api/selskapstyper`;
export const kontrakterUrl = `${bymeldingServiceBaseUrl}/api/kontrakter`;
export const avtalerUrl = `${bymeldingServiceBaseUrl}/api/avtaler`;
export const bulkoppdateringerUrl = `${bymeldingServiceBaseUrl}/api/bulkoppdateringer`;
export const prosessKodeSkjemaerUrl = bymeldingServiceBaseUrl + '/api/prosesskodeskjemaer';
export const prosesskodeEnheterUrl = bymeldingServiceBaseUrl + '/api/prosesskodeEnheter';
export const prosesskoderUrl = `${bymeldingServiceBaseUrl}/api/prosesskoder`;
export const prisendringerUrl = `${bymeldingServiceBaseUrl}/api/prisendringer`;

// export const selskapsBrukere = function (selskapsId) {
//     let uri = "/api/selskaper/" + selskapsId + "/brukere";
//     return bymeldingServiceBaseUrl + uri;
// };
export const selskapsBrukere = bymeldingServiceBaseUrl + '/api/serviceavdelinger/brukere';

// brukes for alle brukeroperasjoner etter overgang til OneLogin
export const brukere = bymeldingServiceBaseUrl + '/api/brukere';
export const selskapsbrukere = bymeldingServiceBaseUrl + '/api/selskapsbrukere';

//= ================================= Entreprenorer ===================================
export const bronnoysundregistreneUrl = OrganisationNumber => {
    return 'https://data.brreg.no/enhetsregisteret/enhet/' + OrganisationNumber + '.json';
};
export const entreprenorerBaseUrl = `${bymeldingServiceBaseUrl}/api/entreprenorer`;
export const entreprenorerByselskapId = function(selskapId) {
    return entreprenorerBaseUrl + '/' + selskapId;
};
export const entreprenorBrukere = function(selskapsId) {
    const uri = `${selskapsbrukere}?selskapId=${selskapsId}`;
    return uri;
};

export const selskaperByType = function(selskapsType) {
    if (selskapsType === 1) return `${bymeldingServiceBaseUrl}/api/serviceavdelinger`;
    if (selskapsType === 3) return `${bymeldingServiceBaseUrl}/api/entreprenorer`;
    return selskapsTyperUrl + '/' + selskapsType + '/selskaper';
};

export const getEntrepreneurKontrakterUrl = selskapId => {
    return bymeldingServiceBaseUrl + '/api/entreprenorer/' + selskapId + '/kontrakter';
};
export const addEntrepreneurKontraktUrl = selskapId => {
    return bymeldingServiceBaseUrl + '/api/entreprenorer/' + selskapId + '/kontrakter';
};
export const updateEntrepreneurKontraktUrl = (selskapId, kontraktId) => {
    return `${bymeldingServiceBaseUrl}/api/entreprenorer/${selskapId}/kontrakter/${kontraktId}`;
};

export const getselskapsIdForUserUrl = brukerId => {
    return `${bymeldingServiceBaseUrl}/api/entreprenorer/bruker/${brukerId}/selskapid`;
};

export const getContractBySelskapsId = selskapsId => {
    return selskaper + '/' + selskapsId + '/kontrakter';
};
/** ********************************** WORM meldinger ********************************** */
export const getPdfMeldingByMeldingIdUrl = function(meldingArray) {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingArray;
};
export const getPdfMeldingByBrukerIdUrl = function(brukerId, sortBy, filterByFromDate) {
    const uri =
        '/api/wormbrukere/' +
        brukerId +
        '/wormmeldingermedkart?SortBy=' +
        sortBy +
        '&FilterByFromDate=' +
        filterByFromDate;
    return bymeldingServiceBaseUrl + uri;
};

/** ********************************** WORM meldinger ********************************** */
export const getWormMeldingByMeldingIdUrl = function(meldingId) {
    const uri = `/api/wormmeldingermedkart/${meldingId}`;
    return bymeldingServiceBaseUrl + uri;
};
export const getWormMeldingByBrukerIdUrl = function(brukerId, sortBy, filterByFromDate) {
    const uri =
        '/api/wormbrukere/' +
        brukerId +
        '/wormmeldingermedkart?SortBy=' +
        sortBy +
        '&FilterByFromDate=' +
        filterByFromDate;
    return bymeldingServiceBaseUrl + uri;
};
export const getWormMeddingKartByMeldingIdUrl = function(meldingId) {
    const uri = `/api/wormmeldingermedkart/${meldingId}`;
    return bymeldingServiceBaseUrl + uri;
};

export const getWormMeldingBildeUrl = function(ostUtm32, nordUtm32) {
    // let uri="/api/wormmeldinger/base64kartutm32/"+ostUtm32+"/"+nordUtm32
    const uri = `/api/wormmeldinger/base64kartutm32/${ostUtm32}/${nordUtm32}`;

    return bymeldingServiceBaseUrl + uri;
};

/** ********************************** meldinger  ********************* */
export const avtaler = `${bymeldingServiceBaseUrl}/api/avtaler`;

export const getAvtalerForEntrepreneurUrl = (selskapId, brukerId) => {
    return `${bymeldingServiceBaseUrl}/api/entreprenorer/${selskapId}/brukere/${brukerId}/avtaler`;
};
export const getAvtalerForEntrepreneurAdminUrl = selskapId => {
    return bymeldingServiceBaseUrl + '/api/entreprenorer/' + selskapId + '/avtaler';
};

export const updateAvtaleUrl = avtaleId => {
    return bymeldingServiceBaseUrl + '/api/avtaler/' + avtaleId;
};

export const meldingstyper = `${bymeldingServiceBaseUrl}/api/meldingstyper`;
export const meldingstyperSynligeForPublikum = soketekst =>
    `${bymeldingSokServiceBaseUrl}/sok/meldingstype/autocomplete?tekst=${soketekst}`;

export const meldingkatagorier = bymeldingServiceBaseUrl + '/api/meldingskategorier';
export const meldingkatagorigrupper = bymeldingServiceBaseUrl + '/api/meldingskategorigrupper';

export const meldingstatuser = `${bymeldingServiceBaseUrl}/api/meldingsstatuser`;
export const putMelidngstatusMal = meldingstatusid => {
    return `${bymeldingServiceBaseUrl}/api/meldingstatusmal/${meldingstatusid}`;
};

const includeInMeldinger = '?Include=kontrakt&Include=avtale&Include=selskap&Include=bilde&Include=dokumentasjon';

export const processcodeSkjemaPrisUrl = kontraktId => {
    return bymeldingServiceBaseUrl + '/api/kontrakter/' + kontraktId + '/priser';
};
export const meldingerForPdfUrl = meldingIds => {
    return bymeldingServiceBaseUrl + '/api/meldinger?Include=kartutsnitt&' + meldingIds;
};

export const pdfExportUrl = meldingId => {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingId + '/pdf';
};

export const bymeldingPdfExportUrl = meldingIds => {
    return bymeldingServiceBaseUrl + '/api/meldinger/pdf?' + meldingIds;
};

export const meldingerForServiceAvdelingUrl = `${selskaperUrl}/meldinger`;
export const getFilteredMeldingerForServiceavdelingUrl = filter =>
    `${bymeldingSokServiceBaseUrl}/sok/melding/serviceavdeling${filter ? `/?${filter}` : ''}`;
export const PutserviceAvdelingMeldingByIdUrl = meldingId => {
    return meldingerForServiceAvdelingUrl + '/' + meldingId;
};
export const serviceAvdelingMeldingByIdUrl = meldingId => {
    return meldingerForServiceAvdelingUrl + '/' + meldingId + includeInMeldinger;
};

export const routeMeldingerUrl = meldingId => {
    return bymeldingRutingServiceBaseUrl + '/api/melding/' + meldingId + '/tildel';
};

export const dokumentasjonMeldingerUrl = meldingId => {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingId + '/dokumentasjon';
};

export const meldingerForEntrepreneursUrl = selskapsId => {
    return entreprenorerBaseUrl + '/' + selskapsId + '/meldinger';
};
export const getFilteredMeldingerForEntrepreneurs = (selskapsId, filter) =>
    `${bymeldingSokServiceBaseUrl}/sok/melding/entreprenor/${selskapsId}${filter ? `/?${filter}` : ''}`;
// `${entreprenorerBaseUrl  }/${selskapsId}/meldinger/?${filter}`);
export const getEntrepreneursMeldingByIdUrl = (selskapsId, meldingId) => {
    return `${entreprenorerBaseUrl}/${selskapsId}/meldinger/${meldingId}${includeInMeldinger}`;
};
export const PutEntrepreneuMeldingByIdUrl = (selskapId, meldingId) => {
    return entreprenorerBaseUrl + '/' + selskapId + '/meldinger/' + meldingId;
};

export const PostNewMeldingUrl = `${bymeldingServiceBaseUrl}/api/meldinger`;

export const imageUrl = imageId => {
    let uri = '/api/bilder/' + imageId;
    return bymeldingServiceBaseUrl + uri;
};

export const imageThumbUrl = imageId => {
    let uri = '/api/bilder/' + imageId + '/thumb';
    return bymeldingServiceBaseUrl + uri;
};

// /api/dokumentasjon/\{dokumentasjonid\}/base64
export const dokumentUrl = docId => {
    let uri = `/api/dokumentasjon/${docId}/base64`;
    return bymeldingServiceBaseUrl + uri;
};
// /api/meldinger/\{meldingId\}/dokumentasjon/base64
export const dokumentasjonUrl = meldingId => {
    let uri = `/api/meldinger/${meldingId}/dokumentasjon/base64`;
    return bymeldingServiceBaseUrl + uri;
};

export const loadSavedMeldingSearchUrl = brukerId => {
    return bymeldingServiceBaseUrl + '/api/brukere/' + brukerId + '/meldingsfiltre';
};
export const SavedMeldingSearchUrl = brukerId => {
    return bymeldingServiceBaseUrl + '/api/brukere/' + brukerId + '/meldingsfiltre';
};

export const DeleteMeldingSearchUrl = (brukerId, searchId) => {
    return `${bymeldingServiceBaseUrl}/api/brukere/${brukerId}/meldingsfiltre/${searchId}`;
};

/** ********************************** ressursbruk for entreprenører  ********************* */
export const skiftUrl = `${bymeldingServiceBaseUrl}/api/skift`;

export const getRessursbrukListUrl = meldingId => {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingId + '/ressursforbruk';
};
export const addMeldingRessursbrukByIdUrl = meldingId => {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingId + '/ressursforbruk';
};
export const getRessursBrukById = ressursforbrukId => {
    return bymeldingServiceBaseUrl + '/api/ressursforbruk/' + ressursforbrukId;
};
export const updateMeldingRessursbrukByIdUrl = (meldingId, ressursbrukId) => {
    return `${bymeldingServiceBaseUrl}/api/meldinger/${meldingId}/ressursforbruk/${ressursbrukId}`;
};
export const deleteMeldingResourcebrukUrl = ressursforbrukId => {
    return bymeldingServiceBaseUrl + '/api/ressursforbruk/' + ressursforbrukId;
};

export const getkontraktByAvtaleIdUrl = avtaleId => {
    return bymeldingServiceBaseUrl + '/api/avtaler/' + avtaleId + '/kontrakt';
};
export const getPriserByKontraktIdUrl = kontraktId => {
    return bymeldingServiceBaseUrl + '/api/kontrakter/' + kontraktId + '/priser';
};
export const GlobalRessursbrukUrl = kontraktId => {
    return `${bymeldingServiceBaseUrl}/api/kontrakter/${kontraktId}/ressursforbruk`;
};

export const GlobalFilteredRessursbrukUrl = (kontraktId, filter) => {
    let url = `${bymeldingServiceBaseUrl}/api/kontrakter/${kontraktId}/ressursforbruk`;
    return url + '/?' + filter;
};
export const UpdateGlobalRessursbrukUrl = (kontraktId, ressursbrukId) => {
    return `${bymeldingServiceBaseUrl}/api/kontrakter/${kontraktId}/ressursforbruk/${ressursbrukId}`;
};

/** ********************************** kommentar & historisk & tilbakemeldin for meldinger  ********************* */
export const getKommentarerForMeldingUrl = meldingId => {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingId + '/kommentarer';
};
export const postKommentarerForMeldingUrl = meldingId => {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingId + '/kommentarer';
};
export const getHistoriskForMeldingUrl = meldingId => {
    return bymeldingServiceBaseUrl + '/api/meldinger/' + meldingId + '/historikk';
};
export const getTilbakemeldingerForMeldingUrl = meldingId => {
    return `${bymeldingServiceBaseUrl}/api/meldinger/${meldingId}/tilbakemeldinger`;
};
export const postTilbakemeldingerForMeldingUrl = meldingId => {
    return `${bymeldingServiceBaseUrl}/api/meldinger/${meldingId}/tilbakemeldinger`;
};

/** ********************************** Rapporter av meldinger ********************* */

export const GetRapportByContractIdUrl = contractId => {
    return bymeldingServiceBaseUrl + `/api/kontrakter/${contractId}/fakturagrunnlag`;
};

/** ********************************** Rapporter av meldinger ********************* */
export const GetRessursforbrukRapportByContractIdUrl = `${bymeldingServiceBaseUrl}/api/rapporter/ressursforbrukrapport`;
export const GetRessursforbrukRapportByContractIdUrlOld =
    bymeldingServiceBaseUrl + '/api/rapporter/old/ressursforbrukrapport';
export const GetFakturagrunnlagRapportByContractIdUrl = bymeldingServiceBaseUrl + '/api/rapporter/fakturagrunnlag';
export const GetFakturagrunnlagRapportByContractIdUrlOld =
    bymeldingServiceBaseUrl + '/api/rapporter/old/fakturagrunnlag';
export const GetVinterForbrukGrunnlagRapportUrl =
    bymeldingServiceBaseUrl + '/api/rapporter/vinterforbrukfakturagrunnlag';
export const GetVinterForbrukGrunnlagRapportUrlOld =
    bymeldingServiceBaseUrl + '/api/rapporter/old/vinterforbrukfakturagrunnlag';
export const GetAvvisteMeldingerRapportForServiceAvdelingUrl = `${bymeldingServiceBaseUrl}/api/rapporter/avvistemeldingerrapport`;
export const GetAvvisteMeldingerRapportForServiceAvdelingUrlOld = `${bymeldingServiceBaseUrl}/api/rapporter/old/avvistemeldingerrapport`;
export const GetInnmeldingerRapportForServiceAvdelingUrl = `${bymeldingServiceBaseUrl}/api/rapporter/innmeldingerrapport`;
export const GetInnmeldingerRapportForServiceAvdelingUrlOld =
    bymeldingServiceBaseUrl + '/api/rapporter/old/innmeldingerrapport';
export const GetMeldingsstatistikkRapportUrl = bymeldingServiceBaseUrl + '/api/rapporter/meldingsstatistikkrapport';
export const GetMonedsrapportmateriellrapportUrl = bymeldingServiceBaseUrl + '/api/rapporter/materiellrapport';
export const GetMonedsrapportavviksarbeidrapportUrl = `${bymeldingServiceBaseUrl}/api/rapporter/avviksarbeidrapport`;
export const GetGatelysMonedsrapportUrl = `${bymeldingServiceBaseUrl}/api/rapporter/gatelysmonedsrapporter`;
export const GetVinterForbrukOkonomiRapportUrl = bymeldingServiceBaseUrl + '/api/rapporter/vinterforbrukokonomirapport';
export const GetDagmulktRapportUrl = bymeldingServiceBaseUrl + '/api/rapporter/dagmulktrapport';

export const GetBymeldingServiceHealthCheckUrl = `${bymeldingServiceBaseUrl}`;
export const GetAutentiseringServiceHealthCheckUrl = `${bymeldingServiceBaseUrl}`;

/** ********************* Delbydeler  ********************************* */
export const delbydelerUrl = `${bymeldingServiceBaseUrl}/api/delbydeler`;

/** ********************* Feilretting  ********************************* */
export const feilrettingskoder = `${bymeldingServiceBaseUrl}/api/feilretting/koder`;

const feilrettingskodeForMelding = meldingId => `${bymeldingServiceBaseUrl}/api/meldinger/${meldingId}/feilretting`;
export const PutFeilrettingskodeForMelding = (meldingId, feilrettingskodeId) => {
    return feilrettingskodeForMelding(meldingId);
};

/** ********************* Varsel  ********************************* */
const notificationService = `${getHostFromEnv('URL_BYMELDING_NOTIFICATION_SERVICE')}/api`;

export const getNotificationServiceVarselData = klientKey => {
    return `${notificationService}/varsel?klientKey=${klientKey}`;
};
