import _ from 'lodash';
import AuthorizationHandler from './AuthorizationHandler';
import * as G from '../constants/Globals';
import * as cookieHandler from '../utils/cookieHandler';

export const allusers = AuthorizationHandler([]);
export const AllAdmins = AuthorizationHandler([
  G.bymeldingAdmin,
  G.entrepreneurAdmin,
  G.serviceAvdelingAdmin,
]);
export const bymeldingAdminAndEntrepreneurAdmin = AuthorizationHandler([
  G.bymeldingAdmin,
  G.entrepreneurAdmin,
]);
export const bymeldingAdminAndServiceAvdelingAdmin = AuthorizationHandler([
  G.bymeldingAdmin,
  G.serviceAvdelingAdmin,
]);
export const EntreprenørAndEntrepreneurAdmin = AuthorizationHandler([
  G.entrepreneurs,
  G.entrepreneurAdmin,
]);
export const bymeldingAdmin = AuthorizationHandler([G.bymeldingAdmin]);
export const serviceavdelingAdmin = AuthorizationHandler([
  G.serviceAvdelingAdmin,
]);
export const serviceavdeling = AuthorizationHandler([G.serviceavdeling]);
export const entrepreneurAdmin = AuthorizationHandler([G.entrepreneurAdmin]);
export const entrepreneurUsers = AuthorizationHandler([G.entrepreneurs]);
//const driftandforvalting=AuthorizationHandler([''])

export const wormMeldinger = AuthorizationHandler([
  G.bymeldingAdmin,
  G.entrepreneurAdmin,
  G.entrepreneurs,
  G.serviceAvdelingAdmin,
  G.serviceavdeling,
]);
export const saksbehandlerMeldingsDetail = AuthorizationHandler([
  G.serviceAvdelingAdmin,
  G.serviceavdeling,
]);
export const saksbehandlerMeldinger = AuthorizationHandler([
  G.serviceAvdelingAdmin,
  G.serviceavdeling,
]);
export const bymiljoetaten = AuthorizationHandler([
  G.bymeldingAdmin,
  G.serviceAvdelingAdmin,
]);
export const entrepreneurs = AuthorizationHandler([G.bymeldingAdmin]);
export const entrepreneurMeldinger = AuthorizationHandler([
  G.entrepreneurs,
  G.entrepreneurAdmin,
]);
export const entrepreneurMeldingsDetail = AuthorizationHandler([
  G.entrepreneurs,
  G.entrepreneurAdmin,
]);
export const globalressursbruk = AuthorizationHandler([G.entrepreneurAdmin]);
export const selskapKontrakterDetail = AuthorizationHandler([
  G.entrepreneurAdmin,
]);
export const selskapKontrakPriser = AuthorizationHandler([G.entrepreneurAdmin]);
export const entrepreneurDetails = AuthorizationHandler([G.bymeldingAdmin]);
export const avtaler = AuthorizationHandler([G.bymeldingAdmin]);
export const processcodes = AuthorizationHandler([G.bymeldingAdmin]);
export const processcodeDetails = AuthorizationHandler([G.bymeldingAdmin]);
export const contractDetails = AuthorizationHandler([G.bymeldingAdmin]);
export const selskapBrukere = AuthorizationHandler([G.entrepreneurAdmin]);
export const rapporter = AuthorizationHandler([G.serviceAvdelingAdmin]);

export const userIsServiceavdeling = () => {
  const roles = cookieHandler.UsersRoleAndProcessroleRoles();
  const allowedRoles = [G.serviceAvdelingAdmin, G.serviceavdeling];
  return _.intersection(allowedRoles, roles).length > 0;
};

export const userIsEntrepreneur = () => {
  const roles = cookieHandler.UsersRoleAndProcessroleRoles();
  const allowedRoles = [G.entrepreneurs];
  return _.intersection(allowedRoles, roles).length > 0;
};
export const userIsEntrepreneurAdamin = () => {
  const roles = cookieHandler.UsersRoleAndProcessroleRoles();
  const allowedRoles = [G.entrepreneurAdmin];
  return _.intersection(allowedRoles, roles).length > 0;
};

export const userHasAccessWithRole = queyRoles => {
  const roles = cookieHandler.UsersRoleAndProcessroleRoles();
  const allowedRoles = [...queyRoles];
  return _.intersection(allowedRoles, roles).length > 0;
};
