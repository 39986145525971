import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HealtchCheck extends Component {
    static propTypes = {
        servicesstatus: PropTypes.any.isRequired,
    };
    render() {
        return (
            <div className="Login_center_div">
                <div className="text-center">
                    <center>
                        <h1>Uff da ...</h1>
                        <h3> Tjenesten er dessverre ikke tilgjengelig for øyeblikket. :-( </h3>
                    </center>
                </div>
            </div>
        );
    }
}

export default HealtchCheck;
