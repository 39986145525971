/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Button, Glyphicon } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import '../../css/Ressursbruk/RessursbrukFilters.css';
import ListSortBtn from './ListSortBtn';
import './RessursbrukList.css';
import { GlobalRessursforbruk } from '../../constants/Globals';
import RessursbrukRow from './RessursbrukRow';
import { userIsEntrepreneurAdamin } from '../../utils/Authorization';

class RessursbrukList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleRow: null,
      sortByField: 'Dato',
      sortDirection: 'desc',
      currentPage: 0,
      recordsPerPage: 20,
      paginationActive: false,
      numberOfPages: 0,
      hidePrice: !userIsEntrepreneurAdamin(),
    };
  }

  componentDidUpdate = prevProps => {
    const { recordsPerPage, currentPage } = this.state;
    const { resourcebrukList } = this.props;
    if (prevProps.resourcebrukList.length !== resourcebrukList.length) {
      const paginationActive = resourcebrukList.length > recordsPerPage;
      const numberOfPages = Math.ceil(resourcebrukList.length / recordsPerPage);
      const page =
        numberOfPages !== 0 && numberOfPages - 1 <= currentPage
          ? numberOfPages - 1
          : currentPage;
      this.setState({
        paginationActive,
        numberOfPages,
        currentPage: page,
      });
    }
  };

  setSortBy = newSortByField => {
    const { sortByField, sortDirection } = this.state;
    if (newSortByField === sortByField) {
      this.setState({
        sortDirection: sortDirection === 'desc' ? 'asc' : 'desc',
        currentPage: 0,
      });
    } else {
      this.setState({
        sortDirection: 'desc',
        sortByField: newSortByField,
        currentPage: 0,
      });
    }
  };

  getSkiftText = (skiftItems, SkiftId) => {
    return skiftItems && SkiftId && skiftItems[SkiftId]
      ? skiftItems[SkiftId].navn
      : '';
  };

  toggle = index => {
    this.setState({
      toggleRow: this.state.toggleRow === index ? null : index,
    });
  };

  remove = (e, i) => {
    const { onRemoveRessursbruk } = this.props;
    onRemoveRessursbruk(e, i.ressursforbrukId);
  };

  sortedList = () => {
    const { resourcebrukList } = this.props;
    const { sortByField, sortDirection } = this.state;

    return orderBy(resourcebrukList, [sortByField, 'Dato'], [sortDirection]);
  };

  render() {
    const {
      typeOfRessursList,
      // resourcebrukList,
      onSubmitEditableDate,
      onSubmitTid,
      onSubmitEditableComment,
      onSubmitEditableEnhent,
      sum,
      editable,
    } = this.props;
    const {
      sortByField,
      sortDirection,
      toggleRow,
      currentPage,
      recordsPerPage,
      paginationActive,
      numberOfPages,
      hidePrice,
    } = this.state;

    const paginatedList = this.sortedList().slice(
      currentPage * recordsPerPage,
      currentPage * recordsPerPage + recordsPerPage
    );

    // if (currentPage !== 0 && numberOfPages <= currentPage) {
    //     this.setState({
    //         currentPage: numberOfPages,
    //     });
    // }
    return (
      <Table id="ressursbruktable" responsive condensed striped={false}>
        <thead>
          <tr className="ressursbruk-header-row">
            <th />
            <th colSpan={2}>
              <ListSortBtn
                onClick={this.setSortBy}
                sortDirection={sortDirection}
                active={sortByField === 'Dato'}
                sortByField="Dato"
                label="Dato"
              />
            </th>
            <th className="ressursbruk-header-th">
              <ListSortBtn
                onClick={this.setSortBy}
                sortDirection={sortDirection}
                active={sortByField === 'prosesskodeNavn'}
                sortByField="prosesskodeNavn"
                label="Prosesskode"
              />
            </th>
            <th colSpan={2}>
              <ListSortBtn
                onClick={this.setSortBy}
                sortDirection={sortDirection}
                active={sortByField === 'prosesskodeEnhetsVerdi'}
                sortByField="prosesskodeEnhetsVerdi"
                label="Mengde"
              />
            </th>
            {/* <th>Enhet</th> */}
            {!hidePrice && (
              <th className="ressursbruk-td-pris">
                <ListSortBtn
                  onClick={this.setSortBy}
                  sortDirection={sortDirection}
                  active={sortByField === 'calculatedPrice'}
                  sortByField="calculatedPrice"
                  label="Pris"
                />
              </th>
            )}
            <th />
          </tr>
          {paginationActive && (
            <tr className="ressursbruk-header-pagination-row">
              <th colSpan={8}>
                <div className="ressursbruk-header-pagination-cell">
                  <Button
                    disabled={currentPage <= 0}
                    className="ressursbruk-header-pagination-cell-btn"
                    onClick={() =>
                      this.setState({
                        currentPage: currentPage - 1,
                      })
                    }
                  >
                    <Glyphicon glyph="menu-left" />
                    Forrige
                  </Button>
                  {`Side ${currentPage + 1} av ${numberOfPages}`}
                  <Button
                    disabled={currentPage + 1 >= numberOfPages}
                    className="ressursbruk-header-pagination-cell-btn"
                    onClick={() =>
                      this.setState({
                        currentPage: currentPage + 1,
                      })
                    }
                  >
                    Neste <Glyphicon glyph="menu-right" />
                  </Button>
                </div>
              </th>
            </tr>
          )}
          {/* {children} */}
        </thead>
        {/* <tbody> */}
        {_.map(paginatedList, i => {
          const isToggled = i.ressursforbrukId === toggleRow;
          let isEditable;

          if (typeOfRessursList && typeOfRessursList === GlobalRessursforbruk) {
            isEditable = editable && i.erGlobaltRessursforbruk;
          } else {
            isEditable = editable;
          }

          return (
            <RessursbrukRow
              hidePrice={hidePrice}
              ressursbruk={i}
              isEditable={isEditable}
              onSubmitEditableComment={onSubmitEditableComment}
              onSubmitEditableDate={onSubmitEditableDate}
              onSubmitEditableEnhent={onSubmitEditableEnhent}
              onSubmitTid={onSubmitTid}
              isToggled={isToggled}
              toggle={this.toggle}
              remove={this.remove}
            />
          );
        })}
        {/* </tbody> */}
        {!hidePrice && (
          <tfoot>
            <tr>
              <td colSpan={7} className="sumRow ressursbruk-td-pris">
                SUM&nbsp;&nbsp;kr
                <span>
                  {sum &&
                    Number(sum).toLocaleString('nb-NO', {
                      minimumFractionDigits: 2,
                    })}
                </span>
              </td>
              <th className="sumRow" />
            </tr>
          </tfoot>
        )}
      </Table>
    );
  }
}

RessursbrukList.propTypes = {
  editable: PropTypes.bool.isRequired,
  typeOfRessursList: PropTypes.string.isRequired,
  sum: PropTypes.string.isRequired,
  onSubmitEditableDate: PropTypes.func.isRequired,
  onSubmitTid: PropTypes.func.isRequired,
  onSubmitEditableComment: PropTypes.func.isRequired,
  onSubmitEditableEnhent: PropTypes.func.isRequired,
};

export default RessursbrukList;
